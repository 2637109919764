(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('LocationInfoTabController', LocationInfoTabController);

    LocationInfoTabController.$inject = [
        '$q',
        '$timeout',
        '$scope',
        '$stateParams',
        '$rootScope',
        '$kWindow',
        'authService',
        'locationsService',
        'containerService',
        'fractionsUtility',
        'formattingUtility',
        'externalSystemUtility',
        'formTypes',
        'containerEditModalWidth',
        'selectDisplayedDevices',
        'deviceUtility',
        'mapService',
        '$translate',
        'defaultSensorHeight'
    ];

    function LocationInfoTabController(
        $q,
        $timeout,
        $scope,
        $stateParams,
        $rootScope,
        $kWindow,
        authService,
        locationsService,
        containerService,
        fractionsUtility,
        formattingUtility,
        externalSystemUtility,
        formTypes,
        containerEditModalWidth,
        selectDisplayedDevices,
        deviceUtility,
        mapService,
        $translate,
        defaultSensorHeight
    ) {
        var locationId = $stateParams.locationId;
        var unwatchLocationChanged = null;

        $scope.tabStripOptions = {
            animation: false,
            scrollable: false
        };

        $scope.savePosition = savePosition;
        $scope.resetPosition = resetPosition;
        $scope.openUpdateContainerModal = openUpdateContainerModal;
        $scope.openNewContainerModal = openNewContainerModal;
        $scope.containerRowPaddingLength = containerRowPaddingLength;
        $scope.deleteContainer = deleteContainer;
        $scope.openPlotModal = openPlotModal;
        $scope.openNewLogModal = openNewLogModal;

        $scope.position = {};
        $scope.containerInfoList = [];
        $scope.showPositionEditButtons = false;
        $scope.isDeletableContainer = false;

        initController();

        function initController() {
            if (locationId) {
                handleSetPositionEvents();
                watchLocationChanged();
                loadLocationContainersData();
            }

            $rootScope.$on('refreshLocationContainers', loadLocationContainersData);

            $('.set-position-info [data-toggle="tooltip"]').tooltip();
            $('.dropdown-toggle').dropdown();
        }

        function handleSetPositionEvents() {
            $scope.$on('setLocationPosition', function (_, options) {
                $scope.position.latitude = options.position.lat;
                $scope.position.longitude = options.position.lng;
                $scope.showPositionEditButtons = true;
            });
        }

        function watchLocationChanged() {
            if (!$scope.location) {
                showBusyIndicator('location-info-table-wrapper');
                unwatchLocationChanged = $scope.$watch('location', handleLocationLoaded);
            } else {
                handleLocationLoaded();
            }
        }

        function handleLocationLoaded() {
            if (!$scope.location) return;

            if (unwatchLocationChanged) {
                unwatchLocationChanged();
            }

            hideBusyIndicator('location-info-table-wrapper');
            resetPosition();
        }

        function openPlotModal(containerInfo) {
            if (!containerInfo?.devicesToDisplay?.length > 0) {
                return; // Early return if devicesToDisplay is empty or undefined
            }
        
            const nrc2Device = containerInfo.devicesToDisplay.find(d => d?.externalSystemName === "NRC Monitor");
        
            if (!nrc2Device) {
                return; // Early return if nrc2Device is not found
            }

            let sensorHeight;
            const devices = containerInfo.container?.devices;
            if (devices && devices.length > 0) {
                devices.forEach(device => {
                    if (device.sensorHeight) {
                        sensorHeight = device.sensorHeight;
                    }
                });
            }
            if (!sensorHeight) {
                sensorHeight = containerInfo?.container?.containerType?.height;
            }
            const windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: get3DPlotModalTitle(nrc2Device.externalId, sensorHeight),
                    resizable: false,
                    height: 640,
                    width: 720
                },
                templateUrl: 'app/location/info/plotly-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'PlotlyController',
                resolve: {
                    externalId: function () {
                        return nrc2Device.externalId;
                    },
                    containerHeight: function () {
                        return sensorHeight;
                    },
                }
            });
        }

        function get3DPlotModalTitle(externalId, containerHeight) {

            if (containerHeight) {
                return $translate.instant('MEASUREMENT_LEVEL_CHART_HEADER', {externalId: externalId});
            }else{
                console.log($translate.instant('MEASUREMENT_LEVEL_CHART_HEADER_DEFAULT', {externalId: externalId, defaultSensorHeight: defaultSensorHeight/1000}));
                return $translate.instant('MEASUREMENT_LEVEL_CHART_HEADER_DEFAULT', {externalId: externalId, defaultSensorHeight: defaultSensorHeight/1000});
            }

        }

        function loadLocationContainersData() {
            showBusyIndicator('location-containers-table-wrapper');

            authService.ready().then(() => {
                //Extract values to be wrapped in closures
                let today = new Date(),
                    start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 5);

                let containerInfoByLocationPromise = containerService.getContainerInfoByLocationId(locationId);
                let getLocationStatisticsPromise = locationsService.getLocationStatisticsByContainer(
                    locationId,
                    moment(start).format('YYYY-MM-DD'),
                    moment(today).format('YYYY-MM-DD')
                );

                containerInfoByLocationPromise.then(containerInfoList => {
                    _.forEach(containerInfoList, extendContainerInfo);

                    //Move containers without devices to the end of the list
                    let containersWithoutDevices = containerInfoList.filter(c => !c.devicesToDisplay || c.devicesToDisplay.length === 0);
                    let containersWithDevices = containerInfoList.filter(c => c.devicesToDisplay && c.devicesToDisplay.length > 0);

                    $scope.containerInfoList = containersWithDevices.concat(containersWithoutDevices);

                    $scope.maxDevicesLength =  Math.max(...$scope.containerInfoList.map(c => c.devicesToDisplay.length));
                    $scope.hasDeletablecolumn = $scope.containerInfoList.some(x => x.isDeletable == true);
                    $scope.hasAtLeast1FillDevice = $scope.containerInfoList.some(x => x.hasFillDevice && x.fill !== 'N/A');
                    $scope.hasAtLeast1VoltValue = $scope.containerInfoList.some(x => x.container?.volt);

                    $scope.containerInfoList.forEach(containerInfo => {
                        if (!containerInfo?.devicesToDisplay?.length > 0 || !containerInfo.devicesToDisplay.find(d => d?.externalSystemName === "NRC Monitor")) {
                            containerInfo.isLevelMeasurementAvailable = false;
                        } else {
                            containerInfo.isLevelMeasurementAvailable = true;
                        }                    
                    });
                    sortContainerInfoListByFractionName();
                }).finally(() => hideBusyIndicator('location-containers-table-wrapper'));
                
                $q.all([containerInfoByLocationPromise, getLocationStatisticsPromise]).then(result => {
                    let containerInfoList = result[0],
                    statistics = result[1];
                    
                    createMiniCharts(containerInfoList, statistics);
                });
            });
            
        }

        function sortContainerInfoListByFractionName() {
            $scope.containerInfoList = _.sortBy($scope.containerInfoList,
                [ (o) => { return o.container.fraction.name; }]);
        }

        function savePosition() {
            if (!$scope.location) return;

            showBusyIndicator('location-info-table-wrapper');
            $scope.showPositionEditButtons = false;
            $scope.location.latitude = $scope.position.latitude;
            $scope.location.longitude = $scope.position.longitude;
            locationsService.updateLocation($scope.location).finally(function () {
                mapService.reloadMap();
                hideBusyIndicator('location-info-table-wrapper');
            });
        }

        function resetPosition() {
            if (!$scope.location) return;

            $scope.position.latitude = $scope.location.latitude;
            $scope.position.longitude = $scope.location.longitude;
            $scope.showPositionEditButtons = false;
        }

        function showBusyIndicator(id) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationInfo' + id,
                destination: '.' + id,
                message: $translate.instant("G_BUSY_INDICATOR"),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });
        }

        function hideBusyIndicator(id) {
            $rootScope.$broadcast('hideBusyIndicator', 'locationInfo' + id);
        }
        
        function openUpdateContainerModal(containerInfo) {
            if (containerInfo && containerInfo.container && containerInfo.container.id) {
                var container = containerInfo.container;
                var titleFraction = container.fraction && container.fraction.name ? (' - ' + container.fraction.name) : '';
                var titleAddress = container.location && container.location.name ? (' - ' + container.location.name) : '';
                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        title: container.containerNumber + titleAddress + titleFraction,
                        resizable: false,
                        height: 600,
                        width: containerEditModalWidth,
                        visible: false
                    },
                    templateUrl: 'app/containerOverviewV2/views/sensor-api-container-details-modal-view.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'SensorApiContainerDetailsModalController',
                    resolve: {
                        currentFormType: function () {
                            return formTypes.edit;
                        },
                        containerId: function () {
                            return containerInfo.container.id;
                        },
                        updateCallbacks: function () {
                            return null;
                        },
                        locationId: function () {
                            return null;
                        }
                    }
                });

                windowInstance.result.then(function (result) {
                    if (result) {
                        loadLocationContainersData();
                    }
                });
            }
        }

        function openNewContainerModal() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant("LOCATION_INFO_TAB_CONTAINER_MODAL_TITLE"),
                    resizable: false,
                    height: 600,
                    width: 700,
                    visible: false
                },
                templateUrl: 'app/containerOverviewV2/views/sensor-api-container-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'SensorApiContainerDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.add;
                    },
                    containerId: function () {
                        return null;
                    },
                    updateCallbacks: function () {
                        return null;
                    },
                    locationId: function () {
                        return locationId;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    loadLocationContainersData();
                }
            });
        }

        function deleteContainer(containerInfo) {
            if (!containerInfo
                || !containerInfo.container
                || !containerInfo.isDeletable
                || !confirm($translate.instant("LOCATION_INFO_TAB_CONTAINER_CONFIRM_DELETE"))
            ) {
                return;
            }

            containerService.deleteContainer(containerInfo.container.id)
                .then(() => loadLocationContainersData());
        }

        function extendContainerInfo(containerInfo) {
            if (!containerInfo) return;

            containerInfo.fractionIcon = fractionsUtility.getFractionIcon(containerInfo.container.fraction);
            containerInfo.latestStatus = containerInfo.latestStatus || {};

            containerInfo.fill = 'N/A';
            containerInfo.dateWhenFull = 'N/A';

            if (moment().diff(moment(containerInfo.latestStatus.createdAt), 'days') === 0) {
                if (containerInfo.latestStatus.fill) {
                    containerInfo.fill = containerInfo.latestStatus.fill + '%';
                }
                containerInfo.dateWhenFull = getDateWhenFull(containerInfo.latestStatus.dateWhenFull);
            }
            
            containerInfo.fillStatisticsLink = containerInfo.container.fillPriorityDevice
                && containerInfo.container.fillPriorityDevice.id
                && `main.statistics({ deviceId: "${containerInfo.container.fillPriorityDevice.id}" })`;

            //If there is no fill priority device, use the current one
            if (!containerInfo.fillStatisticsLink
                && containerInfo.container.devices
                && containerInfo.container.devices.length > 0) {
                const firstDevice = containerInfo.container.devices[0];
                containerInfo.fillStatisticsLink = firstDevice.id && `main.statistics({ deviceId: "${firstDevice.id}" })`;
            }

            containerInfo.devicesToDisplay = getDevicesToDisplay(containerInfo);

            //Check if container is deletable
            containerInfo.isDeletable = false;

            if (containerInfo.container
                && containerInfo.container.containerCustomerSystems
                && containerInfo.container.containerCustomerSystems.length === 0
                && containerInfo.container.devices
                && containerInfo.container.devices.length === 0) {

                containerInfo.isDeletable = true;
            }

            if (containerInfo.latestStatuses) {
                var statusWithVolt = containerInfo.latestStatuses.find(s => s.volt);
                if (statusWithVolt) {
                    containerInfo.container.volt = formattingUtility.toFixedTrunc(
                        statusWithVolt.volt, 2
                    );
                }
            }

            containerInfo.hasFillDevice = false;
            if (containerInfo.container && containerInfo.container.devices && containerInfo.container.devices.some(d => d.deviceType !== 0)) {
                containerInfo.hasFillDevice = true;
            }
        }

        function getDevicesToDisplay(containerInfo) {
            return selectDisplayedDevices(containerInfo.container.devices).map((device) => {
                const externalSystemName = getExternalSystemName(device);
                const deviceData = {
                    externalSystemName,
                    externalSystemIcon: externalSystemUtility.getExternalSystemIcon(device.externalSystem),
                    externalSystemIconStyle: externalSystemUtility.getExtSystemPlaceholderColor(externalSystemName),
                    externalId: device.externalId,
                };

                if (device && device.externalSystem) {
                    let externalSystem = device.externalSystem;
                    deviceData.detailsLink = deviceUtility.getDeviceDetailsLink(externalSystem.name, externalSystem.id, device.externalId);
                } else {
                    deviceData.detailsLink = '';
                }

                return deviceData;
            });
        }

        function getExternalSystemName(device) {
            return device
                && device.externalSystem
                && device.externalSystem.name;
        }

        function getDateWhenFull(dateWhenFullText) {
            if (dateWhenFullText) {
                var dateWhenFull = moment(dateWhenFullText);
                if (dateWhenFull < moment().endOf('day')) {
                    return $translate.instant("LOCATION_INFO_TAB_CONTAINER_FULL_ON_DAY");
                }
                if (dateWhenFull < moment().add(3, 'day').endOf('day')) {
                    return '1-3 ' + $translate.instant("G_DAYS");
                }
                if (dateWhenFull < moment().add(6, 'day').endOf('day')) {
                    return '4-6 ' + $translate.instant("G_DAYS");
                }
                if (dateWhenFull < moment().add(14, 'day').endOf('day')) {
                    return '7-14 ' + $translate.instant("G_DAYS");
                }

                return '> 14 ' + $translate.instant("G_DAYS");
            }
            return 'N/A';
        };

        function containerRowPaddingLength(containerInfo) {
            const devicesLength = containerInfo.devicesToDisplay.length;
            const deleteIconLength = containerInfo.isDeletable ? 1 : 0;

            return Math.max($scope.maxDevicesLength - devicesLength - deleteIconLength, 0);
        }

        //Private functions
        function createMiniCharts(containerInfoList, statistics) {
            if (!containerInfoList
                || containerInfoList.length === 0
                || !statistics
                || statistics === 0) {
                return;
            }

            const maxBarHeight = 20;
            let containerMinCharts = [];

            statistics.forEach(currentStatistic => {
                let relatedContainer = containerInfoList.find(c => c.container.id === currentStatistic.item1);
                if (relatedContainer) {
                    let days = currentStatistic.item2,
                        lastDays = days.slice(days.length - 4, days.length);

                    let barHeights = lastDays.map(stat =>
                        Math.round(maxBarHeight * (Math.max(Math.min(stat.fill, 100) / 100, 0)))
                    );

                    containerMinCharts.push({
                        containerId: relatedContainer.container.id,
                        barHeights
                    });
                }
            });

            $timeout(500).then(() => {
                containerMinCharts.forEach(minChart => {
                    let $minChartElement = $('.mini-chart[container-id="' + minChart.containerId + '"]');

                    if ($minChartElement.length) {
                        for (let barHeight of minChart.barHeights) {
                            $minChartElement.append('<div class="bar" style="height: ' + barHeight + 'px"></div>');
                        }
                    }
                });
            });
        }

        function checkIsDeletableElement() {
            $scope.isDeletableContainer = _.find($scope.containerInfoList, (cont) => {
                if (cont.isDeletable) {
                    return true;
                } else {
                    return false;
                }
            });
        };

        function openNewLogModal(containerInfo) {
            $kWindow.open({
                options: {
                    modal: true,
                    movable: true,
                    title: $translate.instant("CONTAINER_LOG_TAB_VIEW_NEW_LOG"),
                    resizable: true,
                    height: 400,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/location/log/container-log-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ContainerLogModalController',
                resolve: {
                    containers: function () {
                        return [containerInfo.container];
                    }
                }
            });
        }

    }
})();
