(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('authService', authService);

    authService.$inject = [
        '$q',
        '$http',
        '$state',
        '$rootScope',
        '$timeout',
        'qPool',
        'authConfig',
        'localStorageService',
        'mapService',
        'cachingService',
        'clientService',
        'municipalityService',
        'userService'
    ];

    function authService(
        $q,
        $http,
        $state,
        $rootScope,
        $timeout,
        qPool,
        authConfig,
        localStorageService,
        mapService,
        cachingService,
        clientService,
        municipalityService,
        userService
    ) {
        const readyDeferred = $q.defer();
        const authInProgressDeferred = $q.defer();

        let authServiceVersion = '1.0.5',
            isInitialized = false,
            auth0Client = null;

        let authenticationData = {
            isAuth: false,
            token: '',
            username: '',
            userId: '',
            displayName: '',
            contractorId: '',
            activeDepartment: null,
            authUser: null,
            userRoles: [],
            modules: []
        };

        let authService = {
            login: function () {
                auth0Client.loginWithRedirect({
                    redirect_uri: window.location.origin + '/main'
                }).catch(() => {
                    //error while redirecting the user
                });
            },

            logout: function () {
                var authData = localStorageService.get('authenticationData');
                if (!authData) return $q.resolve(authenticationData);

                if (mapService.getLayer) {
                    const containerLayer = mapService.getLayer('containerLayer');
                    if (containerLayer) containerLayer.setLocationFilter();

                    const locationLayer = mapService.getLayer('locationLayer');
                    if (locationLayer) locationLayer.setLocationFilter();
                }

                return cachingService.clear()
                    .finally(() => {
                        authService.clearAuthData();
                        auth0Client.logout({
                            returnTo: window.location.origin
                        });
                    });
            },

            ready: function () {
                return readyDeferred.promise;
            },
            
            authInProgress: function () {
                return authInProgressDeferred.promise;
            },

            init: function () {
                let initDeferred = $q.defer();

                authService.configureClient()
                    .then(() => authService.handleRedirectCallback())
                    .then(() => {
                        authService.fillAuthData();

                        isInitialized = true;
                        initDeferred.resolve(authenticationData);

                        //Ensure that init promise callbacks are handled first
                        $timeout(100).then(() => readyDeferred.resolve(authenticationData));
                    });

                return initDeferred.promise;
            },

            isInitialized: function () {
                return isInitialized;
            },

            isUpToDate: function () {
                return authenticationData.version === authServiceVersion;
            },

            handleRedirectCallback() {
                let redirectDeferred = $q.defer(),
                    query = window.location.search;

                if (query.includes("code=") && query.includes("state=")) {
                    authInProgressDeferred.resolve(true);
                    auth0Client.handleRedirectCallback().then(() => {
                        window.history.replaceState({}, document.title, "/");

                        authService.setAuthenticationData(redirectDeferred);
                    });
                } else {
                    redirectDeferred.resolve(false);
                }

                return redirectDeferred.promise;
            },

            setAuthenticationData: function (deferred) {
                const getTokenSilentlyPromise = auth0Client.getTokenSilently(),
                getUserPromise = auth0Client.getUser();

                $q.all([
                    getTokenSilentlyPromise,
                    getUserPromise
                ]).then(result => {
                    const accessToken = result[0],
                        user = result[1];

                    let authData = localStorageService.get('authenticationData') || {};

                    authData.token = accessToken;
                    authData.username = user.email;
                    authData.userId = user.email;
                    authData.version = authServiceVersion;
                    authData.isAuth = true;
                    authData.displayName = user.email;
                    authData.userId = user.email;
                    authData.authUser = user;

                    authenticationData = authData;
                    localStorageService.set('authenticationData', authData);

                    authService.saveUserDetailsToAuthenticationData(deferred);
                });
            },

            saveUserDetailsToAuthenticationData: function (deferred) {
                authService.getUserDetails().then(([groups, accessRights, municipalities, user, clients]) => {
                    let authData = localStorageService.get('authenticationData');

                    $rootScope.startPageCacheKey = 'selectedStartPage_' + authData.userId;

                    let savedStartingPage = JSON.parse(localStorage.getItem($rootScope.startPageCacheKey));

                    authData.authUser.accessRights = accessRights;
                    authData.authUser.groups = groups;
                    authData.authUser.municipalities = user.municipalities.map(m => municipalities.find(x => x.id === m.municipalityId)).filter(x => x);
                    
                    municipalities;

                    authData.clientId = user.clientId;
                    authData.preferredLanguage = user.preferredLanguage;
                    authData.startPage = savedStartingPage;
                                        
                    let relatedClient = clients.find(c => c.id === user.clientId);

                    if (relatedClient && relatedClient.name) {
                        authData.clientName = relatedClient.name;
                        authData.modules = relatedClient.clientModules.map(x => x.module?.key);
                    }

                    localStorageService.set('authenticationData', authData);
                    authService.fillAuthData();

                    $rootScope.authData = authData;

                    if (savedStartingPage) {
                        $state.transitionTo(savedStartingPage.pageUrl);
                    } else {
                        authService.navigateToStartPage(groups);
                    }
                    
                    deferred.resolve(true);
                });
            },

            changeClient: function () {
                const deferred = $q.defer();
                authService.saveUserDetailsToAuthenticationData(deferred);
                return deferred.promise;
            },

            getAuthData: function () {
                return authenticationData;
            },

            getAccessRights: function () {
                return readyDeferred.promise.then(authService.getAccessRightsInternal);
            },

            getAuthGroups: function () {
                var deferred = $q.defer();

                $http.get('api/auth/groups')
                    .then(function (result) {
                        var resultData = result.data;
                        if ($rootScope.isSuperAdmin() === false) {
                            resultData = resultData.filter(x => x.groupName != "SuperAdmin");
                        }
                        deferred.resolve(resultData);
                    }, function () {
                        deferred.reject();
                    });

                return deferred.promise;
            },

            getUserDetails: function () {
                const getGroups = $http.get('api/user/groups?username=' + authenticationData.username)
                    .then((result) => (result.data));
                const getMunicipalities = municipalityService.getMunicipalities();
                const getAccessRights = authService.getAccessRightsInternal();
                const getUser = userService.getUserByName(authenticationData.username);
                const getClients = clientService.getClients();

                return $q.all([getGroups, getAccessRights, getMunicipalities, getUser, getClients]);
            },

            //Private functions
            configureClient: function () {
                let deferred = $q.defer();

                createAuth0Client({
                    domain: authConfig.domain,
                    client_id: authConfig.clientId,
                    audience: authConfig.apiIdentifier,
                    useRefreshTokens: true,
                }).then(client => {
                    auth0Client = client;

                    deferred.resolve(client);
                }).catch(() => deferred.reject);

                return deferred.promise;
            },

            clearAuthData: function () {
                localStorageService.remove('authenticationData');

                authenticationData.version = authServiceVersion;
                authenticationData.isAuth = false;
                authenticationData.token = '';
                authenticationData.username = '';
                authenticationData.displayName = '';
                authenticationData.userId = '';
                authenticationData.clientId = '';
                authenticationData.clientName = '';
                authenticationData.authUser = null;
            },

            fillAuthData: function () {
                let authData = localStorageService.get('authenticationData');
                if (authData) {
                    authenticationData.version = authData.version;
                    authenticationData.isAuth = authData.isAuth;
                    authenticationData.token = authData.token;
                    authenticationData.username = authData.username;
                    authenticationData.userId = authData.userId;
                    authenticationData.clientId = authData.clientId;
                    authenticationData.clientName = authData.clientName;
                    authenticationData.displayName = authData.displayName;
                    authenticationData.authUser = authData.authUser;
                    authenticationData.preferredLanguage = authData.preferredLanguage;
                    authenticationData.modules = authData.modules;

                    authenticationData.hasAccessToFeature = (area, accessType) => {
                        if (authenticationData
                            && authenticationData.authUser
                            && authenticationData.authUser.accessRights) {
                            let currentRights = authenticationData.authUser.accessRights[area];

                            return currentRights && currentRights.includes(accessType);
                        }

                        return false;
                    };

                    authenticationData.hasAccessToModule = (module) => {
                        if (authenticationData
                            && authenticationData.modules) {
                            return authenticationData.modules.includes(module);
                        }

                        return false;
                    };
                }
            },

            //Required to call before readyDeferred is resolved
            getAccessRightsInternal: function () {
                let deferred = qPool.defer('accessrights');

                //Store base data in global state
                if ($rootScope.accessRights) {
                    deferred.resolve($rootScope.accessRights);
                } else {

                    if (!deferred.hasCompleted() && deferred.isFirst) {
                        $http.get('api/accessrights').then(result => {
                            let accessRights = Object.fromEntries(result.data.accessRights
                                .map(a => [a.area, a.accessType]));

                            $rootScope.accessRights = accessRights;

                            deferred.resolve(accessRights);
                        }).catch(deferred.reject);
                    }
                }

                return deferred.promise;
            },

            navigateToStartPage: function (groups) {
                const groupId = groups[0].groupId;
                switch (groupId) {
                    case 1:
                        $state.transitionTo('main.administration');
                        return;
                    case 2:
                        $timeout(250).then(() =>
                        {
                            if($rootScope.isVisible('ACCESSITEMS',{})){
                                $state.transitionTo('main.accessItems');
                            }else{
                                $state.transitionTo('main.locations');
                            }
                        });
                        return;
                    case 3:
                        $state.transitionTo('main.reports');
                        return;
                    case 4:
                        $state.transitionTo('main.administration');
                        return;
                    default:
                        $state.transitionTo('main.locations');
                        return;
                };
            }
        };

        return authService;
    }
})();
