(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('GenericReportsController', GenericReportsController);

    GenericReportsController.$inject = [
        '$timeout',
        '$rootScope',
        '$scope',
        '$translate'
    ];

    function GenericReportsController(
        $timeout,
        $rootScope,
        $scope,
        $translate
    ) {
        const minFrameWidth = 450;
        const minFrameHeight = 300;

        $scope.selectedReportId = null;
        $scope.allReports = [];
        $scope.changeReport = changeReport;

        $scope.reportsDdlOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readReports
                }
            }),
            dataTextField: 'reportName',
            optionLabel: $translate.instant('G_CHOOSE_GENERIC_REPORT'),
            dataValueField: 'id'
        };

        $timeout(250).then(initController);

        function initController() {
            const windowSize = {
                width: $(document).width(),
                height: $(document).height()
            };

            const viewSize = $('#generic-reports').width();
            if (viewSize > 1400) {
                $scope.frameWidth = Math.max(viewSize, minFrameWidth);
            } else {
                $scope.frameWidth = Math.max(windowSize.width / 2 - 40, minFrameWidth);
            }

            $scope.frameHeight = Math.max(windowSize.height - 290, minFrameHeight);
        }

        function changeReport() {
            $('#powerbi-statistics').empty()

            if ($scope.selectedReportId != null && $scope.selectedReportId.length > 0) {
                $('#stat-placeholder').hide();
                let selectedReport = $scope.allReports.find(x => x.id == $scope.selectedReportId);
                $scope.powerBILink = selectedReport.reportUrlBase;

                let powerBiFrame = $('<iframe frameborder="0" allowFullScreen="true" src="{{powerBILink | trustAsResourceUrl}}" />');
                powerBiFrame.attr('width', $scope.frameWidth);
                powerBiFrame.attr('height', $scope.frameHeight);
                powerBiFrame.attr('src', $scope.powerBILink);

                $('#powerbi-statistics').append(powerBiFrame);
            }else{
                $('#stat-placeholder').show();
            }
        }

        function readReports(e) {
            $rootScope.appInit().then(() => {
                if ($rootScope.externalReports && Array.isArray($rootScope.externalReports)) {
                    const genericReports = $rootScope.externalReports.filter(x => x.reportType === 'GenericReport');

                    $scope.allReports = genericReports;
                    e.success(genericReports);
                } else {
                    e.success([])
                }
            });
        }
    }
})();
