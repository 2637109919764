(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ClientChangeModalController', ClientChangeModalController);

    ClientChangeModalController.$inject = [
        '$rootScope',
        '$scope',
        '$windowInstance',
        'authService',
        'clientService'
    ];

    function ClientChangeModalController(
        $rootScope,
        $scope,
        $windowInstance,
        authService,
        clientService
    ) {
        $scope.clients = [];
        $scope.selectedClientId = null;

        $scope.save = save;
        $scope.close = close;

        $scope.clientIdsOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readClients
                }
            }),
            dataTextField: 'localizedName',
            dataValueField: 'id'
        };

        //Data retrieval
        function readClients(e) {
            authService.ready().then(() => {
                if ($rootScope.isSuperAdmin()) {
                    clientService.getClients().then(function (response) {
                        let clients = response
                            .filter(c => c.status === 'Active')
                            .map(c => ({ ...c, localizedName: c.name }));

                        let relatedClient = clients.find(c => c.id === $rootScope.authData.clientId);

                        $scope.selectedClientId = relatedClient && relatedClient.id;
                        $scope.clients = clients;

                        $scope.clients = clients;

                        e.success(clients);
                    }).catch(function (error) {
                        e.success([]);
                    });
                }
                else {
                    e.success([]);
                }
            });
        }

        function save() {
            event.preventDefault();

            let relatedClient = $scope.clients.find(c => c.id === $scope.selectedClientId);
            $windowInstance.close(relatedClient);
        }

        function close() {
            event.preventDefault();
            $windowInstance.close(false);
        }
    }
})();
