(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('containerService', containerService);

    containerService.$inject = [
        '$q',
        '$http',
        'qPool'
    ];

    function containerService(
        $q,
        $http,
        qPool
    ) {
        let containerService = {
            getContainersV2: getContainersV2,
            getContainersWithTracking: getContainersWithTracking,
            getContainerByContainerId: getContainerByContainerId,
            getContainerById: getContainerById,
            getLogsByExtDeviceIdAndSystem: getLogsByExtDeviceIdAndSystem,
            getLogs: getLogs,
            getLogsByFilter: getLogsByFilter,
            getLogsByFilterBulk: getLogsByFilterBulk,
            getDevice: getDevice,
            getCustomerSystems: getCustomerSystems,
            getContainerInfoByLocationId: getContainerInfoByLocationId,
            getContainerStatistics: getContainerStatistics,
            getContainerByRelatedDevice: getContainerByRelatedDevice,

            addContainerV2: addContainerV2,
            updateContainerV2: updateContainerV2,
            updateDeviceCustomerSystem: updateDeviceCustomerSystem,
            updateContainerCustomerSystem: updateContainerCustomerSystem,
            getCustomerSystemsForContainer: getCustomerSystemsForContainer,
            addCustomerSystemForContainer: addCustomerSystemForContainer,
            removeCustomerSystemFromContainer: removeCustomerSystemFromContainer,
            connectDevice: connectDevice,
            addContainerLog: addContainerLog,
            getContainersExpress : getContainersExpress,
            deleteContainer,
        };

        return containerService;

        function getContainersExpress() {
            let deferred = qPool.defer('express');

            if (!deferred.hasCompleted() && deferred.isFirst) {
                $http.get('api/containersv2/express').then(function (result) {
                    deferred.resolve(result.data);
                }, function () {
                    deferred.reject();
                });
            }

            return deferred.promise;
        }

        function connectDevice(device, containerId) {
            var deferred = $q.defer();

            var connectDeviceDto = {
                containerId: containerId,
                externalId: device.externalId,
                externalSystemId: device.externalSystemId
            }

            $http.post('api/containersv2/connect', connectDeviceDto)
                .then(function (result) {
                    if (result) {
                        deferred.resolve(result);
                    }
                    else {
                        deferred.reject();
                    }
                }, function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function removeCustomerSystemFromContainer(containerCustomerSystemId) {
            var deferred = $q.defer();

            $http.delete('api/containersv2/customerSystem/' + containerCustomerSystemId)
                .then(function (result) {
                    if (result && result.data) {
                        deferred.resolve(result.data);
                    }
                    else {
                        deferred.reject();
                    }
                }, function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function addCustomerSystemForContainer(containerCustomerSystem, containerId) {
            var deferred = $q.defer();

            var ccSystem = {
                containerId: containerId,
                externalId: containerCustomerSystem.externalId,
                customerSystemId: containerCustomerSystem.selectedCustomerSystemId
            }

            $http.post('api/containersv2/customerSystem', ccSystem)
                .then(function (result) {
                    if (result && result.data) {
                        deferred.resolve(result.data);
                    }
                    else {
                        deferred.reject();
                    }
                }, function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function updateContainerCustomerSystem(containerCustomerSystem) {
            var deferred = $q.defer();

            var ccSystem = {
                id: containerCustomerSystem.id,
                externalId: containerCustomerSystem.externalId,
                customerSystem: containerCustomerSystem.customerSystem
            }
            $http.put('api/containersv2/customerSystem', ccSystem)
                .then(function (result) {
                    if (result && result.data) {
                        deferred.resolve(result.data);
                    }
                    else {
                        deferred.reject();
                    }
                }, function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function getCustomerSystemsForContainer(containerId) {
            var deferred = $q.defer();

            $http.get('api/customerSystems/' + containerId)
                .then(function (result) {
                    if (result && result.data) {
                        deferred.resolve(result.data);
                    }
                    else {
                        deferred.reject();
                    }
                }, function () {
                    deferred.reject();
                });

            return deferred.promise;
        }
       
        function addContainerV2(container) {
            return $http.post('api/containersV2/', container)
                .then(result => result.data);
        }

        function getContainersV2() {
            var deferred = $q.defer();

            $http.get('api/containersV2').then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        }

        function getContainersWithTracking() {
            var deferred = $q.defer();

            $http.get('api/containersV2/with-positions').then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        }

        function getContainerByContainerId(containerId) {
            var deferred = $q.defer();

            $http.get('api/containersV2/' + containerId).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        }

        function getContainerInfoByLocationId(locationId) {
            var deferred = $q.defer();

            $http.get('api/containersV2/ByLocationId/' + locationId).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        }

        function getContainerById(containerId) {

            var deferred = $q.defer();

            getContainers().then(function (containers) {
                var cId = parseInt(containerId);
                var matchingContainers = _.filter(containers, c => c.id === cId);

                if (matchingContainers.length > 0) {
                    var groups = _.map(matchingContainers, function (cg) {
                        return {
                            groupId: cg.groupId,
                            group: cg.group
                        }
                    });

                    var container = matchingContainers[0];
                    container.groups = groups;
                    deferred.resolve(container);
                } else {
                    deferred.resolve(null);
                }
            }, function () {
                deferred.resolve(null);
            });

            return deferred.promise;
        }

        function getContainerStatistics(deviceId, from, to) {
            var deferred = $q.defer();

            $http.get('api/containersv2/statistics?deviceId=' + deviceId + '&from=' + from + '&to=' + to).then(function (result) {
                if (result && result.data) {

                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        }

        function getContainerByRelatedDevice(deviceId) {
            var deferred = $q.defer();

            $http.get('api/containersv2/byDevice/' + deviceId).then(function (result) {
                if (result && result.data) {

                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        }

        function updateContainerV2(container) {
            return $http.put('api/containersv2/', container)
                .then(result => result.data);
        }

        function getLogsByExtDeviceIdAndSystem(containerId) {
            var deferred = $q.defer();

            $http.get('api/containers/GetLogsByExtDeviceIdAndSystem/' + containerId).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getLogs(from, to) {
            var deferred = $q.defer();

            $http.get('api/containersv2/logs?from=' + from + '&to=' + to).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getLogsByFilter(filter) {
            var deferred = $q.defer();

            $http.post('api/containersv2/filter', filter).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getLogsByFilterBulk(filterList) {
            var deferred = $q.defer();

            $http.post('api/containersv2/filter/bulk', filterList).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getDevice(containerId, externalSystemId) {
            var deferred = $q.defer();

            $http.get('api/containers/GetDeviceExtDeviceIdAndSystem/' + containerId + '/' + externalSystemId).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getCustomerSystems() {
            var deferred = $q.defer();

            $http.get('api/containers/GetCustomerSystems').then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function updateDeviceCustomerSystem(containerId, externalSystemId, customerSystemId, customerSystemExternalId) {
            var deferred = $q.defer();

            var deviceDto = {
                externalId: containerId,
                externalSystemId: externalSystemId,
                CustomerSystemId: customerSystemId,
                customerSystemExternalId: customerSystemExternalId
            };

            $http.put('api/containers/UpdateDeviceCustomerSystem', deviceDto).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function addContainerLog(containerLog) {
            var deferred = $q.defer();

            $http.post('api/containerLog', containerLog).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function deleteContainer(containerId) {
            return $http.delete(`api/containersV2/${containerId}`)
                .then(result => result.data);
        }
    }
})();
