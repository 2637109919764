angular.module('UndergroundWebApp')
.factory(
    'mapService', 
    [
        '$q', 
        'esriLoader', 
        '$timeout', 
        '$rootScope', 
        '$translate',
        'mapUtility',
        function (
            $q, 
            esriLoader, 
            $timeout, 
            $rootScope, 
            $translate,
            mapUtility,
        ){
    'use strict';

    const persistenceRootKey = 'MAP_LAYER_VISIBILITY_LIST';

    const defaultVisibilityList = {
        MapLayer: true,
        FlyFotoLayer: false,
        containerLayer: true,
        locationLayer: false,
        areaLayer: false,
        fillDateLayer: false,
        batteryStatusLayer: false,
        containerPositionLayer: false,
    };

    var readyDeferred = null,
        mapCreatedDeferred = null;
    var mapService = {
        _factories: [],
        _services: [],
        _mapView: null,
        _targetElement: null,
        _initialPosition: null,
        _initialZoom: null,
              
        ctxMenu: null,

        setInitialPosition: function (position, zoom) {
            mapService._initialPosition = position;
            mapService._initialZoom = zoom;
        },

        mapCreated: function () {
            if (mapCreatedDeferred === null) {
                mapCreatedDeferred = $q.defer();
            }

            return mapCreatedDeferred.promise;
        },

        ready: function () {
            if (readyDeferred === null) {
                readyDeferred = $q.defer();
            }

            return readyDeferred.promise;
        }
    };
    
    esriLoader.require([
        'esri/Map',
        'esri/views/MapView',
        'esri/layers/GraphicsLayer',
        'esri/Graphic',
        'esri/geometry/Point',
        'esri/geometry/ScreenPoint',
        'dojo/on',
        'dijit/Menu',
        'esri/geometry/Extent',
        'esri/geometry/support/webMercatorUtils',
        "esri/geometry/SpatialReference",
    ], function (Map, MapView, GraphicsLayer, Graphic, Point, ScreenPoint, on, Menu, esriExtent, WebMercatorUtils, SpatialReference) {
        if (!readyDeferred) {
            readyDeferred = $q.defer();
        }

        var callbacks = {
            'mousemove': [],
            'click': [],
            'rightclick': [],
            'double-click': [],
            'zoom': [],
            'center': [],
            'mapview-create': [],
            'zoomFinished': []
        };

        function executeCallbacks(eventName, evt, mapView, hitResponse) {
            if (callbacks[eventName]) {
                for (var i = 0; i < callbacks[eventName].length; ++i) {
                    const stopPropagation = callbacks[eventName][i](evt, mapView, hitResponse);
                    if (stopPropagation) break;
                }
            }
        }

        function getScreenPointFromMenuPosition(mapView, box) {
            var x = box.x, y = box.y;
            switch (box.corner) {
                case "TR":
                    x += box.w;
                    break;
                case "BL":
                    y += box.h;
                    break;
                case "BR":
                    x += box.w;
                    y += box.h;
                    break;
            }

            return new ScreenPoint(x - mapView.position[0], y - mapView.position[1]);
        }

        //Internal event handler functions
        function onMouseMove(evt) {
            //Dispatch only if not dragging
            //TODO: don't dispatch if not zooming
            if (evt.buttons !== 0) return;
            var screenPoint = new ScreenPoint(evt.offsetX, evt.offsetY);

            executeCallbacksWithHitResponse('mousemove', screenPoint);
        }

        function onClick(evt) {
            if (evt && evt.screenPoint) {
                executeCallbacksWithHitResponse('click', evt.screenPoint);
            }
        }

        function onRightClick(box) {
            var screenPoint = getScreenPointFromMenuPosition(mapService._mapView, box);
            executeCallbacksWithHitResponse('rightclick', screenPoint);
        }

        function executeCallbacksWithHitResponse(eventType, screenPoint) {
            var hitTestPromise = mapService._mapView.hitTest(screenPoint);
            if (!hitTestPromise) return;

            var evt = {
                offsetX: screenPoint.x,
                offsetY: screenPoint.y
            };

            hitTestPromise.then(function (response) {
                if (response.results && response.results.length > 0) {
                    //TODO: get the graphic with the highest z coordinate
                    executeCallbacks(eventType, evt, mapService._mapView, response);
                } else {
                    executeCallbacks(eventType, evt, mapService._mapView);
                }
            });

        }

        function onDoubleClick(evt) {
            executeCallbacks('double-click', evt, mapService._mapView);
        }

        function onZoom(evt) {
            executeCallbacks('zoom', evt, mapService._mapView);

            if (evt % 1 === 0) {
                executeCallbacks('zoomFinished', evt, mapService._mapView);
            }
        }

        function onCenter(evt) {
            executeCallbacks('center', evt);
        }

        var map = new Map();
        mapService.layers = [];

        mapService.createMap = function (mapId) {
            if (mapCreatedDeferred === null) {
                mapCreatedDeferred = $q.defer();
            }

            if (mapService._targetElement && mapService._mapView) {
                $('#' + mapId).replaceWith(mapService._targetElement);

                return;
            }

            if (!mapService._initialPosition || !mapService._initialZoom) {
                mapService.setInitialPosition([15, 63], 4);
            }

            mapService._targetElement = $('#' + mapId);
            mapService._mapView = new MapView({
                container: mapId,
                map: map,
                zoom: mapService._initialZoom,
                center: mapService._initialPosition,
                constraints: {
                    minZoom: 3,
                    maxZoom: 20,
                    rotationEnabled: false
                }
            });

            mapService._mapView.then(function () {
                mapCreatedDeferred.resolve();
            });

            //Set mapView for services
            for (var i = 0; i < mapService._services.length; ++i) {
                mapService._services[i].ready().then(function (service) {
                    service.mapView = mapService._mapView;
                });
            }

            on(mapService._mapView.container, 'mousemove', onMouseMove);
            on(mapService._mapView, 'double-click', onDoubleClick);

            mapService._mapView.on('click', onClick);

            mapService._mapView.watch('center', onCenter);
            mapService._mapView.watch('zoom', onZoom);

            mapService.ctxMenu = new Menu({ onOpen: onRightClick });
            mapService.ctxMenu.startup();
            mapService.ctxMenu.bindDomNode(mapService._mapView.container);

            executeCallbacks('mapview-create', null, mapService._mapView);
        };

        mapService.isInitialized = function () {
            return mapService.layers.length !== 0 || mapService._services.length !== 0;
        };

        mapService.reInitialize = function () {
            mapService.layers = [];
            map.layers.removeAll();

            for (var i = 0; i < mapService._factories.length; ++i) {
                map.layers.add(mapService._factories[i].createLayer());
            }
        }

        mapService.addLayerFactory = function (factories) {
            const createLayersPromises = factories.map((factory) => factory.ready().then(function (layerFactory) {
                if (layerFactory.createLayerOnAdd) {
                    var layer = layerFactory.createLayer();

                    if (layer.onMouseMove) {
                        callbacks.mousemove.push(layer.onMouseMove.bind(layer));
                    }

                    if (layer.onRightClick) {
                        callbacks.rightclick.push(layer.onRightClick.bind(layer));
                    }

                    if (layer.onClick) {
                        callbacks.click.push(layer.onClick.bind(layer));
                    }

                    if (layer.onDoubleClick) {
                        callbacks['double-click'].push(layer.onDoubleClick.bind(layer));
                    }

                    if (layer.onZoom) {
                        callbacks.zoom.push(layer.onZoom.bind(layer));
                    }

                    if (layer.onZoomFinished) {
                        callbacks.zoomFinished.push(layer.onZoomFinished.bind(layer));
                    }

                    mapService._factories.push(layerFactory);
                    mapService.layers.push(layer);
                    if (layer.zIndex) {
                        map.add(layer, layer.zIndex);
                    } else {
                        map.add(layer);
                    }
                }
            }));

            $q.all(createLayersPromises).then(() => { updateLayersVisibility(); });
        }

        mapService.addLayerService = function (service) {
            service.ready().then(function (service) {
                service.setup(mapService);
                mapService._services.push(service);
            });
        }

        mapService.getLayer = function (layerName) {
            for (var i = 0; i < mapService.layers.length; ++i) {
                if (mapService.layers[i].name === layerName) {
                    return mapService.layers[i];
                }
            }

            return null;
        };

        mapService.toggleLayerVisibility = function (layerName) {
            var layer = mapService.getLayer(layerName);

            if (!layer || !layer.toggleVisibility) {
                return;
            }

            layer.toggleVisibility();
            saveLayerVisibilityList();
            $rootScope.$broadcast('layerVisibilityChanged', {
                [layer.name]: layer.visible,
            });
        }

        mapService.disableLayerVisibility = function (layerName) {

            var layer = mapService.getLayer(layerName);

            if (!layer) {
                return;
            }

            layer.visible = false;
            saveLayerVisibilityList();
            $rootScope.$broadcast('layerVisibilityChanged', {
                [layer.name]: layer.visible,
            });
        }

        mapService.enableLayerVisibility = function (layerName) {

            var layer = mapService.getLayer(layerName);

            if (!layer) {
                return;
            }

            layer.visible = true;
            saveLayerVisibilityList();
            $rootScope.$broadcast('layerVisibilityChanged', {
                [layer.name]: layer.visible,
            });
        }
        
        /**
         * Registers a callback function for a specific event.
         * @param {} eventName 
         * @param {} callback 
         * @returns {} 
         */
        mapService.on = function (eventName, callback) {
            if (callbacks[eventName]) {
                callbacks[eventName].push(callback);
            }
        }

        mapService.refreshMap = function () {
            mapService._mapView.extent = mapService._mapView.extent;
        }

        /**
        * zooms to a array of graphics
        * @param {} graphicsArray
        * @param {Number} expansionFactor
        * @returns {}
        */
        mapService.zoomToGraphics = function (graphicsArray, expansionFactor) {
            if (!expansionFactor) {
                return mapService._mapView.goTo(graphicsArray);
            }

            if (!Array.isArray(graphicsArray)) {
                graphicsArray = [graphicsArray];
            }
            if (graphicsArray.length === 0) return;

            const extent = graphicsArray.slice(1).reduce((fullExtent, graphic) => (
                fullExtent.union(graphic.geometry.extent)
            ), graphicsArray[0].geometry.extent);

            mapService._mapView.goTo(extent.expand(expansionFactor));
        }

        /**
        * zooms to a extent of the map
        * @param {} extent
        * @returns {}
        */
        mapService.zoomToExtent = function (extent) {
            mapService._mapView.extent = extent;
        }

        mapService.GetLayerGraphics = function (index) {
            return mapService._mapView.map.allLayers.items[index];
        }
        /**
         * Zooms to a given screenPoint.
         * @param {} point 
         * @returns {} 
         */
        mapService.zoomTo = function (point) {
            var screenPoint = new ScreenPoint(point.x, point.y);
            var locationPoint = mapService._mapView.toMap(screenPoint);

            mapService._mapView.center = locationPoint;
            mapService._mapView.zoom = 17;
        }

        /**
         * Zooms to a given point
         * @param {} point 
         * @returns {} 
         */
        mapService.zoomToLocation = function (point, zoomLevel) {
            mapService._mapView.center = point;
            mapService._mapView.zoom = zoomLevel || 17;
        }

        mapService.zoomOut = function () {
            mapService._mapView.zoom = 9;
        }

        mapService.zoomRelative = function (zoomLevel) {
            mapService._mapView.zoom += zoomLevel;
        }

        mapService.getMapPoint = function (point) {
            var screenPoint = new ScreenPoint(point.x, point.y);
            return mapService._mapView.toMap(screenPoint);
        };

        mapService.reloadMap = function (showBusyIndicator = true) {
            if (showBusyIndicator) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'mapBusyIndicator',
                    destination: '#left-pane',
                    message: $translate.instant('G_BUSY_INDICATOR'),
                    overlay: true,
                    positionClass: {
                        top: '50%',
                        left: '0px',
                        right: '0px'
                    }
                });
            }

            const loadDataPromises = mapService.layers
                .filter((layer) => !!layer.loadData)
                .map((layer) => layer.loadData());

            return $q.all(loadDataPromises).finally(() => {
                if (showBusyIndicator) {
                    $rootScope.$broadcast('hideBusyIndicator', 'mapBusyIndicator');
                }
            });
        }

        function updateLayersVisibility() {
            $timeout(() => {
                const visibilityList = loadLayerVisibilityList();

                if (visibilityList) {
                    mapService.layers.forEach((layer) => {
                        if (visibilityList[layer.name] != null) {
                            layer.visible = visibilityList[layer.name];
                        }
                    });
                }

                const newVisibilityList = saveLayerVisibilityList();
                $rootScope.$broadcast('layerVisibilityChanged', newVisibilityList);
            }, 500);
        }

        function loadLayerVisibilityList() {
            return JSON.parse(window.localStorage.getItem(persistenceRootKey))
                || defaultVisibilityList;
        }

        function saveLayerVisibilityList() {
            const visibilityList = Object.fromEntries(mapService.layers
                .map((layer) => ([
                    layer.name,
                    layer.visible
                ]))
            );

            window.localStorage.setItem(persistenceRootKey, JSON.stringify(visibilityList));
            return visibilityList;
        }

        mapService.zoomToContainers = function (clusterLayer) {
            let clusterLayerGfx = clusterLayer.graphics.items;

            if (clusterLayerGfx.length > 0 && clusterLayerGfx[0].attributes) {
                //In case of single point the point attribute is filled in instead of the points
                if (
                    clusterLayerGfx[0].attributes.point &&
                    !clusterLayerGfx[0].attributes.points
                ) {
                    clusterLayerGfx[0].attributes.points = [
                        clusterLayerGfx[0].attributes.point,
                    ];
                }

                var xmin = clusterLayerGfx[0].attributes.points[0].degLong;
                var ymin = clusterLayerGfx[0].attributes.points[0].degLat;
                var xmax = clusterLayerGfx[0].attributes.points[0].degLong;
                var ymax = clusterLayerGfx[0].attributes.points[0].degLat;

                for (var i = 0; i < clusterLayerGfx.length; i++) {
                    if (clusterLayerGfx[i].attributes.points) {
                        for (
                            var j = 0;
                            j < clusterLayerGfx[i].attributes.points.length;
                            j++
                        ) {
                            let currentPoint =
                                clusterLayerGfx[i].attributes.points[j];

                            if (
                                currentPoint.degLong !== 0 &&
                                currentPoint.degLat !== 0
                            ) {
                                xmax =
                                    currentPoint.degLong > xmax
                                        ? currentPoint.degLong
                                        : xmax;
                                xmin =
                                    currentPoint.degLong < xmin
                                        ? currentPoint.degLong
                                        : xmin;
                                ymax =
                                    currentPoint.degLat > ymax
                                        ? currentPoint.degLat
                                        : ymax;
                                ymin =
                                    currentPoint.degLat < ymin
                                        ? currentPoint.degLat
                                        : ymin;
                            }
                        }
                    }
                }

                let currentProjection = mapUtility.getCurrentProjection(),
                    minFactor = 0.999,
                    maxFactor = 1.001;

                if (
                    currentProjection == "WGS84" &&
                    (Math.abs(ymax - ymin) > 10 || Math.abs(xmax - xmin) > 10)
                ) {
                    minFactor = 0.99;
                    maxFactor = 1.01;
                }
                if (
                    currentProjection == "UTM33N" &&
                    (Math.abs(ymax - ymin) > 1000000 ||
                        Math.abs(xmax - xmin) > 100000)
                ) {
                    minFactor = 0.9;
                    maxFactor = 1.1;
                }

                xmin = xmin * minFactor;
                ymin = ymin * minFactor;
                xmax = xmax * maxFactor;
                ymax = ymax * maxFactor;

                let fullExt = new esriExtent(
                    xmin,
                    ymin,
                    xmax,
                    ymax,
                    new SpatialReference(mapUtility.getCurrentWkid())
                );
                if (currentProjection == "WGS84") {
                    fullExt = WebMercatorUtils.geographicToWebMercator(fullExt);
                }
                console.log(fullExt);
                mapService.zoomToExtent(fullExt);
            }
        };

        readyDeferred.resolve();
    
    });
    return mapService;
}]);
