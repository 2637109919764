(function () {
    angular.module('UndergroundWebApp').factory('containerPositionLayerFactory', containerPositionLayerFactory);

    containerPositionLayerFactory.$inject = [
        '$q',
        '$rootScope',
        'mapUtility',
        'esriLoader',
        "containerService",
        "$translate"
    ]

    function containerPositionLayerFactory(
        $q,
        $rootScope,
        mapUtility,
        esriLoader,
        containerService,
        $translate
    ) {
        let readyDeferred = null,
            initDeferred = null;

        const containerPositionLayerFactory = {
            createLayerOnAdd: true,

            ready: function () {
                if (readyDeferred === null) {
                    readyDeferred = $q.defer();
                }

                return readyDeferred.promise;
            },

            initialized: function () {
                if (initDeferred === null) {
                    initDeferred = $q.defer();
                }

                return initDeferred.promise;
            }
        };

        esriLoader.require([
            'esri/geometry/Point',
            'mcl/MarkerClusterLayer',
            'esri/symbols/SimpleMarkerSymbol',
            'esri/symbols/SimpleLineSymbol',
        ], function (Point, MarkerClusterLayer, SimpleMarkerSymbol, SimpleLineSymbol) {
            if (readyDeferred === null) {
                readyDeferred = $q.defer();
            }
            if (initDeferred === null) {
                initDeferred = $q.defer();
            }

            const clusterSettings = {
                singleLocationIcon: '../img/gps_position.png',
                emptyingLayerClusterMarkerFactory:
                function getClusterMarker(containersWithEmptyingDate) {
                    const pointCount = containersWithEmptyingDate.length;

                    const closestEmptyingDateValue = containersWithEmptyingDate.reduce((prev, current) => {
                        if (current.dateWhenFull < prev.dateWhenFull) {
                          return current;
                        } else {
                          return prev;
                        }
                    })?.dateWhenFull;

                    const colors = {
                        green: [64, 230, 42, 0.6],
                        red: [232, 75, 51, 0.6],
                        orange: [232, 118, 0, 0.6],
                        yellow: [226, 212, 49, 0.6]
                    }     

                    if(closestEmptyingDateValue == null){
                        //TODO return grey dot
                    }

                    const closestEmptyingDate = new Date(closestEmptyingDateValue)
                    const now = new Date();
                    const dayInMillis = 24 * 60 * 60 * 1000;

                    let color = colors.green;

                    if ((closestEmptyingDate.getTime() - now.getTime()) <= dayInMillis){
                        color = colors.red;
                    }else if((closestEmptyingDate.getTime() - now.getTime()) <= dayInMillis * 2){
                        color = colors.orange;
                    }else if((closestEmptyingDate.getTime() - now.getTime()) <= dayInMillis * 3){
                        color = colors.yellow;
                    }

                    if (pointCount < 19) {
                        return new SimpleMarkerSymbol({ size: 22, outline: new SimpleLineSymbol({ color: color }), color: color })
                    } else if (pointCount >= 19 && pointCount < 150) {
                        return new SimpleMarkerSymbol({ size: 24, outline: new SimpleLineSymbol({ color: color }), color: color })
                    } else if (pointCount >= 150 && pointCount < 250) {
                        return new SimpleMarkerSymbol({ size: 28, outline: new SimpleLineSymbol({ color: color }), color: color })
                    } else if (pointCount >= 250 && pointCount < 500) {
                        return new SimpleMarkerSymbol({ size: 32, outline: new SimpleLineSymbol({ color: color }), color: color })
                    } else if (pointCount >= 500 && pointCount < 1000) {
                        return new SimpleMarkerSymbol({ size: 64, outline: new SimpleLineSymbol({ color: color }), color: color })
                    } else {
                        return new SimpleMarkerSymbol({ size: 76, outline: new SimpleLineSymbol({ color: color }), color: color })
                    }
                },

                emptyingLayerClustering: true
            };    

            containerPositionLayerFactory.createLayer = function () {
                var containerPositionLayer = new MarkerClusterLayer(clusterSettings);
                containerPositionLayer.name = 'containerPositionLayer';
                containerPositionLayer.zIndex = 15;
                containerPositionLayer.popupVisible = false;

                containerPositionLayer.toggleVisibility = function () {
                    containerPositionLayer.visible = !containerPositionLayer.visible;
                }

                containerPositionLayer.onMouseMove = function (evt, mapView, hitResponse) {
                    const hitResult = hitResponse && hitResponse.results
                        .find(r => r.graphic.layer.name === containerPositionLayer.name);
                    if (
                        !hitResult
                        || hitResult.graphic.attributes.isCluster
                    ) {
                        if (containerPositionLayer.popupVisible) {
                            $rootScope.$broadcast('hideContainerPositionPopup');
                            containerPositionLayer.popupVisible = false;
                            $rootScope.isPopupVisible = false;
                        }
                        return;
                    }

                    if (!$rootScope.isPopupVisible) { // we only draw it if there is no other popup
                        const graphic = hitResult.graphic;
                    
                        const graphicScreenPoint = {
                            x: evt.offsetX,
                            y: evt.offsetY
                        };

                        const coords = containerPositionLayer.transformCoordinates(graphic.attributes.latitude, graphic.attributes.longitude);

                        containerPositionLayer.popupVisible = true;
                        $rootScope.isPopupVisible = true;

                        const dateFormat = $translate.instant('G_MOMENT_DATE_FORMAT');

                        const timestamp = graphic.attributes.latestStatusTimeStamp 
                            ? moment.utc(graphic.attributes.latestStatusTimeStamp).local().format(dateFormat) 
                            : '';

                        $rootScope.$broadcast('showContainerPositionPopup', {
                            left: graphicScreenPoint.x + mapView.position[0] + 20,
                            top: graphicScreenPoint.y + mapView.position[1] + 10,
                            displayText: graphic.attributes.containerNumber || '',
                            fraction: graphic.attributes.fraction?.name || '',
                            name: graphic.attributes.name || '',
                            place: "Lat: " + coords.latitude +' Lon:' + coords.longitude  || '',                
                            timestamp: timestamp                  
                        });
                    }
                };

                containerPositionLayer.transformCoordinates = function(latitude, longitude) {
                    const transformedLatitude = parseFloat(latitude).toFixed(6);
                    const transformedLongitude = parseFloat(longitude).toFixed(6);
                    
                    return {
                      latitude: transformedLatitude,
                      longitude: transformedLongitude
                    };
                };

                containerPositionLayer.onRightClick = function (evt, mapView, hitResponse) {
                    if (hitResponse) {
                        var graphic = hitResponse.results[hitResponse.results.length - 1].graphic;

                        if (graphic.layer.name === containerPositionLayer.name) {
                            var screenPoint = graphic.attributes.screenPoint || {
                                x: evt.offsetX,
                                y: evt.offsetY
                            };

                            if (!graphic.attributes || (!graphic.attributes.isCluster && !(graphic.attributes.type === 'sublocation'))) {
                                $rootScope.$broadcast('showContextMenu', {
                                    item: graphic.attributes.point,
                                    mapPosition: mapView.position,
                                    screenPoint: screenPoint,
                                    type: 'Position'
                                });

                            } else if (graphic.attributes && graphic.attributes.type === 'sublocation') {
                                var item = graphic.attributes.point;
                                item.locationNumber = graphic.attributes.id;
                                item.commune = graphic.attributes.commune;
                                item.containerId = graphic.attributes.containerId;
                                item.displayText = graphic.attributes.displayText;
                                item.fraction = graphic.attributes.fraction;
                                item.containerType = graphic.attributes.containerType;
                                item.degLat = graphic.attributes.lat;
                                item.degLong = graphic.attributes.lon;
                                item.fill = graphic.attributes.fill;
                                item.hasPosition = graphic.attributes.hasPosition;
                                item.poiId = graphic.attributes.poiId;
                                item.place = graphic.attributes.place;
                                item.placeNr = graphic.attributes.placeNr;
                                item.time = graphic.attributes.time;
                                item.locationId = graphic.attributes.locationId;
                                item.volt = graphic.attributes.volt;

                                $rootScope.$broadcast('showContextMenu', {
                                    item: item,
                                    mapPosition: mapView.position,
                                    screenPoint: screenPoint,
                                    focusPoint: mapView.toScreen(graphic.attributes.point),
                                    type: 'Container'
                                });
                            } else if (graphic.attributes && graphic.attributes.isCluster) {
                                $rootScope.$broadcast('showContextMenu', {
                                    mapPosition: mapView.position,
                                    screenPoint: screenPoint,
                                    clusterPoints: graphic.attributes.points,
                                    type: 'Cluster'
                                });
                            }
                        }
                    }
                };

                $rootScope.$on("redrawCluster", function () {
                    containerPositionLayer.redrawData();
                });

                containerPositionLayer.upsertLocationData = function (locations) {
                    containerPositionLayer.updateData(locations);
                };

                containerPositionLayer.loadData = function () {
                    return $rootScope.appInit().then(() => { 
                        return containerService.getContainersWithTracking().then(function (containers) {
                                                     
                            const containersWithPositions = containers.filter(c => c.latitude && c.longitude);
                                                      
                            const containersToLoad = containersWithPositions.reduce((toReturn, current) => {
                                const coords = mapUtility.projectCoordinates(current.longitude, current.latitude);
                                        
                                return toReturn.concat({
                                    ...current,
                                    degLat: coords.Y, 
                                    degLong: coords.X
                                });

                            }, []);

                            containerPositionLayer._locations = containersToLoad;
                            containerPositionLayer.upsertLocationData(containersToLoad);
                        });
                    });
                }

                /**
                 * Filters the displayed locations with the provided deviceExternalId
                 * @param deviceExternalIds The list of deviceExternalIds used to filter the locations by `location.displayText`. 
                 * Leave empty to reset the filtering.
                 */
                containerPositionLayer.setLocationFilter = function (deviceExternalIds) {
                    containerPositionLayerFactory.initialized().then(function () {
                        if (!deviceExternalIds) {
                            containerPositionLayer.upsertLocationData(containerPositionLayer._locations);
                            return;
                        }

                        if (deviceExternalIds.length === 0) {
                            containerPositionLayer.upsertLocationData([]);
                            return;
                        }
                     
                        var filteredLocations = _.filter(containerPositionLayer._locations, function (location) {
                            return _.includes(deviceExternalIds, location.displayText);
                        });
                        containerPositionLayer.upsertLocationData(filteredLocations);
                    });
                }

                containerPositionLayer.loadData().then((locations) => {
                    $rootScope.$broadcast('containerPositionLayerLoaded', {
                        locations
                    });

                    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
                        containerPositionLayer.upsertLocationData(containerPositionLayer._locations);
                    });

                    initDeferred.resolve();
                });

                return containerPositionLayer;
            };

            readyDeferred.resolve(containerPositionLayerFactory);
        });

        return containerPositionLayerFactory;
    }
})();
