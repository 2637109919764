(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('areaDetailsEditorController', areaDetailsEditorController);

    areaDetailsEditorController.$inject = [
        '$rootScope',
        '$scope',
        '$stateParams',
        'sensorApiAreaService',
        '$state',
        'mapService',
        'mapUtility',
        '$translate'
    ];

    function areaDetailsEditorController(
        $rootScope,
        $scope,
        $stateParams,
        sensorApiAreaService,
        $state,
        mapService,
        mapUtility,
        $translate
    ) {
        $scope.save = save;
        $scope.cancel = cancel;
        $scope.handleStartDrawing = handleStartDrawing;
        $scope.handleCancelDrawing = handleCancelDrawing;
        $scope.deleteArea = deleteArea;

        $scope.title = '';
        $scope.isEdit = true;
        $scope.isAreaEditing = false;
        $scope.newPoints = [];

        initController();

        function initController() {
            $scope.isEdit = $stateParams.areaId !== 'new';

            if ($scope.isEdit) {
                loadArea();
            } else {
                $scope.title = $translate.instant('AREA_DETAILS_EDITOR_CONTROLLER_ADD_AREA');
            }

            $scope.$on('$stateChangeStart', function (event, toState, params) {
                clearDrawing();
                if (!toState.name.startsWith('main.areaDetails')) {
                    mapService.getLayer('areaLayer').clearSelectedArea();
                }
            });
        };

        function loadArea() {
            showBusyIndicator();
            sensorApiAreaService.getArea($stateParams.areaId)
                .then(handleAreaResponse)
                .finally(hideBusyIndicator);
        }

        function handleAreaResponse(area) {
            $scope.area = area;
            $scope.areaForm.$setPristine()
            $scope.title = area.description;
            mapService.getLayer('areaLayer').selectArea(area.id);
        }

        function handleStartDrawing(event) {
            event.preventDefault();
            if (
                $scope.newPoints.length !== 0
                && !confirm($translate.instant('AREA_DETAILS_EDITOR_CONTROLLER_START_DRAWING_CONFIRM'))
            ) {
                return;
            }

            clearDrawing();
            $scope.isAreaEditing = true;
            mapService.getLayer('selectionLayer').startSelection()
                .then(onDrawingFinished);
        }

        function onDrawingFinished(points) {
            $scope.newPoints = points;
            $scope.isAreaEditing = false;
            mapService.getLayer('areaLayer').drawTemporaryArea(points);
        }

        function handleCancelDrawing(event) {
            event.preventDefault();
            clearDrawing();
        }

        function clearDrawing() {
            $scope.isAreaEditing = false;
            $scope.newPoints = [];
            mapService.getLayer('selectionLayer').cancelSelection();
            mapService.getLayer('areaLayer').clearTemporaryArea();
        }

        function save(event) {
            event.preventDefault();
            if (!$scope.validator.validate()) {
                return;
            }

            let request;
            const areaDto = getAreaDto();
            showBusyIndicator();
            if ($scope.isEdit) {
                request = sensorApiAreaService.updateArea(areaDto);
            } else {
                request = sensorApiAreaService.createArea(areaDto);
            }

            request.then((area) => {
                clearDrawing();
                mapService.getLayer('areaLayer').loadData();
                $state.go('main.areaDetails', { areaId: area.id });
            }).finally(hideBusyIndicator);
        }

        function cancel(event) {
            event.preventDefault();

            if ($scope.isEdit) {
                $state.go('main.areaDetails');
            } else {
                $state.go('main.area');
            }
        }

        function getAreaDto() {
            const updateModel = {
                id: $scope.area.id,
                description: $scope.area.description,
                isActive: $scope.area.isActive,
            };

            if ($scope.newPoints.length > 0) {
                updateModel.points = $scope.newPoints.map((point, index) => {
                    
                    let coords = mapUtility.convertToWGS84IfNecessary(point[0], point[1]);

                    return {
                        latitude: coords.lat,
                        longitude: coords.lng,
                        order: index,
                    };
                });
            }

            return updateModel;
        }

        function deleteArea(event) {
            event.preventDefault();
            let confirmMsg = $translate.instant('AREA_DETAILS_EDITOR_CONTROLLER_DELETE_AREA_CONFIRM');

            if (!confirm(`${confirmMsg}: ${$scope.area.description}?`)) {
                return;
            }

            sensorApiAreaService.deleteArea($stateParams.areaId)
                .then(() => {
                    mapService.getLayer('areaLayer').loadData();
                    $state.go('main.area', null, { location: 'replace' });
                });
        }

        function showBusyIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'areaDetailsIndicator',
                destination: '#area-details-editor-view',
                message: $translate.instant('G_BUSY_INDICATOR'),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                },
            });
        }

        function hideBusyIndicator() {
            $rootScope.$broadcast('hideBusyIndicator', 'areaDetailsIndicator');
        }
    }
})();
