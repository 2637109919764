(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('AccessItemsOverviewController', AccessItemsOverviewController);

    AccessItemsOverviewController.$inject = [
        '$q',
        '$timeout',
        '$rootScope',
        '$scope',
        '$kWindow',
        'formTypes',
        'accessItemsService',
        'accessItemGroupsService',
        '$translate',
    ];

    function AccessItemsOverviewController(
        $q,
        $timeout,
        $rootScope,
        $scope,
        $kWindow,
        formTypes,
        accessItemsService,
        accessItemGroupsService,
        $translate,
    ) {
        $scope.selectedAccessItems = [];
        $scope.allAccessItemsOverviewGridData = [];
        $scope.showAllItems = false;
        
        $scope.handleItemCheckboxClick = handleItemCheckboxClick;

        $scope.openAccessItemRegistrationModal = openAccessItemRegistrationModal;
        $scope.openUpdateAccessItemModal = openUpdateAccessItemModal;
        $scope.openAddExtraAccessItemModal = openAddExtraAccessItemModal;
        $scope.openReplaceAccessItemModal = openReplaceAccessItemModal;
        $scope.openGroupDetailsModal = openGroupDetailsModal;
        $scope.openMultipleAccessItemEditModal = openMultipleAccessItemEditModal;
        $scope.openGroupUpdateModal = openGroupUpdateModal;
        $scope.openAccessItemImportModal = openAccessItemImportModal;
        $scope.filterActiveItems = filterActiveItems;
        $scope.archiveAccessItem = archiveAccessItem;

        $scope.exportToExcel = exportToExcel;

        const actionColumnsHeaderTitle = ' ';

        //KeyCard type "enum"
        const Keyfob = 0;
        const KeyfobOther = 1;
        const DigitalKey = 2;

        let isData;

        $scope.keycardTypes = [
            {id: Keyfob, name: $translate.instant('ACCESS_CONTROL_KEYCARD_TYPE_KEYFOB')},
            {id: KeyfobOther, name: $translate.instant('ACCESS_CONTROL_KEYCARD_TYPE_KEYFOB_ANNET')},
            {id: DigitalKey, name: $translate.instant('ACCESS_CONTROL_KEYCARD_TYPE_DIGITAL_KEY')}
        ];

        $scope.statusTypes = [
            {id: 0, name: $translate.instant('ACCESS_CONTROL_ITEM_DETAILS_BLOCKED')},
            {id: 1, name: $translate.instant('G_ACTIVE')}
        ];

        const localizedFilename = $translate.instant('ACCESS_CONTROL_CONTROLLER_ACCESS_ITEM_OVERVIEW_FILENAME');
        $scope.excelFileName = `${localizedFilename}.xls`;

        $scope.groupOptions = {
            dataSource: {
                transport: {
                    read: readGroups
                }
            },
            dataTextField: 'name',
            dataValueField: 'id',
            change: handleSelectedGroupChange,
            filter: 'contains'
        };

        $scope.allAccessItemsOverviewGridOptions = {
            dataSource: {
                transport: {
                    read: readAccessItemOverviewGridOptions
                },
                sort: {
                    field: "cardNumber",
                    dir: "asc"
                },
                schema: {
                    model: {
                        fields: {
                            cardNumber: { type: 'string' },
                            isActiveString: { type: 'string' },
                            firstName: { type: 'string' },
                            lastName: { type: 'string' },
                            cityAddress: { type: 'string' },
                            streetAddress: { type: 'string' },
                            userName: { type: 'string' },
                            propertyId: { type: 'string' },
                            hnr: { type: 'string' },
                            knr: { type: 'number' },
                            gnr: { type: 'number' },
                            bnr: { type: 'number' },
                            fnr: { type: 'number' },
                            snr: { type: 'number' },
                            bid: { type: 'number' },
                            type: { type: 'string' },
                            typeName: { type: 'string' },
                            errorText: {}
                        }
                    }
                },
            },
            dataBound: function(e) {
                this.autoFitColumn('type');
                this.autoFitColumn('typeName');
                this.autoFitColumn('isActiveString');
                this.autoFitColumn('firstName');
                this.autoFitColumn('lastName');
                this.autoFitColumn('streetAddress');
                this.autoFitColumn('cityAddress');
                this.autoFitColumn('propertyId');
                this.autoFitColumn('knr');
                this.autoFitColumn('gnr');
                this.autoFitColumn('bnr');
                this.autoFitColumn('fnr');
                this.autoFitColumn('snr');
                this.autoFitColumn('hnr');
                this.autoFitColumn('bid');
            },
            groupable: false,
            sortable: true,
            scrollable: true,
            filterable: {
                mode: "row",
                operators: {
                    string: {
                        "contains": $translate.instant('ACCESS_CONTROL_CONTROLLER_ACCESS_ITEM_OVERVIEW_FILTER_OPERATOR_CONTAINS'),
                        "doesnotcontain": $translate.instant('ACCESS_CONTROL_CONTROLLER_ACCESS_ITEM_OVERVIEW_FILTER_OPERATOR_DOES_NOT_CONTAIN'),
                        "endswith": $translate.instant('ACCESS_CONTROL_CONTROLLER_ACCESS_ITEM_OVERVIEW_FILTER_OPERATOR_ENDS_WITH'),
                        "eq": $translate.instant('ACCESS_CONTROL_CONTROLLER_ACCESS_ITEM_OVERVIEW_FILTER_OPERATOR_EQUALS'),
                        "neq": $translate.instant('ACCESS_CONTROL_CONTROLLER_ACCESS_ITEM_OVERVIEW_FILTER_OPERATOR_NOT_EQUAL'),
                        "startswith": $translate.instant('ACCESS_CONTROL_CONTROLLER_ACCESS_ITEM_OVERVIEW_FILTER_OPERATOR_START_WITH'),
                    }
                }
            },
            noRecords: true,
            resizable: true,
            messages: {
                noRecords: $translate.instant('G_NO_DATA')
            },
            pageable: {
                pageSize: 100
            },
            columns: [
                {
                    field: 'actions',
                    title: actionColumnsHeaderTitle,
                    width: '30px',
                    template: '<input class="checkbox" type="checkbox" id="check" ng-checked="isChecked(dataItem)" ng-click="handleItemCheckboxClick(dataItem)" />',
                    filterable: false,
                    sortable: false,
                    groupable: false
                },
                {
                    field: 'cardNumber',
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_CARD_NUMBER"),
                    width: 190,
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                        }
                    },
                    lockable: false,
                },               
                {
                    field: 'firstName',
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_FIRST_NAME"),
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                        }
                    },
                    lockable: false,
                },
                {
                    field: 'lastName',
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_LAST_NAME"),
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                        }
                    },
                    lockable: false,
                },
                {
                    field: 'streetAddress',
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_STREET_ADDRESS"),
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                        }
                    },
                    lockable: false,
                },
                {
                    field: 'cityAddress',
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_CITY_ADDRESS"),
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                        }
                    },
                    lockable: false,
                },
                {
                    field: 'zipCode',
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_ZIP_CODE"),
                    width: 100,
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                        }
                    },
                    lockable: false,
                },
                {
                    field: 'apartmentNumber',
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_APARTMENT_NUMBER"),
                    width: 100,
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                        }
                    },
                    lockable: false,
                },
                
                {
                    field: 'propertyId',
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_PROPERTYID"),
                    headerTemplate: '<span title="{{ \'ACCESS_ITEMS_OVERVIEW_PROPERTYID_TOOLTIP\' | translate }}">{{ \'ACCESS_ITEMS_OVERVIEW_PROPERTYID\' | translate }}</span>',
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                        }
                    },
                    lockable: false,
                },
                {
                    field: 'knr',
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_KNR"),
                    headerTemplate: '<span title="{{ \'ACCESS_ITEMS_OVERVIEW_KNR_TOOLTIP\' | translate }}">{{ \'ACCESS_ITEMS_OVERVIEW_KNR\' | translate }}</span>',
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                        }
                    },
                    lockable: false,
                },
                {
                    field: 'gnr',
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_GNR"),
                    headerTemplate: '<span title="{{ \'ACCESS_ITEMS_OVERVIEW_GNR_TOOLTIP\' | translate }}">{{ \'ACCESS_ITEMS_OVERVIEW_GNR\' | translate }}</span>',
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                        }
                    },
                    lockable: false,
                },
                {
                    field: 'bnr',
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_BNR"),
                    headerTemplate: '<span title="{{ \'ACCESS_ITEMS_OVERVIEW_BNR_TOOLTIP\' | translate }}">{{ \'ACCESS_ITEMS_OVERVIEW_BNR\' | translate }}</span>',
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                        }
                    }
                },
                {
                    field: 'fnr',
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_FNR"),
                    headerTemplate: '<span title="{{ \'ACCESS_ITEMS_OVERVIEW_FNR_TOOLTIP\' | translate }}">{{ \'ACCESS_ITEMS_OVERVIEW_FNR\' | translate }}</span>',
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                        }
                    },
                    lockable: false,
                },
                {
                    field: 'snr',
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_SNR"),
                    headerTemplate: '<span title="{{ \'ACCESS_ITEMS_OVERVIEW_SNR_TOOLTIP\' | translate }}">{{ \'ACCESS_ITEMS_OVERVIEW_SNR\' | translate }}</span>',
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                        }
                    },
                    lockable: false,
                },
                {
                    field: 'hnr',
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_HNR"),
                    headerTemplate: '<span title="{{ \'ACCESS_ITEMS_OVERVIEW_HNR_TOOLTIP\' | translate }}">{{ \'ACCESS_ITEMS_OVERVIEW_HNR\' | translate }}</span>',
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                        }
                    },
                    lockable: false,
                },
                {
                    field: 'bid',
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_BID"),
                    headerTemplate: '<span title="{{ \'ACCESS_ITEMS_OVERVIEW_BID_TOOLTIP\' | translate }}">{{ \'ACCESS_ITEMS_OVERVIEW_BID\' | translate }}</span>',
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                        }
                    },
                    lockable: false,
                },
                {
                    field: 'typeName',
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_CARD_TYPE"),
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                        }
                    },
                    lockable: false,
                },
                {
                    field: 'isActiveString',
                    title: $translate.instant('ACCESS_CONTROL_ITEM_DETAILS_STATUS'),
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                        }
                    },
                    lockable: false,
                },
                {
                    field: 'groupId',
                    hidden: true,
                    lockable: false,
                },
                {
                    field: 'type',
                    hidden: true,
                    lockable: false,
                }, 
                {
                    field: 'actions',
                    title: actionColumnsHeaderTitle,
                    width: '30px',
                    template: '<div class="grid-tooltip action-icons" ng-click="openUpdateAccessItemModal(dataItem)"><i class="fa fa-1-5x fa-pencil" aria-hidden="true"></div>',
                    filterable: false,
                    locked: true,
                    lockable: false
                },
                {
                    field: 'actions',
                    title: actionColumnsHeaderTitle,
                    width: '30px',
                    template: '<div class="grid-tooltip action-icons" ng-click="openAddExtraAccessItemModal(dataItem)"><i class="fa fa-1-5x fa-plus" aria-hidden="true"></div>',
                    filterable: false,
                    locked: true,
                    lockable: false
                },
                {
                    field: 'actions',
                    title: actionColumnsHeaderTitle,
                    width: '35px',
                    template: '<div class="grid-tooltip action-icons" ng-click="openReplaceAccessItemModal(dataItem)"><i class="fa fa-1-5x fa-exchange" aria-hidden="true"></div>',
                    filterable: false,
                    locked: true,
                    lockable: false
                },
                {
                    field: 'actions',
                    title: actionColumnsHeaderTitle,
                    width: '30px',
                    template: '<div class="grid-tooltip action-icons" ng-click="archiveAccessItem(dataItem)"><i class="fa fa-1-5x fa-trash" aria-hidden="true"></div>',
                    filterable: false,
                    locked: true,
                    lockable: false
                },
            ],
            columnMenu: true,
            columnMenuInit: function(e) {
                var menu = e.container.find('.k-menu');
                
                // Find and remove menu items related to "actions" columns
                menu.find('li.k-item').each(function() {
                    var text = $(this).text().trim();
                    if (text === '') {
                        $(this).remove();
                    }
                });

                menu.find("input[type='checkbox']").on("click", function() {
                    var checkbox = $(this);
                    var columnField = checkbox.closest("li.k-item").attr("data-field");
        
                    handleColumnSelectionChange();
                });

            },
            excel: {
                fileName: $scope.excelFileName,
                filterable: true,
                allPages: true
            },
            excelExport: allAccessItemsOverviewGridExcelExport
        };

        $timeout(250).then(initController);

        // Recalculate the columns widths after select/deselect them
        function handleColumnSelectionChange() {
            setTimeout(function() {
                var grid = $("#allAccessItemsOverviewGrid").getKendoGrid();
                if (grid) {
                    var totalWidth = grid.table.width();
                    var visibleColumns = grid.columns.filter(col => !col.hidden && col.field !== 'actions');
                    var numVisibleColumns = visibleColumns.length;
                    var avgColumnWidth = totalWidth / numVisibleColumns;

                    visibleColumns.forEach(col => {
                        if (col.field !== 'actions') {
                            col.width = avgColumnWidth + 50 + "px";
                        }
                    });

                    grid.refresh();
                }
            }, 100);
        };

        function initController() {
            showBusyIndicator();

            let request;

            if($rootScope.isAdmin() || $rootScope.isSuperAdmin()) {
                request = accessItemGroupsService.getAll();
            } else {
                if($rootScope.authData?.authUser?.municipalities?.length > 0) {
                    request = accessItemGroupsService.getGroupsByMunicipality($rootScope.authData.authUser.municipalities.map(m => m.code));
                }
            }

            if (request) {
                request.then(data => {
                    const accessItemGroups = _.orderBy(data, 'name');

                    $scope.groupsDropdown.dataSource.data(accessItemGroups);
                    $scope.groupsDropdown.refresh();
                    isData = accessItemGroups.length > 0 && accessItemGroups[0].id;
                    if (isData) {
                        $scope.selectedGroup = accessItemGroups[0];

                        return loadAccessItemsByGroup(accessItemGroups[0].id);
                    }
                }).finally(() => {
                    if (!isData) {
                        hideBusyIndicator
                    }
                });
            }else {
                hideBusyIndicator();
            }
        }

        //Data loading
        function loadAccessItemsByGroup(accessItemGroupId) {
            return accessItemsService.getAllAccessItemsByGroup(accessItemGroupId)
                .then(function (accessItems) {
                    const accessItemsWithStringType = accessItems?.map(accessItem => {
                        return {
                          ...accessItem,
                          typeName: getKeycardTypeNameById(accessItem.type),
                          isActiveString: getAccesItemStatus(accessItem.isActive)
                        };
                    });
                    $scope.accessItemsOfSelectedGroup = accessItemsWithStringType;

                    filterActiveItems();

                    $scope.selectedAccessItems = [];
                });
        }

        //Event handlers
        function handleSelectedGroupChange() {
            const selectedGroupId = this.value();
            if (selectedGroupId) {
                loadAccessItemsByGroup(selectedGroupId);
            }
        }

        function openAccessItemRegistrationModal() {
            const windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ACCESS_CONTROL_ITEMS_OVERVIEW_VIEW_ACCESS_ITEM_REGISTRATION'),
                    resizable: false,
                    height: 730,
                    visible: false
                },
                templateUrl: 'app/accesscontrol/views/access-item-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'AccessItemDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.add;
                    },
                    currentGroup: function () {
                        return $scope.selectedGroup;
                    },
                    currentAccessItem: function () {
                        return null;
                    },
                    multiEditAccessItems: function () {
                        return [];
                    }
                }
            });

            windowInstance.result.then(function (result) {
                $timeout(250).then(() => loadAccessItemsByGroup($scope.selectedGroup.id));
            });
        }

        function openAccessItemImportModal() {
            const windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ACCESS_CONTROL_ITEMS_OVERVIEW_VIEW_ACCESS_ITEM_IMPORT'),
                    resizable: false,
                    height: 500,
                    width: 1100,
                    visible: false
                },
                templateUrl: 'app/accesscontrol/views/access-item-import-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'AccessItemImportModalController',
                resolve: {
                    currentGroup: function () {
                        return $scope.selectedGroup;
                    },
                }
            });

            windowInstance.result.then(function (result) {
                $timeout(250).then(() => loadAccessItemsByGroup($scope.selectedGroup.id));
            });
        }

        function openUpdateAccessItemModal(dataItem) {
            const windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ACCESS_CONTROL_ITEMS_OVERVIEW_CONTROLLER_KEYCARD'),
                    resizable: false,
                    height: 750,
                    visible: false
                },
                templateUrl: 'app/accesscontrol/views/access-item-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'AccessItemDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.edit;
                    },
                    currentGroup: function () {
                        return $scope.selectedGroup;
                    },
                    currentAccessItem: function () {
                        return dataItem;
                    },
                    multiEditAccessItems: function () {
                        return [];
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    $timeout(250).then(() => loadAccessItemsByGroup($scope.selectedGroup.id));
                }
            });
        }

        function openAddExtraAccessItemModal(dataItem) {
            const windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('LOCATION_ACCESS_CONTROL_ADD_EXTRA_KEYCARD'),
                    resizable: false,
                    height: 550,
                    visible: false
                },
                templateUrl: 'app/accesscontrol/views/access-item-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'AccessItemDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.extra;
                    },
                    currentGroup: function () {
                        return $scope.selectedGroup;
                    },
                    currentAccessItem: function () {
                        return dataItem;
                    },
                    multiEditAccessItems: function () {
                        return [];
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    $timeout(250).then(() => loadAccessItemsByGroup($scope.selectedGroup.id));
                }
            });
        }

        function openReplaceAccessItemModal(dataItem) {
            const windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ACCESS_CONTROL_ITEMS_OVERVIEW_CONTROLLER_REPLACE_KEYCARD'),
                    resizable: false,
                    height: 550,
                    width: 500,
                    visible: false
                },
                templateUrl: 'app/accesscontrol/views/access-item-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'AccessItemDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.replace;
                    },
                    currentGroup: function () {
                        return $scope.selectedGroup;
                    },
                    currentAccessItem: function () {
                        return dataItem;
                    },
                    multiEditAccessItems: function () {
                        return [];
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    $timeout(250).then(() => loadAccessItemsByGroup($scope.selectedGroup.id));
                }
            });
        }

        function openGroupDetailsModal() {
            const windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ACCESS_CONTROL_ITEMS_OVERVIEW_VIEW_ADD_GROUP'),
                    resizable: false,
                    height: 150,
                    width: 300,
                    visible: false
                },
                templateUrl: 'app/accessControl/views/access-item-group-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'AccessItemGroupDetailsModalController',
                resolve: {
                    newGroupOnlyMode: function () {
                        return true;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result && result.id) {
                    const currentGroups = $scope.groupsDropdown.dataSource.data();

                    if (!currentGroups.find(g => g.id === result.id)) {
                        currentGroups.push(result);
                        $scope.groupsDropdown.dataSource.data(currentGroups);

                        //In case this is the first assigned group, load the items
                        if (currentGroups.length === 1) {
                            loadAccessItemsByGroup(result.id);
                        }
                    }
                }
            });
        }

        function openGroupUpdateModal() {
            const windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ACCESS_CONTROL_ITEMS_OVERVIEW_VIEW_RENAME_GROUP'),
                    resizable: false,
                    height: 150,
                    width: 600,
                    visible: false
                },
                templateUrl: 'app/accessControl/views/access-item-group-update-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'AccessItemGroupUpdateModalController',
                resolve: {
                    currentGroup: function () {
                        return $scope.selectedGroup;
                    },
                }
            });

            windowInstance.result.then(function (result) {
                if (result?.id) {
                    const currentGroups = $scope.groupsDropdown.dataSource.data();
                    const affectedGroup = currentGroups.find(g => g.id === result.id);

                    if (affectedGroup) {
                        currentGroups.splice(currentGroups.indexOf(affectedGroup),1);
                        currentGroups.push(result);
                        $scope.groupsDropdown.dataSource.data(currentGroups);                        
                        $scope.selectedGroup = result;
                        $scope.groupsDropdown.select(currentGroups.length -1);
                       
                        loadAccessItemsByGroup(result.id);                        
                    }
                }
            });
        }

        function openMultipleAccessItemEditModal() {
            if ($scope.selectedAccessItems.length === 0) return;

            const windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ACCESS_CONTROL_ITEMS_OVERVIEW_CONTROLLER_MULTIPLE_ACCESS_ITEM_EDIT'),
                    resizable: false,
                    height: 750,
                    visible: false
                },
                templateUrl: 'app/accesscontrol/views/access-item-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'AccessItemDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.editMultiple;
                    },
                    currentGroup: function () {
                        return $scope.selectedGroup;
                    },
                    currentAccessItem: function () {
                        return null;
                    },
                    multiEditAccessItems: function () {
                        return $scope.selectedAccessItems;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    $timeout(250).then(() => loadAccessItemsByGroup($scope.selectedGroup.id));
                }
            });
        }

        function allAccessItemsOverviewGridExcelExport(e) {
            const fileName = $translate.instant('ACCESS_CONTROL_ITEMS_OVERVIEW_CONTROLLER_EXPORT_FILENAME');
            e.workbook.fileName = `${fileName} ${kendo.toString(new Date, 'ddMMyyyy_HHmmss')}.xlsx`;
            const filteredSheet = deleteEmptyColumns(e.workbook.sheets[0]);
            e.workbook.sheets[0] = filteredSheet;
            const columnWidths = this.element.find('.k-grid-header colgroup col');

            for (let i = 0; i < e.workbook.sheets[0].columns.length; i++) {
                if ($(columnWidths[i]).attr('style')) {
                    e.workbook.sheets[0].columns[i].width = 160;
                } else {
                    e.workbook.sheets[0].columns[i].width = 16;
                }
            }
        }

        function deleteEmptyColumns(table) {
            const rows = table.rows;
            const columns = table.columns;
          
            // Find the indices of the columns with empty titles in the header row
            const headerRow = rows.find(row => row.type === 'header');
            const indicesToDelete = headerRow.cells.reduce((acc, cell, index) => {
              if (cell.value.trim() === '') {
                acc.push(index);
              }
              return acc;
            }, []);
          
            // Remove the cells with the found indices from all data rows
            rows.forEach(row => {
              if (row.type === 'data') {
                indicesToDelete.slice().reverse().forEach(index => row.cells.splice(index, 1));
              }
            });
          
            // Remove the cells with the found indices from the header row
            indicesToDelete.reverse().forEach(index => headerRow.cells.splice(index, 1));
          
            // Update column widths and other properties after deletion
            indicesToDelete.forEach(index => {
              columns.splice(index, 1);
            });
          
            // Update the filter range (if applicable)
            const filter = table.filter;
            filter.to -= indicesToDelete.length;
          
            return table;
          }

        function exportToExcel() {
            alert($translate.instant('ACCESS_CONTROL_ITEMS_OVERVIEW_CONTROLLER_EXPORT_ALERT'));
            $scope.allAccessItemsOverviewGrid.saveAsExcel();
        }

        function handleItemCheckboxClick(item) {
            if ($scope.selectedAccessItems.indexOf(item) !== -1) {
                $scope.selectedAccessItems = _.reject($scope.selectedAccessItems, function (e) { return e.cardNumber === item.cardNumber });
            }
            else {
                $scope.selectedAccessItems.push(item);
            }
        }

        //Private helpers
        function getKeycardTypeNameById(id) {
            const name = $scope.keycardTypes.find(
                (type) => type.id == id
                )?.name;
                return name;
        }

        function getAccesItemStatus(accessItemStatus) {
            if (accessItemStatus) {
                return $translate.instant('G_ACTIVE');
            } else {
                return $translate.instant('ACCESS_CONTROL_ITEM_DETAILS_BLOCKED');
            }
        }

        function showBusyIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'AllAccessItemsOverviewIndicator',
                destination: '#allAccessItemsOverviewGrid',
                message: $translate.instant('G_BUSY_INDICATOR'),
                overlay: true,
                positionClass: {
                    top: '150px',
                    left: '0px',
                    right: '0px'
                }
            });
        }

        function hideBusyIndicator() {
            $rootScope.$broadcast('hideBusyIndicator', 'AllAccessItemsOverviewIndicator');
        }

        function filterActiveItems() {
            $timeout(() => {
                const displayedList = $scope.showAllItems ? $scope.accessItemsOfSelectedGroup : $scope.accessItemsOfSelectedGroup.filter(el => el.isActive === true);
                $scope.allAccessItemsOverviewGrid.dataSource.data(displayedList);
                $scope.allAccessItemsOverviewGrid.refresh();
                const grid = $('#allAccessItemsOverviewGrid').getKendoGrid();
                grid.autoFitColumn('cardNumber');
                hideBusyIndicator();
            }, 100); //Timeout to allow the slider to finish animating before the grid refresh would block the ui
        }

        function archiveAccessItem(dataItem) {
            if (confirm($translate.instant('ACCESS_CONTROL_ITEM_DETAILS_ARCHIVE_CONFIRM') + `: ${dataItem.cardNumber}?`)) {
                accessItemsService.archiveAccessItem(dataItem.id).then(() => 
                {
                    $scope.accessItemsOfSelectedGroup = $scope.accessItemsOfSelectedGroup.filter(item => item.id !== dataItem.id);
                    $scope.allAccessItemsOverviewGrid.dataSource.data($scope.accessItemsOfSelectedGroup);
                    $scope.allAccessItemsOverviewGrid.refresh();
                });
            }           
        }

        function readGroups(e) {
            e.success([]);
        }

        function readAccessItemOverviewGridOptions(e) {
            e.success([]);
        }

        $rootScope.$on('panelsResized', function () {
            var elements = document.getElementById('accessItemsOverview-view');
            if (elements) {
                if (elements.offsetWidth < 1160) {
                    elements.classList.add('access-menu-view-active');
                } else {
                    elements.classList.remove('access-menu-view-active');
                }
            }
        });

    }
})();
