(function () {
    angular.module('UndergroundWebApp').controller('MainController', MainController);

    MainController.$inject = [
        '$scope',
        '$rootScope',
        '$state',
        '$timeout',
        'globalEvents',
        'environmentConfig',
        'mapService',
        'mapUtility',
        'baseLayerFactory',
        'flyFotoLayerFactory',
        'containerLayerFactory',
        'fillDateLayerFactory',
        'batteryStatusLayerFactory',
        'containerPositionLayerFactory',
        'esriLoader',
        '$kWindow',
        'locationLayerFactory',
        'selectionLayerFactory',
        'areaLayerFactory',
        '$translate'
    ];

    function MainController(
        $scope,
        $rootScope,
        $state,
        $timeout,
        globalEvents,
        environmentConfig,
        mapService,
        mapUtility,
        baseLayerFactory,
        flyFotoLayerFactory,
        containerLayerFactory,
        fillDateLayerFactory,
        batteryStatusLayerFactory,
        containerPositionLayerFactory,
        esriLoader,
        $kWindow,
        locationLayerFactory,
        selectionLayerFactory,
        areaLayerFactory,
        $translate) {

        const zoomToContainersExceptions = [
            'main.areaDetails.edit',
            'main.areaDetails',
            'main.locationDetails'
        ];

        $scope.currentLocationId = null;
        $scope.mapLayers = [];
        $scope.menuOptions = {};
        $rootScope.showMenu = environmentConfig.showMenu || false;
        $scope.showNavbar = true;
        $scope.isDarkLogo = true;

        initController();

        function initController() {
            $rootScope.$on(globalEvents.zoomToLocation, handleZoomToLocation);
            $rootScope.$on(globalEvents.layerVisibilityChanged, handleLayerVisibilityChanged);

            handleInternetExplorer();
        }

        esriLoader.require([
            "esri/geometry/Extent",
            "esri/geometry/SpatialReference",
            'esri/geometry/support/webMercatorUtils'
        ], function (Extent, SpatialReference, WebMercatorUtils) {
            $scope.$on('ContainerLayerLoaded', function () {
                if (zoomToContainersExceptions.includes($state.current.name)) return;

                var clusterLayer = mapService.getLayer('containerLayer');
                if (!clusterLayer) return;

                $rootScope.appInit().then(() => {
                    mapService.zoomToContainers(clusterLayer);
                });
            });
        });

        mapService.ready().then(function () {
            mapService.createMap('map');

            mapService.on('zoom', function () {
                if ($scope.menuOptions.close) {
                    $scope.menuOptions.close();
                }
            });
            mapService.on('center', function () {
                if ($scope.menuOptions.close) {
                    $scope.menuOptions.close();
                }
            });
            mapService.on('click', function () {
                if ($scope.menuOptions.close) {
                    $scope.menuOptions.close();
                }
            });

            if (!mapService.isInitialized()) {
                mapService.addLayerFactory([
                    baseLayerFactory,
                    flyFotoLayerFactory,
                    containerLayerFactory,
                    fillDateLayerFactory,
                    containerPositionLayerFactory,
                    batteryStatusLayerFactory,
                    locationLayerFactory,
                    selectionLayerFactory,
                    areaLayerFactory,
                ]);
            } else if ($rootScope.initRequired) {
                mapService.reInitialize();
                $rootScope.initRequired = false;
            }
        });

        //Event handlers
        function handleZoomToLocation(_, location) {
            mapService.ready()
                .then(() => mapService.setInitialPosition(location, 17))
                .then(mapService.mapCreated)
                .then(() => mapService.zoomToLocation(location));
        }

        function handleLayerVisibilityChanged(_, layerInfo) {
            $scope.isDarkLogo = !layerInfo.FlyFotoLayer;
        }

        //Private functions
        function handleInternetExplorer() {
            if (window.document.documentMode) {
                $kWindow.open({
                    options: {
                        modal: true,
                        draggable: false,
                        title: $translate.instant('G_WARNING'),
                        resizable: false,
                        height: 120,
                        width: 400,
                        visible: false,
                        close: function (e) {
                            e.preventDefault();
                        },
                        actions: []
                    },
                    templateUrl: 'app/main/internet-explorer-warning.html',
                    windowTemplateUrl: 'app/shared/modal-base.html'
                });
            }
        }
    }
})();
