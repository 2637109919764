/**
 * Collection of service calls related to unmodified or rarely modified data.
 */
(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory(BaseDataService);

    BaseDataService.$inject = ['$q', '$http', 'serviceUrls'];

    function BaseDataService($q, $http, serviceUrls) {
        var locationBaseUrl = serviceUrls.serviceBaseUrl + serviceUrls.locationServiceUrl;
        var service = {
            getMeasurementUnitTypes: getMeasurementUnitTypes,
            getMeasurementTypes: getMeasurementTypes
        };

        return service;

        function getMeasurementUnitTypes() {
            var deferred = $q.defer(),
                getMeasurementUnitTypesUrl = locationBaseUrl + '/GetMeasurementUnitTypes';

            $http.get(getMeasurementUnitTypesUrl).then(function (response) {
                deferred.resolve(response.data.GetMeasurementUnitTypesResult);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getMeasurementTypes() {
            var deferred = $q.defer(),
                getMeasurementTypesUrl = locationBaseUrl + '/getMeasurementTypes';

            $http.get(getMeasurementTypesUrl).then(function (response) {
                deferred.resolve(response.data.GetMeasurementTypesResult);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
