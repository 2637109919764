(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('VehicleDetailsModalController', VehicleDetailsModalController);

    VehicleDetailsModalController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$windowInstance',
        'vehicleService',
        'formTypes',
        'currentFormType',
        'vehicle',
        'fuelTypes',
        '$translate',
        'locationsService'
    ];

    function VehicleDetailsModalController(
        $q,
        $scope,
        $rootScope,
        $windowInstance,
        vehicleService,
        formTypes,
        currentFormType,
        vehicle,
        fuelTypes,
        $translate,
        locationsService,
    ) {
        $scope.fuelTypes = fuelTypes;

        $scope.saveAndClose = saveAndClose;
        $scope.closeWindow = closeWindow;

        $scope.searchBoxText = "";
        $scope.locations = [];
        $scope.activityTime = {hours: 0, minutes: 0}; 

        $scope.locationSearchOptions = {
            noDataTemplate: $translate.instant("G_NO_DATA"),
            filter: 'contains',
            dataTextField: 'text',
            highlightFirst: true,
            dataSource: new kendo.data.DataSource({
                serverFiltering: true,
                transport: {
                    read: readLocationSearch
                }
            }),
            select: function (e) {
                $scope.vehicle.depotId = e.dataItem.locationId;
            }
        };

        init();

        $scope.vehicle = vehicle != null
            ? { ...vehicle }
            : { fuelType: 0 };

        function saveAndClose(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {
                save().then((result) => {
                    $windowInstance.close(result);
                });
            }
        }

        function init() {
            loadLocations().then(() => {
                if ($scope.vehicle && $scope.vehicle.depotId){
                    let location = $scope.locations.find(x => x.id == $scope.vehicle.depotId);
                    if(location){
                        $scope.searchBoxText = location.name;
                    }
                }

                if ($scope.vehicle && $scope.vehicle.numberOfActiveMinutes){
                    $scope.activityTime.hours = Math.floor($scope.vehicle.numberOfActiveMinutes / 60);
                    $scope.activityTime.minutes = $scope.vehicle.numberOfActiveMinutes % 60;
                }
            })
        }

        function closeWindow(event) {
            event.preventDefault();
            $windowInstance.close(false);
        }

        function readLocationSearch(e) {
            if ($scope.locations.length === 0) {
                loadLocations().then(() => {
                    readLocationsSuggestionInternal(e);
                })
            } else {
                readLocationsSuggestionInternal(e);
            }
        }

        function readLocationsSuggestionInternal(e) {
            var suggestions = [];
            $scope.vehicle.depotId = null;

            //Search for suggestions in our access items
            if ($scope.searchBoxText.length > 2) {
              
                var relatedLocations = $scope.locations.filter(function (item) {
                    return item.name && item.name.toLowerCase().indexOf($scope.searchBoxText.toLowerCase()) === 0;
                });

                relatedLocations.forEach(function (item) {
                    suggestions.push({
                        locationId: item.id,
                        text: item.name,
                        magicKey: item.id,
                        searchType: 'Location'
                    });
                });

                e.success(_.uniqBy(_.orderBy(suggestions, 'text'), 'text'));
            } else {
                e.success(_.uniqBy(suggestions, 'text'));
            }
        }

        function loadLocations() {
            return locationsService.getLocations(false).then(function (locations) {
                $scope.locations = locations;
            });
        }

        function save() {
            $rootScope.$broadcast('showGlobalBusyIndicator', 'vehicleIndicator');

            $scope.vehicle.numberOfActiveMinutes = $scope.activityTime.hours * 60 + $scope.activityTime.minutes;

            const vehiclePromise = currentFormType === formTypes.edit
                ? vehicleService.updateVehicle($scope.vehicle)
                : vehicleService.createVehicle($scope.vehicle);
            let savePromise = vehiclePromise;

            if ($scope.vehicleImage != null) {
                const imagePromise = vehiclePromise.then((vehicle) => {
                    return vehicleService.addImage(vehicle.id, $scope.vehicleImage);
                });
                savePromise = $q.all([vehiclePromise, imagePromise])
                    .then(([vehicle]) => vehicle);
            }

            return savePromise
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'vehicleIndicator'));
        }

    }
})();
