(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ClientDetailsModalController', ClientDetailsModalController);

    ClientDetailsModalController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$timeout',
        '$windowInstance',
        'clientService',
        'externalSystemsService',
        'formTypes',
        'currentFormType',
        'client',
        'allClients',
        'statuses',
        'modulesService'
    ];

    function ClientDetailsModalController($q,
        $scope,
        $rootScope,
        $timeout,
        $windowInstance,
        clientService,
        externalSystemsService,
        formTypes,
        currentFormType,
        client,
        allClients,
        statuses,
        modulesService
    ) {
        $scope.isEdit = currentFormType === formTypes.edit;
        $scope.isAdd = currentFormType === formTypes.add;
        $scope.clientName = '';
        $scope.status = '';

        $scope.addedExtermalSystems = [];
        $scope.addedClientModules = [];
        $scope.allExtermalSystems = [];        
        $scope.selectedExternalSystemId = '';

        $scope.ClientStatusOptions = {
            dataSource: {
                transport: {
                    read: readClientStatusOptions
                }
            },
            dataTextField: 'LocalizedName',
            dataValueField: 'Id'
        };

        $scope.externalSystemOptions = {
            dataSource: {
                transport: {
                    read: readExternalSystems
                }
            },
            dataTextField: 'name',
            dataValueField: 'id'
        };

        $scope.clientModuleOptions = {
            dataSource: {
            transport: {
                read: readModules
            }
            },
            dataTextField: 'key',
            dataValueField: 'id'
        };
    
        $scope.clientNameValid = true;
        
        //Public functions        
        $scope.saveAndClose = saveAndClose;
        $scope.closeWindow = closeWindow;
        $scope.addExternalSystem = addExternalSystem;
        $scope.removeExternalSystem = removeExternalSystem;
        $scope.addClientModule = addClientModule;
        $scope.removeClientModule = removeClientModule;

        $timeout(250).then(initController);

        function initController() {
            
            if ($scope.isEdit) {
                $scope.clientName = client.name;
                $scope.status = statuses.filter(x => x.Name == client.status)[0];

                let index = statuses.indexOf($scope.status, 0);

                var dropdownlist = $("#client-status").data("kendoDropDownList");
                dropdownlist.select(index);            
            }else{
                $scope.status = statuses[0];
            }
        }

        function saveAndClose() {
            save().then(function (result) {
                $windowInstance.close(result);
                $('#clientOverviewGrid').getKendoGrid().dataSource.read();
            });
        }

        function closeWindow(result) {
            event.preventDefault();
            $windowInstance.close(result);
        }

        function save() {
            event.preventDefault();

            let deferred = $q.defer();
            $scope.clientNameValid = validateClientName();

            if ($scope.validator.validate()
                && $scope.clientNameValid) {

                $rootScope.$broadcast('showGlobalBusyIndicator', 'clientIndicator');

                let clientDto = {
                    Name: $scope.clientName,
                    Status: $scope.status.Name,
                    ClientExternalSystems: $scope.addedExtermalSystems.map(x => ({ExternalSystemId: x.id})),
                    ClientModules: $scope.addedClientModules.map(x => ({ModuleId: x.id}))                    
                }

                if (currentFormType === formTypes.edit) {
                    clientDto.Id = client.id,

                    clientService.updateClient(clientDto).then(function (result) {
                        deferred.resolve(result);
                        $rootScope.$broadcast('hideBusyIndicator', 'clientIndicator');
                    }, function () {
                        deferred.reject();
                        $rootScope.$broadcast('hideBusyIndicator', 'clientIndicator');
                    });
                }
                else {
                    clientService.addClient(clientDto).then(function (result) {
                        deferred.resolve(result);
                        $rootScope.$broadcast('hideBusyIndicator', 'clientIndicator');
                    }, function () {
                        deferred.reject();
                        $rootScope.$broadcast('hideBusyIndicator', 'clientIndicator');
                    });
                }
            }

            return deferred.promise;
        }
      
        function validateClientName() {
            var matchClients;
            if (currentFormType === formTypes.edit) {
                //can edit own name
                matchClients = _.filter(allClients, ['name', $scope.clientName]);
                if (matchClients && matchClients.length) {
                    if (matchClients.length === 1 && matchClients[0].id == client.id) {
                        return true;
                    }
                    return false;
                }
                return true;
            } else {
                //only can add client once
                matchClients = _.filter(allClients, ['name', $scope.name]);
                if (matchClients.length) {
                    return false;
                }
                return true;
            }
        }

        function addExternalSystem(evt, selectedExternalSystemId) {
            evt.preventDefault();

            if (selectedExternalSystemId && !$scope.addedExtermalSystems.some(es => es.id === selectedExternalSystemId)) {
                let extermalSystemToAdd = $scope.allExtermalSystems.find(es => es.id === selectedExternalSystemId);
                if (extermalSystemToAdd) {
                    $scope.addedExtermalSystems.push(extermalSystemToAdd);
                }
            }
        }

        function removeExternalSystem(evt, externalSystem) {
            var index = $scope.addedExtermalSystems.findIndex(es => es.id === externalSystem.id);
            if (index > -1) {
                $scope.addedExtermalSystems.splice(index, 1);
            }
        }

        function readExternalSystems(e) {
            externalSystemsService.getAllExternalSystems()
                .then(result => {
                    $scope.allExtermalSystems = result;

                    return result;
                })
                .then((externalSystems) => {
                    e.success(externalSystems);

                    if ($scope.isEdit && client && client.clientExternalSystems) {
                        $scope.addedExtermalSystems = externalSystems.filter(es => client.clientExternalSystems.some(ces => es.id === ces.externalSystemId));
                    }
                });
        }
       
        function addClientModule(evt, selectedClientModuleId) {
            evt.preventDefault();
        
            if (selectedClientModuleId && !$scope.addedClientModules.some(cm => cm.id === selectedClientModuleId)) {
                const clientModuleToAdd = $scope.allModules.find(cm => cm.id === selectedClientModuleId);
            if (clientModuleToAdd) {
                $scope.addedClientModules = [...$scope.addedClientModules, clientModuleToAdd];
            }
            }
        };
    
        function removeClientModule(evt, clientModule) {
            $scope.addedClientModules = $scope.addedClientModules.filter(cm => cm.id !== clientModule.id);
        };
        
        function readModules(e) {
            modulesService.getModules()
                .then(result => {
                    $scope.allModules = result.filter(m => m.key !== 'CORE');
        
                    return result;
                })
                .then((modules) => {

                    if ($scope.isEdit && client && client.clientModules) {
                        $scope.addedClientModules = modules.filter(cm => client.clientModules.some(ccm => cm.id === ccm.moduleId));
                    } else if($scope.isAdd) {
                        $scope.addedClientModules = modules.filter(cm => cm.key === 'CORE');
                    }

                    e.success(modules.filter(m => m.key !== 'CORE'));
                })
                .catch(e => {
                    console.log("Error when getting modules list: ", e);
                });
        }

        function readClientStatusOptions(e) {
            e.success(statuses);
        }

    }
})();
