(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('FractionsController', FractionsController);

    FractionsController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$kWindow',
        'fractionsService',
        'formTypes',
        'statusOptions',
        'filterStateService',
        '$translate'
    ];

    function FractionsController(
        $q,
        $scope,
        $rootScope,
        $kWindow,
        fractionsService,
        formTypes,
        statusOptions,
        filterStateService,
        $translate
    ) {
        $scope.sortedFractions = [];
        $scope.openNewFraction = openNewFraction;
        $scope.deleteFraction = deleteFraction;
        $scope.openUpdateFraction = openUpdateFraction;

        $scope.fractionOverviewGridOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readFractions
                },
                pageSize: 70,
                sort: filterStateService.getSortFromQueryString(),
                filter: filterStateService.getFilterFromQueryString(),
            }),
            columns: [
                {
                    field: 'imageUrl',
                    title: $translate.instant('G_IMAGE'),
                    width: '58px',
                    filterable: false,
                    sortable: false,
                    resizable: false,
                    template: `
                        <img ng-src="{{dataItem.imageUrl}}"
                            style="height: 24px; margin-top: 0.5em;" />`
                },
                { 
                    field: 'externalIdNumber', 
                    title: $translate.instant('G_EXTERNAL_ID'),
                    width: '120px' 
                },
                { 
                    field: 'name', 
                    title: $translate.instant('G_NAME')
                },
                {
                    field: 'edit',
                    filterable: false,
                    sortable: false,
                    groupable: false,
                    title: ' ',
                    width: '80px',
                    template: '<i class="fa fa-1-5x fa-pencil fa-fw" ng-click="openUpdateFraction(dataItem)" aria-hidden="true"></i>' +
                        '<i class="fa fa-1-5x fa-trash fa-fw" ng-click="deleteFraction(dataItem)" aria-hidden="true"></i>'
                }
            ],
            scrollable: {
                virtual: true
            },
            sortable: true,
            filterable: true,
            noRecords: true,
            resizable: true,
            selectable: false,
            change: onFractionRowSelected,
            sort: filterStateService.storeGridSortInQueryString,
            filter: filterStateService.storeGridFilterInQueryString,
        };

        function readFractions(e) {
            $scope.showBusyIndicator();
            $q.all([
                fractionsService.getFractions(),
                fractionsService.getStandardFractions()
            ]).then(data => {
                extFractions(data[0], data[1]);
                let fractions = data[0];
                $scope.sortedFractions = _.chain(fractions)
                    .filter(f => f.status === statusOptions.active.Id)
                    .sortBy(f => f.name.toLowerCase())
                    .value();
                $scope.hideBusyIndicator();
                e.success($scope.sortedFractions);
            }, function () {
                $scope.hideBusyIndicator();
                e.success([]);
            });

            $q.all([
                fractionsService.getFractions(),
                fractionsService.getStandardFractions()
            ]).then(data => {
                extFractions(data[0], data[1]);
            });
        }

        function extFractions(fractions, standardFractions) {
            _.forEach(fractions, f => {
                f.externalIdNumber = Number(f.externalId);
                if (standardFractions) {
                    const fractionWithImage = standardFractions.find(sf => sf.id === f.standardFractionId);
                    f.imageUrl = fractionWithImage ? fractionWithImage.imageUrl : '';
                }
            });
        }

        function openNewFraction() {
            openFractionModal($translate.instant('ADMINISTRATION_FRACTION_NEW_FRACTION'), formTypes.add)
                .result.then(function() { });
        }

        function openUpdateFraction(dataItem) {
            openFractionModal($translate.instant('ADMINISTRATION_FRACTION_FRACTION'), formTypes.edit, dataItem)
                .result.then(function () { });
        }

        function deleteFraction(dataItem) {
            let confirmText = $translate.instant('ADMINISTRATION_FRACTION_DIALOG_CONFIRM_DELETE');

            if (confirm(`${confirmText} ${dataItem.name}?`)) {
                dataItem.status = statusOptions.deleted.Id;
                fractionsService.updateFraction(dataItem).then(() => {
                    $('#fractionsGrid').getKendoGrid().dataSource.read();
                });
            }
        }

        function onFractionRowSelected() {
            var selectedRows = this.select();
            $rootScope.selectedContainerId = this.dataItem(selectedRows[0]).id;
        }

        function openFractionModal(title, formType, fraction) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: title,
                    resizable: false,
                    height: 325,
                    width: 500,
                    visible: false
                },
                templateUrl: 'app/administration/fraction/fraction-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'FractionDetailsModalController',
                resolve: {
                    currentFormType: () => formType,
                    fraction: () => fraction
                }
            });
        }
    }
})();

