(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('MunicipalityDetailsModalController', MunicipalityDetailsModalController);

    MunicipalityDetailsModalController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$timeout',
        '$windowInstance',
        'municipalityService',
        'formTypes',
        'currentFormType',
        'municipality',
        'allMunicipalities'
    ];

    function MunicipalityDetailsModalController($q,
        $scope,
        $rootScope,
        $timeout,
        $windowInstance,
        municipalityService,
        formTypes,
        currentFormType,
        municipality,
        allMunicipalities
    ) {
        $scope.isEdit = currentFormType === formTypes.edit;
        $scope.municipalityName = '';
        $scope.municipalityCode = '';

        $scope.municipalityNameValid = true;
        $scope.municipalityCodeValid = true;
        
        //Public functions        
        $scope.saveAndClose = saveAndClose;
        $scope.closeWindow = closeWindow;

        $timeout(250).then(initController);

        function initController() {
            
            if ($scope.isEdit) {
                $scope.municipalityName = municipality.name;                
                $scope.municipalityCode = municipality.code;                
            }
        }

        function saveAndClose() {
            save().then(function (result) {
                $windowInstance.close(result);
                $('#municipalityOverviewGrid').getKendoGrid().dataSource.read();
            });
        }

        function closeWindow(result) {
            event.preventDefault();
            $windowInstance.close(result);
        }

        function save() {
            event.preventDefault();

            let deferred = $q.defer();
            $scope.municipalityNameValid = validateMunicipalityName();

            if ($scope.validator.validate()
                && $scope.municipalityNameValid) {

                $rootScope.$broadcast('showGlobalBusyIndicator', 'municipalityIndicator');

                let municipalityDto = {
                    Name: $scope.municipalityName,
                    Code: $scope.municipalityCode
                }

                if (currentFormType === formTypes.edit) {
                    municipalityDto.Id = municipality.id,

                    municipalityService.updateMunicipality(municipalityDto).then(function (result) {
                        deferred.resolve(result);
                        $rootScope.$broadcast('hideBusyIndicator', 'municipalityIndicator');
                    }, function () {
                        deferred.reject();
                        $rootScope.$broadcast('hideBusyIndicator', 'municipalityIndicator');
                    });
                }
                else {
                    municipalityService.addMunicipality(municipalityDto).then(function (result) {
                        deferred.resolve(result);
                        $rootScope.$broadcast('hideBusyIndicator', 'municipalityIndicator');
                    }, function () {
                        deferred.reject();
                        $rootScope.$broadcast('hideBusyIndicator', 'municipalityIndicator');
                    });
                }
            }

            return deferred.promise;
        }
      
        function validateMunicipalityName() {
            var matchMunicipalities;
            if (currentFormType === formTypes.edit) {
                //can edit own name
                matchMunicipalities = _.filter(allMunicipalities, ['name', $scope.municipalityName]);
                if (matchMunicipalities && matchMunicipalities.length) {
                    if (matchMunicipalities.length === 1 && matchMunicipalities[0].id == municipality.id) {
                        return true;
                    }
                    return false;
                }
                return true;
            } else {
                //only can add municipality once
                matchMunicipalities = _.filter(allMunicipalities, ['name', $scope.name]);
                if (matchMunicipalities.length) {
                    return false;
                }
                return true;
            }
        }

        // function validateMunicipalityCode() {
        //     var matchMunicipalities;
        //     if (currentFormType === formTypes.edit) {
        //         //can edit own code
        //         matchMunicipalities = _.filter(allMunicipalities, ['code', $scope.municipalityName]);
        //         if (matchMunicipalities && matchMunicipalities.length) {
        //             if (matchMunicipalities.length === 1 && matchMunicipalities[0].id == municipality.id) {
        //                 return true;
        //             }
        //             return false;
        //         }
        //         return true;
        //     } else {
        //         //only can add municipality once
        //         matchMunicipalities = _.filter(allMunicipalities, ['name', $scope.name]);
        //         if (matchMunicipalities.length) {
        //             return false;
        //         }
        //         return true;
        //     }
        // }
    }
})();
