(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('deviceService', deviceService);

    deviceService.$inject = ['$q', '$http', 'encapsuledHttpService'];

    function deviceService($q, $http, encapsuledHttpService) {
        var service = {
            addDevice: addDevice,
            updateDevice: updateDevice,
            getDevices: getDevices,
            getDevice: getDevice,
            deleteDevice: deleteDevice,
            disconnectDevice: disconnectDevice,
            getLevelMeasurementDataByExternalId: getLevelMeasurementDataByExternalId
        };

        return service;

        function addDevice(device) {
            var deferred = $q.defer();

            $http.post('/api/device', device).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        }

        function deleteDevice(deviceId) {
            var deferred = $q.defer();

            $http.delete('/api/device/' + deviceId).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        }

        function updateDevice(device) {
            var deferred = $q.defer();

            $http.put('/api/device', device).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        }

        function getDevices() {
            var deferred = $q.defer();

            $http.get('/api/device').then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getLevelMeasurementDataByExternalId(externalId) {
            var deferred = $q.defer();

            encapsuledHttpService.getRequest(`api/device/level-measurement/${externalId}`, deferred);        
         
            return deferred.promise;
        }

        function getDevice(externalSystemId, externalId) {
            var deferred = $q.defer();

            $http.get('/api/device/' + externalId + '/' + externalSystemId).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function disconnectDevice(externalId, externalSystemId) {
            var deferred = $q.defer();

            var disconnectDeviceDto = {
                externalId,
                externalSystemId,
            }

            $http.post('api/device/disconnect', disconnectDeviceDto)
                .then(function (result) {
                    if (result && result.data) {
                        deferred.resolve(result.data);
                    }
                    else {
                        deferred.reject();
                    }
                }, function () {
                    deferred.reject();
                });

            return deferred.promise;
        }
    }
})();
