(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ExternalLinksController', ExternalLinksController);

    ExternalLinksController.$inject = [
        '$rootScope',
        '$scope',
        '$kWindow',
        'externalLinkService',
        'formTypes',
        '$translate'
    ];

    function ExternalLinksController(
        $rootScope,
        $scope,
        $kWindow,
        externalLinkService,
        formTypes,
        $translate,
    ) {
        $scope.openNewExternalLink = openNewExternalLink;
        $scope.openUpdateExternalLink = openUpdateExternalLink;
        $scope.deleteExternalLink = deleteExternalLink;

        $scope.externalLinksGridOptions = {
            dataSource: {
                transport: {
                    read: readExternalLinks
                },
                sort: { field: 'description', dir: 'asc' },
                pageSize: 70,
            },
            sortable: true,
            filterable: true,
            resizable: true,
            noRecords: true,
            messages: {
                noRecords: $translate.instant('G_NO_DATA')
            },
            scrollable: {
                virtual: true
            },
            columns: [
                {
                    field: 'description',
                    title: $translate.instant('G_NAME'),
                    width: 140,
                },
                {
                    field: 'externalLinkUrl',
                    title: $translate.instant('ADMINISTRATION_EXTERNAL_LINKS_TABLE_HEADER_URL'),
                },
                {
                    field: 'fontAwesomeCode',
                    title: $translate.instant('ADMINISTRATION_EXTERNAL_LINKS_TABLE_HEADER_ICON'),
                    width: 120,
                },
                {
                    field: 'edit',
                    filterable: false,
                    sortable: false,
                    groupable: false,
                    title: ' ',
                    width: 80,
                    template: `<i class="fa fa-1-5x fa-pencil fa-fw" ng-show="isVisible('CORE', { Area: 'ExternalLink', AccessType: 'W' })" ng-click="openUpdateExternalLink(dataItem)" aria-hidden="true"></i>` +
                        `<i class="fa fa-1-5x fa-trash fa-fw" ng-show="isVisible('CORE', { Area: 'ExternalLink', AccessType: 'W' })" ng-click="deleteExternalLink(dataItem)" aria-hidden="true"></i>`
                }
            ],
        };

        function readExternalLinks(e) {
            $scope.showBusyIndicator();
            externalLinkService.getExternalLinks().then((externalLinks) => {
                $rootScope.externalLinks = externalLinks;
                e.success(externalLinks);
            }).finally(() => {
                $scope.hideBusyIndicator();
            });
        }

        function openNewExternalLink() {
            openExternalLinkModal($translate.instant("ADMINISTRATION_EXTERNAL_LINKS_NEW_EXTERNAL_LINK"), formTypes.add)
                .result.then((result) => {
                    if (result) {
                        $scope.externalLinksGrid.dataSource.add(result);
                        $rootScope.externalLinks = $scope.externalLinksGrid.dataSource.data();
                    }
                });
        }

        function openUpdateExternalLink(externalLink) {
            openExternalLinkModal($translate.instant("ADMINISTRATION_EXTERNAL_LINKS_EDIT_LINK"), formTypes.edit, externalLink)
                .result.then((result) => {
                    if (result) {
                        removeExternalLinkFromGrid(result.id);
                        $scope.externalLinksGrid.dataSource.add(result);
                        $rootScope.externalLinks = $scope.externalLinksGrid.dataSource.data();
                    }
                });
        }

        function deleteExternalLink(externalLink) {
            let confirmText = $translate.instant("ADMINISTRATION_EXTERNAL_LINKS_DIALOG_CONFIRM_DELETE");

            if (confirm(`${confirmText} ${externalLink.description}?`)) {
                externalLinkService.deleteExternalLink(externalLink.id).then((deletedLink) => {
                    if (deletedLink) {
                        removeExternalLinkFromGrid(deletedLink.id);
                        $rootScope.externalLinks = $scope.externalLinksGrid.dataSource.data();
                    }
                });
            }
        }

        function removeExternalLinkFromGrid(idToDelete) {
            const links = $scope.externalLinksGrid.dataSource.data();
            const linkToDelete = links.find(l => l.id === idToDelete);
            $scope.externalLinksGrid.dataSource.remove(linkToDelete);
        }

        function openExternalLinkModal(title, formType, externalLink) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: title,
                    resizable: false,
                    height: 300,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/administration/externalLinks/external-link-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base-allow-overflow.html',
                controller: 'ExternalLinkDetailsModalController',
                resolve: {
                    currentFormType: () => formType,
                    externalLink: () => externalLink,
                }
            });
        }
    }
})();
