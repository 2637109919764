(function () {

    angular.module("UndergroundWebApp").factory("keycardUtility", keycardUtility);

    keycardUtility.$inject = [
        '$translate'
    ];

    function keycardUtility($translate) {
        const allowedKeyCardLength = 14;
        const validCharacterList = [
            '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'
        ];

        let utility = {
            convertKeycardNumber: convertKeycardNumber,
        }

        return utility;

        function convertKeycardNumber(inputString) {
            const suffixStartIndex = 12;
            const suffixLength = 2;
            let errorMessage = '';

            let keycardInput = inputString.toUpperCase().trim();
            var returnStr = '';

            if (keycardInput.length === allowedKeyCardLength) {
                var encounteredError = !(_.every(keycardInput, k => validCharacterList.includes(k)));

                if (!encounteredError) {
                    var convertedKeycardNumber = "";

                    for (var i = 0; i < 7; i++) {
                        var startIndex = i * 2;
                        var numberOfCharacters = 2;
                        convertedKeycardNumber = keycardInput.substring(startIndex, startIndex + numberOfCharacters) + convertedKeycardNumber;
                    }

                    returnStr = convertedKeycardNumber;
                }
                else errorMessage = $translate.instant('UTILITIES_KEYCARD_UTILITY_INVALID_CHAR_ERROR');
            }
            else {
                if (keycardInput.length > allowedKeyCardLength) errorMessage = $translate.instant('UTILITIES_KEYCARD_UTILITY_TOO_MANY_CHAR');
                if (keycardInput.length < allowedKeyCardLength) errorMessage = $translate.instant('UTILITIES_KEYCARD_UTILITY_TOO_FEW_CHAR');
            }

            return {
                returnStr,
                errorMessage
            };
        }
    }
})();
