(function () {

    angular.module('UndergroundWebApp').factory('municipalityService', municipalityService);

    municipalityService.$inject = [
        '$q',
        '$http'
    ];

    function municipalityService(
        $q,
        $http
    ) {
        return {
            getMunicipalities,
            addMunicipality,
            updateMunicipality,
            getMunicipalitiesByCode,
        };

        function getMunicipalities() {
            var deferred = $q.defer();

            $http.get('api/municipalities').then(function (result) {
                if (result && result.data) {
                    deferred.resolve(getOrderedMunicipalities(result.data));
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }
       
        function addMunicipality(municipality) {
            var deferred = $q.defer();
            $http.post('api/municipalities/', municipality).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        }

        function updateMunicipality(municipality) {
            var deferred = $q.defer();
            $http.put('api/municipalities/', municipality).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        }

        function getMunicipalitiesByCode(municipalityCodes) {
            var deferred = $q.defer();

            $http.post('api/municipalities/byCodes', municipalityCodes).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(getOrderedMunicipalities(result.data));
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getOrderedMunicipalities(municipalities) {
            if (!municipalities) return [];

            return _.orderBy(municipalities, 'name');
        }
    }
}());
