(function () {
    angular.module('UndergroundWebApp').factory('httpErrorInterceptorService', httpErrorInterceptorService);

    httpErrorInterceptorService.$inject = [
        '$q',
        '$rootScope',
        'globalEvents'
    ];

    function httpErrorInterceptorService(
        $q,
        $rootScope,
        globalEvents
    ) {
        return {
            responseError
        };

        function responseError(response) {
            //If request is sent to a relative url
            if (response.status === 401
                && response.config.url.indexOf('api') === 0
                && response.config.headers.Authorization.indexOf('Bearer ') === 0) {

                $rootScope.$broadcast(globalEvents.accessTokenExpired, response);
            } else {
                $q.reject(response);
            }
        }
    }
})();
