(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('LogActionDetailsModalController', LogActionDetailsModalController);

    LogActionDetailsModalController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$timeout',
        '$windowInstance',
        'logActionService',
        'formTypes',
        'currentFormType',
        'logAction',
        'allLogActions',
        'statusesWithoutDelete',
        'actions'
    ];

    function LogActionDetailsModalController($q,
        $scope,
        $rootScope,
        $timeout,
        $windowInstance,
        logActionService,
        formTypes,
        currentFormType,
        logAction,
        allLogActions,
        statusesWithoutDelete,
        actions
    ) {
        $scope.isEdit = currentFormType === formTypes.edit;
        //Public functions
        $scope.saveAndClose = saveAndClose;
        $scope.closeWindow = closeWindow;
        $scope.initController = initController;
        $scope.logActionNameValid = true;

        $scope.logActionAction;
        $scope.logActionActionOptions = {
            dataSource: {
                transport: {
                    read: readLogActionActionOptions
                }
            },
            dataTextField: 'name',
            dataValueField: 'id'
        };

        
        $scope.logActionStatusOptions = {
            dataSource: {
                transport: {
                    read: readLogActionStatusOptions
                }
            },
            dataTextField: 'LocalizedName',
            dataValueField: 'Id'
        };

        $timeout(250).then(initController);

        function initController() {
            
            if ($scope.isEdit) {
                $scope.logActionName = logAction.name;                
                $scope.logActionExternalId = logAction.externalId;
                $scope.logActionStatus = statusesWithoutDelete.filter(x => x.Id == logAction.status)[0];
                $scope.statusDropdownDisabled = false;                
                $scope.isEditable = logAction.isEditable;
                $scope.logActionAction = actions.find(action => action.id === logAction.actionId);
            }else{
                $scope.logActionStatus = statusesWithoutDelete[0];
                $scope.statusDropdownDisabled = true;                
                $scope.isEditable = true;
                $scope.logActionAction = actions[0];
            }


            const index = statusesWithoutDelete.indexOf($scope.logActionStatus, 0);
            const dropdownlist = $("#logAction-status").data("kendoDropDownList");
            dropdownlist.select(index);            
        }
        
        function saveAndClose() {
            save().then(function () {
                $('#logActionOverviewGrid').getKendoGrid().dataSource.read();
            }).catch((err) => {
                console.log(err);
            }).finally(() => {                
                $windowInstance.close();
            });
        }

        function closeWindow(result) {
            event.preventDefault();
            $windowInstance.close(result);
        }

        function save() {
            const deferred = $q.defer();
            $scope.logActionNameValid = validateLogActionName();

            if ($scope.validator.validate()
                && $scope.logActionNameValid) {

                $rootScope.$broadcast('showGlobalBusyIndicator', 'logActionIndicator');

                const logActionDto = {
                    Name: $scope.logActionName,
                    ExternalId: $scope.logActionExternalId,
                    Status: $scope.logActionStatus?.Id ?? statusesWithoutDelete[0].Id,
                    ActionId: $scope.logActionAction.id
                }

                if (currentFormType === formTypes.edit) {
                    logActionDto.Id = logAction.id,

                    logActionService.updateLogAction(logActionDto).then(function (result) {
                        deferred.resolve(result);
                    }).catch((err) => {
                        console.log(err);
                        deferred.reject();
                    }).finally(() => {                        
                        $rootScope.$broadcast('hideBusyIndicator', 'logActionIndicator');
                    })
                }
                else {
                    logActionService.addLogAction(logActionDto).then(function (result) {
                        deferred.resolve(result);
                    }).catch((err) => {
                        console.log(err);
                        deferred.reject();
                    }).finally(() => {                        
                        $rootScope.$broadcast('hideBusyIndicator', 'logActionIndicator');
                    })
                }
            }

            return deferred.promise;
        }

        function validateLogActionName() {
            let matchLogActions;
            if (currentFormType === formTypes.edit) {
                //can edit own name
                matchLogActions = _.filter(allLogActions, ['name', $scope.logActionName]);
                if (matchLogActions && matchLogActions.length) {
                    if (matchLogActions.length === 1 && matchLogActions[0].id == logAction.id) {
                        return true;
                    }
                    return false;
                }
                return true;
            } else {
                //only can add municipality once
                matchLogActions = _.filter(allLogActions, ['name', $scope.logActionName]);
                if (matchLogActions.length) {
                    return false;
                }
                return true;
            }
        }

        function readLogActionActionOptions(e) {
            e.success(actions);
        }

        function readLogActionStatusOptions(e) {
            e.success(statusesWithoutDelete);
        }
      
    }
})();
