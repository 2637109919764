(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('chartOptionsService', chartOptionsService)

    chartOptionsService.$inject = [
        '$translate',
        '$rootScope'
    ];
    function chartOptionsService($translate, $rootScope) {
        const chartTypes = {
            fill: 'fill',
            rssi: 'rssi',
            volt: 'volt',
            temperature: 'temperature',
            usageCount: 'usageCount',
        }

        const isoDateFormat = $rootScope.getCurrentIsoLanguageCode();

        const service = {
            createChart: createChart,
            chartTypes: chartTypes
        };

        function getCategories(items) {
            const categories = _.map(items, function (item) {
                const date = new Date(item.timestamp);
                return {
                    date: date.toLocaleDateString(isoDateFormat),
                    time: moment(date).format("HH:mm")
                };
            });
            return categories;
        };

        function createChart(items, field, name, color, additionalOptions) {

            //if there are at least one day where we have multiple entries, 
            //we have to increase the resolution to show them
            //otherwise original data will be used
            const useHourResolution = shouldExtendCollection(items);
            const extendedItems = useHourResolution ? extendItemCollection(items) : items;
            
            const options = {
                dataSource: {
                    transport: {
                        read: (e) => e.success(extendedItems)
                    }
                },
                legend: {
                    position: 'bottom'
                },
                seriesDefaults: {
                    type: "area",
                    missingValues: useHourResolution ? "interpolate": "gap",
                     area: {
                        line: {
                            style: "smooth"
                        }
                    }
                },
                tooltip: {
                    visible: true,
                    template: getPopupTemplateAccordingToResolution(useHourResolution)
                },
                categoryAxis: {
                    categories: getCategories(extendedItems),
                    majorGridLines: {
                        visible: false
                    },
                    labels: {
                        step: Math.floor((extendedItems?.length ?? 7) / (7)),
                        template: '#= value.date #'
                    }
                },
                series: [{
                    field: field,
                    name: name,
                    color: color,
                }],
                chartArea: {
                    height: 200
                },
            };

            return Object.assign(options, additionalOptions);
        }

        function extendItemCollection(items) {
            if(items) {
                const timestamps = items.map(x => new Date(x.timestamp));
                const minDate = new Date(Math.min(...timestamps));
                const maxDate = new Date(Math.max(...timestamps));
                const msInHours = 60 * 60 * 1000;

                const allHoursInTheTimePeriod = 
                    Array.from({ length: (maxDate - minDate) / msInHours + 1 }, (_, i) => new Date(minDate.getTime() + i * msInHours));

                const extended = allHoursInTheTimePeriod.map((hour) => {
                    const actualItem = items.find(x => findDataInHour(new Date(x.timestamp), hour));
                    
                    if (actualItem) {
                        return actualItem;
                    }

                    return { timestamp: hour.toISOString() };                    
                });

                return extended;
            }
        }

        function findDataInHour(dateOfItem, date){
            const comparison = dateOfItem.getFullYear() == date.getFullYear()
            && dateOfItem.getMonth() == date.getMonth()
            && dateOfItem.getDate() == date.getDate()            
            && dateOfItem.getHours() == date.getHours()

            return comparison
        }

        function shouldExtendCollection(items) {
            const itemsByDay = new Map();
            return items.some((item) => {
                const day = item.timestamp.substring(0, 10);

                if (itemsByDay.has(day)) {
                    return true;
                } 

                itemsByDay.set(day, [item]);
                return false;
            });
        }

        function getPopupTemplateAccordingToResolution(isHighResolution){
            if(isHighResolution) {
                return `${$translate.instant('G_DAY')}: #= category.date # #= category.time #<br />#= series.name #: #= value #`;
            }

            return `${$translate.instant('G_DAY')}: #= category.date # <br />#= series.name #: #= value #`
        }

        return service;
    }
})();