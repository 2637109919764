(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('statusService', statusService);

    statusService.$inject = ['$q', '$http'];

    function statusService($q, $http) {
        var service = {
            getStatusesByDate,
            getLatestStatuses,
        };

        function getStatusesByDate(from, to, includeContainers = false, errorOnly = false) {
            var deferred = $q.defer();

            const filter = {
                fromDate: from,
                toDate: to,
                errorOnly,
            };
            $http.post(`/api/status/filter?includeContainers=${!!includeContainers}`, filter).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function (result) {
                deferred.reject(result.data);
            });

            return deferred.promise;
        }

        function getLatestStatuses(includeContainers = false, from = null, errorOnly = false) {
            var deferred = $q.defer();
            $http.get('/api/status', {
                params: {
                    includeContainers,
                    from,
                    errorOnly,
                },
            }).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function (result) {
                deferred.reject(result.data);
            });

            return deferred.promise;
        }

        return service;
    }
})();
