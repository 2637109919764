(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('kendoGridUtility', kendoGridUtility);

    const OPTIONS_KEY_PREFIX = 'GRID_OPTIONS';

    function kendoGridUtility() {
        var service = {
            sortMulticheckboxFilterValues,
            filterCheckboxTemplate,
            excludeColumnFromMenuList,
            persistOptions,
            loadOptions,
            checkBoxColumnTemplate,
        };

        function sortMulticheckboxFilterValues(fieldNames, direction = 'asc') {
            let fieldList = fieldNames;
            if (!Array.isArray(fieldList)) {
                fieldList = [fieldNames];
            }

            return function (e) {
                if (fieldList.includes(e.field)) {
                    var filterMultiCheck = this.thead.find(`[data-field=${e.field}]`).data('kendoFilterMultiCheck')
                    filterMultiCheck.container.empty();
                    filterMultiCheck.checkSource.sort({ field: e.field, dir: direction });
                    filterMultiCheck.checkSource.data(filterMultiCheck.checkSource.view().toJSON());
                    filterMultiCheck.createCheckBoxes();
                }
            }
        }

        function filterCheckboxTemplate(emptyFieldText) {
            return function (e) {
                return `<li class="k-item">
                    <label class="k-label">
                        <input type="checkbox" value="#= data.${e.field}#">
                        #= data.all || (data.${e.field} ? data.${e.field} : '${emptyFieldText}') # 
                    </label>
                </li>`;
            }
        };

        function excludeColumnFromMenuList(fieldNames) {
            let fieldList = fieldNames;
            if (!Array.isArray(fieldList)) {
                fieldList = [fieldNames];
            }

            return function (e) {
                e.container.find('li[role="menuitemcheckbox"]').each((_index, e) => {
                    const elem = $(e);

                    const containsField = fieldList.some((field) =>
                        elem.find(`input[data-field="${field}"]`).length === 1
                    );

                    if (containsField) {
                        elem.remove();
                    }
                });
            }
        }

        function persistOptions(tableVersion) {
            return function () {
                const options = this.getOptions();
                options.dataSource = null;

                const entry = {
                    options,
                    tableVersion,
                }

                const id = this.element.attr('id');
                window.localStorage.setItem(`${OPTIONS_KEY_PREFIX}#${id}`, JSON.stringify(entry));
            }
        }

        function loadOptions(id, tableVersion) {
            const entry = JSON.parse(window.localStorage.getItem(getOptionsStorageKey(id)));
            if (entry && entry.tableVersion === tableVersion) {
                return entry.options;
            }

            return null;
        }

        function getOptionsStorageKey(id) {
            return `${OPTIONS_KEY_PREFIX}#${id}`;
        }

        function checkBoxColumnTemplate(fieldName) {
            return `<input type="checkbox" disabled #= ${fieldName} ? 'checked="checked"' : "" # />`;
        }

        return service;
    }
})();
