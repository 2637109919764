angular.module('UndergroundWebApp').directive('mapLayersPanel', ['$rootScope', 'mapService', function ($rootScope, mapService) {
    'use strict';

    return {
        restrict: 'AE',
        replace: true,
        templateUrl: 'app/main/directives/map-layers-panel.html',
        link: function (scope, element, attrs) {
            $rootScope.$on('layerVisibilityChanged', (_scope, visibilityList) => {
                Object.entries(visibilityList).forEach(([layerName, visible]) => {
                    const button = element.find(`.dropdown-item[name='${layerName}']`);
                    if (visible) {
                        button.addClass('active');
                    } else {
                        button.removeClass('active');
                    }
                });
            });

            scope.toggleLayer = function (event) {
                event.stopPropagation();
                const layerName = event.target.name ?? event.target.id;
                mapService.toggleLayerVisibility(layerName);
            };
        }
    };
}]);
