(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('FractionDetailsModalController', FractionDetailsModalController);

    FractionDetailsModalController.$inject = [
        '$q',
        '$timeout',
        '$scope',
        '$rootScope',
        '$windowInstance',
        'fractionsService',
        'formTypes',
        'currentFormType',
        'fraction',
        '$translate'
    ];

    function FractionDetailsModalController(
        $q,
        $timeout,
        $scope,
        $rootScope,
        $windowInstance,
        fractionsService,
        formTypes,
        currentFormType,
        fraction,
        $translate) {

        $scope.saveAndClose = saveAndClose;
        $scope.closeWindow = closeWindow;
        $scope.standardFractions = [];
        $scope.selectedStandardFractionId = '';

        $scope.fraction = fraction != null ? { ...fraction } : {};

        $scope.standardFractionsOptions = {
            dataSource: {
                transport: {
                    read: readstandardFractions
                }
            },
            dataTextField: 'name',
            dataValueField: 'id',
            select: handleFractionSelect
        };

        function readstandardFractions(e) {
            fractionsService.getStandardFractions()
                .then(result => {
                    result.forEach(standardFraction => {
                        standardFraction.name = $translate.instant(standardFraction.designation);
                    });

                    result = _.orderBy(result, 'name');

                    $scope.standardFractions = result;
                    $scope.standardFractions.splice(0, 0, {
                        id: 'custom',
                        name: $translate.instant('STANDARD_FRACTION_CUSTOM_FRACTION')
                    });


                    $timeout(250).then(() => {
                        if (!$scope.selectedStandardFractionId) {
                            $scope.selectedStandardFractionId = 'custom';
                        }
                    });

                    return result;
                })
                .then((result) => {
                    e.success(result);
                    init();
                });
        }

        function init() {
            if (currentFormType === 'edit') {
                if (fraction.standardFractionId !== null) {
                    let selectedStandardFraction = $scope.standardFractions.find(x => x.id === fraction.standardFractionId);

                    if (selectedStandardFraction) {
                        $scope.fraction.imageUrl = selectedStandardFraction.imageUrl;
                        $scope.selectedStandardFractionId = selectedStandardFraction.id;

                        let index = $scope.standardFractions.indexOf(selectedStandardFraction, 0);
                        var dropdownlist = $("#standard-factions-list").data("kendoDropDownList");

                        dropdownlist.select(index); // Choose fraction... = 0
                    }
                }
            }
        }

        function saveAndClose(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {
                save().then(function (result) {
                    $windowInstance.close(result);
                    $('#fractionsGrid').getKendoGrid().dataSource.read();
                });
            }
        }

        function closeWindow(result) {
            $windowInstance.close(result);
        }

        function getSaveFunction(currentFormType) {
            if (currentFormType === formTypes.edit) {
                return fractionsService.updateFraction.bind(fractionsService);
            }

            return fractionsService.addFraction.bind(fractionsService);
        }

        function save() {
            var deferred = $q.defer();

            $rootScope.$broadcast('showGlobalBusyIndicator', 'fractionIndicator');

            var saveFunction = getSaveFunction(currentFormType);

            saveFunction($scope.fraction)
                .then(result => deferred.resolve(result),
                    () => deferred.reject())
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'fractionIndicator'));

            return deferred.promise;
        }

        function handleFractionSelect(evt) {
            console.log(evt);

            let selectedItem = evt.dataItem;
            let currentStandardFraction = $scope.standardFractions.find(x => x.id === $scope.selectedStandardFractionId);

            if (selectedItem && selectedItem.id !== 'custom') {
                let shouldChangeName = !$scope.fraction.name || $scope.fraction.name === currentStandardFraction.name;
                if (shouldChangeName) {
                    $scope.fraction.name = $translate.instant(selectedItem.designation);
                }

                let shouldChangeExtId = !$scope.fraction.externalId || $scope.fraction.externalId == currentStandardFraction.number;
                if (shouldChangeExtId) {
                    $scope.fraction.externalId = selectedItem.number;
                }

                $scope.fraction.imageUrl = selectedItem.imageUrl;
                $scope.fraction.standardFractionId = selectedItem.id;
            } else {
                $scope.fraction.imageUrl = null;
                $scope.fraction.standardFractionId = null;
            }
        }
    }
})();
