(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('FooterController', FooterController);

    FooterController.$inject = [
        '$timeout',
        '$scope',
        'viewModes',
        'displayService'
    ];

    function FooterController(
        $timeout,
        $scope,
        viewModes,
        displayService
    ) {
        $scope.viewModes = viewModes;
        $scope.currentViewMode;

        $scope.setViewMode = setViewMode;

        $timeout(250).then(initController);

        function initController() {
            displayService.getUserViewMode().then(currentViewMode => {
                $scope.currentViewMode = currentViewMode;
            })
        }

        function setViewMode(viewMode) {
            if ($scope.currentViewMode === viewMode) {
                $scope.currentViewMode = null;
            } else {
                $scope.currentViewMode = viewMode;
            }

            displayService.setUserViewMode($scope.currentViewMode);
        }
    }
})();
