(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('LocationKeycardTabController', LocationKeycardTabController);

    LocationKeycardTabController.$inject = ['$scope', '$state', '$stateParams', 'locationsService'];

    function LocationKeycardTabController($scope, $state, $stateParams, locationsService) {
        var locationId = $stateParams.locationId;

        initController();

        function initController() {
        };
    }
})();
