(function () {
    'use strict';

    const undergroundWebApp = angular.module('UndergroundWebApp');

    const nextEmptyingDateEnums = {
        IN_ONE_DAY: 'IN_ONE_DAY',
        IN_TWO_DAYS: 'IN_TWO_DAYS',
        IN_THREE_DAYS: 'IN_THREE_DAYS',
        IN_FOUR_DAYS: 'IN_FOUR_DAYS'
    }

    undergroundWebApp.constant('nextEmptyingDateEnums', nextEmptyingDateEnums);

    undergroundWebApp.constant('nextEmptyingDates', [
        {
            id: 0,
            value: nextEmptyingDateEnums.IN_ONE_DAY,
        },
        {
            id: 1,
            value: nextEmptyingDateEnums.IN_TWO_DAYS,
        },
        {
            id: 2,
            value: nextEmptyingDateEnums.IN_THREE_DAYS,
        },
        {
            id: 3,
            value: nextEmptyingDateEnums.IN_FOUR_DAYS,
        }
    ]);
})();
