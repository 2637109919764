(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('AccessItemGroupDetailsModalController', AccessItemGroupDetailsModalController);

    AccessItemGroupDetailsModalController.$inject = [
        '$scope',
        '$rootScope',
        '$stateParams',
        '$windowInstance',
        'accessItemGroupsService',
        'newGroupOnlyMode',
        '$translate'
    ];

    function AccessItemGroupDetailsModalController(
        $scope,
        $rootScope,
        $stateParams,
        $windowInstance,
        accessItemGroupsService,
        newGroupOnlyMode,
        $translate
    ) {
        const currentLocationId = $stateParams.locationId;

        $scope.newGroupOnlyMode = newGroupOnlyMode;
        $scope.shouldAddGroup = $scope.newGroupOnlyMode;
        $scope.newGroupValue = '';
        $scope.showUniqueErrorMsg = false;
        
        $scope.chooseGroup = '+ ' + $translate.instant('G_CHOOSE_GROUP');
        $scope.createGroup = '+ ' + $translate.instant('G_CREATE_GROUP');

        $scope.isSaveDisabled = isSaveDisabled;
        $scope.close = close;
        $scope.saveAndClose = saveAndClose;
        $scope.toggleGroupMode = toggleGroupMode;

        $scope.groupOptions = {
            dataSource: {
                transport: {
                    read: readGroups
                }
            },
            dataTextField: 'name',
            dataValueField: 'id',
            optionLabel: {
                name: $translate.instant('G_CHOOSE_GROUP'),
                id: ''
            },
            filter: 'contains'
        };

        initController();

        function initController() {
            $scope.$watch('newGroupValue', function () {
                $scope.showUniqueErrorMsg = false;
            });
        }

        //Event handlers
        function saveAndClose() {
            if ($scope.shouldAddGroup) {
                showBusyIndicator();

                validateGroupName({ name: $scope.newGroupValue }).then(function (isUnique) {
                    $scope.showUniqueErrorMsg = !isUnique;
                    if (!isUnique) {
                        hideBusyIndicator()
                        return;
                    }
                    
                    let addedGroup = null;
                    accessItemGroupsService.addAccessItemGroup({ name: $scope.newGroupValue })
                        .then(function (result) {
                            addedGroup = result;

                            if (currentLocationId) {
                                return accessItemGroupsService.addLocationsToAccessItemGroup(addedGroup.id, [currentLocationId]);
                            } else {
                                return addedGroup;
                            }
                        }).then(function () {
                            hideBusyIndicator();
                            $windowInstance.close(addedGroup);
                        });                  
                });
            } else {
                if ($scope.selectedGroup) {
                    showBusyIndicator()
                    accessItemGroupsService.addLocationsToAccessItemGroup($scope.selectedGroup.id, [currentLocationId]).then(function () {
                        hideBusyIndicator()
                        $windowInstance.close($scope.selectedGroup)
                    });
                }
            }
        }

        function close() {
            $windowInstance.close(true);
        }

        function toggleGroupMode() {
            $scope.shouldAddGroup = !$scope.shouldAddGroup;
            $scope.showUniqueErrorMsg = false;
        }

        function isSaveDisabled() {
            if ($scope.shouldAddGroup) {
                return $scope.newGroupValue.length === 0;
            } else {
                return !$scope.selectedGroup || !$scope.selectedGroup.id;
            }
        }

        //Private functions
        function validateGroupName(group) {
            return accessItemGroupsService.isGroupNameUnique(group);
        }

        function readGroups(e) {
            accessItemGroupsService.getAll().then(function (itemGroups) {
                let accessItemGroups = _.orderBy(itemGroups, 'name');
                e.success(accessItemGroups);
            }, function () {
                e.success([]);
            });
        }

        function showBusyIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'GroupLocationModalIndicator',
                destination: '#group-location-modal',
                message: $translate.instant('G_BUSY_INDICATOR'),
                overlay: true,
                positionClass: {
                    top: '60px',
                    left: '0px',
                    right: '0px'
                }
            });
        }

        function hideBusyIndicator() {
            $rootScope.$broadcast('hideBusyIndicator', 'GroupLocationModalIndicator');
        }
    }
})();
