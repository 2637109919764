(function () {
    angular.module('UndergroundWebApp').factory('batteryVoltagesService', batteryVoltagesService);

    batteryVoltagesService.$inject = [
        '$q',
        '$http'
    ];

    function batteryVoltagesService(
        $q,
        $http
    ) {
        var service = {
            getBatteryVoltages: getBatteryVoltages,
            getBatteryVoltagesByMunicipality: getBatteryVoltagesByMunicipality,
        };

        return service;

        function getBatteryVoltages() {
            let deferred = $q.defer();

            $http.get('/api/battery-voltages').then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }).catch(deferred.reject);

            return deferred.promise;
        }

        function getBatteryVoltagesByMunicipality(municipalityCodes) {
            let deferred = $q.defer();

            $http.post('api/battery-voltages/byMunicipality', municipalityCodes).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                } else {
                    deferred.reject();
                }
            }).catch(deferred.reject);

            return deferred.promise;
        }
    }
})();
