(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('externalLinkService', externalLinkService);

    externalLinkService.$inject = ['$http'];

    function externalLinkService($http) {
        var service = {
            getExternalLinks,
            updateExternalLink,
            createExternalLink,
            deleteExternalLink,
        };

        function getExternalLinks() {
            return $http.get('/api/externalLink')
                .then((result) => result.data);
        }

        function updateExternalLink(externalLink) {
            return $http.put('/api/externalLink', externalLink)
                .then((result) => result.data);
        }

        function createExternalLink(externalLink) {
            return $http.post('/api/externalLink', externalLink)
                .then((result) => result.data);
        }

        function deleteExternalLink(externalLinkId) {
            return $http.delete(`/api/externalLink/${externalLinkId}`)
                .then((result) => result.data);
        }

        return service;
    }
})();
