(function(){
    'use strict';

    angular.module('UndergroundWebApp').factory('customerSystemsService',customerSystemsService)

    customerSystemsService.$inject =['$q', '$http', '$rootScope'];

    function customerSystemsService($q, $http, $rootScope){
        var service = {
            getCustomerSystems: getCustomerSystems
        };

        return service;

        function getCustomerSystems(){
            var deferred = $q.defer();
            $http.get('api/customerSystems').then(function (result) {
                if (result && result.data) {

                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
