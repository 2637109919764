(function () {
    const SELECTED_LANGUAGE_ID = "SELECTED_LANGUAGE_ID";

    var undergroundWebApp = angular.module('UndergroundWebApp', [
        'kendo.directives',
        'kendo.window',
        'ui.router',
        'LocalStorageModule',
        'LocalForageModule',
        'esri.map',
        'angularjs-dropdown-multiselect',
        'angulartics',
        'angulartics.google.analytics',
        'ui.toggle',
        'ui-iconpicker',
        'pascalprecht.translate',
        'ngCookies'
    ]);

    undergroundWebApp.constant('defaultSelectedLanguageId', 'no');

    undergroundWebApp.constant('availableLanguages', [
        { id: 'de', name: 'Deutsche', isoLanguageCode: 'de-DE',  icon: 'german-flag-48.png', mapType: 'international', kendoCulture: 'en-US', importTemplateUrl: 'keycard_import_template_de.xlsx'},
        { id: 'no', name: 'Norsk', isoLanguageCode: 'no-NO', icon: 'norway-flag-48.png', mapType: 'norwegian', kendoCulture: 'no-NO', importTemplateUrl: 'keycard_import_template_no.xlsx' },
        { id: 'en', name: 'English', isoLanguageCode: 'en-US', icon: 'great-britain-flag-48.png', mapType: 'international', kendoCulture: 'en-US', importTemplateUrl: 'keycard_import_template_en.xlsx' },
        { id: 'nl', name: 'Dutch', isoLanguageCode: 'nl-NL', icon: 'dutch-flag-48.png', mapType: 'international', kendoCulture: 'en-US', importTemplateUrl: 'keycard_import_template_nl.xlsx' },
        { id: 'se', name: 'Svenska', isoLanguageCode: 'se-SE', icon: 'sweden-flag-48.png', mapType: 'international', kendoCulture: 'en-US', importTemplateUrl: 'keycard_import_template_se.xlsx' },
        { id: 'hr', name: 'Hrvatski', isoLanguageCode: 'hr-HR', icon: 'croatian-flag-48.png', mapType: 'international', kendoCulture: 'en-US', importTemplateUrl: 'keycard_import_template_hr.xlsx' },
    ]);

    undergroundWebApp.config([
        '$locationProvider',
        '$httpProvider',
        '$translateProvider',
        'availableLanguages',
        
        function (
            $locationProvider,
            $httpProvider, 
            $translateProvider,
            availableLanguages) {

        $locationProvider.html5Mode(true);
        $httpProvider.interceptors.push('authInterceptorService');

        $translateProvider
        .useStaticFilesLoader({
            prefix: 'app/locales/',
            suffix: '.json'
        })
        .registerAvailableLanguageKeys(
            availableLanguages.map(l => l.id),
            {
                en: 'en',
                no: 'no',
                de: 'de',
                nl: 'nl',
                se: 'se',
                hr: 'hr'
            }
        )
        .useSanitizeValueStrategy('escapeParameters');

        $translateProvider.useLocalStorage();
        
        $httpProvider.interceptors.push('httpErrorInterceptorService');
    }]);

    undergroundWebApp.constant('containerEditModalWidth', 980);

    undergroundWebApp.constant('fallbackErrorMessageText', 'En feil oppstod ved henting av data... Prøv å laste inn side på nytt');

    undergroundWebApp.constant('maxContainerOverviewSensorColumns', 3);

    undergroundWebApp.constant('coordinateBounds', {
        latMin: -0.1,
        latMax: 0.1,
        longMin: -0.1,
        longMax: 0.1,
    });

    undergroundWebApp.constant('selectionSettings', {
        outlineDefault: { color: [255, 255, 255], width: 1 },
        outlineSelected: { color: [0, 162, 235], width: 2 },
        lineSymbol: { color: [0, 162, 235], width: 2 },
        pointSymbol: { color: [255, 0, 0], size: 2 },
        pointerSize: 10,
        pointerSizeDefault: 2
    });

    undergroundWebApp.run([
        '$q',
        '$rootScope',
        '$timeout',
        'globalEvents',
        'pageSpecificDisplays',
        'authService',
        'systemService',
        '$translate',
        'defaultSelectedLanguageId',
        'displayService',
        'externalReportsService',
        'fractionsService',
        'availableLanguages',
        'routeGuard',

        function (
            $q,
            $rootScope, 
            $timeout,
            globalEvents, 
            pageSpecificDisplays,
            authService, 
            systemService, 
            $translate,
            defaultSelectedLanguageId,
            displayService,
            externalReportsService,
            fractionsService,
            availableLanguages,
            routeGuard) {

            let appInitDeferred = $q.defer();

        authService.init().then(authData => {
            $rootScope.authData = authData;
            
            if ($rootScope.authData.isAuth) {
                $q.all([
                    systemService.getSystemInfo(),
                    fractionsService.getStandardFractions(),
                    externalReportsService.getAllExternalReports()
                ]).then(result => {
                    const standardFractions = result[1];
                    const reports = result[2];

                    $rootScope.standardFractions = standardFractions || [];
                    $rootScope.externalReports = reports || [];

                    appInitDeferred.resolve();
                }).catch(appInitDeferred.reject);
            }
        });

        $rootScope.masterMode = false;
        $rootScope.isPopupVisible = false;
        $rootScope.isAdmin = isAdmin;
        $rootScope.isSuperAdmin = isSuperAdmin;
        $rootScope.isVisible = isVisible;
        $rootScope.appInit = appInit;
        $rootScope.getCachePrefix = getCachePrefix;
        $rootScope.getCurrentLanguageId = getCurrentLanguageId;
        $rootScope.getCurrentIsoLanguageCode = getCurrentIsoLanguageCode;
        $rootScope.getLanguageIdByIsoCode = getLanguageIdByIsoCode;
        $rootScope.setLangaugeId = setLangaugeId;
        $rootScope.defaultSelectedLanguageId = defaultSelectedLanguageId;
        $rootScope.getMunicipalityAccess = getMunicipalityAccess;

        routeGuard.init();

        //Subscribe to global events
        $rootScope.$on(globalEvents.$stateChangeSuccess, handleStateChangeSuccess);
        $rootScope.$on(globalEvents.accessTokenExpired, handleAccessTokenExpired);

        setKendoGridFilterOperatorOrder();

        let languageId = $rootScope.getCurrentLanguageId();
        let language = availableLanguages.find(x => x.id == languageId);

        $translate.use(languageId);
        kendo.culture(language.kendoCulture);
              
        //Global event handlers
        function handleStateChangeSuccess(_, toState) {
            $rootScope.selectedContainerId = undefined;

            let pageSpecificDisplay = pageSpecificDisplays[toState.name];
            if (pageSpecificDisplay) {
                $timeout(250).then(displayService.applyPageViewMode(
                    pageSpecificDisplay.viewMode,
                    pageSpecificDisplay.isPriority)
                );
            }
        }

        function handleAccessTokenExpired() {
            authService.logout();
        }

        //Private functions
        function getCachePrefix() {
            return $rootScope.authData.authUser.userName + '_';
        }

        function getCurrentLanguageId() {
            let selectedLanguage = localStorage.getItem(SELECTED_LANGUAGE_ID);
            if (!selectedLanguage) {
                selectedLanguage = defaultSelectedLanguageId;
                localStorage.setItem(SELECTED_LANGUAGE_ID, selectedLanguage);
            }

            return selectedLanguage;
        }

        function getLanguageIdByIsoCode(isoLanguageCode) {
            const languageId = availableLanguages.find(x => x.isoLanguageCode == isoLanguageCode)?.id || defaultSelectedLanguageId;
            return languageId;
        }

        function getCurrentIsoLanguageCode() {
            const currentLanguageId = getCurrentLanguageId();
            return availableLanguages.find(x => x.id == currentLanguageId).isoLanguageCode;
        }

        function setLangaugeId(selectedLanguage) {
            localStorage.setItem(SELECTED_LANGUAGE_ID, selectedLanguage);
        }

        function isVisible(module, permissions){
            const authDataIsIncomplete = !($rootScope.authData
                && $rootScope.authData.isAuth
                && $rootScope.authData.hasAccessToFeature
                && $rootScope.authData.hasAccessToModule);

            if(authDataIsIncomplete){
                return false;
            }

            const haveToCheckPermissions = permissions && Object.keys(permissions).length > 0 && permissions.constructor === Object;

            const hasAccessToModule = $rootScope.authData.hasAccessToModule(module);            
            const hasAccessToFeature = haveToCheckPermissions ? $rootScope.authData.hasAccessToFeature(permissions.Area, permissions.AccessType) : true;

            return hasAccessToModule && hasAccessToFeature;
        }

        function isAdmin() {
            return ($rootScope.authData
                && $rootScope.authData.authUser
                && $rootScope.authData.authUser.groups
                && $rootScope.authData.authUser.groups.some(g => g.group.groupName === 'Admin'))
                || isSuperAdmin();
        }

        function isSuperAdmin() {
            return $rootScope.authData
                && $rootScope.authData.authUser
                && $rootScope.authData.authUser.groups
                && $rootScope.authData.authUser.groups.some(g => g.group.groupName === 'SuperAdmin');
        }

        function getMunicipalityAccess(municipalityCode) {
            return $rootScope.authData
                && $rootScope.authData.authUser   
                && $rootScope.authData.authUser.municipalities
                && $rootScope.authData.authUser.municipalities.some(m => m.code == municipalityCode);
        }

        function appInit() {
            return appInitDeferred.promise;
        }
    }]);

    //Private handlers
    function setKendoGridFilterOperatorOrder() {
        kendo.ui.FilterMenu.fn.options.operators.string = {
            contains: kendo.ui.FilterCell.fn.options.operators.string.contains,
            doesnotcontain: kendo.ui.FilterCell.fn.options.operators.string.doesnotcontain,
            endswith: kendo.ui.FilterCell.fn.options.operators.string.endswith,
            eq: kendo.ui.FilterCell.fn.options.operators.string.eq,
            isempty: kendo.ui.FilterCell.fn.options.operators.string.isempty,
            isnotempty: kendo.ui.FilterCell.fn.options.operators.string.isnotempty,
            isnotnull: kendo.ui.FilterCell.fn.options.operators.string.isnotnull,
            isnotnullorempty: kendo.ui.FilterCell.fn.options.operators.string.isnotnullorempty,
            isnull: kendo.ui.FilterCell.fn.options.operators.string.isnull,
            isnullorempty: kendo.ui.FilterCell.fn.options.operators.string.isnullorempty,
            neq: kendo.ui.FilterCell.fn.options.operators.string.neq,
            startswith: kendo.ui.FilterCell.fn.options.operators.string.startswith,
        };
    }
}());
