(function () {

    angular.module('UndergroundWebApp').factory('clientService', clientService);

    clientService.$inject = [
        '$q',
        '$http'
    ];

    function clientService(
        $q,
        $http
    ) {
        return {
            getClients: getClients,
            getClientsByStatus: getClientsByStatus,
            changeClient: changeClient,
            addClient: addClient,
            updateClient: updateClient,
        };

        function getClients() {
            var deferred = $q.defer();

            $http.get('api/client').then(function (result) {
                if (result && result.data) {
                    deferred.resolve(getOrderedClients(result.data));
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getClientsByStatus(status) {
            var deferred = $q.defer();

            $http.get('api/client/bystatus/' + status).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(getOrderedClients(result.data));
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function changeClient(clientId) {
            var deferred = $q.defer();
            $http.get('api/user/changeclient?clientId=' + clientId).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function addClient(client) {
            var deferred = $q.defer();
            $http.post('api/client/', client).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        }

        function updateClient(client) {
            var deferred = $q.defer();
            $http.put('api/client/', client).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        }

        function getOrderedClients(clients) {
            if (!clients) return [];

            return _.orderBy(clients, 'name');
        }
    }
}());
