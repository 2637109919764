angular.module('UndergroundWebApp').directive('secondaryMapLayersPanelPointer', ['$rootScope', function($rootScope) {
    'use strict';

    return {
        restrict: 'AE',
        replace: true,
        templateUrl: 'app/shared/popups/secondary-map-layers-panel-pointer.html',
        link: function (scope, element, attrs) {
            $rootScope.$on('showPanelPointer', function (event, model) {                
                if(element.is(":visible")){
                    return;
                }

                // Subtract the tooltip width and the arrow width (10px) from the left position
                element.css('left', model.left - element[0].offsetWidth - 10);
                element.css('top', model.top);
            
                element.find('.panel-pointer-text').text(model.text);
            
                element.show();
            });

            $rootScope.$on('hidePanelPointer', function () {
                element.hide();
            });
        }
    }
}]);