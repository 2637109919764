(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ClientsController', ClientsController);

    ClientsController.$inject = [
        '$scope',
        'clientService',
        '$kWindow',
        'formTypes',
        'filterStateService',
        'statuses',
        '$translate'
    ];

    function ClientsController(
        $scope,
        clientService,
        $kWindow,
        formTypes,
        filterStateService,
        statuses,
        $translate
    ) {
        $scope.clientDetailsModalHeight = 500;
        $scope.sortedClients = [];
        $scope.openNewClient = openNewClient;
        $scope.openUpdateClient = openUpdateClient;
        $scope.clientOverviewGridOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readClients
                },
                pageSize: 70,
                sort: filterStateService.getSortFromQueryString(),
                filter: filterStateService.getFilterFromQueryString(),
            }),
            columns: [
                { field: 'name', title: $translate.instant('CLIENT_GLOBAL_NAME') },
                { field: 'localizedName', title: $translate.instant('CLIENT_VIEW_STATUS') },
                {
                    field: 'edit',
                    filterable: false,
                    sortable: false,
                    groupable: false,
                    title: ' ',
                    width: '50px',
                    template: '<i class="fa fa-1-5x fa-pencil action-hover" ng-click="openUpdateClient(dataItem)" aria-hidden="true">'
                }
            ],
            scrollable: {
                virtual: true
            },            
            sortable: true,
            language: "english",
            filterable: true,
            resizable: true,
            noRecords: true,
            groupable: {
                messages: {
                  empty: $translate.instant('G_DROP_COLUMNS_HERE')
                }
            },
            selectable: false,
            //change: onClientRowSelected,
            sort: filterStateService.storeGridSortInQueryString,
            filter: filterStateService.storeGridFilterInQueryString,
        };
       
        function readClients(e) {

            $scope.showBusyIndicator();

            clientService.getClients().then(function (clients) {
                clients.forEach(element => {
                    let status =  statuses.filter(x => x.Name === element.status)[0];
                    element.localizedName = status.LocalizedName;
                });

                $scope.sortedClients = clients.sort(function (a, b) {
                    return (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0));
                });

                e.success($scope.sortedClients);
                $scope.hideBusyIndicator();
            }, 
            function () {
                $scope.hideBusyIndicator();
                e.success([]);
            });
        }

        function openNewClient() {
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('CLIENT_VIEW_NEW_CLIENT'),
                    resizable: false,
                    height: $scope.clientDetailsModalHeight,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/administration/clients/client-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ClientDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.add;
                    },
                    client: function () {
                        return null;
                    },
                    allClients: function () {
                        return $scope.sortedClients;
                    }
                }
            });
        }

        function openUpdateClient(dataItem) {
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('CLIENT_DETAIL_MODAL_HEADER'),
                    resizable: false,
                    height: $scope.clientDetailsModalHeight,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/administration/clients/client-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ClientDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.edit;
                    },
                    client: function () {
                        return dataItem;
                    },
                    allClients: function () {
                        return $scope.sortedClients;
                    }
                }
            });
        }
    }
})();
