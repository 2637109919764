(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('systemService', systemService)

    systemService.$inject = ['$q', '$http', '$rootScope'];

    function systemService($q, $http, $rootScope) {
        var systemInfoData = null

        var service = {
            getSystemInfo: getSystemInfo
        };

        return service;

        function getSystemInfo() {
            var deferred = $q.defer();

            if (systemInfoData) {
                $rootScope.masterMode = systemInfoData.masterMode;
                deferred.resolve(systemInfoData);
            }
            else {
                $http.get('api/system/info').then(function (result) {
                    if (result && result.data) {

                        systemInfoData = result.data;
                        $rootScope.masterMode = result.data.masterMode;

                        deferred.resolve(result.data);
                    }
                    else {
                        deferred.reject();
                    }
                }, function () {
                    deferred.reject();
                });
            }

            return deferred.promise;
        }
    }
})();
