(function () {
    angular.module('UndergroundWebApp').factory('nextEmptyingFilterUtils', nextEmptyingFilterUtils);

    nextEmptyingFilterUtils.$inject = [
        'nextEmptyingDates',
        'nextEmptyingDateEnums',
        '$translate'
    ];

    function nextEmptyingFilterUtils(
        nextEmptyingDates,
        nextEmptyingDateEnums,
        $translate
    ) {
        const nextEmptyingFilterUtils = {
            nextEmptyingDateMatching,
            getNextEmptyingDatesForFilter
        };

        function nextEmptyingDateMatching(location, selectedFilters) {
            if (!selectedFilters.length) {
                return true;
            }

            if (!location.containers?.length) {
                return false;
            }

            const hasContainerWithEmptyDate = location.containers.some((container) => container.dateWhenFull);

            if (!hasContainerWithEmptyDate) {
                return false;
            }

            const sortedEmptyingDates = selectedFilters.sort((a, b) => a.id - b.id);

            const hasContainerWithCloseEmptyDate = sortedEmptyingDates.reduce((toReturn, current) => {
                const emptyingTimeEnum = nextEmptyingDates.find(emptyingDate => emptyingDate.id === current.id)?.value;
                const dateWhenFull = location.containers.some((container) => {
                    return isInEmptyingTimeRange(container.dateWhenFull, emptyingTimeEnum);
                });

                if (dateWhenFull) {
                    return true;
                } else {
                    return toReturn;
                }
            }, false);

            return hasContainerWithCloseEmptyDate;

        }

        function isInEmptyingTimeRange(dateWhenFull, emptyingTimeEnum){
            if (!dateWhenFull) return false;
            const date = Date.parse(dateWhenFull);
            const currentDate = new Date().setDate(new Date().getDate());
            const oneDayInMiliseconds = 86400000;

            if (date >= currentDate) {
                switch (emptyingTimeEnum) {
                    case nextEmptyingDateEnums.IN_ONE_DAY:
                        if (date - currentDate <= oneDayInMiliseconds) {
                            return true;
                        } 
                        return false;
    
                    case nextEmptyingDateEnums.IN_TWO_DAYS:
                        if (date - currentDate <= oneDayInMiliseconds * 2) {
                            return true;
                        } 
                        return false;
    
                    case nextEmptyingDateEnums.IN_THREE_DAYS:
                        if (date - currentDate <= oneDayInMiliseconds * 3) {
                            return true;
                        } 
                        return false;
    
                    case nextEmptyingDateEnums.IN_FOUR_DAYS:
                        if (date - currentDate <= oneDayInMiliseconds * 4) {
                            return true;
                        } 
                        return false;
                
                    default:
                        return false;
                }
            }
        }

        function getNextEmptyingDatesForFilter(locations) {
            if (!locations) {
                return [];
            }

            const dates = [
                {
                    label: $translate.instant('LOCATION_NEXT_EMPTYING_FILTER_IN_ONE_DAY'),
                    id: nextEmptyingDates.find(date => date.value === nextEmptyingDateEnums.IN_ONE_DAY).id
                },
                {
                    label: $translate.instant('LOCATION_NEXT_EMPTYING_FILTER_IN_TWO_DAYS'),
                    id: nextEmptyingDates.find(date => date.value === nextEmptyingDateEnums.IN_TWO_DAYS).id                    
                },
                {
                    label: $translate.instant('LOCATION_NEXT_EMPTYING_FILTER_IN_THREE_DAYS'),
                    id: nextEmptyingDates.find(date => date.value === nextEmptyingDateEnums.IN_THREE_DAYS).id
                },
                {
                    label: $translate.instant('LOCATION_NEXT_EMPTYING_FILTER_IN_FOUR_DAYS'),
                    id: nextEmptyingDates.find(date => date.value === nextEmptyingDateEnums.IN_FOUR_DAYS).id                    
                },
            ];

            return dates;
        }

        return nextEmptyingFilterUtils;

    }
})();
