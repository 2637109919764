angular.module('UndergroundWebApp').directive('containerPositionPopup', ['localStorageService', '$rootScope', function (localStorageService, $rootScope) {
    'use strict';

    return {
        restrict: 'AE',
        replace: true,
        templateUrl: 'app/shared/popups/container-position-popup.html',
        link: function ($rootScope, element, attributes) {
            $rootScope.$on('showContainerPositionPopup', function (event, model) {
                element.show();

                element.css('left', model.left);
                element.css('top', model.top);

                //Set attributes
                element.find('.place').text(model.place);
                element.find('.timestamp').text(model.timestamp);
                element.find('.fraction').text(model.fraction);
                element.find('.name').text(model.name);

                var authData = localStorageService.get('authenticationData');

                if (authData && authData.isAuth) {
                    element.find('.displayText').text(model.displayText);
                }
                else {
                    element.find('.displayText').text('');
                }
            });

            $rootScope.$on('hideContainerPositionPopup', function (event, args) {
                element.hide();
            });
        }
    }
}]);
