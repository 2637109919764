(function () {
    angular.module('UndergroundWebApp').factory('deviceUtility', deviceUtility);

    deviceUtility.$inject = [
        'serviceUrls',
        'externalSystems'
    ];

    function deviceUtility(
        serviceUrls,
        externalSystems
    ) {
        let deviceUtility = {
            getDeviceDetailsLink,
            getDeviceVoltageRanges
        }

        return deviceUtility;

        function getDeviceVoltageRanges() {
            return [
                { id: 1, min: 0.0, max: 11.9 },
                { id: 2, min: 12.0, max: 12.4 },
                { id: 3, min: 12.0, max: 12.6 },
                { id: 4, min: 12.6, max: 20.0 },
            ];
        }

        function getDeviceDetailsLink(externalSystemName, externalSystemId, deviceExternalId) {
            if (!externalSystemName || !deviceExternalId) {
                return null;
            }

            switch (externalSystemName.toLowerCase()) {
                case externalSystems.shop2win:
                    return {
                        type: 'external',
                        url: getLinkToS2WDetails(externalSystemId, deviceExternalId)
                    }
                case externalSystems.reen:
                    return {
                        type: 'external',
                        url: getLinkToReenDetails(deviceExternalId),
                    };
                case externalSystems.nrc:
                    return {
                        type: 'external',
                        url: getLinkToNRCDetails(externalSystemId, deviceExternalId)
                    } 
                case externalSystems.nrc_monitor:
                    return {
                        type: 'external',
                        url: getLinkToNRCMonitorDetails(externalSystemId, deviceExternalId)
                    }
                case externalSystems.mr_fill:
                    return {
                        type: 'external',
                        url: getLinkToMrFillDetails(deviceExternalId)
                    }
            }

            return null;
        }

        function getLinkToReenDetails(deviceExternalId) {
            return serviceUrls.reenHubContainer + deviceExternalId;
        }

        function getLinkToS2WDetails(externalSystemId, deviceExternalId) {
            return GetLinkToIntellicontApp(externalSystemId, deviceExternalId);
        }


        function getLinkToNRCDetails(externalSystemId, deviceExternalId) {
            return GetLinkToIntellicontApp(externalSystemId, deviceExternalId);
        } 

        function getLinkToNRCMonitorDetails(externalSystemId, deviceExternalId) {
            return GetLinkToIntellicontApp(externalSystemId, deviceExternalId);
        } 
        
        function getLinkToMrFillDetails(deviceExternalId) {
            if (!deviceExternalId) return '';

            return serviceUrls.mrFillContainer
                + deviceExternalId;
        }

        function GetLinkToIntellicontApp(externalSystemId, deviceExternalId){
            if (!externalSystemId || !deviceExternalId) return '';

            return serviceUrls.nrcContainer
                + '?externalSystemId=' + externalSystemId
                + '&externalDeviceId=' + deviceExternalId;
        }
    }
})();
