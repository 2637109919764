angular.module('UndergroundWebApp').directive('containerPopup', ['localStorageService', function (localStorageService) {
    'use strict';

    return {
        restrict: 'AE',
        replace: true,
        templateUrl: 'app/shared/popups/container-Popup.html',
        link: function (scope, element, attributes) {
            scope.$on('showContainerPopup', function (event, model) {
                element.show();

                element.css('left', model.left);
                element.css('top', model.top);

                //Set attributes
                element.find('.place').text(model.place);
                element.find('.fraction').text(model.fraction);
                element.find('.containerType').text(model.containerType);

                var authData = localStorageService.get('authenticationData');
                var formattedVoltValue = model.volt ? model.volt.toFixed(2).replace('.', ',') : model.volt;

                if (authData && authData.isAuth) {
                    element.find('.displayText').text(model.displayText);
                    element.find('.volt').text(formattedVoltValue);
                    if (!model.fill) {
                        element.find('.fill').text(' - ');
                    } else {
                        element.find('.fill').text(`${model.fill} %`);
                    }
                }
                else {
                    element.find('.displayText').text('');
                    element.find('.fill').text('');
                    element.find('.volt').text('');
                    element.find('#fillTd').text('');
                    element.find('#voltTd').text('');
                }
            });

            scope.$on('hideContainerPopup', function (event, args) {
                element.hide();
            });
        }
    }
}]);
