(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('LocationDetailsModalController', LocationDetailsModalController);

    LocationDetailsModalController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$windowInstance',
        'locationsService',
        'formTypes',
        'currentFormType',
        'location',
        'municipalityService',
        '$translate'
    ];

    function LocationDetailsModalController(
        $q,
        $scope,
        $rootScope,
        $windowInstance,
        locationsService,
        formTypes,
        currentFormType,
        location,
        municipalityService,
        $translate
    ) {
        $scope.saveAndClose = saveAndClose;
        $scope.closeWindow = closeWindow;
        $scope.location = location != null ? { ...location } : {};

        $scope.municipalityCodeOptions = {
            dataSource: {
                transport: {
                    read: readMunicipalities,
                }
            },
            dataTextField: 'name',
            dataValueField: 'code',
            optionLabel: $translate.instant('G_CHOOSE_MUNICIPALITY'),
            noDataTemplate: $translate.instant('G_NO_DATA'),
            valuePrimitive: true,
            template: '#: name # (#: code #)',
            valueTemplate: '#: name # (#: code #)',
        };

        function readMunicipalities(e) {
            municipalityService.getMunicipalities().then(e.success.bind(e));
        }

        function saveAndClose(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {
                save().then(function (result) {
                    $windowInstance.close(result);
                });
            }
        }

        function closeWindow(event) {
            event.preventDefault();
            $windowInstance.close(false);
        }

        function getSaveFunction(currentFormType) {
            if (currentFormType === formTypes.edit) {
                return locationsService.updateLocation.bind(locationsService);
            }

            return locationsService.addLocation.bind(locationsService);
        }

        function save() {
            var deferred = $q.defer();

            $rootScope.$broadcast('showGlobalBusyIndicator', 'locationModalIndicator');

            var saveFunction = getSaveFunction(currentFormType);

            if ($scope.location.latitude) {
                $scope.location.latitude.toString().replace(',', '.');
                $scope.location.latitude = Number($scope.location.latitude);
            }

            if ($scope.location.longitude) {
                $scope.location.longitude.toString().replace(',', '.');
                $scope.location.longitude = Number($scope.location.longitude);
            }

            saveFunction($scope.location)
                .then(result => deferred.resolve(result),
                    () => deferred.reject())
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'locationModalIndicator'));

            return deferred.promise;
        }
    }
})();
