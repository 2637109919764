(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ReportsController', ReportsController);

    ReportsController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        'tabstripUtility',
        '$translate'
    ];

    function ReportsController(
        $rootScope,
        $scope,
        $state,
        tabstripUtility,
        $translate
    ) {
        $scope.tabStripOptions = {
            animation: false,
            select: tabstripUtility.handleTabSelected,
            dataTextField: 'name',
            dataContentUrlField: 'contentUrl',
            dataSource: {
                transport: {
                    read: readReportTabOptions
                }
            },
        };
        
        initController();

        function initController() {
            $scope.$watch(() => $state.current.name, (state) => {
                tabstripUtility.changeTabByState($scope.reportsTab, state);
            });
        };

        function readReportTabOptions(e){
            e.success([
                {
                    name: $translate.instant("REPORTS_VIEW_TAB_LOG"),
                    contentUrl: 'app/reports/containerLogs/container-logs-view.html',
                    state: 'main.reports.containerlogs',
                },
                {
                    name: $translate.instant("REPORTS_VIEW_TAB_ERROR"),
                    contentUrl: 'app/reports/errorStatus/error-status-view.html',
                    state: 'main.reports.errorStatus',
                },
                {
                    name: $translate.instant("REPORTS_VIEW_TAB_BATTERY"),
                    contentUrl: 'app/reports/batteryVoltage/battery-voltage-view.html',
                    state: 'main.reports.batteryVoltage',
                },
                {
                    name: $translate.instant("REPORTS_VIEW_TAB_GENERIC"),
                    contentUrl: 'app/reports/genericReports/generic-reports-tab-view.html',
                    state: 'main.reports.genericReports',
                },
            ]);
        }
    }
})();
