(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('NavbarController', NavbarController);

    NavbarController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$state',
        '$kWindow',
        '$timeout',
        '$translate',
        'globalEvents',
        'availableLanguages',
        'setAttribute',
        'focus',
        'geocodeAPIService',
        'mapUtility',
        'mapService',
        'authService',
        'keycardUtility',
        'containerService',
        'formTypes',
        'externalLinkService',
        'accessItemsService',
        'localStorageService',
        'clientService',
        'externalReportsService',
        'userService',
        'filterStateService'
    ];

    function NavbarController(
        $q,
        $scope,
        $rootScope,
        $state,
        $kWindow,
        $timeout,
        $translate,
        globalEvents,
        availableLanguages,
        setAttribute,
        focus,
        geocodeAPIService,
        mapUtility,
        mapService,
        authService,
        keycardUtility,
        containerService,
        formTypes,
        externalLinkService,
        accessItemsService,
        localStorageService,
        clientService,
        externalReportsService,
        userService,
        filterStateService
    ) {
        $scope.searchItems = [];
        $scope.filteredSearchItems = [];
        $scope.accessItems = [];
        $scope.showClientSelector = false;
        $scope.showLoginLoadingIcon = false;
        $scope.currentLanguageId = null;

        $scope.defaultSearchFilterType = 'Containere';
        $scope.defaultSearchFilterTypeForAnonymUsers = 'Adresse';
        $scope.searchFilterTypeCacheKey = 'navbarSearchbar';
        $scope.searchFilterType =  $scope.defaultSearchFilterTypeForAnonymUsers;
        $scope.hasDepartment = false;
        $scope.departmentName = '';
        $scope.search = search;
        $scope.searchFilter = searchFilter;
        $scope.handleSearchButtonClick = handleSearchButtonClick;
        $scope.handleSelectLanguage = handleSelectLanguage;
        $scope.openKeycardOverview = openKeycardOverview;
        $scope.openReports = openReports;
        $scope.openAccessItemsOverview = openAccessItemsOverview;
        $scope.toMapView = toMapView;
        $scope.login = login;
        $scope.logout = logout;
        $scope.openAddKeycardModal = openAddKeycardModal;
        $scope.openClientSelectorModal = openClientSelectorModal;
        $scope.openAccessItemRegistrationModal = openAccessItemRegistrationModal;
        $scope.handleSelectStartingPage = handleSelectStartingPage;
        $scope.SearchFilterOption = 0;
        $scope.searchBoxText = '';
        $scope.AddressSuggestions = [];

        $scope.clientIds = [];
        $scope.selectedClientId = null;

        $scope.availableLanguages = availableLanguages;
        $scope.selectedStartPage = '';

        $scope.pages = [];

        $scope.overviewContainers = [];

        $scope.addressSearchOptions = {
            noDataTemplate: $translate.instant("G_NO_DATA"),
            filter: 'contains',
            dataTextField: 'text',
            template: (dataItem) => {
                if($scope.searchFilterType == 'AccessItem'){
                    return transformAccessItemSearchResult(dataItem);
                }
                return dataItem.text
            },
            highlightFirst: true,
            dataSource: new kendo.data.DataSource({
                serverFiltering: true,
                transport: {
                    read: readAddressSearch
                },
                sort: { field: "text", dir: "asc" }
            }),
            select: function (e) {
                $scope.search(this, e);
            }
        };

        function transformAccessItemSearchResult(dataItem){
            const parts = dataItem.text.split('|');

            const [streetAddress, cityAddress, firstName, lastName, cardNumber, locationName] = parts;

            if($scope.searchBoxText.startsWith('04')){
                return `<span class="location-name-span">${locationName} </span><span>${cardNumber}</span>`
            }else{

                const divider = "<span><strong> | </strong></span>";
                const displayText = `${streetAddress ? streetAddress : ''}${cityAddress ? divider + cityAddress : ''}${firstName && lastName ? divider + firstName + ' ' + lastName : ''}`;

                return displayText;
            }
        }

        $rootScope.appInit().then(() => $timeout(250).then(initController));

        authService.authInProgress().then(() => {
            $scope.showLoginLoadingIcon = true;
        });
     
        function initController() {
            $scope.currentLanguageId = $rootScope.getCurrentLanguageId();

            //Global event handler subscriptions
            $rootScope.$on(globalEvents.$stateChangeSuccess, onStateChanged);
            $rootScope.$on(globalEvents.overviewContainersChanged, onOverviewContainersChanged);

            //fetching searchable items

            authService.ready().then(authData => {
                loadNavbar(authData);

                if ($rootScope.isSuperAdmin()) {
                    $scope.showClientSelector = true;
                }
                $scope.pages = [
                    {
                        pageName: $translate.instant("NAVBAR_LOCATIONS"),
                        pageUrl: "main.locations",
                        isVisible: true,
                    },
                    {
                        pageName: $translate.instant("NAVBAR_CONTAINEROVERVIEW"),
                        pageUrl: "main.containerOverviewV2",
                        isVisible: $rootScope.isVisible("CORE", {
                            Area: "OverView",
                            AccessType: "R",
                        }),
                    },
                    {
                        pageName: $translate.instant("NAVBAR_KEYCARD"),
                        pageUrl: "main.accessItems",
                        isVisible: $rootScope.isVisible("ACCESSITEMS", {}),
                    },
                    {
                        pageName: $translate.instant("NAVBAR_REPORTS_CONTAINERLOGS"),
                        pageUrl: "main.reports.containerlogs",
                        isVisible: true,
                    },
                    {
                        pageName: $translate.instant("NAVBAR_SENSOR_OVERVIEW"),
                        pageUrl: "main.sensorOverview",
                        isVisible: true,
                    },
                    {
                        pageName: $translate.instant("NAVBAR_AREA"),
                        pageUrl: "main.area",
                        isVisible: $rootScope.isVisible("CORE", {
                            Area: "Area",
                            AccessType: "R",
                        }),
                    },
                    {
                        pageName: $translate.instant("NAVBAR_ADMINISTRATION"),
                        pageUrl: "main.administration",
                        isVisible:
                            $rootScope.isVisible("CORE", {
                                Area: "NewFraction",
                                AccessType: "R",
                            }) ||
                            $rootScope.isVisible("CORE", {
                                Area: "NewContainerType",
                                AccessType: "R",
                            }) ||
                            $rootScope.isVisible("CORE", { Area: "UserAdmin", AccessType: "R" }),
                    },
                ];
            });
        }

        function loadNavbar(authData) {
            if (!authData) return;

            if (authData.isAuth) {
                $scope.searchFilterType = filterStateService.getFilterFromLocalStorage($scope.searchFilterTypeCacheKey, $scope.defaultSearchFilterType);
                checkShouldOpenKeycardModal();               
                changeLanguageAccordingToUserPreferences(authData.preferredLanguage);                               

                //fetching searchable items
                $q.all([
                loadExternalLinks(),
                loadExternalReports()]).then(() => {
                    $scope.showLoginLoadingIcon = false;
                }).catch(() => {
                    $scope.showLoginLoadingIcon = false;
                });

                //Set up submenu dropdowns
                $('#language-selector').hover(
                    () => $('#language-selector').addClass('open'),
                    () => $('#language-selector').removeClass('open')
                );

                $(document).on('click', '#language-selector', function (e) {
                    e.stopPropagation();
                });

                $('#start-page-selector').hover(
                    () => $('#start-page-selector').addClass('open'),
                    () => $('#start-page-selector').removeClass('open')
                );

                $(document).on('click', '#start-page-selector', function (e) {
                    e.stopPropagation();
                });

                const selectedPage = JSON.parse(localStorage.getItem($rootScope.startPageCacheKey));

                if(selectedPage){
                    $scope.selectedStartPage = selectedPage.pageUrl;
                }

                if (!$scope.$$phase) {
                    $scope.$digest();
                }
            }
        }

        function changeLanguageAccordingToUserPreferences(preferredLanguage){
            const defaultLanguage = availableLanguages.find(x => x.id == $rootScope.defaultSelectedLanguageId);

            if (preferredLanguage) {
                const languageId = $rootScope.getLanguageIdByIsoCode(preferredLanguage) ?? defaultLanguage.id ;
                selectLangauge(languageId);
            } else {
                handleSelectLanguage({ isoLanguageCode: defaultLanguage.isoLanguageCode });
            }
        }

        //Event handlers
        function handleSearchButtonClick() {
            if ($scope.searchBoxText !== '' && $scope.searchBox.dataItems()) {

                $scope.searchBox.search($scope.searchBoxText);
                $scope.searchBox.select($scope.searchBox.ul.children().eq(0));
                var firstItem = $scope.searchBox.dataItem();

                if (firstItem) {
                    search(null, { dataItem: firstItem });
                }
            }
        }

        function selectLangauge(languageId) {
            let currentLanguage = $rootScope.getCurrentLanguageId();
            if (currentLanguage != languageId) {
                $rootScope.setLangaugeId(languageId);
                location.reload();
            }
        }

        function handleSelectLanguage(selectedLanguage) {
            const language = selectedLanguage.isoLanguageCode;
            const auth0UpdatePayload = { user_metadata : {selectedLanguageId : language}}

            userService.changeLanguage(auth0UpdatePayload).then(function (user) {
                changePreferredLanguageInLocalStorage(user.user_metadata.selectedLanguageId);
                location.reload();
            });
        }

        function searchFilter(searchFilterType) {
            focus('main-search-box');

            $scope.searchBoxText = '';
            $scope.searchFilterType = searchFilterType;            
            filterStateService.storeFilterInLocalStorage($scope.searchFilterTypeCacheKey, searchFilterType);
            
            switch (searchFilterType) {
                case 'Containere':
                    setAttribute('main-search-box', 'placeholder', $translate.instant("NAVBAR_SEARCH_PLACEHOLDER_CONTAINER"));
                    break;
                case 'Adresse':
                    setAttribute('main-search-box', 'placeholder', $translate.instant("NAVBAR_SEARCH_PLACEHOLDER_ADDRESS"));
                    break;
                case 'AccessItem':
                    setAttribute('main-search-box', 'placeholder', $translate.instant("NAVBAR_SEARCH_PLACEHOLDER_KEYCARD"));
                    break;
            }
        }

        function search(item, e) {
            if (e.dataItem.searchType === 'Address') {
                $q.all([
                    geocodeAPIService.getGeocodePosition(e.dataItem.magicKey)
                ]).then(function (data) {
                    if (data
                        && data.length > 0
                        && data[0].candidates
                        && data[0].candidates.length > 0
                        && data[0].candidates[0].location) {
                        mapService.zoomToLocation({ x: data[0].candidates[0].location.x, y: data[0].candidates[0].location.y });
                    }
                });

                return;
            }

            if (e.dataItem.searchType === 'Container') {
                var idx = _.findIndex($scope.mergedContainers, function (o) { return o.displayName === e.dataItem.text; });
                if (idx >= 0) {
                    var container = $scope.mergedContainers[idx];

                    goToLocationDetails(container);
                }
            }

            if (e.dataItem.searchType === 'AccessItem') {
                goToLocationAccessItems(e.dataItem);
            }
        }

        function goToLocationDetails(container) {
            $rootScope.$broadcast('setSplitterSize', {
                left: '50%',
                right: '50%'
            });

            if (container && container.locationId) {
                let param = {
                    locationId: container.locationId
                };
                $state.go('main.locationDetails.info', param);
            } else if (container.lat && container.lon) {
                mapService.zoomToLocation({ x: container.lon, y: container.lat });
            }
        }

        //Data loading
        function loadAccessItems() {
            return accessItemsService.getAll().then(function (accessItems) {
                $scope.accessItems = accessItems;
            });
        }

        function extendOverviewContainers(containers) {
            _.forEach(containers, oc => {
                oc.containerId = oc.id;
                oc.displayName = oc.containerId + " - " + oc.address + " - " + oc.containerType
            });
        }

        function createMergedSearchContainerList(sourceContainers) {
            $scope.overviewContainers = sourceContainers;
            extendOverviewContainers($scope.overviewContainers);
            $scope.mergedContainers = $scope.overviewContainers ? $scope.searchItems.concat($scope.overviewContainers) : $scope.searchItems;
        }

        function addSeachItems() {
            showSearchLoading();

            let requests = [containerService.getContainersExpress()],
                isLoggedIn = $rootScope.authData.isAuth;

            if (isLoggedIn) {
                requests.push(containerService.getContainersV2());
            }

            return $q.all(requests)
                .then(function (data) {
                    addContainerSearchItems(data[0]);
                    if (isLoggedIn) {
                        createMergedSearchContainerList(data[1]);
                    } else {
                        createMergedSearchContainerList([]);
                    }

                    hideSearchLoading();
                });
        }

        function addContainerSearchItems(s2wOverviewContainers) {
            for (var i = 0; i < s2wOverviewContainers.length; i++) {
                var dataItem = s2wOverviewContainers[i];

                let coords = mapUtility.projectCoordinates(dataItem.degLong, dataItem.degLat);

                $scope.searchItems.push({
                    commune: dataItem.commune,
                    containerId: dataItem.containerId,
                    containerType: dataItem.containerType,
                    fill: dataItem.fill,
                    hasPosition: dataItem.hasPosition,
                    lat: coords.Y,
                    lon: coords.X,
                    place: dataItem.place,
                    placeNumber: dataItem.placeNr,
                    poiId: dataItem.poiId,
                    time: dataItem.time,
                    volt: dataItem.volt,
                    displayName: dataItem.containerId + " - " + dataItem.place + " - " + dataItem.containerType,
                    locationId: dataItem.locationId
                });

            }
        }

        function initSearchByState(state, params) {
            if (state.name === 'main.containerDetails') {
                var relatedItem = _.find($scope.searchItems, { containerId: params.containerId });
                if (relatedItem) {
                    $scope.searchBoxText = relatedItem.displayName;
                }
            }
        }

        function onStateChanged(event, toState, toParams, fromState, fromParams) {
            initSearchByState(toState, toParams);
        }

        function onOverviewContainersChanged(event, containers) {
            createMergedSearchContainerList(containers);
        }

        function loadExternalLinks() {
            const externalLinkPromise = $q.defer();
            authService.getAccessRights().then(() => (
                $rootScope.isVisible('CORE', { Area: 'ExternalLink', AccessType: 'R' })
                    ? externalLinkService.getExternalLinks()
                    : $q.resolve([])
            )).then(function (externalLinks) {
                $rootScope.externalLinks = externalLinks;
                externalLinkPromise.resolve();
            });

            return externalLinkPromise.promise;
        }

        function showSearchLoading() {
            $('.search-box').find('span.k-i-loading').show();
        }

        function hideSearchLoading() {
            $('.search-box').find('span.k-i-loading').hide();
        }

        //Search key loading
        function readAddressSearch(e) {
            switch ($scope.searchFilterType) {
                case "Containere":
                    if ($scope.searchItems.length > 0) {
                        readContainerSuggestions(e)
                    } else {
                        addSeachItems().then(() => readContainerSuggestions(e));
                    }

                    break;
                case "Adresse":
                    readAddressSuggestions(e);
                    break;
                case "AccessItem":
                    readAccessItemSuggestion(e);
                    break;
            }
        }

        function readContainerSuggestions(e) {
            var possibleObjects = _.filter($scope.mergedContainers, function (obj) {
                return obj.displayName.toLowerCase().indexOf($scope.searchBoxText.toLowerCase()) >= 0;
            }),
                suggestions = [];

            if (possibleObjects.length > 0) {
                for (var i = 0; i < possibleObjects.length; i++) {
                    var dataItem = possibleObjects[i];
                    suggestions.push({
                        text: dataItem.displayName,
                        magicKey: dataItem.magicKey,
                        searchType: 'Container'
                    });
                }
            }

            e.success(_.uniqBy(suggestions, 'text'));
        }

        function readAddressSuggestions(e) {
            let countryCode = mapUtility.getCurrentProjection() === 'UTM33N' ? "NOR" : null;

            geocodeAPIService.getGeocodeSuggestions($scope.searchBoxText, countryCode).then(function (searchResult) {
                var suggestions = [];

                if (searchResult && searchResult.suggestions) {
                    for (var i = 0; i < searchResult.suggestions.length; i++) {
                        var dataItem = searchResult.suggestions[i];
                        suggestions.push({
                            text: dataItem.text,
                            magicKey: dataItem.magicKey,
                            searchType: 'Address'
                        });
                    }
                }

                e.success(_.uniqBy(suggestions, 'text'));
            });
        }
        
        function readAccessItemSuggestion(e) {
            var convertResult = keycardUtility.convertKeycardNumber($scope.searchBoxText);
            if (!convertResult.errorMessage) {
                $scope.searchBoxText = convertResult.returnStr;
            }

            var suggestions = [];

            accessItemsService.search($scope.searchBoxText).then(function (accessItems) {
                if(accessItems){       

                    accessItems.filter(x => x.locationName).forEach(function (item) {
                        suggestions.push({
                            accessItemId: item.id,
                            locationId: item.locationId,
                            streetAddress: item.streetAddress,
                            groupId: item.groupId,
                            text: getAccessItemSuggestionText(item.streetAddress, item.cityAddress, item.firstName, item.lastName, item.cardNumber, item.locationName),
                            magicKey: item.cardNumber,
                            searchType: 'AccessItem'
                        });
                    });
                    e.success(_.uniqBy(_.orderBy(suggestions, 'text'), 'text'));
                }else{
                    e.success(_.uniqBy(suggestions, 'text'));
                }
            });            
        }
        
        function getAccessItemSuggestionText(streetAddress, cityAddress, firstName, lastName, cardNumber, locationName){
            return streetAddress + " | " + cityAddress + " | " + firstName + " | " + lastName + " | " + cardNumber + " | " + locationName;
        } 
        
        //$scope's functions
        function logout() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'logout',
                destination: 'body',
                message: $translate.instant("NAVBAR_LOGOUT_BUSY_INDICATOR"),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });

            authService.logout().then(function (authData) {
                $rootScope.authData = authData;
                $rootScope.accessRights = null;
            }).finally(function () {
                $rootScope.$broadcast('hideBusyIndicator', 'logout');
            });

            $rootScope.$broadcast('setSplitterSize', {
                left: '100%',
                right: '0%'
            });

            $state.go('main');
        }

        function login() {
            authService.login();
        }

        function toMapView() {
            $rootScope.$broadcast('hideAllBusyIndicator');
            $state.go('main');
        }

        function openAddKeycardModal() {
            const windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ACCESS_CONTROL_ITEMS_OVERVIEW_VIEW_ACCESS_ITEM_REGISTRATION'),
                    resizable: false,
                    height: 750,
                    width: 500,
                    visible: false
                },
                templateUrl: 'app/accesscontrol/views/access-item-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'AccessItemDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.add;
                    },
                    currentGroup: function () {
                        return null;
                    },
                    currentAccessItem: function () {
                        return null;
                    },
                    multiEditAccessItems: function () {
                        return [];
                    }
                }
            });
        }

        function openKeycardOverview() {
            $rootScope.$broadcast('setSplitterSize', {
                left: '0%',
                right: '100%'
            });

            $state.go('main.keycardOverview');
        }

        function openClientSelectorModal() {
            let windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('CLIENT_CHANGE_MODAL_TITLE'),
                    resizable: false,
                    height: 150,
                    width: 300,
                    visible: false
                },
                templateUrl: 'app/administration/clients/client-change-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ClientChangeModalController',
            });

            windowInstance.result.then((selectedClient) => {
                if (selectedClient) {
                    changeClient(selectedClient);
                }
            });
        }

        function shouldOpenKeycardModal() {
            return $rootScope.authData
                && $rootScope.authData.authUser
                && Array.isArray($rootScope.authData.authUser.groups)
                && $rootScope.authData.authUser.groups.some(g => g.groupName === 'Kommune');
        }

        function loadExternalReports() {
            const externalReportPromise = $q.defer();

            externalReportsService.getAllExternalReports().then(reports => {
                $rootScope.externalReports = reports;
                externalReportPromise.resolve();
            });

            return externalReportPromise.promise;
        }

        //Private functions
        function openAccessItemsOverview() {
            $state.go('main.accessItems');
        }

        function openReports() {
            $state.go('main.reports.containerlogs');
        }

        function checkShouldOpenKeycardModal() {
            if ($rootScope.authData
                && $rootScope.isVisible('ACCESSITEMS',{})
                && $rootScope.authData.authUser                
                && Array.isArray($rootScope.authData.authUser.groups)
                && $rootScope.authData.authUser.groups.some(g => g.group && g.group.groupName === 'Kommune')
            ) {
                openAddKeycardModal();
            }
        }

        function changeClient(selectedClient) {
            if (!selectedClient) {
                return;
            }

            if ($rootScope.isVisible('CORE', { Area: 'ClientId', AccessType: 'W' })) {
                clientService.changeClient(selectedClient.id).then(function (response) {
                    if (response == true) {
                        changeClientIdInLocalStorage(selectedClient);
                        authService.changeClient().then(() => {
                            location.reload();
                        });
                    }
                });
            }
        }

        function goToLocationAccessItems(accessItem) {
            const {accessItemId, locationId, streetAddress, groupId} = accessItem;
            const areKeysValid = accessItemId && locationId && streetAddress && groupId;
            if (!areKeysValid) return;

            $state.go('main.locationDetails.accessItems', {
                accessItemId: accessItem.accessItemId,
                locationId: accessItem.locationId,
                streetAddress: accessItem.streetAddress,
                groupId: accessItem.groupId,
            });
        }

        function changeClientIdInLocalStorage(selectedClient) {
            let authData = localStorageService.get('authenticationData');
            authData.clientId = selectedClient.id;
            authData.clientName = selectedClient.name;
            localStorageService.set('authenticationData', authData);
        }

        function changePreferredLanguageInLocalStorage(isoLanguageCode) {
            let authData = localStorageService.get('authenticationData');
            authData.preferredLanguage = isoLanguageCode;
            localStorageService.set('authenticationData', authData);
        }

        function openAccessItemRegistrationModal() {
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ACCESS_CONTROL_ITEMS_OVERVIEW_VIEW_ACCESS_ITEM_REGISTRATION'),
                    resizable: false,
                    height: 730,
                    visible: false
                },
                templateUrl: 'app/accesscontrol/views/access-item-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'AccessItemDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.add;
                    },
                    currentGroup: function () {
                        return null;
                    },
                    currentAccessItem: function () {
                        return null;
                    },
                    multiEditAccessItems: function () {
                        return [];
                    }
                }
            });

        }

        function handleSelectStartingPage(page) {
            localStorage.setItem($rootScope.startPageCacheKey, JSON.stringify(page));            
            $scope.selectedStartPage = page.pageUrl;
            $('#start-page-selector').removeClass('open');
        }
    }
})();
