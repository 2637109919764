(function () {
    "use strict";

    angular
        .module("UndergroundWebApp")
        .controller(
            "AccessItemImportModalController",
            AccessItemImportModalController
        );

    AccessItemImportModalController.$inject = [
        "$q",
        "$scope",
        "$rootScope",
        "$windowInstance",
        "accessItemsService",
        "currentGroup",
        "accessItemGroupsService",
        "$translate",
        "fallbackErrorMessageText",
        "keycardUtility",
        "availableLanguages"
    ];

    function AccessItemImportModalController(
        $q,
        $scope,
        $rootScope,
        $windowInstance,
        accessItemsService,
        currentGroup,
        accessItemGroupsService,
        $translate,
        fallbackErrorMessageText,
        keycardUtility,
        availableLanguages
    ) {
        $scope.groupOptions = {
            dataSource: {
                transport: {
                    read: readGroups,
                },
            },
            dataTextField: "name",
            dataValueField: "nummer",
            optionLabel: {
                name: $translate.instant("G_CHOOSE_GROUP"),
                nummer: "",
            },
            filter: "contains",
            dataBound: function () {
                $scope.groupDropdown.select(function (dataItem) {
                    return dataItem.id === currentGroup.id;
                });
            },
        };

        $scope.downloadTemplateHref = '';
        $scope.showResults = false;
        $scope.importedData = [];
        $scope.dataWithValidationErrors = [];
        $scope.invalidInputTypeMessage = undefined;
        $scope.close = close;
        $scope.resetData = resetData;
        $scope.readFile = readFile;
        $scope.saveAndClose = saveAndClose;

        //KeyCard type "enum"
        const Keyfob = 0;
        const KeyfobOther = 1;
        const DigitalKey = 2;

        //Validation error "enum"
        const WrongGroup = 0;
        const AlreadyExist = 1;

        $scope.keycardTypes = [
            {
                id: Keyfob,
                name: $translate.instant("ACCESS_CONTROL_KEYCARD_TYPE_KEYFOB"),
            },
            {
                id: KeyfobOther,
                name: $translate.instant(
                    "ACCESS_CONTROL_KEYCARD_TYPE_KEYFOB_ANNET"
                ),
            },
            {
                id: DigitalKey,
                name: $translate.instant(
                    "ACCESS_CONTROL_KEYCARD_TYPE_DIGITAL_KEY"
                ),
            },
        ];

        function initController() {
            $scope.downloadTemplateHref = getCurrentImportTemplateUrl();
            $scope.selectedGroup = currentGroup;

            $scope.groupDropdown.select(function (dataItem) {
                return dataItem.id === currentGroup.id;
            });

            const fileInput = document.getElementById("ngexcelfile");
            $scope.fileInputValue = null;
            $scope.fileInputFileName = '';

            fileInput.addEventListener("change", function () {
                $scope.$apply(function () {
                    $scope.fileInputValue = fileInput.value ? fileInput.value : null;
                    if ($scope.fileInputValue)
                    {
                        const isFileInputTypeValid = isInputTypeValid(fileInput.files[0].name);
                        $scope.fileInputFileName = fileInput.files[0].name;
                        $scope.invalidInputTypeMessage = undefined;
                        if (!isFileInputTypeValid) {
                            $scope.invalidInputTypeMessage = $translate.instant(
                                "ACCESS_CONTROL_CONTROLLER_ACCESS_ITEM_IMPORT_INVALID_FILE_TYPE"
                            )
                        }
                    }
                });
            });

        }

        function readGroups(e) {
            accessItemGroupsService
                .getAll()
                .then(function (groups) {
                    $scope.usersDdlServerErrorText = null;
                    e.success(_.orderBy(groups, "name"));
                })
                .catch(function (error) {
                    $scope.usersDdlServerErrorText =
                        error.localizedMessage || fallbackErrorMessageText;
                    e.error();
                })
                .then(initController);
        }

        function close() {
            event.preventDefault();
            $windowInstance.close(false);
        }

        function resetData() {
            $scope.importedData = [];
            $scope.dataWithValidationErrors = [];
            const fileInput = document.getElementById("ngexcelfile");
            fileInput.value = ""; // Reset the file input value
            $scope.fileInputValue = null;
            $scope.fileInputFileName = '';
            $scope.showResults = false;
            $scope.invalidInputTypeMessage = undefined;
        }

        function readFile() {
            event.preventDefault();
            showBusyIndicator();
            $scope.showResults = false;

            readAndValidateExcelFile()
                .then(() => {
                    if (
                        !$scope.dataWithValidationErrors.length &&
                        $scope.importedData.length
                    ) {
                        // if there is no frontend validation error, then validate on backend too
                        return getBackendValidationErrors().then(function (backendValidationErrors) {
                            if (backendValidationErrors?.length) {
                                backendValidationErrors.forEach((errorObj) => {
                                    const errorMessage = getBackendValidationErrorMessage(errorObj.errorMessageType);
                                    $scope.dataWithValidationErrors.push({keycardNumber:
                                    errorObj.cardNumber,
                                    errorMessage: errorMessage});
                                });
                            }
                        });
                    }
                })
                .finally(function () {
                    hideBusyIndicator();
                    $scope.showResults = true;
                });
        }

        function saveAndClose() {
            if (
                $scope.showResults &&
                !$scope.dataWithValidationErrors.length &&
                $scope.importedData.length
            ) {
                event.preventDefault();
                showBusyIndicator();

                importKeycards()
                    .then(function (result) {
                        hideBusyIndicator();
                        $windowInstance.close(result);
                    })
                    .finally(function () {
                        hideBusyIndicator();
                    });
            }
        }

        function importKeycards() {
            const deferred = $q.defer();

            const importAccessItems = $scope.importedData.map((item) => {
                return {
                    ...item,
                    isActive: true,
                    groupId: $scope.selectedGroup && $scope.selectedGroup.id,
                };
            });

            accessItemsService
                .importAccessItems(importAccessItems)
                .then(function (result) {
                    deferred.resolve(result);
                })
                .catch(() => {
                    deferred.reject();
                });

            return deferred.promise;
        }

        // private functions
        function getCurrentImportTemplateUrl() {
            let selectedLanguageId = $rootScope.getCurrentLanguageId();
            let selectedLanguage = availableLanguages.find(x => x.id == selectedLanguageId);
            return selectedLanguage.importTemplateUrl;
        };

        function getBackendValidationErrors() {
            const deferred = $q.defer();

            const importAccessItems = $scope.importedData.map((item) => {
                return {
                    ...item,
                    isActive: true,
                    groupId: $scope.selectedGroup && $scope.selectedGroup.id,
                };
            });

            accessItemsService
                .validateAccessItems(importAccessItems)
                .then(function (result) {
                    deferred.resolve(result);
                })
                .catch(() => {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function readAndValidateExcelFile() {
            const deferred = $q.defer();

            try {
                $scope.importedData = [];
                $scope.dataWithValidationErrors = [];
                let xlsxflag = false; /* Flag for checking whether excel is .xls format or .xlsx format */
                if (
                    $("#ngexcelfile").val().toLowerCase().indexOf(".xlsx") > 0
                ) {
                    xlsxflag = true;
                }
                const reader = new FileReader();
                reader.onload = function (e) {
                    const data = e.target.result;
                    let workbook;
                    if (xlsxflag) {
                        workbook = XLSX.read(data, { type: "binary" });
                    } else {
                        workbook = XLS.read(data, { type: "binary" });
                    }

                    const firstSheetName = workbook.SheetNames[0];

                    let exceljson;
                    if (xlsxflag) {
                        exceljson = XLSX.utils
                            .sheet_to_json(workbook.Sheets[firstSheetName], {
                                header: 1,
                                range: 1
                            });
                    } else {
                        exceljson = XLS.utils
                            .sheet_to_row_object_array(
                                workbook.Sheets[firstSheetName],
                                { header: 1 }
                            )
                            .slice(1);
                    }
                    exceljson = exceljson.filter(e => e.length > 0);
                    if (exceljson.length > 0) {
                        for (let i = 0; i < exceljson.length; i++) {
                            const newAccessItemFromExcel = {};

                            newAccessItemFromExcel.cardNumber = !_.isNil(
                                exceljson[i][0]
                            )
                                ? exceljson[i][0]
                                : null;
                            newAccessItemFromExcel.cardType = !_.isNil(
                                exceljson[i][1]
                            )
                                ? getKeycardTypeNameById(exceljson[i][1])
                                : null;
                            newAccessItemFromExcel.firstName = !_.isNil(
                                exceljson[i][2]
                            )
                                ? exceljson[i][2]
                                : null;
                            newAccessItemFromExcel.lastName = !_.isNil(
                                exceljson[i][3]
                            )
                                ? exceljson[i][3]
                                : null;
                            newAccessItemFromExcel.cityAddress = !_.isNil(
                                exceljson[i][4]
                            )
                                ? exceljson[i][4]
                                : null;
                            newAccessItemFromExcel.streetAddress = !_.isNil(
                                exceljson[i][5]
                            )
                                ? exceljson[i][5]
                                : null;
                            newAccessItemFromExcel.zipCode = !_.isNil(
                                exceljson[i][6]
                            )
                                ? exceljson[i][6]
                                : null;
                            newAccessItemFromExcel.apartmentNumber = !_.isNil(
                                exceljson[i][7]
                            )
                                ? exceljson[i][7]
                                : null;
                            newAccessItemFromExcel.propertyId = !_.isNil(
                                exceljson[i][8]
                            )
                                ? exceljson[i][8]
                                : null;
                            newAccessItemFromExcel.knr = !_.isNil(
                                exceljson[i][9]
                            )
                                ? exceljson[i][9]
                                : null;
                            newAccessItemFromExcel.gnr = !_.isNil(
                                exceljson[i][10]
                            )
                                ? exceljson[i][10]
                                : null;
                            newAccessItemFromExcel.bnr = !_.isNil(
                                exceljson[i][11]
                            )
                                ? exceljson[i][11]
                                : null;
                            newAccessItemFromExcel.fnr = !_.isNil(
                                exceljson[i][12]
                            )
                                ? exceljson[i][12]
                                : null;
                            newAccessItemFromExcel.snr = !_.isNil(
                                exceljson[i][13]
                            )
                                ? exceljson[i][13]
                                : null;
                            newAccessItemFromExcel.hnr = !_.isNil(
                                exceljson[i][14]
                            )
                                ? exceljson[i][14]
                                : null;
                            newAccessItemFromExcel.bid = !_.isNil(
                                exceljson[i][15]
                            )
                                ? exceljson[i][15]
                                : null;

                            // validate and decide where to put: importedData or dataWithValidationErrors
                            const validationErrorMessage =
                                getValidationErrorMessage(
                                    newAccessItemFromExcel
                                );
                            if (validationErrorMessage) {
                                $scope.dataWithValidationErrors.push({
                                    keycardNumber:
                                        newAccessItemFromExcel.cardNumber,
                                    errorMessage: validationErrorMessage,
                                });
                            }
                            $scope.importedData.push(newAccessItemFromExcel);
                        }
                        const firstDuplicateCardNumber =
                            findFirstDuplicateCardNumber($scope.importedData);
                        if (firstDuplicateCardNumber) {
                            $scope.dataWithValidationErrors.push({
                                keycardNumber: firstDuplicateCardNumber,
                                errorMessage: $translate.instant(
                                    "ACCESS_CONTROL_ITEM_DETAILS_VIEW_DUPLICATED_ACCESS_ITEM"
                                ),
                            });
                        }
                        $scope.$apply();
                        deferred.resolve();
                    }
                };
                if (xlsxflag) {
                    reader.readAsArrayBuffer($("#ngexcelfile")[0].files[0]);
                } else {
                    reader.readAsBinaryString($("#ngexcelfile")[0].files[0]);
                }
            } catch (error) {
                deferred.reject();
            }
            return deferred.promise;
        }

        function keycardTypeStringToId(localizedTypeName) {
            const id = $scope.keycardTypes.find(
                (type) => type.name === localizedTypeName
            )?.id;
            return id;
        }

        function getKeycardTypeNameById(id) {
            const name = $scope.keycardTypes.find(
                (type) => type.id == id
                )?.name;
                return name;
        }

        function getValidationErrorMessage(newAccessItem) {
            //validate keycardType
            if (_.isNil(newAccessItem.cardType)) {
                return $translate.instant(
                    "ACCESS_CONTROL_ITEM_DETAILS_VIEW_CHOOSE_KEYCARD_TYPE"
                );
            }
            if (
                $scope.keycardTypes.findIndex(
                    (type) => type.name === newAccessItem.cardType
                ) === -1
            ) {
                return $translate.instant(
                    "ACCESS_CONTROL_ITEM_DETAILS_VIEW_NO_SUCH_KEYCARD_TYPE"
                );
            }

            //validate keycardNumber
            if (!newAccessItem.cardNumber) {
                return $translate.instant(
                    "ACCESS_CONTROL_ITEM_DETAILS_VIEW_CARD_NUMBER_VALIDATION_ERROR"
                );
            }

            const keyCardTypeId = keycardTypeStringToId(newAccessItem.cardType);
            switch (keyCardTypeId) {
                case Keyfob:
                    const keyFobValidationError =
                        getKeyfobTypeKeyCardNumberValidationError(
                            newAccessItem
                        );
                    if (keyFobValidationError) return keyFobValidationError;
                    break;
                case KeyfobOther:
                case DigitalKey:
                    const validationError = getKeyCardNumberValidationError(
                        newAccessItem.cardNumber
                    );
                    if (validationError) return validationError;
                    break;
                default:
                    $scope.errorMessage = $translate.instant(
                        "ACCESS_CONTROL_ITEM_DETAILS_VIEW_NO_SUCH_KEYCARD_TYPE"
                    );
            }

            // validate streetAddress
            if (!newAccessItem.streetAddress) {
                return $translate.instant(
                    "G_ADDRESS_REQUIRED_VALIDATION_MESSAGE"
                );
            }
            // validate propertyId
            if (newAccessItem.propertyId && newAccessItem.propertyId.length > 40) {
                return $translate.instant(
                    "ACCESS_CONTROL_ITEM_DETAILS_PROPERTYID_MAX_LENGTH"
                );
            }

            // validate zip code and apartment number to be less than 10 characters
            if (newAccessItem.zipCode && newAccessItem.zipCode.length > 10) {
                return $translate.instant(
                    "ACCESS_CONTROL_ITEM_DETAILS_VIEW_ZIP_CODE_VALIDATION_ERROR"
                );
            }

            if (newAccessItem.apartmentNumber && newAccessItem.apartmentNumber.length > 10) {
                return $translate.instant(
                    "ACCESS_CONTROL_ITEM_DETAILS_VIEW_APARTMENT_NUMBER_VALIDATION_ERROR"
                );
            }

            //before save check if there is element in importedData and dataWithValidationErrors has no element

            return null;
        }

        function findFirstDuplicateCardNumber(importedAccessItems) {
            const cardNumbers = importedAccessItems.map(
                (item) => item.cardNumber
            );
            const duplicateIndex = cardNumbers.findIndex(
                (cardNumber, index) => cardNumbers.indexOf(cardNumber) !== index
            );

            return duplicateIndex !== -1 ? cardNumbers[duplicateIndex] : null;
        }

        function getKeyfobTypeKeyCardNumberValidationError(newAccessItem) {
            const cardNumberString = newAccessItem.cardNumber.toString();
                     
            const convertedKey = keycardUtility.convertKeycardNumber(cardNumberString)

            //if convertedKey has errorMessageProperty return it
            if (convertedKey.errorMessage) {
                return convertedKey.errorMessage;
            }
            else {
                //if valid keyfob type keycardNumber, convert it for saving
                newAccessItem.cardNumber = convertedKey.returnStr;
                return null;
            }
        }

        function getBackendValidationErrorMessage(errorMessageTypeValue) {
            switch (errorMessageTypeValue) {
                case WrongGroup:
                    return $translate.instant(
                        "ACCESS_CONTROL_CONTROLLER_ACCESS_ITEM_IMPORT_GROUP_ERROR"
                    );
                case AlreadyExist:
                    return $translate.instant(
                        "ACCESS_CONTROL_CONTROLLER_ACCESS_ITEM_IMPORT_ALREADY_EXIST_IN_DB"
                    );
                default:
                    return $translate.instant(
                        "ACCESS_CONTROL_CONTROLLER_ACCESS_ITEM_IMPORT_OTHER_VALIDATION_ERROR"
                    );
              }
        }

        function isInputTypeValid(fileName) {
            const regex = /.*\.(xlsx|xls)/g;
            const isExcelFile = regex.test(fileName);
            return isExcelFile;

        }

        function getKeyCardNumberValidationError(keycardNumber) {
            if (keycardNumber.toString().length > 32) {
                return $translate.instant(
                    "ACCESS_CONTROL_ITEM_DETAILS_VIEW_CARD_NUMBER_VALIDATION_ERROR"
                );
            }
            return null;
        }

        function showBusyIndicator() {
            $rootScope.$broadcast("showBusyIndicator", {
                id: "AccessItemImportModalIndicator",
                destination: "#import-modal-container",
                message: $translate.instant("G_BUSY_INDICATOR"),
                overlay: true,
                positionClass: {
                    top: "250px",
                    left: "0px",
                    right: "0px",
                },
            });
        }

        function hideBusyIndicator() {
            $rootScope.$broadcast(
                "hideBusyIndicator",
                "AccessItemImportModalIndicator"
            );
        }
    }
})();