(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('selectionLayerFactory', selectionLayerFactory);

    selectionLayerFactory.$inject = [
        '$q',
        'esriLoader',
        '$timeout',
        'selectionSettings',
        'mapUtility'
    ];

    function selectionLayerFactory(
        $q,
        esriLoader,
        $timeout,
        selectionSettings,
        mapUtility,
    ) {
        const layerFactory = this;
        const readyDeferred = $q.defer();

        layerFactory.createLayerOnAdd = true;
        layerFactory.ready = () => readyDeferred.promise;

        loadResources().then(() => readyDeferred.resolve(layerFactory));
        return layerFactory;

        /**
         * load ESRI resources and make map logic
         */
        function loadResources() {
            return esriLoader.require([
                'dojo/_base/declare',
                'esri/Graphic',
                'esri/layers/GraphicsLayer',
                'esri/symbols/SimpleMarkerSymbol',
                'esri/symbols/SimpleLineSymbol',
                'esri/geometry/Point',
                'esri/geometry/Polyline',
                'esri/geometry/SpatialReference'
            ],
                function (
                    declare,
                    Graphic,
                    GraphicsLayer,
                    SimpleMarkerSymbol,
                    SimpleLineSymbol,
                    Point,
                    Polyline,
                    SpatialReference,
                ) {
                    var lineSym = new SimpleLineSymbol(selectionSettings.lineSymbol);
                    var pointSym = new SimpleMarkerSymbol(selectionSettings.pointSymbol);

                    layerFactory.createLayer = () => new SelectionLayer({});

                    const SelectionLayer = declare([GraphicsLayer], {
                        constructor: function () {
                            this.name = 'selectionLayer';
                            this.enabled = false;
                            this.selectionDeferred = null;
                            this.points = [];
                        },

                        createPoint: function (mapPoint, mapType) {
                            var point;
                            let spatialReference = new SpatialReference({ wkid: mapUtility.getCurrentWkid() })

                            //mapPoint contains both WGS84 and UTM33N coordinates
                            if (mapUtility.getCurrentProjection() === 'UTM33N') { 
                                point = new Point(mapPoint.x, mapPoint.y, spatialReference);
                            } else {
                                point = new Point({
                                                longitude: mapPoint.longitude,
                                                latitude: mapPoint.latitude
                                            }, spatialReference);
                            }
                            return point;
                        },

                        onClick: function (evt, mapView) {
                            if (!this.enabled) {
                                return;
                            }

                            // convert screen coordinates to map coordinates 
                            var mapPoint = mapView.toMap({
                                x: evt.offsetX,
                                y: evt.offsetY
                            });

                            // if the point exists render the actual obj
                            // if it's the first point save it.
                            if (mapPoint) {
                                let point = this.createPoint(mapPoint, mapUtility.getCurrentProjection());

                                if (this._isPointNearStartPoint(point, mapView)) {
                                    point = this.points[0];
                                    $timeout(this.onSelectionComplete.bind(this), 150);
                                }

                                this.points.push(point);
                                this.render(point, mapView);
                                return true;
                            }
                        },

                        /**
                         * onDoubleClick
                         * @param evt click event
                         * @param mapView 
                         */
                        onDoubleClick: function (evt, mapView) {
                            if (!this.enabled) {
                                return;
                            }

                            // disable zooming the map
                            mapView.goTo({
                                target: mapView.center,
                                zoom: mapView.zoom
                            });

                            // convert screen coordinates to map coordinates 
                            var mapPoint = mapView.toMap({
                                x: evt.x,
                                y: evt.y
                            });

                            // Removing last (double) point
                            this.points.pop();

                            // Polygon must consist of at least 3 points
                            if (this.points.length <= 3) {
                                return;
                            }

                            // if the point exists render the actual obj
                            // if its the first point save it.
                            if (mapPoint) {
                                this.points.pop();
                                this.points.push(this.points[0]);

                                this.render(this.points[0], mapView);

                                $timeout(this.onSelectionComplete.bind(this), 150);
                            }
                        },

                        /**
                         * render
                         * clears the graphics and add rectangle
                         * @param point map coordinates
                         */
                        render: function (point, mapView) {
                            this.clearGraphics();

                            pointSym.size = (this._isPointNearStartPoint(point, mapView)) ? selectionSettings.pointerSize : selectionSettings.pointerSizeDefault;

                            this.add(new Graphic({
                                geometry: this.points[0],
                                symbol: pointSym
                            }));

                            this.add(new Graphic({
                                geometry: this.renderGeometry(point),
                                symbol: lineSym
                            }));
                        },

                        /**
                         * renderRectangle
                         * @param point map coordinates
                         * @returns Polyline the rectangle
                         */
                        renderGeometry: function (point) {
                            var polyline = new Polyline({
                                hasZ: false,
                                hasM: false,
                                paths: this.getPolygonPaths(point),
                                spatialReference: { wkid: mapUtility.getCurrentWkid() }
                            });
                            return polyline;
                        },

                        cancelSelection: function () {
                            this.clearGraphics();

                            this.enabled = false;
                            this.points = [];
                            this.selectionDeferred = null;
                        },

                        startSelection: function () {
                            this.enabled = true;
                            this.selectionDeferred = $q.defer();

                            return this.selectionDeferred.promise;
                        },

                        onSelectionComplete: function () {
                            this.selectionDeferred.resolve(this.getSelectionResult());
                            this.cancelSelection();
                        },

                        /**
                         * getSelectionResult
                         * @returns null|polynom [ [x,y], ..., [m,n] ] 
                         */
                        getSelectionResult: function () {
                            if (this.points.length < 2) {
                                return null;
                            }

                            return this.getPolygonPaths(this.points[this.points.length - 1]);
                        },

                        /**
                         * getPolygonPaths
                         */
                        getPolygonPaths: function () {
                            var path = [];
                            for (var cv = 0; cv < this.points.length; cv++) {
                                path.push([this.points[cv].x, this.points[cv].y]);
                            }

                            return path;
                        },

                        /**
                         * clearGraphics
                         * remove all graphics from the layer
                         */
                        clearGraphics: function () {
                            this.removeAll();
                        },

                        _isPointNearStartPoint: function (point, mapView) {
                            if (this.points.length < 2) {
                                return false;
                            }

                            var screenPoint = mapView.toScreen(point),
                                firstScreenPoint = mapView.toScreen(this.points[0]),
                                distanceTreshold = mapUtility.getCurrentProjection() == "UTM33N" ? 5 : 5e-5;

                            var xDiff = Math.abs(screenPoint.x - firstScreenPoint.x),
                                yDiff = Math.abs(screenPoint.y - firstScreenPoint.y);

                            var distance = Math.sqrt(xDiff * xDiff + yDiff * yDiff);

                            if (distance < distanceTreshold) {
                                return true;
                            } else {
                                return false;
                            }
                        },
                    });
                }
            );
        };
    };
})();
