(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('UserDetailsModalController', UserDetailsModalController);

    UserDetailsModalController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$timeout',
        '$windowInstance',
        'userService',
        'authService',
        'formTypes',
        'currentFormType',
        'user',
        'allUsers',
        'fallbackErrorMessageText',
        'municipalityService',
        '$translate'
    ];

    function UserDetailsModalController(
        $q,
        $scope,
        $rootScope,
        $timeout,
        $windowInstance,
        userService,
        authService,
        formTypes,
        currentFormType,
        user,
        allUsers,
        fallbackErrorMessageText,
        municipalityService,
        $translate
    ) {
        $scope.isEdit = currentFormType === formTypes.edit;
        $scope.auth0UserId = null;
        $scope.userName = '';
        $scope.sendPasswordChange = false;
        $scope.selectedMunicipalityId = '';

        $scope.userNameValid = true;
        $scope.isEmailFormatValid = true;

        $scope.authTypes = [];
        $scope.selectedAuthTypes = [];
        $scope.addedMunicipalities = [];
        $scope.allMunicipalities = [];

        $scope.municipalityOptions = {
            dataSource: {
                transport: {
                    read: readMunicipalities
                }
            },
            dataTextField: 'name',
            dataValueField: 'id'
        };

        //Public functions
        $scope.addMunicipality = addMunicipality;
        $scope.removeMunicipality = removeMunicipality;
        $scope.saveAndClose = saveAndClose;
        $scope.closeWindow = closeWindow;

        $timeout(250).then(initController);

        function initController() {
            readAuthTypes();

            if ($scope.isEdit) {
                $scope.auth0UserId = user.auth0UserId;
                $scope.userId = user.userId;
                $scope.userName = user.userName;
                $scope.selectedAuthTypes = user.authTypes;
            }
        }

        function addMunicipality(evt, selectedMunicipalityId) {
            evt.preventDefault();

            if (selectedMunicipalityId && !$scope.addedMunicipalities.some(m => m.id === selectedMunicipalityId)) {
                let municipalityToAdd = $scope.allMunicipalities.find(m => m.id === selectedMunicipalityId);
                if (municipalityToAdd) {
                    $scope.addedMunicipalities.push(municipalityToAdd);
                }
            }
        }

        function removeMunicipality(evt, municipality) {
            var index = $scope.addedMunicipalities.findIndex(m => m.id === municipality.id);
            if (index > -1) {
                $scope.addedMunicipalities.splice(index, 1);
            }
        }
     
        function saveAndClose() {
            save().then(function (result) {
                $windowInstance.close(result);
                $('#userOverviewGrid').getKendoGrid().dataSource.read();
            });
        }

        function closeWindow(result) {
            event.preventDefault();
            $windowInstance.close(result);
        }

        function save() {
            event.preventDefault();

            let deferred = $q.defer();
            $scope.isEmailFormatValid = validateEmailFormat($scope.userName);

            if ($scope.validator.validate()
                && $scope.userNameValid
                && $scope.isEmailFormatValid) {

                $rootScope.$broadcast('showGlobalBusyIndicator', 'userIndicator');

                var user = getUserData();
                if (currentFormType === formTypes.edit) {
                    userService.updateUser(user)
                        .then(result => deferred.resolve(result))
                        .catch(deferred.reject)
                        .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'userIndicator'));
                }
                else {
                    $scope.userNameValid = validateUserName();

                    if ($scope.userNameValid) {
                        userService.addUser(user)
                            .then(result => deferred.resolve(result))
                            .catch(deferred.reject)
                            .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'userIndicator'));
                    }
                }
            }

            return deferred.promise;
        }

        //Data retrieval
        function readAuthTypes(e) {
            authService.getAuthGroups().then(function (authTypes) {
                $scope.authTypes = authTypes;
                
                if(user != null){
                    let groupIds = user.groups.map(x => x.groupId);

                    if ($scope.authTypes && currentFormType === formTypes.edit && groupIds.length > 0) {
                        $scope.selectedAuthTypes = $scope.authTypes.filter(x => x.groupId == groupIds[0])
                    }
                }
            });
        }

        function readMunicipalities(e) {
            municipalityService.getMunicipalities()
                .then(result => {
                    $scope.allMunicipalities = result;

                    return result;
                })
                .then((municipalities) => {
                    e.success(municipalities);

                    if ($scope.isEdit && user && user.municipalities) {
                        $scope.addedMunicipalities = municipalities.filter(m => user.municipalities.some(um => m.id === um.municipalityId));
                    }
                });
        }

        //Helper functions
        function getUserData() {
            let selectedMunicipalities = $scope.addedMunicipalities.map(m => ({
                municipalityId: m.id
            }));

            let userData = {
                Auth0UserId: $scope.auth0UserId,
                UserId: $scope.userId,
                UserName: $scope.isEdit ? $scope.userName: $scope.userName.toLowerCase(),
                SendPasswordChange: $scope.sendPasswordChange,
                AuthTypes: $scope.selectedAuthTypes,
                Municipalities: selectedMunicipalities
            };

            return userData;
        }

        function validateUserName() {
            var matchUsers;
            if (currentFormType === formTypes.edit) {
                //can edit own name
                matchUsers = _.filter(allUsers, ['userName', $scope.userName]);
                if (matchUsers) {
                    if (matchUsers.length === 1 && matchUsers[0].userId == user.userId) {
                        return true;
                    }
                    return false;
                }
                return true;
            } else {
                //only can add user once
                matchUsers = _.filter(allUsers, ['userName', $scope.userName]);
                if (matchUsers.length) {
                    return false;
                }
                return true;
            }
        }

        function validateEmailFormat(email) {
            let emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return emailRegexp.test(email);
        }
    }
})();
