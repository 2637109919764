(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('StatisticsController', StatisticsController);

    StatisticsController.$inject = [
        '$scope',
        '$rootScope',
        '$stateParams',
        'containerService',
        'chartOptionsService',
        '$translate'
    ];

    function StatisticsController(
        $scope,
        $rootScope,
        $stateParams,
        containerService,
        chartOptionsService,
        $translate
    ) {

        $scope.locationId = null;
        $scope.dateRange = {};

        $scope.charts = [];
        $scope.getChartData = getChartData;

        initController();

        function initController() {
            loadContainer();
        }

        function loadContainer() {
            containerService.getContainerByRelatedDevice($stateParams.deviceId)
                .then(function (container) {
                    if (container) {
                        $scope.locationId = container.locationId;
                        $scope.title = getPageTitle(container);
                    }
                });
        }

        function getPageTitle(container) {
            var titleParts = [];
            if (container.location && container.location.name) {
                titleParts.push(container.location.name);
            }
            if (container.containerNumber) {
                titleParts.push(container.containerNumber);
            }
            if (container.fraction && container.fraction.name) {
                titleParts.push(container.fraction.name);
            }

            return titleParts.join(' - ');
        }

        function getChartData() {
            containerService.getContainerStatistics(
                $stateParams.deviceId,
                moment($scope.dateRange.from).format('YYYY-MM-DD'),
                moment($scope.dateRange.to).format('YYYY-MM-DD'),
            ).then(function (statistics) {
                var charts = [];

                if(hasDataToDrawChart(statistics, chartOptionsService.chartTypes.fill)){
                    charts.push(chartOptionsService.createChart(statistics, chartOptionsService.chartTypes.fill, $translate.instant('STATISTICS_CONTROLLER_CHART_FILL'), 'blue', {
                        valueAxis: {
                            min: 0,
                            max: 100,
                        },
                    }));
                }
                
                if ($rootScope.isVisible('CORE', { Area: 'SignalStrengthReport', AccessType: 'W' }) && hasDataToDrawChart(statistics, chartOptionsService.chartTypes.rssi)) {
                    charts.push(chartOptionsService.createChart(statistics, chartOptionsService.chartTypes.rssi, $translate.instant('STATISTICS_CONTROLLER_CHART_SIGNAL_STRENGTH'), 'red'));
                }

                statistics = _.filter(statistics, ({volt}) => volt > 0);

                // if(hasDataToDrawChart(statistics, chartOptionsService.chartTypes.volt)){
                //     charts.push(chartOptionsService.createChart(statistics, chartOptionsService.chartTypes.volt, $translate.instant('STATISTICS_CONTROLLER_CHART_BATTERY'), 'green'));
                // }
                
                // if(hasDataToDrawChart(statistics, chartOptionsService.chartTypes.temperature)){
                //     charts.push(chartOptionsService.createChart(statistics, chartOptionsService.chartTypes.temperature, $translate.instant('STATISTICS_CONTROLLER_CHART_TEMPERATURE'), 'orange'));
                // }

                let userCanAccessUsageData = $rootScope.isVisible('CORE', { Area: 'ContainerUsageForPeriodeReport', AccessType: 'R' })
                    || $rootScope.isVisible('CORE', { Area: 'ContainerUsageReport', AccessType: 'R' });

                if (userCanAccessUsageData && hasDataToDrawChart(statistics, chartOptionsService.chartTypes.usageCount)
                ) {
                    charts.push(chartOptionsService.createChart(statistics, chartOptionsService.chartTypes.usageCount, $translate.instant('STATISTICS_CONTROLLER_CHART_USAGE_COUNT'), 'purple'));
                }

                $scope.charts = charts;
            });
        }

        function hasDataToDrawChart(statistics, type){
            if (!statistics || !statistics.length){
                return false;
            }

            const atLeastOneDataEntryHasValue = statistics.some(x => x[type] != 0);
            return atLeastOneDataEntryHasValue;
        }
    }
})();
