(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ConnectSensorModalController', ConnectSensorModalController);

    ConnectSensorModalController.$inject = [
        '$q',
        '$timeout',
        '$scope',
        '$rootScope',
        '$windowInstance',
        'externalSystemsService',
        'deviceService'
    ];

    function ConnectSensorModalController(
        $q,
        $timeout,
        $scope,
        $rootScope,
        $windowInstance,
        externalSystemsService,
        deviceService
    ) {
        $scope.selectedDevices = [];
        $scope.filterText = '';

        $scope.filterChanged = filterChanged;
        $scope.close = close;

        //Kendo controls must be initialized after modal is created in the DOM
        $timeout(250).then(initController);

        function initController() {
            showBusyIndicator();

            $q.all([
                externalSystemsService.getExternalSystems(),
                deviceService.getDevices()
            ]).then(data => {
                $scope.externalSystems = data[0];
                $scope.allDisconnectedDevices = _.filter(data[1], d => d.containerId === null);
                $scope.disconnectedDevices = $scope.allDisconnectedDevices;

                extDevices($scope.disconnectedDevices);
            })
            .finally(()=>{
                $rootScope.$broadcast('hideBusyIndicator', 'loadDevices');
            });
        }

        //Event handlers
        function close() {
            $windowInstance.close($scope.selectedDevices ? $scope.selectedDevices : null);
        }

        function filterChanged(){
            $scope.disconnectedDevices = _.filter($scope.allDisconnectedDevices, f => f.name.includes($scope.filterText));
        }

        //Private helpers
        function extDevices(devices) {
            _.forEach(devices, d => {
                var extSystem = _.find($scope.externalSystems, s => s.id.toLowerCase() === d.externalSystemId);
                d.name = d.externalId + (extSystem ? (' - ' + extSystem.name) : '');
            })
        }

        function showBusyIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: "loadDevices",
                destination: '#connect-sensor-modal',
                message: 'Henter data...',
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            })
        }
    }
})();
