(function(){
    'use strict';

    angular.module('UndergroundWebApp').factory('externalSystemsService',externalSystemsService)

    externalSystemsService.$inject =['$q', '$http', '$rootScope'];

    function externalSystemsService($q, $http, $rootScope){
        var service = {
            getExternalSystems: getExternalSystems,
            getAllExternalSystems: getAllExternalSystems
        };

        return service;

        function getExternalSystems(){
            var deferred = $q.defer();
            $http.get('api/externalSystems').then(function (result) {
                if (result && result.data) {

                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function (result) {
                deferred.reject(result.data);
            });

            return deferred.promise;
        }

        function getAllExternalSystems(){
            var deferred = $q.defer();
            $http.get('api/externalSystems/all').then(function (result) {
                if (result && result.data) {

                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function (result) {
                deferred.reject(result.data);
            });

            return deferred.promise;
        }
    }
})();
