(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('LocationPowerBITabController', LocationPowerBITabController);

    LocationPowerBITabController.$inject = [
        '$stateParams',
        '$rootScope',
        '$scope',
        '$timeout'
    ];

    function LocationPowerBITabController(
        $stateParams,
        $rootScope,
        $scope,
        $timeout
    ) {
        const minFrameWidth = 450;
        const minFrameHeight = 300;

        let windowSize = {
            width: $(document).width(),
            height: $(document).height()
        };

        $scope.frameWidth = Math.max(windowSize.width / 2 - 40, minFrameWidth);
        $scope.frameHeight = Math.max(windowSize.height - 224, minFrameHeight);

        $timeout(250).then(initController);

        function initController() {
            let locationId = $stateParams.locationId,
                filter = "$filter=Locations/Id eq '" + locationId + "'";

                if ($rootScope.externalReports && Array.isArray($rootScope.externalReports)) {
                    let locationDetailsBaseUrl = $rootScope.externalReports.find(x => x.reportType === 'LocationDetailsStatisticsReport');
                    if (locationDetailsBaseUrl) {
                        $scope.powerBILink = locationDetailsBaseUrl.reportUrlBase + '&' + filter;

                        //Needs to add iframe manually to avoid not loading powerbi frame
                        //to avoid HTTP 404 because of unset src attribute value
                        let powerBiFrame = $('<iframe frameborder="0" allowFullScreen="true" src="{{powerBILink | trustAsResourceUrl}}" />');

                        powerBiFrame.attr('width', $scope.frameWidth);
                        powerBiFrame.attr('height', $scope.frameHeight);
                        powerBiFrame.attr('src', $scope.powerBILink);

                        $('#powerbi-statistics').append(powerBiFrame);
                    }
                }
            }
        }
    }
)();
