(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('AccessItemGroupUpdateModalController', AccessItemGroupUpdateModalController);

    AccessItemGroupUpdateModalController.$inject = [
        '$scope',
        '$rootScope',
        '$windowInstance',
        'accessItemGroupsService',
        'currentGroup',
        '$translate'
    ];

    function AccessItemGroupUpdateModalController(
        $scope,
        $rootScope,
        $windowInstance,
        accessItemGroupsService,
        currentGroup,
        $translate
    ) {
        $scope.groupName = '';
        $scope.groupId = '';
        $scope.showUniqueErrorMsg = false;
        
        $scope.close = close;
        $scope.saveAndClose = saveAndClose;
        initController();

        function initController() {          
            $scope.$watch('groupName', function () {
                $scope.showUniqueErrorMsg = false;
            });  

            $scope.groupName = currentGroup.name;
        }

        //Event handlers
        function saveAndClose() {
            showBusyIndicator();

            validateGroupName({ name: $scope.groupName }).then(function (isUnique) {
                $scope.showUniqueErrorMsg = !isUnique;
                if (!isUnique) {
                    hideBusyIndicator()
                    return;
                }
                
                let updatedGroup = null;
                accessItemGroupsService.updateAccessItemGroup({ ...currentGroup, name: $scope.groupName })
                    .then(function (result) {
                        updatedGroup = result;
                        return updatedGroup;
                        
                    }).catch(() => {

                    }).finally(function () {
                        hideBusyIndicator();
                        $windowInstance.close(updatedGroup);
                    });                  
            });          
        }

        function close() {
            $windowInstance.close(true);
        }

        //Private functions
        function validateGroupName(group) {
            return accessItemGroupsService.isGroupNameUnique(group);
        }
      
        function showBusyIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'GroupLocationModalIndicator',
                destination: '#group-location-modal',
                message: $translate.instant('G_BUSY_INDICATOR'),
                overlay: true,
                positionClass: {
                    top: '60px',
                    left: '0px',
                    right: '0px'
                }
            });
        }

        function hideBusyIndicator() {
            $rootScope.$broadcast('hideBusyIndicator', 'GroupLocationModalIndicator');
        }
    }
})();
