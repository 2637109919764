(function () {
    angular.module('UndergroundWebApp').factory('batteryStatusLayerFactory', batteryStatusLayerFactory);

    batteryStatusLayerFactory.$inject = [
        '$q',
        '$rootScope',
        'mapUtility',
        'esriLoader',
        'containerService'
    ]

    function batteryStatusLayerFactory(
        $q,
        $rootScope,
        mapUtility,
        esriLoader,
        containerService
    ) {
        let readyDeferred = null,
            initDeferred = null;
        
        const voltageTresholds = {
            greenMin: 12.6,
            yellowMin: 12.4,
            orangeMin: 11.9
        }

        const batteryStatusLayerFactory = {
            createLayerOnAdd: true,

            ready: function () {
                if (readyDeferred === null) {
                    readyDeferred = $q.defer();
                }

                return readyDeferred.promise;
            },

            initialized: function () {
                if (initDeferred === null) {
                    initDeferred = $q.defer();
                }

                return initDeferred.promise;
            }
        };

       
        esriLoader.require([
            'esri/geometry/Point',
            'mcl/MarkerClusterLayer',
            'esri/symbols/SimpleMarkerSymbol',
            'esri/symbols/SimpleLineSymbol',
        ], function (Point, MarkerClusterLayer, SimpleMarkerSymbol, SimpleLineSymbol) {
            if (readyDeferred === null) {
                readyDeferred = $q.defer();
            }
            if (initDeferred === null) {
                initDeferred = $q.defer();
            }

            const clusterSettings = {
                batteryStatusClusterMarkerFactory:
                function getClusterMarker(containers) {
                    const pointCount = containers.length;

                    const lowestVoltageValue = batteryStatusLayerFactory.getLowestVoltage(containers);

                    const colors = {
                        green: [64, 230, 42, 0.6],
                        red: [232, 75, 51, 0.6],
                        orange: [232, 118, 0, 0.6],
                        yellow: [226, 212, 49, 0.6],
                        grey: [192,192,192,0.6]
                    }

                    let color = colors.green;

                    if(lowestVoltageValue == null){
                        color = colors.grey;
                    }


                    if (lowestVoltageValue < voltageTresholds.orangeMin){
                        color = colors.red;
                    }else if(lowestVoltageValue >= voltageTresholds.orangeMin && lowestVoltageValue < voltageTresholds.yellowMin){
                        color = colors.orange;
                    }else if(lowestVoltageValue >= voltageTresholds.yellowMin && lowestVoltageValue < voltageTresholds.greenMin){
                        color = colors.yellow;
                    }

                    if (pointCount < 19) {
                        return new SimpleMarkerSymbol({ size: 22, outline: new SimpleLineSymbol({ color: color }), color: color })
                    } else if (pointCount >= 19 && pointCount < 150) {
                        return new SimpleMarkerSymbol({ size: 24, outline: new SimpleLineSymbol({ color: color }), color: color })
                    } else if (pointCount >= 150 && pointCount < 250) {
                        return new SimpleMarkerSymbol({ size: 28, outline: new SimpleLineSymbol({ color: color }), color: color })
                    } else if (pointCount >= 250 && pointCount < 500) {
                        return new SimpleMarkerSymbol({ size: 32, outline: new SimpleLineSymbol({ color: color }), color: color })
                    } else if (pointCount >= 500 && pointCount < 1000) {
                        return new SimpleMarkerSymbol({ size: 64, outline: new SimpleLineSymbol({ color: color }), color: color })
                    } else {
                        return new SimpleMarkerSymbol({ size: 76, outline: new SimpleLineSymbol({ color: color }), color: color })
                    }
                },

                batteryStatusLayerClustering: true
            };

            batteryStatusLayerFactory.getLowestVoltage = function (containers) {
                let lowestVoltageValue = null;
                const containersWithVoltage = containers.filter(container => {
                    return container.volt !== null && container.volt !== undefined;
                  })
                  
                  if (containersWithVoltage.length > 0) {
                      lowestVoltageValue = containersWithVoltage.reduce((prev, current) => {
                        if (current.volt < prev.volt) {
                          return current;
                        } else {
                          return prev;
                        }
                    })?.volt;
                  }
                return lowestVoltageValue;
            }

            batteryStatusLayerFactory.createLayer = function () {
                const batteryStatusLayer = new MarkerClusterLayer(clusterSettings);
                batteryStatusLayer.name = 'batteryStatusLayer';
                batteryStatusLayer.zIndex = 15;
                batteryStatusLayer.popupVisible = false;

                batteryStatusLayer.toggleVisibility = function () {
                    batteryStatusLayer.visible = !batteryStatusLayer.visible;
                }

                batteryStatusLayer.onMouseMove = function (evt, mapView, hitResponse) {
                    const hitResult = hitResponse && hitResponse.results
                        .find(r => r.graphic.layer.name === batteryStatusLayer.name);
                    if (
                        !hitResult
                        || hitResult.graphic.attributes.isCluster
                    ) {
                        if (batteryStatusLayer.popupVisible) {
                            $rootScope.$broadcast('hideBatteryStatusPopup');
                            batteryStatusLayer.popupVisible = false;
                            $rootScope.isPopupVisible = false;
                        }
                        return;
                    }

                    if (!$rootScope.isPopupVisible) { // we only draw it if there is no other popup
                        const graphic = hitResult.graphic;
                    
                        const graphicScreenPoint = {
                            x: evt.offsetX,
                            y: evt.offsetY
                        };

                        batteryStatusLayer.popupVisible = true;
                        $rootScope.isPopupVisible = true;
                        $rootScope.$broadcast('showBatteryStatusPopup', {
                            left: graphicScreenPoint.x + mapView.position[0] + 20,
                            top: graphicScreenPoint.y + mapView.position[1] + 10,
                            displayText: graphic.attributes.displayText || '',
                            volt: graphic.attributes.volt || ''
                        });
                    }
                };

                batteryStatusLayer.onRightClick = function (evt, mapView, hitResponse) {
                    if (hitResponse) {
                        const graphic = hitResponse.results[hitResponse.results.length - 1].graphic;

                        if (graphic.layer.name === batteryStatusLayer.name) {
                            const screenPoint = {
                                x: evt.offsetX,
                                y: evt.offsetY
                            };

                            if (!graphic.attributes || (!graphic.attributes.isCluster && !(graphic.attributes.type === 'sublocation'))) {
                                $rootScope.$broadcast('showContextMenu', {
                                    item: graphic.attributes.point,
                                    mapPosition: mapView.position,
                                    screenPoint: screenPoint,
                                    type: 'Container'
                                });

                            } else if (graphic.attributes && graphic.attributes.type === 'sublocation') {
                                const item = graphic.attributes.point;
                                item.locationNumber = graphic.attributes.id;
                                item.commune = graphic.attributes.commune;
                                item.containerId = graphic.attributes.containerId;
                                item.displayText = graphic.attributes.displayText;
                                item.fraction = graphic.attributes.fraction;
                                item.containerType = graphic.attributes.containerType;
                                item.degLat = graphic.attributes.lat;
                                item.degLong = graphic.attributes.lon;
                                item.fill = graphic.attributes.fill;
                                item.hasPosition = graphic.attributes.hasPosition;
                                item.poiId = graphic.attributes.poiId;
                                item.place = graphic.attributes.place;
                                item.placeNr = graphic.attributes.placeNr;
                                item.time = graphic.attributes.time;
                                item.locationId = graphic.attributes.locationId;
                                item.volt = graphic.attributes.volt;

                                $rootScope.$broadcast('showContextMenu', {
                                    item: item,
                                    mapPosition: mapView.position,
                                    screenPoint: screenPoint,
                                    focusPoint: mapView.toScreen(graphic.attributes.point),
                                    type: 'Container'
                                });
                            } else if (graphic.attributes && graphic.attributes.isCluster) {
                                $rootScope.$broadcast('showContextMenu', {
                                    mapPosition: mapView.position,
                                    screenPoint: screenPoint,
                                    clusterPoints: graphic.attributes.points,
                                    type: 'Cluster'
                                });
                            }
                        }
                    }
                };

                $rootScope.$on("redrawCluster", function () {
                    batteryStatusLayer.redrawData();
                });

                batteryStatusLayer.upsertLocationData = function (locations) {
                    batteryStatusLayer.updateData(locations);
                };

                batteryStatusLayer.loadData = function () {
                    return $rootScope.appInit().then(() => {
                        return containerService.getContainersExpress().then(function (locations) {
                            const validLocations = _.filter(locations, function (loc) {
                                return loc.degLat !== 0 && loc.degLong !== 0;
                            });

                            const locationsToLoad = [];


                            for (let i = 0; i < validLocations.length; ++i) {
                                let coords = mapUtility.projectCoordinates(validLocations[i].degLong, validLocations[i].degLat);

                                locationsToLoad.push({
                                    ...validLocations[i],
                                    degLat: coords.Y,
                                    degLong: coords.X,                                    
                                });
                            }

                            batteryStatusLayer._locations = locationsToLoad;
                            batteryStatusLayer.upsertLocationData(locationsToLoad);
                        });
                    });
                }

                /**
                 * Filters the displayed locations with the provided deviceExternalId
                 * @param deviceExternalIds The list of deviceExternalIds used to filter the locations by `location.displayText`. 
                 * Leave empty to reset the filtering.
                 */
                batteryStatusLayer.setLocationFilter = function (deviceExternalIds) {
                    batteryStatusLayerFactory.initialized().then(function () {
                        if (!deviceExternalIds) {
                            batteryStatusLayer.upsertLocationData(batteryStatusLayer._locations);
                            return;
                        }

                        if (deviceExternalIds.length === 0) {
                            batteryStatusLayer.upsertLocationData([]);
                            return;
                        }

                        const filteredLocations = _.filter(batteryStatusLayer._locations, function (location) {
                            return _.includes(deviceExternalIds, location.displayText);
                        });
                        batteryStatusLayer.upsertLocationData(filteredLocations);
                    });
                }

                batteryStatusLayer.loadData().then((locations) => {
                    $rootScope.$broadcast('BatteryStatusLayerLoaded', {
                        locations
                    });

                    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
                        batteryStatusLayer.upsertLocationData(batteryStatusLayer._locations);
                    });

                    initDeferred.resolve();
                });

                return batteryStatusLayer;
            };

            readyDeferred.resolve(batteryStatusLayerFactory);
        });

        return batteryStatusLayerFactory;
    }
})();
