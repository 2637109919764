angular.module('UndergroundWebApp').directive('batteryStatusPopup', ['localStorageService', function (localStorageService) {
    'use strict';

    return {
        restrict: 'AE',
        replace: true,
        templateUrl: 'app/shared/popups/battery-status-popup.html',
        link: function (scope, element, attributes) {
            scope.$on('showBatteryStatusPopup', function (event, model) {

                element.css('left', model.left);
                element.css('top', model.top);

                const authData = localStorageService.get('authenticationData');
                const formattedVoltValue = model.volt ? model.volt.toFixed(2).replace('.', ',') : model.volt;

                if (authData && authData.isAuth) {
                    element.find('.displayText').text(model.displayText);
                    element.find('.volt').text(formattedVoltValue);
                }
                else {
                    element.find('.displayText').text('');
                    element.find('#voltTd').text('');
                }

                element.show();
            });

            scope.$on('hideBatteryStatusPopup', function (event, args) {
                element.hide();
            });
        }
    }
}]);
