(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('accessItemsService', accessItemsService);

    accessItemsService.$inject = [
        '$q',
        'encapsuledHttpService'
    ];

    function accessItemsService(
        $q,
        encapsuledHttpService
    ) {
        var accessItemsService = {
            getAll: getAll,
            search: search,
            getAllAccessItemsByGroup: getAllAccessItemsByGroup,
            getLocationsByAccessItem: getLocationsByAccessItem,
            addMultipleAccessItems: addMultipleAccessItems,
            validateAccessItems: validateAccessItems,
            importAccessItems: importAccessItems,
            accessItemSearch: accessItemSearch,
            updateAccessItem: updateAccessItem,
            replaceAccessItem: replaceAccessItem,
            updateMultipleAccessItems: updateMultipleAccessItems,
            archiveAccessItem: archiveAccessItem
        };

        return accessItemsService;

        function getAll() {
            const deferred = $q.defer();            
            encapsuledHttpService.getRequest('api/accessItems', deferred);
            return deferred.promise;
        }

        function search(searchWord) {

            const deferred = $q.defer();            
            encapsuledHttpService.getRequest('api/accessItems/search?searchWord=' + searchWord, deferred);
            return deferred.promise;
        }

        function getAllAccessItemsByGroup(groupId) {
            const deferred = $q.defer();            
            encapsuledHttpService.getRequest('api/accessitems/bygroup/' + groupId, deferred);
            return deferred.promise;
        }

        function getLocationsByAccessItem(accessItemId) {
            const deferred = $q.defer();            
            encapsuledHttpService.getRequest('api/accessitems/' + accessItemId + '/locations', deferred);
            return deferred.promise;           
        }

        function accessItemSearch(filter) {
            const deferred = $q.defer();            
            encapsuledHttpService.getRequest('api/accessItem/AccessItemSearch/' + filter, deferred);
            return deferred.promise;  
        }

        function validateAccessItems(importAccessItems) {
            const deferred = $q.defer();            
            encapsuledHttpService.postRequest('api/accessItems/validate', importAccessItems, deferred);
            return deferred.promise; 
        }

        function importAccessItems(importAccessItems) {
            const deferred = $q.defer();            
            encapsuledHttpService.postRequest('api/accessItems/import', importAccessItems, deferred);
            return deferred.promise; 
        }

        function addMultipleAccessItems(addAccessItemsModel) {
            const deferred = $q.defer();            
            encapsuledHttpService.postRequest('api/accessItems/bulk', addAccessItemsModel, deferred);
            return deferred.promise; 
        }

        function updateAccessItem(accessItem) {
            
            const deferred = $q.defer();            
            encapsuledHttpService.putRequest('api/accessItems', accessItem, deferred);
            return deferred.promise; 
        }

        function replaceAccessItem(accessItem) {
            
            const deferred = $q.defer();            
            encapsuledHttpService.putRequest('api/accessItems/replace', accessItem, deferred);
            return deferred.promise; 
        }

        function updateMultipleAccessItems(accessItems) {
            const deferred = $q.defer();            
            encapsuledHttpService.putRequest('api/accessItems/bulk', accessItems, deferred);
            return deferred.promise;            
        }

        function archiveAccessItem(accessItemId) {
            const deferred = $q.defer();            
            encapsuledHttpService.postRequest(`api/accessItems/${accessItemId}/archive`, [], deferred);
            return deferred.promise;            
        }
    }
})();
