(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('LogActionsController', LogActionsController);

    LogActionsController.$inject = [
        '$scope',
        'logActionService',
        '$kWindow',
        'formTypes',
        'filterStateService',
        'statuses',
        '$translate'        
    ];

    function LogActionsController(
        $scope,
        logActionService,
        $kWindow,
        formTypes,
        filterStateService,
        statuses,
        $translate
    ) {
        $scope.logActionDetailsModalHeight = 250;
        $scope.sortedLogActions = [];
        $scope.openNewLogAction = openNewLogAction;
        $scope.openUpdateLogAction = openUpdateLogAction;
        $scope.deleteLogAction = deleteLogAction;

        let logActionActions = [];

        $scope.logActionOverviewGridOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readLogActions
                },
                pageSize: 70,
                sort: filterStateService.getSortFromQueryString(),
                filter: filterStateService.getFilterFromQueryString(),
            }),
            columns: [
                { field: 'name', title: $translate.instant('G_NAME') },
                { field: 'externalId', title: $translate.instant('G_EXTERNAL_ID') },
                { 
                    field: 'status', 
                    title: $translate.instant('G_STATUS'),
                    width: 100,
                    template: dataItem => getStatus(dataItem)
                 },
                 { 
                    field: 'actionId', 
                    title: $translate.instant('ADMINISTRATION_LOG_ACTIONS_LOG_ACTIONS_ACTION'),
                    width: 130,
                    template: dataItem => getAction(dataItem)
                 },
                {
                    field: 'edit',
                    filterable: false,
                    sortable: false,
                    title: ' ',
                    width: 80,
                    template: dataItem => getEditAndDeleteTemplateIfItemIsEditable(dataItem)
                }
            ],
            scrollable: {
                virtual: true
            },            
            sortable: true,
            language: "english",
            filterable: true,
            noRecords: true,            
            resizable: true,
            selectable: false,
            //change: onLogActionRowSelected,
            sort: filterStateService.storeGridSortInQueryString,
            filter: filterStateService.storeGridFilterInQueryString,
        };

        function getEditAndDeleteTemplateIfItemIsEditable(dataItem){

            const editHtmlElement = `<i class="fa fa-1-5x fa-pencil action-hover" ng-click="openUpdateLogAction(dataItem)" aria-hidden="true"></i>`;
            const deleteHtmlElement = `<i class="fa fa-1-5x fa-trash fa-fw" ng-click="deleteLogAction(dataItem)" aria-hidden="true"></i>`;

            return dataItem?.isEditable ? editHtmlElement + deleteHtmlElement : editHtmlElement;
        }

        function getStatus(dataItem){
            return statuses.find(x => x.Id == dataItem.status)?.LocalizedName;
        }

        function getAction(dataItem){
            return logActionActions.find(action => action.id === dataItem.actionId).name;
        }
       
        function readLogActions(e) {

            $scope.showBusyIndicator();

            logActionService.getLogActionsForAdministration().then(function (logActions) {
                
                $scope.sortedLogActions = logActions.sort(function (a, b) {
                    return (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0));
                });
                
                logActionService.getLogActionActions().then(actions => {

                    actions.forEach(action => localizeLogActionActions(action));

                    if (actions.length > 0) {
                        logActionActions = actions;
                    }
                    e.success($scope.sortedLogActions);
                    $scope.hideBusyIndicator();
                })

            }, 
            function () {
                $scope.hideBusyIndicator();
                e.success([]);
            });
        }

        function localizeLogActionActions(action){
            switch (action.name) {
                case 'None': action.name = $translate.instant('LOG_ACTION_ACTION_NONE'); break;
                case 'TriggerIntegration': action.name = $translate.instant('LOG_ACTION_ACTION_TRIGGER_INTEGRATION'); break;
                default: break;
            }
        }

        function openNewLogAction() {
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMINISTRATION_LOG_ACTIONS_ADD_LOG_ACTION'),
                    resizable: false,
                    height: $scope.logActionDetailsModalHeight,
                    width: 360,
                    height: 300,
                    visible: false
                },
                templateUrl: 'app/administration/logAction/logAction-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'LogActionDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.add;
                    },
                    logAction: function () {
                        return null;
                    },
                    allLogActions: function () {
                        return $scope.sortedLogActions;
                    },
                    actions: () => {
                        return logActionActions;
                    }
                }
            });
        }

        function openUpdateLogAction(dataItem) {
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMINISTRATION_LOG_ACTIONS_UPDATE_LOG_ACTION'),
                    resizable: false,
                    height: $scope.logActionDetailsModalHeight,
                    width: 400,
                    height: 300,
                    visible: false
                },
                templateUrl: 'app/administration/logAction/logAction-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'LogActionDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.edit;
                    },
                    logAction: function () {
                        return dataItem;
                    },
                    allLogActions: function () {
                        return $scope.sortedLogActions;
                    },
                    actions: () => {
                        return logActionActions;
                    }
                }
            });
        }

        function deleteLogAction(dataItem){
            const confirmMessage = $translate.instant('ADMINISTRATION_LOG_ACTIONS_CONFIRM_DELETE') + " " + dataItem.name + "?";

            if(confirm(confirmMessage)){
                logActionService.deleteLogAction(dataItem.id).then(function (result) {
                    if (result) {
                        removeLogActionFromGrid(dataItem.id);
                    }
                });
            }           
        }

        function removeLogActionFromGrid(id){
            const itemToRemove = $scope.sortedLogActions.find(x => x.id == id);
            const index = $scope.sortedLogActions.indexOf(itemToRemove);
            $scope.sortedLogActions.splice(index,1);
            $scope.logActionOverviewGrid.dataSource.read();
        }
    }
})();
