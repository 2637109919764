(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('LocationAccessItemsController', LocationAccessItemsController);

    LocationAccessItemsController.$inject = [
        '$scope',
        '$stateParams',
        '$kWindow',
        '$timeout',
        'formTypes',
        'accessItemsService',
        'accessItemGroupsService',
        '$translate',
    ];

    function LocationAccessItemsController(
        $scope,
        $stateParams,
        $kWindow,
        $timeout,
        formTypes,
        accessItemsService,
        accessItemGroupsService,
        $translate
    ) {
        const currentLocationId = $stateParams.locationId;
        const currentStreetAddress = $stateParams.streetAddress;
        const currentGroupId = $stateParams.groupId;
        const currentAccessItemId = $stateParams.accessItemId;

        $scope.selectedAccessItems = [];
        $scope.showAllItems = false;

        $scope.handleItemCheckboxClick = handleItemCheckboxClick;

        $scope.accessItemGroups = [];
        $scope.accessItemsOfSelectedGroup = [];

        $scope.openAddGroupsModal = openAddGroupsModal;
        $scope.openAccessItemDetailsModal = openAccessItemDetailsModal;
        $scope.openReplaceAccessItemModal = openReplaceAccessItemModal;
        $scope.openMultipleAccessItemEditModal = openMultipleAccessItemEditModal;
        $scope.openAddExtraAccessItemModal = openAddExtraAccessItemModal;
        $scope.isChecked = isChecked;
        $scope.filterActiveItems = filterActiveItems;        
        $scope.archiveAccessItem = archiveAccessItem;

        //KeyCard type "enum"
        const Keyfob = 0;
        const KeyfobOther = 1;
        const DigitalKey = 2;
        
        $scope.keycardTypes = [
            {id: Keyfob, name: $translate.instant('ACCESS_CONTROL_KEYCARD_TYPE_KEYFOB')},
            {id: KeyfobOther, name: $translate.instant('ACCESS_CONTROL_KEYCARD_TYPE_KEYFOB_ANNET')},
            {id: DigitalKey, name: $translate.instant('ACCESS_CONTROL_KEYCARD_TYPE_DIGITAL_KEY')}
        ];

        $scope.statusTypes = [
            {id: 0, name: $translate.instant('ACCESS_CONTROL_ITEM_DETAILS_BLOCKED')},
            {id: 1, name: $translate.instant('G_ACTIVE')}
        ];

        $scope.groupOptions = {
            dataSource: {
                transport: {
                    read: readGroupOptions
                }
            },
            dataTextField: 'name',
            dataValueField: 'id',
            change: handleSelectedGroupChange 
        };

        $scope.keycardsOverviewGridOptions = {
            dataSource: {
                pageSize: 70,
                transport: {
                    read: readAccessItemsOfSelectedGroup
                },
                sort: {
                    field: "cardNumber",
                    dir: "asc"
                }
            },
            dataBound: function(e) {
                this.autoFitColumn('type');
                this.autoFitColumn('typeName');
                this.autoFitColumn('firstName');
                this.autoFitColumn('lastName');
                this.autoFitColumn('streetAddress');
                this.autoFitColumn('cityAddress');
                this.autoFitColumn('propertyId');
                this.autoFitColumn('knr');
                this.autoFitColumn('gnr');
                this.autoFitColumn('bnr');
                this.autoFitColumn('fnr');
                this.autoFitColumn('snr');
                this.autoFitColumn('hnr');
                this.autoFitColumn('bid');
                this.autoFitColumn('createdAt');
                this.autoFitColumn('isActiveString');
              },
            sortable: true,
            filterable: true,
            noRecords: true,
            resizable: true,
            messages: {
                noRecords: $translate.instant('G_NO_DATA')
            },
            scrollable: {
                virtual: true
            },
            columns: [
                {
                    field: 'actions',
                    title: ' ',
                    width: '30px',
                    template: '<input class="checkbox" type="checkbox" id="check" ng-checked="isChecked(dataItem)" ng-click="handleItemCheckboxClick(dataItem)" />',
                    filterable: false,
                    sortable: false,
                    groupable: false
                },
                {
                    title: $translate.instant('LOCATION_ACCESS_CONTROL_HEADER_CARD_NUMBER'),
                    field: 'cardNumber',
                    width: '140px'
                },               
                {
                    title: $translate.instant('LOCATION_ACCESS_CONTROL_HEADER_FIRST_NAME'),
                    field: 'firstName',
                },
                {
                    title: $translate.instant('LOCATION_ACCESS_CONTROL_HEADER_LAST_NAME'),
                    field: 'lastName',
                },
                {
                    title: $translate.instant('LOCATION_ACCESS_CONTROL_HEADER_ADDRESS'),
                    field: 'streetAddress',
                },
                {
                    title: $translate.instant('LOCATION_ACCESS_CONTROL_HEADER_CITY'),
                    field: 'cityAddress',
                },
                {
                    field: 'zipCode',
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_ZIP_CODE"),    
                    width: 100
                },
                {
                    field: 'apartmentNumber',
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_APARTMENT_NUMBER"),
                    width: 100
                },
                {
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_PROPERTYID"),
                    field: 'propertyId',
                    headerTemplate: '<span title="{{ \'ACCESS_ITEMS_OVERVIEW_PROPERTYID_TOOLTIP\' | translate }}">{{ \'ACCESS_ITEMS_OVERVIEW_PROPERTYID\' | translate }}</span>',
                },
                {
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_KNR"),
                    field: 'knr',
                    headerTemplate: '<span title="{{ \'ACCESS_ITEMS_OVERVIEW_KNR_TOOLTIP\' | translate }}">{{ \'ACCESS_ITEMS_OVERVIEW_KNR\' | translate }}</span>',
                },
                {
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_GNR"),
                    field: 'gnr',
                    headerTemplate: '<span title="{{ \'ACCESS_ITEMS_OVERVIEW_GNR_TOOLTIP\' | translate }}">{{ \'ACCESS_ITEMS_OVERVIEW_GNR\' | translate }}</span>',
                },
                {
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_BNR"),
                    field: 'bnr',
                    headerTemplate: '<span title="{{ \'ACCESS_ITEMS_OVERVIEW_BNR_TOOLTIP\' | translate }}">{{ \'ACCESS_ITEMS_OVERVIEW_BNR\' | translate }}</span>',
                },
                {
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_FNR"),
                    field: 'fnr',
                    headerTemplate: '<span title="{{ \'ACCESS_ITEMS_OVERVIEW_FNR_TOOLTIP\' | translate }}">{{ \'ACCESS_ITEMS_OVERVIEW_FNR\' | translate }}</span>',
                },
                {
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_SNR"),
                    field: 'snr',
                    headerTemplate: '<span title="{{ \'ACCESS_ITEMS_OVERVIEW_SNR_TOOLTIP\' | translate }}">{{ \'ACCESS_ITEMS_OVERVIEW_SNR\' | translate }}</span>',
                },
                {
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_HNR"),
                    field: 'hnr',
                    headerTemplate: '<span title="{{ \'ACCESS_ITEMS_OVERVIEW_HNR_TOOLTIP\' | translate }}">{{ \'ACCESS_ITEMS_OVERVIEW_HNR\' | translate }}</span>',
                },
                {
                    title: $translate.instant("ACCESS_ITEMS_OVERVIEW_BID"),
                    field: 'bid',
                    headerTemplate: '<span title="{{ \'ACCESS_ITEMS_OVERVIEW_BID_TOOLTIP\' | translate }}">{{ \'ACCESS_ITEMS_OVERVIEW_BID\' | translate }}</span>',
                },
                {
                    title: $translate.instant('LOCATION_ACCESS_CONTROL_HEADER_CREATED_AT'),
                    field: 'createdAt',
                    width: '145px',
                    template: '#= kendo.toString(kendo.parseDate(createdAt), "dd.MM.yyyy HH:mm:ss") #'
                },
                {
                    title: $translate.instant('ACCESS_ITEMS_OVERVIEW_CARD_TYPE'),
                    field: 'typeName',
                },
                {
                    field: 'isActiveString',
                    title: $translate.instant('ACCESS_CONTROL_ITEM_DETAILS_STATUS'),
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                        }
                    }
                },
                {
                    field: 'actions',
                    title: ' ',
                    width: '40px',
                    template: '<div class="grid-tooltip" ng-click="openAccessItemDetailsModal(dataItem)"><i class="fa fa-1-5x fa-pencil" aria-hidden="true"></div>',
                    filterable: false,
                    sortable: false,
                    groupable: false
                },
                {
                    field: 'actions',
                    title: ' ',
                    width: '40px',
                    template: '<div class="grid-tooltip" ng-click="openAddExtraAccessItemModal(dataItem)"><i class="fa fa-1-5x fa-plus" aria-hidden="true"></div>',
                    filterable: false
                },
                {
                    field: 'actions',
                    title: ' ',
                    width: '40px',
                    template: '<div class="grid-tooltip" ng-click="openReplaceAccessItemModal(dataItem)"><i class="fa fa-1-5x fa-exchange" aria-hidden="true"></div>',
                    filterable: false
                },
                {
                    field: 'actions',
                    title: ' ',
                    width: '30px',
                    template: '<div class="grid-tooltip action-icons" ng-click="archiveAccessItem(dataItem)"><i class="fa fa-1-5x fa-trash" aria-hidden="true"></div>',
                    filterable: false
                },
                {
                    title: $translate.instant('ACCESS_ITEMS_OVERVIEW_CARD_TYPE'),
                    field: 'type',
                    hidden: true
                },
            ],
        };

        $timeout(250).then(initController);

        function initController() {
            accessItemGroupsService.getGroupsByLocation(currentLocationId).then(function (itemGroups) {
                $scope.accessItemGroups = _.orderBy(itemGroups, 'name');

                $scope.groupsDropdown.dataSource.data($scope.accessItemGroups);
                $scope.groupsDropdown.refresh();

                if(currentGroupId && currentAccessItemId){
                    loadUiToDisplaySearchResult()
                }
                else if ($scope.accessItemGroups.length > 0 && $scope.accessItemGroups[0].id) {
                    loadAccessItemsByGroup($scope.accessItemGroups[0].id);
                }
            });
        }

        function loadUiToDisplaySearchResult(){
            loadAccessItemsByGroup(currentGroupId);            
            $scope.groupsDropdown.select((dataItem) => dataItem.id === currentGroupId);            
        }

        //Event handlers
        function handleSelectedGroupChange() {
            let selectedGroupId = this.value();
            if (selectedGroupId) {
                loadAccessItemsByGroup(selectedGroupId);
            }
        }

        function readAccessItemsOfSelectedGroup(e) {
            e.success($scope.accessItemsOfSelectedGroup);
        }

        const modalDefaultOptions = {
            modal: true,
            movable: false,
            resizable: false,
            visible: false
        };

        const modalSettings = {           
            templateUrl: 'app/accesscontrol/views/access-item-details-modal-view.html',
            windowTemplateUrl: 'app/shared/modal-base.html',
            controller: 'AccessItemDetailsModalController',           
        }

        function openAddGroupsModal() {
            var windowInstance = $kWindow.open({
                templateUrl: 'app/accessControl/views/access-item-group-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'AccessItemGroupDetailsModalController',
                options: {
                    ...modalDefaultOptions,                    
                    title: $translate.instant('LOCATION_ACCESS_CONTROL_ADD_GROUP'),
                    height: 250,
                    width: 325,
                },
                resolve: {
                    newGroupOnlyMode: function () {
                        return false;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result && result.id) {
                    let currentGroups = $scope.groupsDropdown.dataSource.data();

                    if (!currentGroups.find(g => g.id === result.id)) {
                        currentGroups.push(result);
                        $scope.groupsDropdown.dataSource.data(currentGroups);

                        //In case this is the first assigned group, load the items
                        if (currentGroups.length === 1) {
                            loadAccessItemsByGroup(result.id);
                        }
                    }
                }
            });
        }

        function openAccessItemDetailsModal(accessItemToEdit) {
            var windowInstance = $kWindow.open({
                ...modalSettings,
                options: {
                    ...modalDefaultOptions,
                    title: $translate.instant('LOCATION_ACCESS_CONTROL_REGISTER_KEYCARD'),
                    height: 750,
                },
                resolve: {
                    currentFormType: function () {
                        return accessItemToEdit ? formTypes.edit : formTypes.add;
                    },
                    currentGroup: function () {
                        return $scope.groupsDropdown.dataItem();
                    },
                    currentAccessItem: function () {
                        return accessItemToEdit;
                    },
                    multiEditAccessItems: function () {
                        return [];
                    }
                }
            });
            
            windowInstance.result.then(function (result) {
                if (result) {
                    const selectedGroupId = $scope.groupsDropdown.value();
                    loadAccessItemsByGroup(selectedGroupId);
                }
            });
        }

        function openReplaceAccessItemModal(dataItem) {
            var windowInstance = $kWindow.open({
                ...modalSettings,
                options: {
                    ...modalDefaultOptions,
                    title: $translate.instant('LOCATION_ACCESS_CONTROL_REPLACE_KEYCARD'),
                    height: 550,
                    width: 500,
                },
                resolve: {
                    currentFormType: function () {
                        return formTypes.replace;
                    },
                    currentGroup: function () {
                        return $scope.groupsDropdown.dataItem();
                    },
                    currentAccessItem: function () {
                        return dataItem;
                    },
                    multiEditAccessItems: function () {
                        return [];
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    const selectedGroupId = $scope.groupsDropdown.value();
                    loadAccessItemsByGroup(selectedGroupId);
                }
            });
        }

        function openAddExtraAccessItemModal(dataItem) {
            var windowInstance = $kWindow.open({
                ...modalSettings,
                options: {
                    ...modalDefaultOptions,
                    title: $translate.instant('LOCATION_ACCESS_CONTROL_ADD_EXTRA_KEYCARD'),
                    height: 550,
                    width: 500,
                },
                resolve: {
                    currentFormType: function () {
                        return formTypes.extra;
                    },
                    currentGroup: function () {
                        return $scope.groupsDropdown.dataItem();
                    },
                    currentAccessItem: function () {
                        return dataItem;
                    },
                    multiEditAccessItems: function () {
                        return [];
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    const selectedGroupId = $scope.groupsDropdown.value();
                    loadAccessItemsByGroup(selectedGroupId);
                }
            });
        }


        function openMultipleAccessItemEditModal() {
            if ($scope.selectedAccessItems.length === 0) return;

            var windowInstance = $kWindow.open({
                ...modalSettings,
                options: {
                    ...modalDefaultOptions,
                    title: $translate.instant('LOCATION_ACCESS_CONTROL_EDIT_MULTIPLE_KEYCARD'),
                    height: 750,
                    width: 500,
                },
                resolve: {
                    currentFormType: function () {
                        return formTypes.editMultiple;
                    },
                    currentGroup: function () {
                        return $scope.groupsDropdown.dataItem();
                    },
                    currentAccessItem: function () {
                        return null;
                    },
                    multiEditAccessItems: function () {
                        return $scope.selectedAccessItems;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    $timeout(250).then(() => loadAccessItemsByGroup($scope.groupsDropdown.dataItem().id));
                }
            });
        }

        function handleItemCheckboxClick(item) {
            selectAccessItem(item)
        }

        function selectAccessItem(item){
            if ($scope.selectedAccessItems.indexOf(item) !== -1) {
                $scope.selectedAccessItems = _.reject($scope.selectedAccessItems, function (e) { return e.cardNumber === item.cardNumber });
            }
            else {
                $scope.selectedAccessItems.push(item);
            }
        }

        function isChecked(dataItem){
            return $scope.selectedAccessItems.some(x => x.id == dataItem.id);
        }

        //Private functions
        function loadAccessItemsByGroup(accessItemGroupId) {
            return accessItemsService.getAllAccessItemsByGroup(accessItemGroupId)
                .then(function (accessItems) {
                    const accessItemsWithStringType = accessItems?.map(accessItem => {
                        return {
                          ...accessItem,
                          typeName: getKeycardTypeNameById(accessItem.type),
                          isActiveString: getAccesItemStatus(accessItem.isActive)
                        };
                    });
                    $scope.accessItemsOfSelectedGroup = accessItemsWithStringType;

                    filterActiveItems();

                    if(currentAccessItemId){
                        const selectedAccessItem = $scope.accessItemsOfSelectedGroup.find(x => x.id == currentAccessItemId);
                        selectAccessItem(selectedAccessItem);
                        filterAccessItemsToSearchResult();
                    }else{
                        $scope.selectedAccessItems = [];
                    }
                });
        }

        function getKeycardTypeNameById(id) {
            const name = $scope.keycardTypes.find(
                (type) => type.id == id
                )?.name;
                return name;
        }

        function filterAccessItemsToSearchResult(){
            if(!currentStreetAddress){
                return;
            }
            $scope.keycardsOverviewGrid.dataSource.filter([]);

            const filter = {
                field: 'streetAddress',
                operator: 'eq',
                value: currentStreetAddress
            };

            $timeout(function() {
                $scope.keycardsOverviewGrid.dataSource.filter(filter);
            });
        }

        function filterActiveItems() {
            $timeout(() => {
                const displayedList = $scope.showAllItems ? $scope.accessItemsOfSelectedGroup : $scope.accessItemsOfSelectedGroup.filter(el => el.isActive === true);
                $scope.keycardsOverviewGrid.dataSource.data(displayedList);
                $scope.keycardsOverviewGrid.refresh();
            }, 100); //Timeout to allow the slider to finish animating before the grid refresh would block the ui
        }

        function getAccesItemStatus(accessItemStatus) {
            if (accessItemStatus) {
                return $translate.instant('G_ACTIVE');
            } else {
                return $translate.instant('ACCESS_CONTROL_ITEM_DETAILS_BLOCKED');
            }
        }

        function archiveAccessItem(dataItem) {
            if (confirm($translate.instant('ACCESS_CONTROL_ITEM_DETAILS_ARCHIVE_CONFIRM') + `: ${dataItem.cardNumber}?`)) {
                accessItemsService.archiveAccessItem(dataItem.id).then(() => 
                {
                    $scope.accessItemsOfSelectedGroup = $scope.accessItemsOfSelectedGroup.filter(item => item.id !== dataItem.id);
                    $scope.keycardsOverviewGrid.dataSource.data($scope.accessItemsOfSelectedGroup);
                    $scope.keycardsOverviewGrid.refresh();
                });
            }
        }

        function readGroupOptions(e) {
            e.success([]);
        }
    }
})();
