(function () {
    angular.module('UndergroundWebApp').factory('fillDateLayerFactory', fillDateLayerFactory);

    fillDateLayerFactory.$inject = [
        '$q',
        '$rootScope',
        'mapUtility',
        'esriLoader',
        'locationsService'
    ]

    function fillDateLayerFactory(
        $q,
        $rootScope,
        mapUtility,
        esriLoader,
        locationsService
    ) {
        let readyDeferred = null,
            initDeferred = null;

        const fillDateLayerFactory = {
            createLayerOnAdd: true,

            ready: function () {
                if (readyDeferred === null) {
                    readyDeferred = $q.defer();
                }

                return readyDeferred.promise;
            },

            initialized: function () {
                if (initDeferred === null) {
                    initDeferred = $q.defer();
                }

                return initDeferred.promise;
            }
        };

       
        esriLoader.require([
            'esri/geometry/Point',
            'mcl/MarkerClusterLayer',
            'esri/symbols/SimpleMarkerSymbol',
            'esri/symbols/SimpleLineSymbol',
        ], function (Point, MarkerClusterLayer, SimpleMarkerSymbol, SimpleLineSymbol) {
            if (readyDeferred === null) {
                readyDeferred = $q.defer();
            }
            if (initDeferred === null) {
                initDeferred = $q.defer();
            }

            const clusterSettings = {
                emptyingLayerClusterMarkerFactory:
                function getClusterMarker(containersWithEmptyingDate) {
                    const pointCount = containersWithEmptyingDate.length;

                    const closestEmptyingDateValue = containersWithEmptyingDate.reduce((prev, current) => {
                        if (current.dateWhenFull < prev.dateWhenFull) {
                          return current;
                        } else {
                          return prev;
                        }
                    })?.dateWhenFull;

                    const colors = {
                        green: [64, 230, 42, 0.6],
                        red: [232, 75, 51, 0.6],
                        orange: [232, 118, 0, 0.6],
                        yellow: [226, 212, 49, 0.6]
                    }     

                    if(closestEmptyingDateValue == null){
                        //TODO return grey dot
                    }

                    const closestEmptyingDate = new Date(closestEmptyingDateValue)
                    const now = new Date();
                    const dayInMillis = 24 * 60 * 60 * 1000;

                    let color = colors.green;

                    if ((closestEmptyingDate.getTime() - now.getTime()) <= dayInMillis){
                        color = colors.red;
                    }else if((closestEmptyingDate.getTime() - now.getTime()) <= dayInMillis * 2){
                        color = colors.orange;
                    }else if((closestEmptyingDate.getTime() - now.getTime()) <= dayInMillis * 3){
                        color = colors.yellow;
                    }

                    if (pointCount < 19) {
                        return new SimpleMarkerSymbol({ size: 22, outline: new SimpleLineSymbol({ color: color }), color: color })
                    } else if (pointCount >= 19 && pointCount < 150) {
                        return new SimpleMarkerSymbol({ size: 24, outline: new SimpleLineSymbol({ color: color }), color: color })
                    } else if (pointCount >= 150 && pointCount < 250) {
                        return new SimpleMarkerSymbol({ size: 28, outline: new SimpleLineSymbol({ color: color }), color: color })
                    } else if (pointCount >= 250 && pointCount < 500) {
                        return new SimpleMarkerSymbol({ size: 32, outline: new SimpleLineSymbol({ color: color }), color: color })
                    } else if (pointCount >= 500 && pointCount < 1000) {
                        return new SimpleMarkerSymbol({ size: 64, outline: new SimpleLineSymbol({ color: color }), color: color })
                    } else {
                        return new SimpleMarkerSymbol({ size: 76, outline: new SimpleLineSymbol({ color: color }), color: color })
                    }
                },

                emptyingLayerClustering: true
            };    

            fillDateLayerFactory.createLayer = function () {
                var fillDateLayer = new MarkerClusterLayer(clusterSettings);
                fillDateLayer.name = 'fillDateLayer';
                fillDateLayer.zIndex = 15;
                fillDateLayer.popupVisible = false;

                fillDateLayer.toggleVisibility = function () {
                    fillDateLayer.visible = !fillDateLayer.visible;
                }

                fillDateLayer.onMouseMove = function (evt, mapView, hitResponse) {
                    const hitResult = hitResponse && hitResponse.results
                        .find(r => r.graphic.layer.name === fillDateLayer.name);
                    if (
                        !hitResult
                        || hitResult.graphic.attributes.isCluster
                    ) {
                        if (fillDateLayer.popupVisible) {
                            $rootScope.$broadcast('hideEmptyingDatePopup');
                            fillDateLayer.popupVisible = false;
                            $rootScope.isPopupVisible = false;
                        }
                        return;
                    }

                    if (!$rootScope.isPopupVisible) { // we only draw it if there is no other popup
                        const graphic = hitResult.graphic;
                    
                        const graphicScreenPoint = {
                            x: evt.offsetX,
                            y: evt.offsetY
                        };

                        fillDateLayer.popupVisible = true;
                        $rootScope.isPopupVisible = true;
                        $rootScope.$broadcast('showEmptyingDatePopup', {
                            left: graphicScreenPoint.x + mapView.position[0] + 20,
                            top: graphicScreenPoint.y + mapView.position[1] + 10,
                            id: graphic.attributes.containerNumber || '',
                            date: graphic.attributes.dateWhenFull || '',
                        });
                    }
                };

                fillDateLayer.onRightClick = function (evt, mapView, hitResponse) {
                    if (hitResponse) {
                        var graphic = hitResponse.results[hitResponse.results.length - 1].graphic;

                        if (graphic.layer.name === fillDateLayer.name) {
                            var screenPoint = {
                                x: evt.offsetX,
                                y: evt.offsetY
                            };

                            if (!graphic.attributes || (!graphic.attributes.isCluster && !(graphic.attributes.type === 'sublocation'))) {
                                $rootScope.$broadcast('showContextMenu', {
                                    item: graphic.attributes.point,
                                    mapPosition: mapView.position,
                                    screenPoint: screenPoint,
                                    type: 'Container'
                                });

                            } else if (graphic.attributes && graphic.attributes.type === 'sublocation') {
                                var item = graphic.attributes.point;
                                item.locationNumber = graphic.attributes.id;
                                item.commune = graphic.attributes.commune;
                                item.containerId = graphic.attributes.containerId;
                                item.displayText = graphic.attributes.displayText;
                                item.fraction = graphic.attributes.fraction;
                                item.containerType = graphic.attributes.containerType;
                                item.degLat = graphic.attributes.lat;
                                item.degLong = graphic.attributes.lon;
                                item.fill = graphic.attributes.fill;
                                item.hasPosition = graphic.attributes.hasPosition;
                                item.poiId = graphic.attributes.poiId;
                                item.place = graphic.attributes.place;
                                item.placeNr = graphic.attributes.placeNr;
                                item.time = graphic.attributes.time;
                                item.locationId = graphic.attributes.locationId;
                                item.volt = graphic.attributes.volt;

                                $rootScope.$broadcast('showContextMenu', {
                                    item: item,
                                    mapPosition: mapView.position,
                                    screenPoint: screenPoint,
                                    focusPoint: mapView.toScreen(graphic.attributes.point),
                                    type: 'Container'
                                });
                            } else if (graphic.attributes && graphic.attributes.isCluster) {
                                $rootScope.$broadcast('showContextMenu', {
                                    mapPosition: mapView.position,
                                    screenPoint: screenPoint,
                                    clusterPoints: graphic.attributes.points,
                                    type: 'Cluster'
                                });
                            }
                        }
                    }
                };

                $rootScope.$on("redrawCluster", function () {
                    fillDateLayer.redrawData();
                });

                fillDateLayer.upsertLocationData = function (locations) {
                    fillDateLayer.updateData(locations);
                };

                fillDateLayer.loadData = function () {
                    return $rootScope.appInit().then(() => {
                        return locationsService.getLocations(true).then(function (locations) {
                            var validLocations = _.filter(locations, function (loc) {
                                return loc.latitude !== 0 && loc.longitude !== 0;
                            });

                            const containersToLoad = validLocations.reduce((toReturn, current) => {
                                const coords = mapUtility.projectCoordinates(current.longitude, current.latitude);
                                const containers = current.containers;
                               
                                const containersWithCoordinates = containers
                                        .filter(x => x.dateWhenFull)
                                        .map(x => ({
                                            ...x, 
                                            degLat: coords.Y, 
                                            degLong: coords.X
                                        }));                                

                                return toReturn.concat(containersWithCoordinates);
                            }, []);

                            fillDateLayer._locations = containersToLoad;
                            fillDateLayer.upsertLocationData(containersToLoad);
                        });
                    });
                }

                /**
                 * Filters the displayed locations with the provided deviceExternalId
                 * @param deviceExternalIds The list of deviceExternalIds used to filter the locations by `location.displayText`. 
                 * Leave empty to reset the filtering.
                 */
                fillDateLayer.setLocationFilter = function (deviceExternalIds) {
                    fillDateLayerFactory.initialized().then(function () {
                        if (!deviceExternalIds) {
                            fillDateLayer.upsertLocationData(fillDateLayer._locations);
                            return;
                        }

                        if (deviceExternalIds.length === 0) {
                            fillDateLayer.upsertLocationData([]);
                            return;
                        }
                     
                        var filteredLocations = _.filter(fillDateLayer._locations, function (location) {
                            return _.includes(deviceExternalIds, location.displayText);
                        });
                        fillDateLayer.upsertLocationData(filteredLocations);
                    });
                }

                fillDateLayer.loadData().then((locations) => {
                    $rootScope.$broadcast('FillDateLayerLoaded', {
                        locations
                    });

                    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
                        fillDateLayer.upsertLocationData(fillDateLayer._locations);
                    });

                    initDeferred.resolve();
                });

                return fillDateLayer;
            };

            readyDeferred.resolve(fillDateLayerFactory);
        });

        return fillDateLayerFactory;
    }
})();
