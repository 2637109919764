(function () {
    angular.module('UndergroundWebApp').factory('containerLayerFactory', containerLayerFactory);

    containerLayerFactory.$inject = [
        '$q',
        '$rootScope',
        'mapUtility',
        'esriLoader',
        'containerService'
    ]

    function containerLayerFactory(
        $q,
        $rootScope,
        mapUtility,
        esriLoader,
        containerService
    ) {
        var readyDeferred = null,
            initDeferred = null;

        var containerLayerFactory = {
            createLayerOnAdd: true,

            ready: function () {
                if (readyDeferred === null) {
                    readyDeferred = $q.defer();
                }

                return readyDeferred.promise;
            },

            initialized: function () {
                if (initDeferred === null) {
                    initDeferred = $q.defer();
                }

                return initDeferred.promise;
            }
        };

        esriLoader.require([
            'esri/geometry/Point',
            'mcl/MarkerClusterLayer'
        ], function (Point, MarkerClusterLayer) {
            if (readyDeferred === null) {
                readyDeferred = $q.defer();
            }
            if (initDeferred === null) {
                initDeferred = $q.defer();
            }

            containerLayerFactory.createLayer = function () {
                var containerLayer = new MarkerClusterLayer({});
                containerLayer.name = 'containerLayer';
                containerLayer.zIndex = 15;
                containerLayer.popupVisible = false;

                containerLayer.toggleVisibility = function () {
                    containerLayer.visible = !containerLayer.visible;
                }

                containerLayer.onMouseMove = function (evt, mapView, hitResponse) {
                    const hitResult = hitResponse && hitResponse.results
                        .find(r => r.graphic.layer.name === containerLayer.name);
                    if (
                        !hitResult
                        || hitResult.graphic.attributes.isCluster
                    ) {
                        if (containerLayer.popupVisible) {
                            $rootScope.$broadcast('hideContainerPopup');
                            containerLayer.popupVisible = false;
                            $rootScope.isPopupVisible = false;
                        }
                        return;
                    }

                    if (!$rootScope.isPopupVisible) { // we only draw it if there is no other popup
                        const graphic = hitResult.graphic;
                    
                        var graphicScreenPoint = {
                            x: evt.offsetX,
                            y: evt.offsetY
                        };
    
                        containerLayer.popupVisible = true;
                        $rootScope.isPopupVisible = true;
                        $rootScope.$broadcast('showContainerPopup', {
                            left: graphicScreenPoint.x + mapView.position[0] + 20,
                            top: graphicScreenPoint.y + mapView.position[1] + 10,
                            displayText: graphic.attributes.displayText || '',
                            fraction: graphic.attributes.fraction || '',
                            place: graphic.attributes.place || '',
                            containerType: graphic.attributes.containerType || '',
                            fill: graphic.attributes.fill || '',
                            volt: graphic.attributes.volt || ''
                        });
                    }
                };

                containerLayer.onRightClick = function (evt, mapView, hitResponse) {
                    if (hitResponse) {
                        var graphic = hitResponse.results[hitResponse.results.length - 1].graphic;

                        if (graphic.layer.name === containerLayer.name) {
                            var screenPoint = {
                                x: evt.offsetX,
                                y: evt.offsetY
                            };

                            if (!graphic.attributes || (!graphic.attributes.isCluster && !(graphic.attributes.type === 'sublocation'))) {
                                $rootScope.$broadcast('showContextMenu', {
                                    item: graphic.attributes.point,
                                    mapPosition: mapView.position,
                                    screenPoint: screenPoint,
                                    type: 'Container'
                                });

                            } else if (graphic.attributes && graphic.attributes.type === 'sublocation') {
                                var item = graphic.attributes.point;
                                item.locationNumber = graphic.attributes.id;
                                item.commune = graphic.attributes.commune;
                                item.containerId = graphic.attributes.containerId;
                                item.displayText = graphic.attributes.displayText;
                                item.fraction = graphic.attributes.fraction;
                                item.containerType = graphic.attributes.containerType;
                                item.degLat = graphic.attributes.lat;
                                item.degLong = graphic.attributes.lon;
                                item.fill = graphic.attributes.fill;
                                item.hasPosition = graphic.attributes.hasPosition;
                                item.poiId = graphic.attributes.poiId;
                                item.place = graphic.attributes.place;
                                item.placeNr = graphic.attributes.placeNr;
                                item.time = graphic.attributes.time;
                                item.locationId = graphic.attributes.locationId;
                                item.volt = graphic.attributes.volt;

                                $rootScope.$broadcast('showContextMenu', {
                                    item: item,
                                    mapPosition: mapView.position,
                                    screenPoint: screenPoint,
                                    focusPoint: mapView.toScreen(graphic.attributes.point),
                                    type: 'Container'
                                });
                            } else if (graphic.attributes && graphic.attributes.isCluster) {
                                $rootScope.$broadcast('showContextMenu', {
                                    mapPosition: mapView.position,
                                    screenPoint: screenPoint,
                                    clusterPoints: graphic.attributes.points,
                                    type: 'Cluster'
                                });
                            }
                        }
                    }
                };

                $rootScope.$on("redrawCluster", function () {
                    containerLayer.redrawData();
                });

                containerLayer.upsertLocationData = function (locations) {
                    containerLayer.updateData(locations);
                };

                containerLayer.loadData = function () {
                    return $rootScope.appInit().then(() => {
                        return containerService.getContainersExpress().then(function (locations) {
                            var validLocations = _.filter(locations, function (loc) {
                                return loc.degLat !== 0 && loc.degLong !== 0;
                            });

                            if(!$rootScope.isAdmin() && !$rootScope.isSuperAdmin()) {
                                validLocations = validLocations.filter(l => $rootScope.getMunicipalityAccess(l.commune));
                            }

                            var locationsToLoad = [];


                            for (var i = 0; i < validLocations.length; ++i) {
                                let coords = mapUtility.projectCoordinates(validLocations[i].degLong, validLocations[i].degLat);

                                locationsToLoad.push({
                                    ...validLocations[i],
                                    degLat: coords.Y,
                                    degLong: coords.X,                                    
                                });
                            }

                            containerLayer._locations = locationsToLoad;
                            containerLayer.upsertLocationData(locationsToLoad);
                        });
                    });
                }

                /**
                 * Filters the displayed locations with the provided deviceExternalId
                 * @param deviceExternalIds The list of deviceExternalIds used to filter the locations by `location.displayText`. 
                 * Leave empty to reset the filtering.
                 */
                containerLayer.setLocationFilter = function (deviceExternalIds) {
                    containerLayerFactory.initialized().then(function () {
                        if (!deviceExternalIds) {
                            containerLayer.upsertLocationData(containerLayer._locations);
                            return;
                        }

                        if (deviceExternalIds.length === 0) {
                            containerLayer.upsertLocationData([]);
                            return;
                        }

                        var filteredLocations = _.filter(containerLayer._locations, function (location) {
                            return _.includes(deviceExternalIds, location.displayText);
                        });
                        containerLayer.upsertLocationData(filteredLocations);
                    });
                }



                containerLayer.loadData().then((locations) => {
                    $rootScope.$broadcast('ContainerLayerLoaded', {
                        locations
                    });

                    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
                        containerLayer.upsertLocationData(containerLayer._locations);
                    });

                    initDeferred.resolve();
                });

                return containerLayer;
            };

            readyDeferred.resolve(containerLayerFactory);
        });

        return containerLayerFactory;
    }
})();
