(function () {
    "use strict";

    angular.module('UndergroundWebApp').factory('deviationReportService', deviationReportService);

    deviationReportService.$inject = ['$http'];

    function deviationReportService($http) {
        return {
            getDeviationReports,
            getDeviations,
            getDeviationReportQueries,
            addDeviationReport,
            updateDeviationReport,
            deleteDeviationReport
        };

        function getDeviations() {
            return $http.get("api/deviations")
                .then(result => result.data);
        }

        function getDeviationReportQueries() {
            return $http.get("api/deviation-reports/queries")
                .then(result => result.data);
        }

        function getDeviationReports() {
            return $http.get("api/deviation-reports")
                .then(result => result.data);
        }

        function addDeviationReport(deviationReport) {
            return $http.post("api/deviation-reports", deviationReport)
                .then(result => result.data);
        }

        function updateDeviationReport(deviationReport) {
            return $http.put("api/deviation-reports", deviationReport)
                .then(result => result.data);
        }

        function deleteDeviationReport(deviationReportId) {
            return $http.delete("api/deviation-reports/" + deviationReportId)
                .then(result => result.data);
        }
    }
})();
