(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('LocationDetailsController', LocationDetailsController);

    LocationDetailsController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        'locationsService',
        'containerLayerFactory',
        'mapService',
        'mapUtility',
        'serviceUrls',
        'formTypes',
        '$kWindow',
        'tabstripUtility',
        '$translate'
    ];

    function LocationDetailsController(
        $rootScope,
        $scope,
        $state,
        $stateParams,
        locationsService,
        containerLayerFactory,
        mapService,
        mapUtility,
        serviceUrls,
        formTypes,
        $kWindow,
        tabstripUtility,
        $translate
    ) {
        var locationId = $stateParams.locationId;

        $scope.openUpdateLocation = openUpdateLocation;
        $scope.openNewContainerModal = openNewContainerModal;
        $scope.location = null;
        $scope.locationsFilter = $stateParams.locationsFilter;
        $scope.isLocationDeletable = isLocationDeletable;
        $scope.deleteLocation = deleteLocation;

        $scope.isHeaderMenuView = false;

        var tabStripData = [];

        $scope.tabStripOptions = {
            animation: false,
            select: tabstripUtility.handleTabSelected,
            dataTextField: 'name',
            dataContentUrlField: 'contentUrl',
            dataSource: {
                transport: {
                    read: readTabStripOptions
                }
            },
        };

        initController();

        function initController() {
            addModuleDependantTabs();

            $scope.$watch(() => $state.current.name, (state) => {
                tabstripUtility.changeTabByState($scope.locationDetailsTab, state);
            });
            loadLocation();
        };

        //add module-dependant tabs to tabstrip
        function addModuleDependantTabs() {
            if($rootScope.isVisible('ACCESSITEMS',{})){
                tabStripData.push({
                    name: $translate.instant('LOCATION_DETAILS_CONTROLLER_HEADER_ACCESS_CONTROL'),
                    contentUrl: 'app/location/accesscontrol/views/location-access-items-view.html',
                    state: 'main.locationDetails.accessItems'
                });
        
                var tabStripElement = $("#locationDetailsTab");
                if (tabStripElement.data("kendoTabStrip")) {
                    var tabStrip = tabStripElement.data("kendoTabStrip");
                    tabStrip.dataSource.read();
                } else {
                    tabStripElement.kendoTabStrip($scope.tabStripOptions);
                }
            }
        }

        function setExternalLink() {
            // Reen externalId is at least 5 characters long while S2W group id is below 10000
            if ($scope.location.externalId && $scope.location.externalId.length > 4) {
                $scope.externalLink = serviceUrls.reenHubLocation + $scope.location.externalId;
            }
        }

        function loadLocation() {
            locationsService.getLocation(locationId).then(function (location) {
                $scope.location = location;
                zoomToLocation(location.latitude, location.longitude, 16);
                setExternalLink();
            });
        }

        function zoomToLocation(latitude, longitude, zoomLevel) {
            if (latitude && longitude) {
                containerLayerFactory.initialized().then(function () {
                    
                    var point = mapUtility.projectCoordinates(longitude, latitude)

                    mapService.zoomToLocation({
                        x: point.X,
                        y: point.Y
                    }, zoomLevel);
                    $rootScope.$broadcast("redrawCluster");
                }, function () {
                });
            }
        }

        function openUpdateLocation(location) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: true,
                    title: $translate.instant('LOCATION_LOCATION'),
                    resizable: true,
                    height: 680,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/location/location-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'LocationDetailsModalController',
                resolve: {
                    currentFormType: () => formTypes.edit,
                    location: () => location
                }
            });

            windowInstance.result.then(function (location) {
                let currentContainers = $scope.location.containers;

                if (location) {
                    $scope.location = location;
                    $scope.location.containers = currentContainers;
                }
            });
        }

        function openNewContainerModal() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('G_Container'),
                    resizable: false,
                    height: 600,
                    width: 980,
                    visible: false
                },
                templateUrl: 'app/containerOverviewV2/views/sensor-api-container-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'SensorApiContainerDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.add;
                    },
                    containerId: function () {
                        return null;
                    },
                    updateCallbacks: function () {
                        return null;
                    },
                    locationId: function () {
                        return $scope.location && $scope.location.id;
                    }
                }
            });

            windowInstance.result.then(function () {
                $rootScope.$broadcast('refreshLocationContainers');
            });
        }

        function isLocationDeletable() {
            return $scope.location
                && $scope.location.containers
                && $scope.location.containers.length === 0
                && $scope.location.externalWMPlaceNr === null;
        }

        function readTabStripOptions(e){
            tabStripData = [
                {
                    name: $translate.instant('LOCATION_DETAILS_CONTROLLER_HEADER_INFO'),
                    contentUrl: 'app/location/info/location-info-tab-view.html',
                    state: 'main.locationDetails.info',
                },
                {
                    name: $translate.instant('LOCATION_DETAILS_CONTROLLER_HEADER_STATISTICS'),
                    contentUrl: 'app/location/statistics/location-statistics-tab-view.html',
                    state: 'main.locationDetails.statistics',
                },
                {
                    name: $translate.instant('LOCATION_DETAILS_CONTROLLER_HEADER_LOG'),
                    contentUrl: 'app/location/log/location-log-tab-view.html',
                    state: 'main.locationDetails.log',
                },
                {
                    name: $translate.instant('LOCATION_DETAILS_CONTROLLER_HEADER_ACCESS_CONTROL'),
                    contentUrl: 'app/location/accesscontrol/views/location-access-items-view.html',
                    state: 'main.locationDetails.powerbi',
                },
            ];

            e.success(tabStripData);
        }

        function deleteLocation() {
            if (
                !isLocationDeletable()
                || !confirm($translate.instant('LOCATION_DETAILS_CONTROLLER_CONFIRM_DELETE'))
            ) {
                return;
            }

            locationsService.deleteLocation($scope.location.id).then(() =>
                $state.go('main.locations', { filter: $scope.locationsFilter })
            );
        }
        
        $rootScope.$on('panelsResized', function () {
            var elements = document.getElementById('location-info-view');
            if (elements) {
                if (elements.offsetWidth < 815) {
                    elements.classList.add('menu-view-active');
                } else {
                    elements.classList.remove('menu-view-active');
                }
            }
        });
    }
})();
