(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('fractionsService', fractionsService);

    fractionsService.$inject = ['$q', '$http'];

    function fractionsService($q, $http) {
        var service = {
            addFraction: addFraction,
            updateFraction: updateFraction,
            getFractions: getFractions,
            getActiveFractions: getActiveFractions,
            getStandardFractions: getStandardFractions
        };

        return service;

        function addFraction(fraction) {
            var deferred = $q.defer();

            $http.post("api/fractions", fraction).then(
                function (result) {
                    if (result && result.data) {
                        deferred.resolve(result.data);
                    } else {
                        deferred.reject();
                    }
                },
                function () {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function updateFraction(fraction) {
            var deferred = $q.defer();

            $http.put("api/fractions", fraction).then(
                function (result) {
                    if (result && result.data) {
                        deferred.resolve(result.data);
                    } else {
                        deferred.reject();
                    }
                },
                function () {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function getFractions() {
            var deferred = $q.defer();

            $http.get("api/fractions").then(
                function (result) {
                    if (result && result.data) {
                        deferred.resolve(result.data);
                    } else {
                        deferred.reject();
                    }
                },
                function (result) {
                    deferred.reject(result.data);
                }
            );
            return deferred.promise;
        }

        function getActiveFractions() {
            var deferred = $q.defer();

            $http.get("api/fractions").then(
                function (result) {
                    if (result && result.data) {
                        deferred.resolve(result.data.filter(f => f.status != 99));
                    } else {
                        deferred.reject();
                    }
                },
                function (result) {
                    deferred.reject(result.data);
                }
            );
            return deferred.promise;
        }

        function getStandardFractions() {
            var deferred = $q.defer();

            $http.get("api/standardfractions").then(
                function (result) {
                    if (result && result.data) {
                        deferred.resolve(result.data.filter(f => f.status != 99));
                    } else {
                        deferred.reject();
                    }
                },
                function (result) {
                    deferred.reject(result.data);
                }
            );
            return deferred.promise;
        }
    }
})();
