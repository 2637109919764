(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('containerTypesService', containerTypesService);

    containerTypesService.$inject = ['$q', '$http'];

    function containerTypesService($q, $http) {
        var service = {
            addContainerType: addContainerType,
            updateContainerType: updateContainerType,
            getContainerTypes: getContainerTypes
        };

        return service;

        function addContainerType(containerType) {
            var deferred = $q.defer();

            $http.post("api/containerType", containerType).then(
                function (result) {
                    if (result && result.data) {
                        deferred.resolve(result.data);
                    } else {
                        deferred.reject();
                    }
                },
                function () {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function updateContainerType(containerType) {
            var deferred = $q.defer();

            $http.put("api/containerType", containerType).then(
                function (result) {
                    if (result && result.data) {
                        deferred.resolve(result.data);
                    } else {
                        deferred.reject();
                    }
                },
                function () {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function getContainerTypes() {
            var deferred = $q.defer();

            $http.get("api/containerType").then(
                function (result) {
                    if (result && result.data) {
                        deferred.resolve(result.data);
                    } else {
                        deferred.reject();
                    }
                },
                function (result) {
                    deferred.reject(result.data);
                }
            );
            return deferred.promise;
        }
    }
})();

