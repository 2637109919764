(function(){
    'use strict';

    angular.module('UndergroundWebApp').factory('encapsuledHttpService',encapsuledHttpService)

    encapsuledHttpService.$inject =['$http'];

    function encapsuledHttpService($http){
        const service = {
            getRequest: getRequest,
            postRequest: postRequest,
            putRequest: putRequest,
            deleteRequest: deleteRequest,
        };

        function getRequest(url, deferred){
            const request = $http.get(url);
            invokeHttpRequest(request, deferred);
        }

        function postRequest(url, payload, deferred){
            const request = $http.post(url, payload);
            invokeHttpRequest(request, deferred);
        }

        function putRequest(url, payload, deferred){
            const request = $http.put(url, payload);
            invokeHttpRequest(request, deferred);
        }

        function deleteRequest(url, deferred){
            const request = $http.delete(url);
            invokeHttpRequest(request, deferred);
        }

        function invokeHttpRequest(httpRequest, deferred){
            httpRequest.then((result) => {
                if (result?.data) {
                    deferred.resolve(result.data);
                } else {
                    deferred.resolve();
                }
                }).catch((err) => {
                    deferred.reject(err.data);
                });
        }

        return service;
    }
})();
