angular.module('UndergroundWebApp').directive('radialMenu', ['$q', '$state', '$rootScope', 'mapService', 'esriLoader', 'mapUtility',
        function ($q, $state, $rootScope, mapService, esriLoader, mapUtility) {
    'use strict';

    var startWidth = 24,
        startHeight = 24,
        maxWidth = 265,
        maxHeight = 265,
        startLeft = 0,
        startTop = 0,
        animationDuration = 300,
        iconsFadeDuration = 200;

    var isOpen = false,
        lastScreenPoint = null,
        lastScreenPointType = null,
        clusterPoints = null,
        item = null;
    return {
        restrict: 'AE',
        replace: true,
        scope: {
            'options': '=',
           
        },
        link: function (scope, element, attrs) {
            scope.$on('showContextMenu', function (event, options) {
                item = options.item;
                lastScreenPointType = options.type;
                clusterPoints = options.clusterPoints ? options.clusterPoints : null;
                scope.options.open(options.screenPoint, options.mapPosition, options.type, options.focusPoint);
                scope.containerId = $rootScope.selectedContainerId;
            });

            scope.$on('hideContextMenu', function () {
                item = null;
                lastScreenPointType = null;
                clusterPoints = null;
                scope.options.close();
            });

            esriLoader.require(["esri/geometry/support/webMercatorUtils", "esri/geometry/SpatialReference", "esri/geometry/Point", "esri/geometry/Extent",], function (webMercatorUtils, SpatialReference, Point, Extent) {
                scope.zoom = function () {
                    if (lastScreenPoint) {

                        switch (lastScreenPointType) {
                            case 'Container':
                                $state.go('main.locationDetails.info', {
                                    locationId: item.locationId,
                                    startTab: 'Info'
                                });
                                break;
                            case 'Location':
                                $state.go('main.locationDetails.info', {
                                    locationId: item.id,
                                    startTab: 'Info'
                                });
                                break;
                            case 'Map':
                                mapService.zoomTo(lastScreenPoint);
                                break;
                            case 'Cluster':
                                const extent = scope.getExtentFromClusterPoints(clusterPoints);
                                mapService.zoomToExtent(extent);
                                break;
                            case 'Position':
                                mapService.zoomTo(lastScreenPoint);
                                break;
                        }

                        scope.options.setOpen(false);
                    }
                };
                scope.setPosition = function (){
                    var a = $rootScope.selectedContainerId;
                };
                scope.setLocationPosition = function () {
                    var mapPoint = mapService.getMapPoint(lastScreenPoint);
                    
                    if (mapUtility.getCurrentProjection() == "WGS84"){
                        if (mapPoint && mapPoint.latitude && mapPoint.longitude) {
                            $rootScope.$broadcast('setLocationPosition',
                            {
                                position: {lat: mapPoint.latitude, lng: mapPoint.longitude}
                            });
                        }
                    }else{
                        if (mapPoint && mapPoint.x && mapPoint.y) {
                            $rootScope.$broadcast('setLocationPosition',
                            {
                                position: mapUtility.convertToWGS84(mapPoint.x, mapPoint.y)
                            });
                        }
                    }
                   
                    scope.options.setOpen(false);
                };
                scope.getExtentFromClusterPoints = function (points) {
                    let ymin = Math.min(...points.map(({degLat}) => degLat));
                    let ymax = Math.max(...points.map(({degLat}) => degLat));
                    let xmin = Math.min(...points.map(({degLong}) => degLong));
                    let xmax = Math.max(...points.map(({degLong}) => degLong));

                    let currentProjection = mapUtility.getCurrentProjection(),
                    minFactor = 0.9999,
                    maxFactor = 1.0001;

                    if (currentProjection == "WGS84" && (Math.abs(ymax - ymin) > 10 || Math.abs(xmax - xmin) > 10)) {
                        minFactor = 0.99;
                        maxFactor = 1.01;
                    }
                    if (currentProjection == "UTM33N" && (Math.abs(ymax - ymin) > 1000000 || Math.abs(xmax - xmin) > 100000)) {
                        minFactor = 0.9;
                        maxFactor = 1.1;
                    }

                    xmin = xmin * minFactor;
                    ymin = ymin * minFactor;
                    xmax = xmax * maxFactor;
                    ymax = ymax * maxFactor;
                                    
                    let fullExt = new Extent(xmin, ymin, xmax, ymax, new SpatialReference(mapUtility.getCurrentWkid()));
                    if (currentProjection == "WGS84") {
                        fullExt = webMercatorUtils.geographicToWebMercator(fullExt);
                    }
    
                    return fullExt;
                };
            });

            scope.options.setOpen = function (newValue, screenPoint, mapPosition, contextType, focusPoint) {
                var deferred = $q.defer();

                try {
                    if (isOpen !== newValue) {
                        if (newValue) {
                            var type = contextType || 'Map';

                            if (focusPoint) {
                                lastScreenPoint = focusPoint;
                            } else {
                                lastScreenPoint = screenPoint;
                            }

                            $(element).find('.menu-item-container').hide();

                            if (type === 'Map') {
                                $(element).find('.map-menu').show();
                            } else if (type === 'Location') {
                                $(element).find('.location-menu').show();
                            } else if (type === 'Container') {
                                $(element).find('.vessel-menu').show();
                            } else if (type === 'Cluster') {
                                $(element).find('.cluster-menu').show();
                            } else if (type === 'Position') {
                                $(element).find('.position-menu').show();
                            }

                            element.width(startWidth);
                            element.height(startHeight);

                            startLeft = screenPoint.x - startWidth / 2;
                            startTop = screenPoint.y + mapPosition[1] - startWidth / 2;
                            element.css('top', startTop + 'px');
                            element.css('left', startLeft + 'px');

                            //Calculate end left and top
                            var endLeft = screenPoint.x - maxHeight / 2;
                            var endTop = screenPoint.y + mapPosition[1] - maxWidth / 2;

                            element.show();

                            element.animate({ width: maxWidth }, { queue: false, duration: animationDuration });
                            element.animate({ height: maxHeight }, { queue: false, duration: animationDuration });
                            element.animate({ left: endLeft }, { queue: false, duration: animationDuration });
                            element.animate({ top: endTop }, {
                                queue: false, duration: animationDuration, complete: function () {
                                    $('.inner-circle .row').fadeIn(iconsFadeDuration);
                                    isOpen = newValue;
                                    deferred.resolve();
                                }
                            });
                        } else {
                            $('.inner-circle .row').fadeOut(iconsFadeDuration, function () {
                                element.animate({ width: startWidth }, { queue: false, duration: animationDuration });
                                element.animate({ height: startHeight }, { queue: false, duration: animationDuration });
                                element.animate({ left: startLeft }, { queue: false, duration: animationDuration });
                                element.animate({ top: startTop }, {
                                    queue: false, duration: animationDuration, complete: function () {
                                        isOpen = newValue;
                                        element.hide();
                                        deferred.resolve();
                                    }
                                });
                            });
                        }
                    }
                } catch (error) {
                    deferred.reject();
                }

                return deferred.promise;
            };

            scope.options.open = function (screenPoint, mapPosition, contextType, focusPoint) {
                var deferred = $q.defer();

                if (isOpen) {
                    scope.options.setOpen(false).then(function () {
                        isOpen = false;
                        scope.options.setOpen(true, screenPoint, mapPosition, contextType, focusPoint).then(deferred.resolve);
                    });
                } else {
                    scope.options.setOpen(true, screenPoint, mapPosition, contextType, focusPoint).then(deferred.resolve);
                }

                return deferred.promise;
            };

            scope.options.close = function () {
                scope.options.setOpen(false);
            };

            scope.locationSetPositionShown = function() {
                return $state.includes('main.locationDetails')
                    && $rootScope.isVisible('CORE', { Area: 'Location', AccessType: 'W' });
            };
        },
        templateUrl: 'app/shared/directives/radial-menu.html'
    }
}]);
