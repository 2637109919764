(function () {
    'use strict';

    var undergroundWebApp = angular.module('UndergroundWebApp');

    undergroundWebApp.constant('formTypes', {
        add: 'add',
        edit: 'edit',
        editMultiple: 'editMultiple',
        replace: 'replace',
        update: 'update',
        extra: 'extra',
    });

    undergroundWebApp.constant('externalSystems', {
        shop2win: 'shop2win',
        reen: 'reen',
        nrc: 'nrc',
        nrc_monitor: 'nrc monitor',
        mr_fill: 'mr:fill'
    });

    undergroundWebApp.provider('containerTypeUnits', function() {
        this.$get = ['$translate', function($translate) {
            return [
                { Id: 0, Name: 'Liter', LocalizedName: $translate.instant('CONTSANT_CONTAINER_TYPE_UNITS_LITER') },
                { Id: 1, Name: 'm3', LocalizedName: $translate.instant('CONTSANT_CONTAINER_TYPE_UNITS_M3') }
            ];
        }];
    });
    
    undergroundWebApp.provider('deviceTypes', function() {
        this.$get = ['$translate', function($translate) {
            return [
                { id: 0, name: 'Access', localizedName: $translate.instant('CONTSANT_DEVICE_TYPES_ACCESS_CONTROL') },
                { id: 1, name: 'Fill', localizedName: $translate.instant('CONTSANT_DEVICE_TYPES_LEVEL_MEASUREMENT') },
                { id: 2, name: 'AccessAndFill', localizedName: $translate.instant('CONTSANT_DEVICE_TYPES_ACCESS_CONTROL_AND_LEVEL_MEASUREMENT')  },
                { id: 3, name: 'Tracking', localizedName: $translate.instant('CONTSANT_DEVICE_TYPES_TRACKING')  },
                { id: 4, name: 'TrackingAndAccess', localizedName: $translate.instant('CONTSANT_DEVICE_TYPES_TRACKING_AND_ACCESS_CONTROL')  },
                { id: 5, name: 'TrackingAndFill', localizedName: $translate.instant('CONTSANT_DEVICE_TYPES_TRACKING_AND_LEVEL_MEASUREMENT')  },
                { id: 6, name: 'TrackingAndAccessAndFill', localizedName: $translate.instant('CONTSANT_DEVICE_TYPES_TRACKING_AND_ACCESS_CONTROL_AND_LEVEL_MEASUREMENT')  }                
            ];
        }];
    });  

    undergroundWebApp.provider('statusOptions', function() {
        this.$get = ['$translate', function($translate) {
            return {
                     active: { Id: 0, Name: 'Active', LocalizedName: $translate.instant('CONTSANT_STATUS_ACTIVE') },
                     deleted: { Id: 99, Name: 'Deleted', LocalizedName: $translate.instant('CONTSANT_STATUS_DELETED') }
                 };
        }];
    });

    undergroundWebApp.provider('deviationStatuses', function() {
        this.$get = ['$translate', function($translate) {
            return {
                     active: { Id: 0, Name: 'Active', LocalizedName: $translate.instant('CONTSANT_STATUS_ACTIVE') },
                     inactive: { Id: 1, Name: 'Inactive', LocalizedName: $translate.instant('CONTSANT_STATUS_INACTIVE') },
                     invisible: { Id: 2, Name: 'Invisible', LocalizedName: $translate.instant('CONTSANT_STATUS_INVISIBLE') },
                     deleted: { Id: 99, Name: 'Deleted', LocalizedName: $translate.instant('CONTSANT_STATUS_DELETED') }
                 };
        }];
    });

    undergroundWebApp.provider('deviationAlertRecurrenceTypes', function() {
        this.$get = ['$translate', function($translate) {
            return {
                0: { id: 0, Name: 'Continuous run', LocalizedName: $translate.instant('ADMINISTRATION_DEVIATION_RECURRENCE_TYPE_CONTINUS_RUN') },
                1: { id: 1, Name: 'Scheduled alert', LocalizedName: $translate.instant('ADMINISTRATION_DEVIATION_RECURRENCE_TYPE_SCHEDULED_ALERT') }
            };
        }];
    });

    undergroundWebApp.provider('daysOfTheWeek', function() {
        this.$get = ['$translate', function($translate) {
            return [
                $translate.instant('ADMINISTRATION_DEVIATION_DAYS_OF_WEEK_MONDAY'),
                $translate.instant('ADMINISTRATION_DEVIATION_DAYS_OF_WEEK_TUESDAY'),
                $translate.instant('ADMINISTRATION_DEVIATION_DAYS_OF_WEEK_WEDNESDAY'),
                $translate.instant('ADMINISTRATION_DEVIATION_DAYS_OF_WEEK_THURSDAY'),
                $translate.instant('ADMINISTRATION_DEVIATION_DAYS_OF_WEEK_FRIDAY'),
                $translate.instant('ADMINISTRATION_DEVIATION_DAYS_OF_WEEK_SATURDAY'),
                $translate.instant('ADMINISTRATION_DEVIATION_DAYS_OF_WEEK_SUNDAY')
            ];
        }];
    });

    undergroundWebApp.provider('statuses', function () {
        this.$get = ['$translate', function ($translate) {
            return [
                { Id: 0, Name: 'Active', LocalizedName: $translate.instant('CONTSANT_STATUS_ACTIVE') },
                { Id: 1, Name: 'Inactive', LocalizedName: $translate.instant('CONTSANT_STATUS_INACTIVE') },
                { Id: 99, Name: 'Deleted', LocalizedName: $translate.instant('CONTSANT_STATUS_DELETED') }
            ];
        }];
    });

    undergroundWebApp.provider('statusesWithoutDelete', function () {
        this.$get = ['$translate', function ($translate) {
            return [
                { Id: 0, Name: 'Active', LocalizedName: $translate.instant('CONTSANT_STATUS_ACTIVE') },
                { Id: 1, Name: 'Inactive', LocalizedName: $translate.instant('CONTSANT_STATUS_INACTIVE') }
            ];
        }];
    });

    undergroundWebApp.provider('fuelTypes', function() {
        this.$get = ['$translate', function($translate) {
            return [
                { id: 0, name: $translate.instant('CONTSANT_GAS') },
                { id: 1, name: $translate.instant('CONTSANT_DIESEL') },
                { id: 2, name: $translate.instant('CONTSANT_HYBRID')  },
                { id: 3, name: $translate.instant('CONTSANT_ELECTRIC')  },
            ];
        }];
    });

    undergroundWebApp.constant('mapTypes', {
        WGS84: 'WGS84',
        UTM33N: 'UTM33N'
    });

    undergroundWebApp.constant('defaultSensorHeight', 2500);

    undergroundWebApp.constant('reportfrequenceList', [
        { text: ' ', id: null },
        { text: '4', id: 4 },
        { text: '8', id: 8 },
        { text: '12', id: 12 },
        { text: '24', id: 24 }
    ]);

    undergroundWebApp.constant('actuatortypeList', [
        { text: ' ', id: null },
        { text: 'SOLENOID', id: 'solenoid' },
        { text: 'LATCH SOLENOID', id: 'latch_solenoid' },
        { text: 'EMZ VARIANT1', id: 'emz_variant1' },
        { text: 'EMZ VARIANT2', id: 'emz_variant2' }
    ]);

    const viewModes = {
        map: 'map',
        split: 'split',
        full: 'full'
    };

    undergroundWebApp.constant('viewModes', viewModes);

    const splitViewMode = { viewMode: viewModes.split },
        fullViewMode = { viewMode: viewModes.full };

    undergroundWebApp.constant('pageSpecificDisplays', {
        'main': { viewMode: viewModes.map, isPriority: true },
        'main.accessItems': fullViewMode,
        'main.administration': fullViewMode,
        'main.area': splitViewMode,
        'main.areaDetails': splitViewMode,
        'main.containerOverview': splitViewMode,
        'main.containerOverviewV2': fullViewMode,
        'main.locations': splitViewMode,
        'main.locationDetails': splitViewMode,
        'main.locationDetails.info': splitViewMode,
        'main.reports': splitViewMode,
        'main.reports.info': splitViewMode,
        'main.sensorOverview': splitViewMode,
    });

    undergroundWebApp.constant('globalEvents', {
        accessTokenExpired: 'accessTokenExpired',
        layerVisibilityChanged: 'layerVisibilityChanged',
        overviewContainersChanged: 'overviewContainersChanged',
        redrawCluster: 'redrawCluster',
        refreshLocationContainers: 'refreshLocationContainers',
        zoomToLocation: 'zoomToLocation',
        $stateChangeStart: '$stateChangeStart',
        $stateChangeSuccess: '$stateChangeSuccess',
    });
})();
