(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('MunicipalitiesController', MunicipalitiesController);

    MunicipalitiesController.$inject = [
        '$scope',
        'municipalityService',
        '$kWindow',
        'formTypes',
        'filterStateService',
        '$translate'
    ];

    function MunicipalitiesController(
        $scope,
        municipalityService,
        $kWindow,
        formTypes,
        filterStateService,
        $translate
    ) {
        $scope.municipalityDetailsModalHeight = 220;

        $scope.municipalities = [];
        $scope.openNewMunicipality = openNewMunicipality;
        $scope.openUpdateMunicipality = openUpdateMunicipality;

        $scope.municipalityOverviewGridOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readMunicipalities
                },
                pageSize: 70,
                sort: filterStateService.getSortFromQueryString(),
                filter: filterStateService.getFilterFromQueryString(),
            }),
            columns: [
                { field: 'name', title: $translate.instant('G_NAME') },
                { field: 'code', title: $translate.instant('MUNICIPALITY_MUNICIPALITY_CODE') },
                {
                    field: 'edit',
                    filterable: false,
                    sortable: false,
                    groupable: false,
                    title: ' ',
                    width: '50px',
                    template: '<i class="fa fa-1-5x fa-pencil action-hover" ng-click="openUpdateMunicipality(dataItem)" aria-hidden="true">'
                }
            ],
            scrollable: {
                virtual: true
            },
            sortable: true,
            filterable: true,
            resizable: true,
            noRecords: true,
            groupable: {
                messages: {
                  empty: $translate.instant('G_DROP_COLUMNS_HERE')
                }
            },
            selectable: false,
            sort: filterStateService.storeGridSortInQueryString,
            filter: filterStateService.storeGridFilterInQueryString,
        };

        function readMunicipalities(e) {
            $scope.showBusyIndicator();

            municipalityService.getMunicipalities().then(municipalities => {
                $scope.municipalities = municipalities;

                e.success($scope.municipalities);
                $scope.hideBusyIndicator();
            }).catch(() => {
                e.success([])
            }).finally($scope.hideBusyIndicator);
        }

        function openNewMunicipality() {
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('MUNICIPALITY_NEW_MUNICIPALITY'),
                    resizable: false,
                    height: $scope.municipalityDetailsModalHeight,
                    width: 360,
                    visible: false
                },
                templateUrl: 'app/administration/municipality/municipality-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'MunicipalityDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.add;
                    },
                    municipality: function () {
                        return null;
                    },
                    allMunicipalities: function () {
                        return $scope.municipalities;
                    }
                }
            });
        }

        function openUpdateMunicipality(dataItem) {
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('MUNICIPALITY_MODAL_HEADER'),
                    resizable: false,
                    height: $scope.municipalityDetailsModalHeight,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/administration/municipality/municipality-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'MunicipalityDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.edit;
                    },
                    municipality: function () {
                        return dataItem;
                    },
                    allMunicipalities: function () {
                        return $scope.municipalities;
                    }
                }
            });
        }
    }
})();
