(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('VehiclesController', VehiclesController);

    VehiclesController.$inject = [
        '$scope',
        '$kWindow',
        'vehicleService',
        'formTypes',
        'fuelTypes',
        'filterStateService',
        'kendoGridUtility',
        'mediaUtility',
        '$translate'
    ];

    function VehiclesController(
        $scope,
        $kWindow,
        vehicleService,
        formTypes,
        fuelTypes,
        filterStateService,
        kendoGridUtility,
        mediaUtility,
        $translate,
    ) {
        $scope.sortedVehicles = [];
        $scope.openNewVehicle = openNewVehicle;
        $scope.deleteVehicle = deleteVehicle;
        $scope.openUpdateVehicle = openUpdateVehicle;
        $scope.openMediaModal = openMediaModal,

        $scope.vehicleOverviewGridOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readVehicles
                },
                pageSize: 70,
                sort: filterStateService.getSortFromQueryString({
                    field: 'name',
                    dir: 'asc'
                }),
                filter: filterStateService.getFilterFromQueryString(),
            }),
            columns: [
                { 
                    field: 'name', 
                    title: $translate.instant('G_NAME'),
                    width: 140 
                },
                { 
                    field: 'externalId', 
                    title: $translate.instant('ADMINISTRATION_VEHICLE_TABLE_HEADER_EXTERNAL_ID'),
                    width: 100 
                },
                { 
                    field: 'brand', 
                    title: $translate.instant('ADMINISTRATION_VEHICLE_TABLE_HEADER_BRAND'),
                    width: 100 
                },
                { 
                    field: 'maxLoadTons', 
                    title: $translate.instant('ADMINISTRATION_VEHICLE_TABLE_HEADER_MAX_LOAD_TONS'),
                    width: 146 
                },
                { 
                    field: 'compressionRate', 
                    title: $translate.instant('ADMINISTRATION_VEHICLE_TABLE_HEADER_COMPRESSION_RATE'),
                    width: 120 
                },
                { 
                    field: 'fuelType', 
                    title: $translate.instant('ADMINISTRATION_VEHICLE_TABLE_HEADER_FUEL_TYPE'),
                    template: getFuelType, 
                    width: 120 
                },
                { 
                    field: 
                    'hasWeight', 
                    title: $translate.instant('ADMINISTRATION_VEHICLE_TABLE_HEADER_HAS_WEIGHT'),
                    template: kendoGridUtility.checkBoxColumnTemplate('hasWeight'), 
                    width: 100 
                },
                {
                    field: 'edit',
                    filterable: false,
                    sortable: false,
                    groupable: false,
                    title: ' ',
                    width: 120,
                    template: `<i class="fa fa-1-5x fa-image fa-fw" ng-click="openMediaModal(dataItem)" aria-hidden="true" ng-show="dataItem.imageId"></i>` +
                        `<i class="fa fa-1-5x fa-pencil fa-fw" ng-click="openUpdateVehicle(dataItem)" aria-hidden="true" ng-show="isVisible('CORE', { Area: 'Vehicle', AccessType: 'W' })"></i>` +
                        `<i class="fa fa-1-5x fa-trash fa-fw" ng-click="deleteVehicle(dataItem)" aria-hidden="true" ng-show="isVisible('CORE', { Area: 'Vehicle', AccessType: 'W' })"></i>`
                }
            ],
            scrollable: {
                virtual: true
            },
            resizable: true,
            sortable: true,
            filterable: true, noRecords: {
                template: $translate.instant('ADMINISTRATION_VEHICLE_NO_VEHICLE'),
            },
            selectable: false,
            sort: filterStateService.storeGridSortInQueryString,
            filter: filterStateService.storeGridFilterInQueryString
        };

        function getFuelType(vehicle) {
            const fuelType = fuelTypes.find(f => f.id === vehicle.fuelType);
            return fuelType && fuelType.name;
        }

        function readVehicles(e) {
            $scope.showBusyIndicator();
            vehicleService.getVehicles().then((vehicles) => {
                e.success(vehicles);
            }).finally(() => {
                $scope.hideBusyIndicator();
            });
        }

        function openNewVehicle() {
            openVehicleModal($translate.instant('ADMINISTRATION_VEHICLE_NEW_VEHICLE'), formTypes.add)
                .result.then((result) => {
                    if (result) {
                        $scope.vehicleOverviewGrid.dataSource.read();
                    }
                });
        }

        function openUpdateVehicle(dataItem) {
            openVehicleModal($translate.instant('ADMINISTRATION_VEHICLE_VEHICLE'), formTypes.edit, dataItem)
                .result.then((result) => {
                    if (result) {
                        $scope.vehicleOverviewGrid.dataSource.read();
                    }
                });
        }

        function deleteVehicle(dataItem) {
            let confirmText = $translate.instant('ADMINISTRATION_VEHICLE_DIALOG_CONFIRM_DELETE');

            if (confirm(`${confirmText} ${dataItem.name}?`)) {
                vehicleService.deleteVehicle(dataItem.id).then(() => {
                    $scope.vehicleOverviewGrid.dataSource.read();
                });
            }
        }

        function openVehicleModal(title, formType, vehicle) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: title,
                    resizable: false,
                    height: 540,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/administration/vehicle/vehicle-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'VehicleDetailsModalController',
                resolve: {
                    currentFormType: () => formType,
                    vehicle: () => vehicle
                }
            });
        }

        function openMediaModal(vehicle) {
            const pictureUrl = mediaUtility.getMediaUrl(vehicle.imageId);
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('G_IMAGE'),
                    resizable: false,
                    width: "30%",
                    position: {
                        top: "5%",
                        left: "35%"
                    },
                    visible: false
                },
                templateUrl: 'app/shared/pictureCarouselModal/picture-carousel.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'PictureCarouselController',
                resolve: {
                    pictureUrls: () => [pictureUrl],
                }
            });
        }
    }
})();
