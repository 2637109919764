(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('accessItemGroupsService', accessItemGroupsService);

    accessItemGroupsService.$inject = [
        '$q',
        'encapsuledHttpService'
    ];

    function accessItemGroupsService(
        $q,
        encapsuledHttpService
    ) {
        var accessItemGroupsService = {
            getAll: getAll,
            getGroupsByLocation: getGroupsByLocation,
            isGroupNameUnique: isGroupNameUnique,
            addAccessItemGroup: addAccessItemGroup,
            addLocationsToAccessItemGroup: addLocationsToAccessItemGroup,
            updateAccessItemGroup: updateAccessItemGroup,
            getGroupsByMunicipality: getGroupsByMunicipality
        };

        return accessItemGroupsService;

        function getGroupsByLocation(locationId) {
            const deferred = $q.defer();
            const url = 'api/accessitemgroups/bylocation/' + locationId;
            encapsuledHttpService.getRequest(url, deferred);     
            return deferred.promise;
        }

        function getAll() {
            let deferred = $q.defer();

            encapsuledHttpService.getRequest('api/accessitemgroups', deferred);        
         
            return deferred.promise;
        }

        function getGroupsByMunicipality(municipalityIds) {
            const deferred = $q.defer();
           
            encapsuledHttpService.postRequest('api/accessitemgroups/byMunicipality', municipalityIds, deferred);            

            return deferred.promise;
        }

        function isGroupNameUnique(accessItemGroup) {
            const deferred = $q.defer();

            encapsuledHttpService.postRequest('api/accessitemgroups/is-unique', accessItemGroup, deferred);

            return deferred.promise;
        }

        function addAccessItemGroup(accessItemGroup) {
            const deferred = $q.defer();

            if (accessItemGroup.name && accessItemGroup.name.length > 0) {
                encapsuledHttpService.postRequest('api/accessitemgroups', {
                    id: accessItemGroup.id,
                    name: accessItemGroup.name
                }, deferred);
            }

            return deferred.promise;
        }

        function updateAccessItemGroup(accessItemGroup) {
            const deferred = $q.defer();
            encapsuledHttpService.putRequest('api/accessitemgroups', accessItemGroup, deferred);
            return deferred.promise;             
        }

        function addLocationsToAccessItemGroup(accessItemGroupId, locationIds) {
            const deferred = $q.defer();
            const url = 'api/accessitemgroups/' + accessItemGroupId + '/add-locations';
            encapsuledHttpService.postRequest(url, locationIds, deferred);
            return deferred.promise;
        }
    }
})();
