(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('LocationStatisticsTabController', LocationStatisticsTabController);

    LocationStatisticsTabController.$inject = ['$scope', '$stateParams', '$timeout', 'locationsService', 'chartOptionsService'];

    function LocationStatisticsTabController($scope, $stateParams, $timeout, locationsService, chartOptionsService) {
        var locationId = $stateParams.locationId;
        $scope.dateRange = {};

        $scope.charts = {};
        $scope.getChartData = getChartData;

        function getChartData() {
            locationsService.getLocationStatistics(
                locationId,
                moment($scope.dateRange.from).format('YYYY-MM-DD'),
                moment($scope.dateRange.to).format('YYYY-MM-DD'),
            ).then(function (statistics) {
                var newCharts = _.mapValues(statistics, function (stats, fraction) {
                    return chartOptionsService.createChart(stats, chartOptionsService.chartTypes.fill, fraction, getFractionColor(fraction), {
                        valueAxis: {
                            min: 0,
                            max: 100,
                        },
                    });
                });

                // Needed to force refresh the charts in the view
                $scope.charts = {};
                $timeout(function() {
                    $scope.charts = newCharts;
                });
            });
        }

        function getFractionColor(fractionName) {
            switch (fractionName.toLowerCase()) {
                case 'bio-næring':
                case 'bio':
                    return '#00983a';
                case 'plast':
                case 'plast-næring':
                    return '#962c84';
                case 'glass/metall':
                    return '#43aa90';
                case 'restavfall':
                case 'rest-vask':
                case 'hytter rest':
                case 'rest-næring':
                    return '#000000';
                case 'papir':
                case 'hytter papir':
                case 'papir-næring':
                    return '#007ebf';
                default:
                    return 'red';
            }
        }
    }
})();
