(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('SensorOverviewController', SensorOverviewController);

    SensorOverviewController.$inject = [
        '$scope',
        '$rootScope',
        '$kWindow',
        'deviceService',
        'filterStateService',
        'formTypes',
        'deviceTypes',
        '$translate',
        'containerEditModalWidth',
    ];

    function SensorOverviewController(
        $scope,
        $rootScope,
        $kWindow,
        deviceService,
        filterStateService,
        formTypes,
        deviceTypes,
        $translate,
        containerEditModalWidth,
    ) {
        let devices = [];

        $scope.openUpdateSensor = openUpdateSensor;
        $scope.openUpdateContainerModal = openUpdateContainerModal;
        $scope.exportToExcel = exportToExcel;

        $scope.isFirstLoad = true;

        const defaultGrouping = [
            {
                field: "externalSystem",
                dir: "asc",
                aggregates: []
            }
        ];


        $scope.sensorOverviewGridOptions = {
            dataSource: {
                transport: {
                    read: readSensors,
                },
                pageSize: 70,
                sort: filterStateService.getSortFromQueryString(),
                filter: filterStateService.getFilterFromQueryString(),
            },
            columns: [
                {
                    field: 'externalSystem',
                    title: $translate.instant("SENSOR_OVERVIEW_CONTROLLER_HEADER_SYSTEM"),
                    width: 86,
                    lockable: false,
                },
                {
                    field: 'externalId',
                    title: $translate.instant("SENSOR_OVERVIEW_CONTROLLER_HEADER_EXTERNAL_ID"),
                    width: 90,
                    lockable: false,
                    headerAttributes: {
                        class: "min-width-external-id"
                    },
                    attributes: {
                        class: "min-width-external-id"
                    }
                },
                {
                    field: 'serial',
                    title: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_TABLE_HEADER_SERIAL"),
                    width: 122,
                    lockable: false,
                },
                {
                    field: 'customerKey',
                    title: $translate.instant("DEVICE_DETAILS_MODAL_CUSTOMER_KEY"),
                    width: 122,
                    lockable: false,
                },
                {
                    field: 'container',
                    title: $translate.instant("SENSOR_OVERVIEW_CONTROLLER_HEADER_DEVICE_CONTAINER"),
                    template: getContainer,
                    width: 100,
                    lockable: false,
                },
                {
                    field: 'deviceType',
                    title: $translate.instant("SENSOR_OVERVIEW_CONTROLLER_HEADER_DEVICE_TYPE"),
                    template: getDeviceTypeText,
                    lockable: false,
                },
                {
                    field: 'battery',
                    title: $translate.instant("CONTAINER_OVERVIEW_BATTERY"),
                    width: '94px',
                    template: `#= typeof battery === 'number' && battery !== -1 ? battery.toFixed(1) : "N/A" #`,
                    lockable: false,
                },
                {
                    field: 'signal',
                    title: $translate.instant("CONTAINER_OVERVIEW_CONTROLLER_TABLE_SIGNAL"),
                    template: `<span class="td-fill-span #: SignalClass#" >#= typeof signal === 'number' ? signal : "N/A" #</span>`,
                    width: '70px',
                    lockable: false,
                },
                {
                    field: 'fullHeight',
                    title: $translate.instant("DEVICE_DETAILS_MODAL_FULL_HEIGHT"),
                    width: '50px',
                    lockable: false,
                },
                {
                    field: 'sensorHeight',
                    title: $translate.instant("DEVICE_DETAILS_MODAL_SENSOR_HEIGHT"),
                    width: '50px',
                    lockable: false,
                },
                {
                    field: 'isActive',
                    title: $translate.instant("SENSOR_OVERVIEW_CONTROLLER_HEADER_IS_ACTIVE"),
                    template: '<input type="checkbox" disabled #= isActive ? \'checked="checked"\' : "" # />',
                    width: 100,
                    lockable: false,
                },
                {
                    field: 'isPriority',
                    title: $translate.instant("SENSOR_OVERVIEW_CONTROLLER_HEADER_IS_PRIORITY"),
                    template: '<input type="checkbox" disabled #= isPriority ? \'checked="checked"\' : "" # />',
                    width: 100,
                    lockable: false,
                },
                {
                    field: 'edit',
                    filterable: false,
                    sortable: false,
                    title: ' ',
                    width: 60,
                    template: '<div><i class="fa fa-1-5x fa-pencil fa-fw" ng-click="openUpdateSensor(dataItem)" aria-hidden="true"></i></div>',
                    locked: true,
                    lockable: false
                }
            ],
            scrollable: {
                virtual: true
            },
            sortable: true,
            filterable: true,
            noRecords: true,
            selectable: false,
            resizable: true,
            sort: filterStateService.storeGridSortInQueryString,
            filter: filterStateService.storeGridFilterInQueryString,
            groupable: {
                messages: {
                  empty: $translate.instant('G_DROP_COLUMNS_HERE')
                }
            },
            group: function(e) {
                saveGroupedStatusToLocalStorage();
            },
            ungroup: function(e) {
                saveGroupedStatusToLocalStorage();
            },
            excelExport: sensorOverviewGridExcelExport,
            dataBound: function(e){
                var grid = this;
                this.lockedTable.find(".k-grouping-row").each(function(index) {
                  var arrow = $(this).find("a");
                  grid.tbody.find(".k-grouping-row:eq("+index+") td").text($(this).text())
                  $(this).find("p").text(" ").append(arrow);
                })
            },
        };

        function readSensors(e) {
            showBusyIndicator();
            deviceService.getDevices().then((result) => {
                devices = result.map(extendDevice);
                e.success(devices);
                hideBusyIndicator();
                const grid = $('#sensor-overview-grid').getKendoGrid();
                grid.autoFitColumn('externalSystem');
                grid.autoFitColumn('externalId');
                loadFilterFromLocalStorage();
            });
        }

        function openUpdateSensor(device) {
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant("SENSOR_OVERVIEW_CONTROLLER_OPEN_UPDATE_SENSOR"),
                    resizable: false,
                    height: 550,
                    width: 534,
                    visible: false
                },
                templateUrl: 'app/device/device-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'DeviceDetailsModalController',
                resolve: {
                    currentFormType: () => formTypes.edit,
                    containerId: () => device.containerId,
                    updateCallbacks: () => ({ updateDeviceToGrid }),
                    device: () => device,
                }
            });
        }

        function updateDeviceToGrid(device) {
            const index = devices.findIndex(d => d.id === device.id);
            if (index === -1) return;
            const updatedDevice = {...devices[index], device}
            devices[index] = updatedDevice;
            $scope.sensorOverviewGrid.dataSource.data(devices);
            $('#sensor-overview-grid').getKendoGrid().dataSource.read();
            $('#sensor-overview-grid').getKendoGrid().refresh();
        }

        function extendDevice(device) {
            return {
                ...device,
                externalSystem: device.externalSystem ? device.externalSystem.name : '',
                SignalClass: getSignalStrength(device.signal),
            }
        }

        function getDeviceTypeText(device) {
            const type = deviceTypes.find(t => t.id === device.deviceType);
            return type ? type.localizedName : '';
        }

        function getContainer(device) {
            if(device.container && device.container.location){
                return `<a ng-click="openUpdateContainerModal(dataItem)" aria-hidden="true" style="cursor: pointer">${device.container.containerNumber}</a>`;
            }else{
                return "";
            }            
        }

        function openUpdateContainerModal(dataItem) {
            var titleFraction = dataItem.container.fraction ? (' - ' + dataItem.container.fraction.name) : '';
            var titleAddress = dataItem.container.address ? (' - ' + dataItem.container.address) : '';
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: dataItem.container.containerNumber + titleAddress + titleFraction,
                    resizable: false,
                    height: 600,
                    width: containerEditModalWidth,
                    visible: false
                },
                templateUrl: 'app/containerOverviewV2/views/sensor-api-container-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'SensorApiContainerDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.edit;
                    },
                    containerId: function () {
                        return dataItem.container.id;
                    },
                    updateCallbacks: function () {
                        return null;
                    },
                    locationId: function () {
                        return null;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    $('#sensor-overview-grid').getKendoGrid().dataSource.read();
                }
            });
        }

        function showBusyIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'sensorOverviewIndicator',
                destination: '.intellicont-overview',
                message: $translate.instant("G_BUSY_INDICATOR"),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });
        }

        function hideBusyIndicator() {
            $rootScope.$broadcast('hideBusyIndicator', 'sensorOverviewIndicator');
        }

        function getSignalStrength(signal) {
            if (typeof signal === "number") {
                if (signal <= 5) {
                    return "critical";
                } else if (signal > 5 && signal <= 7) {
                    return "warning";
                } else if (signal > 7) {
                    return "ok";
                }
            } else {
                return "missing";
            }
        }

        function saveGroupedStatusToLocalStorage() {
            setTimeout(() => {
            var grid = $("#sensor-overview-grid").getKendoGrid();
            var grouped = grid.dataSource.group();
            localStorage.setItem('sensorOverviewGroup', kendo.stringify(grouped));
            }, 100);
        }

        function loadFilterFromLocalStorage() {
            var grouped  = localStorage.getItem('sensorOverviewGroup');
            var grid = $("#sensor-overview-grid").data("kendoGrid");
            if (grouped ) {
                grid.dataSource.group(JSON.parse(grouped ));
            } else {
                grid.dataSource.group(defaultGrouping);
            }
        }

        function exportToExcel() {
            $scope.sensorOverviewGrid.hideColumn(0);
            $scope.sensorOverviewGrid.saveAsExcel();
            $scope.sensorOverviewGrid.showColumn(0);
        }

        function sensorOverviewGridExcelExport(e) {
            e.workbook.fileName =
                $translate.instant("SENSOR_OVERVIEW_SENSORS") +
                kendo.toString(new Date(), "ddMMyyyy_HHmmss") +
                ".xls";
            var sheet = e.workbook.sheets[0];
            var containerColumnIndex = 4;
            var deviceTypeColumnIndex = 5;

            var data = e.sender.dataSource.data();

            var dataRowIndex = 0;
            for (var rowIndex = 1; rowIndex < sheet.rows.length; rowIndex++) {
                var row = sheet.rows[rowIndex];
        
                if (row.type !== "data") {
                    continue;
                }
        
                var dataItem = data[dataRowIndex];
                dataRowIndex++;
        
                var containerValue = getContainerValue(dataItem);
                row.cells[containerColumnIndex].value = containerValue;
        
                var deviceTypeValue = getDeviceTypeValue(dataItem);
                row.cells[deviceTypeColumnIndex].value = deviceTypeValue;
            }
            var columnWidths = this.element.find(".k-grid-header colgroup col");

            for (var i = 0; i < sheet.columns.length; i++) {
                if ($(columnWidths[i]).attr("style")) {
                    sheet.columns[i].width = 160;
                } else {
                    sheet.columns[i].width = 16;
                }
            }
        }

        function getContainerValue(device) {
            if (device && device.container && device.container.containerNumber) {
                return device.container.containerNumber;
            } else {
                return "";
            }
        }
        
        function getDeviceTypeValue(device) {
            if (!device) return '';
            const type = deviceTypes.find(t => t.id === device.deviceType);
            return type ? type.localizedName : '';
        }

        $rootScope.$on('panelsResized', function () {
            const element = document.getElementById('sensor-overview-grid');
            const grid = $("#sensor-overview-grid").getKendoGrid()
            
            if (grid) {
                getDefaultColumnWidths(grid.columns);
                
                const gridWidth = getWidths(grid.columns);
                
                if (element.offsetWidth > $scope.defaultColumWidths) {
                    const newWidth = grid.columns[grid.columns.length - 1].width + (element.offsetWidth - gridWidth) + 14; 
                    grid.setOptions({
                        columns: grid.columns.map((col, idx) => {
                            if (idx === grid.columns.length - 1) {
                                return { ...col, width: newWidth };
                            }
                            return col;
                        })
                });            
                } else {
                    grid.setOptions({
                        columns: grid.columns.map((col, idx) => {
                            if (idx === grid.columns.length - 1) {
                                return { ...col, width: 100 };
                            }
                            return col;
                        })
                    });
                }
                grid.refresh();
            }
        })

        function getDefaultColumnWidths(columns) {
            if ($scope.isFirstLoad) {
                $scope.defaultColumWidths = getWidths(columns);
                $scope.isFirstLoad = false;
            }
        }

        function getWidths(columns) {
            const widths = columns.reduce((sum, column) => {
                const width = parseInt(column.width);
                return sum + (isNaN(width) ? 0 : width);
            }, 0);
            return widths;
        }
    }
})();
