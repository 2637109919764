(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('AccessItemDetailsModalController', AccessItemDetailsModalController);

    AccessItemDetailsModalController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$windowInstance',
        'accessItemsService',
        'accessItemGroupsService',
        'keycardUtility',
        'formTypes',
        'fallbackErrorMessageText',
        'currentFormType',
        'currentGroup',
        'currentAccessItem',
        'multiEditAccessItems',
        '$translate'
    ];

    function AccessItemDetailsModalController(
        $q,
        $scope,
        $rootScope,
        $windowInstance,
        accessItemsService,
        accessItemGroupsService,
        keycardUtility,
        formTypes,
        fallbackErrorMessageText,
        currentFormType,
        currentGroup,
        currentAccessItem,
        multiEditAccessItems,
        $translate
    ) {
        //Properties

        //KeyCard type "enum"
        const Keyfob = 0;
        const KeyfobOther = 1;
        const DigitalKey = 2;

        $scope.currentFormType = currentFormType;

        $scope.isAdditionDisabled = currentFormType !== formTypes.add;
        $scope.isKeycardListVisible = currentFormType !== formTypes.replace && currentFormType !== formTypes.extra;
        $scope.isReplaceForm = currentFormType === formTypes.replace;
        $scope.isExtraCardForm = currentFormType === formTypes.extra;
        $scope.isMultiEditForm = currentFormType === formTypes.editMultiple;
        $scope.showTypeSelector = currentFormType === formTypes.add;
        $scope.showRememberMeCheck = currentFormType === formTypes.add;
        $scope.isTypeSelectorDisabled = currentFormType === formTypes.extra;

        $scope.showNoItemsError = false;

        $scope.lastName = { Value: '', Remember: true };
        $scope.firstName = { Value: '', Remember: true };
        $scope.address = { Value: '', Remember: true };
        $scope.city = { Value: '', Remember: true };
        $scope.zipCode = { Value: '', Remember: true };
        $scope.apartmentNumber = { Value: '', Remember: true };
        $scope.status = { Value: 0, Remember: true };

        $scope.hnr = { Value: null, Remember: true };
        $scope.propertyId = { Value: null, Remember: true };
        $scope.knr = { Value: null, Remember: true };
        $scope.gnr = { Value: null, Remember: true };
        $scope.bnr = { Value: null, Remember: true };
        $scope.fnr = { Value: null, Remember: true };
        $scope.snr = { Value: null, Remember: true };
        $scope.bid = { Value: null, Remember: true };

        $scope.newKey = '';
        $scope.replacementKey = '';
        $scope.users = [];
        $scope.cardKeys = [];
        $scope.errorMessage = undefined;
        $scope.replacementKeyErrorMessage = undefined;
        $scope.duplicatedAccessItemsMessage = undefined;
        $scope.selectedKeyCardType = {};

        $scope.remmemberMeClicked = remmemberMeClicked;

        $scope.cityRemember = true;
        $scope.municipalityRemember = true;

        $scope.keycardTypes = [
            {id: Keyfob, name: $translate.instant('ACCESS_CONTROL_KEYCARD_TYPE_KEYFOB')},
            {id: KeyfobOther, name: $translate.instant('ACCESS_CONTROL_KEYCARD_TYPE_KEYFOB_ANNET')},
            {id: DigitalKey, name: $translate.instant('ACCESS_CONTROL_KEYCARD_TYPE_DIGITAL_KEY')}
        ];

        $scope.validatorOptions = {
            rules: {
                propertyId: function(input) {
                    if (input.is("[name=propertyId]")) {
                        return input.val().length <= 40;
                    }
                    return true;
                }
            },
            messages: {
                propertyId: $translate.instant('ACCESS_CONTROL_ITEM_DETAILS_PROPERTYID_MAX_LENGTH')
            }
        };

        $scope.groupOptions = {
            dataSource: {
                transport: {
                    read: readGroups
                }
            },
            dataTextField: "name",
            dataValueField: "nummer",
            optionLabel: {
                name: $translate.instant('G_CHOOSE_GROUP'),
                nummer: ""
            },
            filter: 'contains',
            dataBound: function () {
                if (currentFormType !== formTypes.add) {
                    $scope.groupDropdown.select(function (dataItem) {
                        return dataItem.id === currentGroup.id;
                    });
                }
            }
        };

        $scope.keycardTypeOptions = {
            dataSource: {
                transport: {
                    read: readKeyCardTypes
                }
            },
            dataTextField: "name",
            dataValueField: "id",           
            filter: 'contains',
            dataBound: function (e) {
                const types = e.sender.dataItems();
                if (types && types.length) {
                    const firstType = types[0];

                    e.sender.select(firstType);
                    $scope.selectedKeyCardType = firstType;
                }
            }
        };

        //Event handlers
        $scope.addKey = addKey;
        $scope.removeKey = removeKey;
        $scope.close = close;
        $scope.save = save;
        $scope.saveAndClose = saveAndClose;

        function initController() {
            $scope.selectedGroup = currentGroup;

            $scope.groupDropdown.select(function (dataItem) {
                const hasDataItem = !!dataItem;
                const hasCurrentGroup = !!currentGroup;

                if(hasDataItem && hasCurrentGroup){
                    return dataItem.id === currentGroup.id;
                }
            });

            if (currentFormType === formTypes.edit
                || currentFormType === formTypes.replace
                || currentFormType === formTypes.extra) {
                if (currentAccessItem) {
                    
                    $scope.newKey = currentAccessItem.cardNumber;
                    $scope.cardKeys.push({ key : currentAccessItem.cardNumber, type: currentAccessItem.type });

                    $scope.firstName.Value = currentAccessItem.firstName;
                    $scope.lastName.Value = currentAccessItem.lastName;
                    $scope.address.Value = currentAccessItem.streetAddress;
                    $scope.city.Value = currentAccessItem.cityAddress;
                    $scope.zipCode.Value = currentAccessItem.zipCode;
                    $scope.apartmentNumber.Value = currentAccessItem.apartmentNumber;
                    $scope.status.Value = currentAccessItem.isActive || currentFormType === formTypes.extra ? 0 : 1;
                    $scope.hnr.Value = currentAccessItem.hnr;
                    $scope.propertyId.Value = currentAccessItem.propertyId;
                    $scope.knr.Value = currentAccessItem.knr;
                    $scope.gnr.Value = currentAccessItem.gnr;
                    $scope.bnr.Value = currentAccessItem.bnr;
                    $scope.fnr.Value = currentAccessItem.fnr;
                    $scope.snr.Value = currentAccessItem.snr;
                    $scope.bid.Value = currentAccessItem.bid;
                }
            } else if (currentFormType === formTypes.editMultiple) {
                multiEditAccessItems.forEach(function (accessItem) {
                    $scope.cardKeys.push({ key : accessItem.cardNumber, type: accessItem.type });
                });
            } else {
                $("#newKey").focus();
            }
        }

        function addKey(e) {
            $scope.errorMessage = undefined;

            const charCode = (e.which) ? e.which : e.keyCode;
            if (charCode === 13) {
                event.preventDefault();

                if ($scope.newKey && $scope.selectedKeyCardType) {

                    switch ($scope.selectedKeyCardType.id){
                        case Keyfob: addKeyfobTypeKeyCard(); break;
                        case KeyfobOther:
                        case DigitalKey: addKeyCard($scope.newKey.length <= 32); break;
                        default: $scope.errorMessage = $translate.instant('ACCESS_CONTROL_ITEM_DETAILS_VIEW_NO_SUCH_KEYCARD_TYPE');
                    }
                }
            }
        }

        function removeKey(e) {
            const index = $scope.cardKeys.indexOf(e);
            if (index > -1) {
                $scope.cardKeys.splice(index, 1);
            }

            validateAddedItems();
        }

        function close() {
            event.preventDefault();
            $windowInstance.close(false);
        }

        function saveAndClose() {
            event.preventDefault(); 
            showBusyIndicator();      

            saveKeycards().then(function (result) {
                hideBusyIndicator();
                $windowInstance.close(result);
            }).finally(function () {
                hideBusyIndicator();
            });
        }

        function save() {
            event.preventDefault();
            showBusyIndicator();

            saveKeycards().then(function () {
                hideBusyIndicator();
                resetFormData();
            }).finally(function () {
                hideBusyIndicator();
            });
        }

        function saveKeycards() {
            const deferred = $q.defer();

            if ($scope.validator.validate() && validateAddedItems()) {
                const accessItemModelBase = {
                    lastName: $scope.lastName.Value,
                    firstName: $scope.firstName.Value,
                    cityAddress: $scope.city.Value,
                    zipCode: $scope.zipCode.Value,
                    apartmentNumber: $scope.apartmentNumber.Value,
                    streetAddress: $scope.address.Value,
                    isActive: $scope.status.Value === 0,
                    hnr: $scope.hnr.Value,
                    propertyId: $scope.propertyId.Value,
                    knr: $scope.knr.Value,
                    gnr: $scope.gnr.Value,
                    bnr: $scope.bnr.Value,
                    fnr: $scope.fnr.Value,
                    snr: $scope.snr.Value,
                    bid: $scope.bid.Value,
                    groupId: $scope.selectedGroup && $scope.selectedGroup.id
                };

                if (currentFormType === formTypes.add || currentFormType === formTypes.extra) {
                    if (currentFormType === formTypes.extra) {
                        $scope.replacementKeyErrorMessage = undefined;
                        validateExtraKeycardNumber($scope.cardKeys[0], $scope.replacementKey);
                        if ($scope.replacementKeyErrorMessage) {
                            deferred.reject();
                            return deferred.promise;
                        }
                    }
                    const addMultipleAccessItemsModel = {
                        ...accessItemModelBase,
                        cardNumbers: currentFormType === formTypes.add ? $scope.cardKeys : [{ ...$scope.cardKeys[0], key: getExtraKeycardNumberByType($scope.cardKeys[0], $scope.replacementKey)}]
                    };

                    accessItemsService.addMultipleAccessItems(addMultipleAccessItemsModel).then(function (result) {
                        $scope.cardKeys = [];
                        deferred.resolve(result);
                    }).catch(() => {
                        deferred.reject();
                    });
                } else if (currentFormType === formTypes.edit
                    || currentFormType === formTypes.replace) {
                        const updateAccessItemModel = {
                        ...accessItemModelBase,
                        id: currentAccessItem.id,
                        cardNumber: $scope.cardKeys[0]
                    };

                    //Set cardNumber to the new value set in the replace form
                    if (currentFormType === formTypes.replace) {
                        $scope.replacementKeyErrorMessage = undefined;
                        validateExtraKeycardNumber($scope.cardKeys[0], $scope.replacementKey);
                        if ($scope.replacementKeyErrorMessage) {
                            deferred.reject();
                            return deferred.promise;
                        }
                        updateAccessItemModel.cardNumber.key = $scope.replacementKey;
                        accessItemsService.replaceAccessItem(updateAccessItemModel).then(function (result) {
                            deferred.resolve(result);
                        }).catch(() => {
                            deferred.reject();
                        });
                    }else{
                        accessItemsService.updateAccessItem(updateAccessItemModel).then(function (result) {
                            deferred.resolve(result);
                        }).catch(() => {
                            deferred.reject();
                        });
                    }                    
                } else if (currentFormType === formTypes.editMultiple) {
                    const accessItemsToUpdate = [];
                    multiEditAccessItems.forEach(function (accessItem) {
                        accessItemsToUpdate.push({
                            id: accessItem.id,
                            cardNumber: accessItem.cardNumber,
                            type: accessItem.type,

                            lastName: $scope.lastName.Value || accessItem.lastName,
                            firstName: $scope.firstName.Value || accessItem.firstName,
                            cityAddress: $scope.city.Value || accessItem.cityAddress,
                            zipCode: $scope.zipCode.Value || accessItem.zipCode,
                            apartmentNumber: $scope.apartmentNumber.Value || accessItem.apartmentNumber,
                            streetAddress: $scope.address.Value || accessItem.streetAddress,
                            isActive: !$scope.status.Value,
                            groups: [],
                            hnr: $scope.hnr.Value || accessItem.hnr,
                            propertyId: $scope.propertyId.Value || accessItem.propertyId,
                            knr: $scope.knr.Value || accessItem.knr,
                            gnr: $scope.gnr.Value || accessItem.gnr,
                            bnr: $scope.bnr.Value || accessItem.bnr,
                            fnr: $scope.fnr.Value || accessItem.fnr,
                            snr: $scope.snr.Value || accessItem.snr,
                            bid: $scope.bid.Value || accessItem.bid,
                        });
                    });

                    accessItemsService.updateMultipleAccessItems(accessItemsToUpdate).then(function (result) {
                        deferred.resolve(result);
                    }).catch(() => {
                        deferred.reject();
                    });
                }
            }else{
                deferred.reject();
            }

            return deferred.promise;
        }

        function getExtraKeycardNumberByType(extraKeycard, replacementKeyNumber) {
            switch (extraKeycard.type){
                case Keyfob: return convertKeyCard(replacementKeyNumber);
                case KeyfobOther:
                case DigitalKey:
                default: return replacementKeyNumber;
            }
        }

        //convert keycard when keyfob type is selected and return it as a string otherwise return the keycard as is
        function convertKeyCard(keyCard) {            
            const convertResult = keycardUtility.convertKeycardNumber(keyCard);
            
            if (!convertResult.errorMessage) {
                return convertResult.returnStr;
            }

            return keyCard;
        }

        //Private functions
        function readGroups(e) {
            accessItemGroupsService.getAll().then(function (groups) {
                $scope.usersDdlServerErrorText = null;
                e.success(_.orderBy(groups, 'name'));
            }).catch(function (error) {
                $scope.usersDdlServerErrorText = error.localizedMessage || fallbackErrorMessageText;
                e.error();
            })
            .then(initController);
        }


        function readKeyCardTypes(e) {
           e.success($scope.keycardTypes)
        }

        function resetFormData() {
            if (!$scope.lastName.Remember) { $scope.lastName.Value = ''; }
            if (!$scope.firstName.Remember) { $scope.firstName.Value = ''; }
            if (!$scope.address.Remember) { $scope.address.Value = ''; }
            if (!$scope.city.Remember) { $scope.city.Value = ''; }
            if (!$scope.zipCode.Remember) { $scope.zipCode.Value = ''; }
            if (!$scope.apartmentNumber.Remember) { $scope.apartmentNumber.Value = ''; }
            if (!$scope.status.Remember) { $scope.status.Value = 0; }
            if (!$scope.hnr.Remember) { $scope.hnr.Value = null; }
            if (!$scope.propertyId.Remember) { $scope.propertyId.Value = null; }
            if (!$scope.knr.Remember) { $scope.knr.Value = null; }
            if (!$scope.gnr.Remember) { $scope.gnr.Value = null; }
            if (!$scope.bnr.Remember) { $scope.bnr.Value = null; }
            if (!$scope.fnr.Remember) { $scope.fnr.Value = null; }
            if (!$scope.snr.Remember) { $scope.snr.Value = null; }
            if (!$scope.bid.Remember) { $scope.bid.Value = null; }
        }

        function validateAddedItems() {
            const hasAddedItems = $scope.cardKeys.length > 0;

            if (hasAddedItems) {
                hideItemsError();
            } else {
                showItemsError();
            }

            return hasAddedItems;
        }

        function showItemsError() {
            $scope.showNoItemsError = true;
        }

        function hideItemsError() {
            $scope.showNoItemsError = false;
        }

        function showBusyIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'AccessItemDetailsModalIndicator',
                destination: '#modal-container',
                message: $translate.instant('G_BUSY_INDICATOR'),
                overlay: true,
                positionClass: {
                    top: '250px',
                    left: '0px',
                    right: '0px'
                }
            });
        }

        function hideBusyIndicator() {
            $rootScope.$broadcast('hideBusyIndicator', 'AccessItemDetailsModalIndicator');
        }

        /// Keycard validation logic by types

        function addKeyfobTypeKeyCard() {
            const convertedKey = convertKeyCard($scope.newKey);
        
            if (convertedKey !== $scope.newKey) {
                $scope.newKey = convertedKey;
                registerKeyCard();
            } else {
                $scope.errorMessage = keycardUtility.convertKeycardNumber($scope.newKey).errorMessage;
            }
        }

        function addKeyCard(validation){
            if(validation){
                registerKeyCard();
            }else{
                $scope.errorMessage = $translate.instant('ACCESS_CONTROL_ITEM_DETAILS_VIEW_CARD_NUMBER_VALIDATION_ERROR');
            }
        }

        function registerKeyCard(){
            const exist = $scope.cardKeys.includes($scope.newKey);
            if (!exist) {
                $scope.cardKeys.push({ key: $scope.newKey, type: $scope.selectedKeyCardType.id });
                $scope.newKey = '';
                validateAddedItems();
            } else {
                const err = $translate.instant('ACCESS_CONTROL_ITEM_DETAILS_VIEW_DUPLICATED_ACCESS_ITEM');
                $scope.duplicatedAccessItemsMessage = err + ": " + $scope.newKey;
                $scope.newKey = '';
            }
        }

        function validateExtraKeycardNumber(oldKeyCard, replacementKey) {
            switch (oldKeyCard.type){
                case Keyfob: validateKeyfobTypeKeyCard(replacementKey); break;
                case KeyfobOther:
                case DigitalKey: validateKeyCard(replacementKey); break;
                default: $scope.replacementKeyErrorMessage = $translate.instant('ACCESS_CONTROL_ITEM_DETAILS_VIEW_NO_SUCH_KEYCARD_TYPE');
            }
        }

        function validateKeyfobTypeKeyCard(replacementKey) {
            const convertedKey = convertKeyCard(replacementKey);
        
            if (convertedKey === replacementKey) {
                $scope.replacementKeyErrorMessage = keycardUtility.convertKeycardNumber(replacementKey).errorMessage;
            }
        }

        function validateKeyCard(replacementKey) {
        
            if (replacementKey.length > 32) {
                $scope.replacementKeyErrorMessage = $translate.instant('ACCESS_CONTROL_ITEM_DETAILS_VIEW_CARD_NUMBER_VALIDATION_ERROR');
            }
        }

        function remmemberMeClicked(elements, value) {
            elements.forEach(element => element.Remember = value);
        }
    }
})();
