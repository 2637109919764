(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('logActionService', logActionService);

    logActionService.$inject = ['$q', 'encapsuledHttpService'];

    function logActionService($q, encapsuledHttpService) {
        var service = {
            getLogActions: getLogActions,
            getLogActionsForAdministration: getLogActionsForAdministration,
            addLogAction: addLogAction,
            updateLogAction: updateLogAction,
            deleteLogAction: deleteLogAction,
            getLogActionActions: getLogActionActions,
        };

        return service;

        function getLogActions() {
            const deferred = $q.defer();
            encapsuledHttpService.getRequest("api/logAction", deferred);

            return deferred.promise;
        }

        function getLogActionsForAdministration() {
            const deferred = $q.defer();
            encapsuledHttpService.getRequest("api/logAction/admin", deferred);

            return deferred.promise;
        }

        function addLogAction(logAction) {
            const deferred = $q.defer();
            encapsuledHttpService.postRequest("api/logAction", logAction, deferred);

            return deferred.promise;
        }

        function updateLogAction(logAction) {
            const deferred = $q.defer();
            encapsuledHttpService.putRequest("api/logAction", logAction, deferred);
  
            return deferred.promise;
        }

        function deleteLogAction(logActionId) {
            const deferred = $q.defer();
            encapsuledHttpService.deleteRequest("api/logAction/" + logActionId, deferred);
           
            return deferred.promise;
        }

        function getLogActionActions() {
            const deferred = $q.defer();
            encapsuledHttpService.getRequest("api/logAction/actions", deferred);

            return deferred.promise;
        }
  }
})();
