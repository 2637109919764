(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('PlotlyController', PlotlyController);

    PlotlyController.$inject = ['externalId', 'containerHeight', 'deviceService', '$rootScope', 'defaultSensorHeight', '$translate'];

    function PlotlyController(externalId, containerHeight, deviceService, $rootScope, defaultSensorHeight, $translate) {
    
        function init(){
            getLevelMeasurementDataByExternalId();
        }

        function getLevelMeasurementDataByExternalId() {
            showBusyIndicator();
            deviceService
                .getLevelMeasurementDataByExternalId(externalId)
                .then(function (result) {
                    let measurements = result.map((x) => x.distances_Mm);

                    let sensorHeight = defaultSensorHeight;
                    let defaultHeightUsed = true;

                    if (containerHeight) {
                        sensorHeight = containerHeight;
                        defaultHeightUsed = false;
                    }

                    measurements = measurements.map((x) =>
                        x.map((y) => sensorHeight - y)
                    );
                    measurements.forEach((element) => {
                        element.push(0);
                        element.splice(0, 0, 0);
                    });
                    measurements.splice(0, 0, Array(10).fill(0));
                    measurements.push(Array(10).fill(0));

                    var data = [
                        {
                            z: measurements,
                            type: "surface",
                            showscale: false,
                        },
                    ];

                    var layout = {
                        autosize: false,
                        showlegend: false,
                        width: 600,
                        height: 600,
                        margin: {
                            l: 0,
                            r: 0,
                            b: 0,
                            t: 10,
                        },
                        scene: {
                            zaxis: {
                                range: [0, sensorHeight],
                            },
                        },
                    };

                    setTimeout(function () {
                        Plotly.newPlot('levelMeasurementChart', data, layout, {
                            displayModeBar: false,
                        });
                    }, 10);
                })
                .finally(function () {
                    hideBusyIndicator();
                });
        }

        function showBusyIndicator() {
            setTimeout(function () {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'PlotlyModalIndicator',
                    destination: '#levelMeasurementChart',
                    message: $translate.instant('G_BUSY_INDICATOR'),
                    overlay: true,
                    positionClass: {
                        top: '300px',
                        left: '0px',
                        right: '0px'
                    }
                });
            }, 10);
        }

        function hideBusyIndicator() {
            $rootScope.$broadcast('hideBusyIndicator', 'PlotlyModalIndicator');
        }
        
        init();
    }
})();
