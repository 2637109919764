(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('AreaOverviewController', AreaOverviewController);

    AreaOverviewController.$inject = [
        '$scope',
        '$rootScope',
        'sensorApiAreaService',
        'filterStateService',
        'kendoGridUtility',
        '$state',
        '$translate'
    ];

    function AreaOverviewController(
        $scope,
        $rootScope,
        sensorApiAreaService,
        filterStateService,
        kendoGridUtility,
        $state,
        $translate
    ) {
        $scope.areaDetails = areaDetails;

        $scope.areaOverviewGridOptions = {
            dataSource: {
                transport: {
                    read: readAreas,
                },
                pageSize: 70,
                sort: filterStateService.getSortFromQueryString(),
                filter: filterStateService.getFilterFromQueryString(),
            },
            columns: [
                {
                    field: 'description',
                    title: $translate.instant('AREA_OVERVIEW_CONTROLLER_TABLE_HEADER_DESCRIPTION'),
                },
                {
                    field: 'isActive',
                    title: $translate.instant('AREA_OVERVIEW_CONTROLLER_TABLE_HEADER_ACTIVE'),
                    template: kendoGridUtility.checkBoxColumnTemplate('isActive'),
                    width: 100,
                },
            ],
            scrollable: {
                virtual: true
            },
            sortable: true,
            filterable: true,
            noRecords: true,
            resizable: true,
            selectable: true,
            sort: filterStateService.storeGridSortInQueryString,
            filter: filterStateService.storeGridFilterInQueryString,
            change: onRowClicked,
        };

        setTimeout(() => $rootScope.$broadcast('showAreaLayer'), 200);

        function readAreas(e) {
            showBusyIndicator();
            sensorApiAreaService.getAreas().then((result) => {
                e.success(result);
            }).finally(() => hideBusyIndicator());
        }

        function showBusyIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'areaOverviewIndicator',
                destination: '#area-overview',
                message: $translate.instant('G_BUSY_INDICATOR'),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });
        }

        function hideBusyIndicator() {
            $rootScope.$broadcast('hideBusyIndicator', 'areaOverviewIndicator');
        }

        function areaDetails(area) {
            $state.go('main.area', {
                areaId: area.id,
            });
        }

        function onRowClicked() {
            var selectedRows = this.select();
            var dataItem = this.dataItem(selectedRows[0]);
            $state.go('main.areaDetails', { areaId: dataItem.id });
        }
    }
})();
