(function () {
    "use strict";

    angular.module('UndergroundWebApp').controller('DeviationReportController', DeviationReportController);

    DeviationReportController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$kWindow',
        'deviationReportService',
        'formTypes',
        'deviationStatuses',
        'filterStateService',
        '$translate',
        'deviationAlertRecurrenceTypes'
    ];

    function DeviationReportController(
        $q,
        $scope,
        $rootScope,
        $kWindow,
        deviationReportService,
        formTypes,
        deviationStatuses,
        filterStateService,
        $translate,
        deviationAlertRecurrenceTypes
    ) {
        $scope.sortedJobs = [];
        $scope.reportQueries = [];
        $scope.deviations = [];

        $scope.openNewDeviationReport = openNewDeviationReport;
        $scope.deleteDeviationReport = deleteDeviationReport;
        $scope.openUpdateDeviationReport = openUpdateDeviationReport;
        $scope.getDaysOfWeekLocalizedString = getDaysOfWeekLocalizedString;
        $scope.getIntervalMinutesLocalizedString =
            getIntervalMinutesLocalizedString;
        $scope.getLocalizedDateString = getLocalizedDateString;
        $scope.getRecurrenceTypeLocalizedString = getRecurrenceTypeLocalizedString;
        $scope.getEmailParticipantsCount = getEmailParticipantsCount;
        $scope.getStatusCheckboxByStatusId = getStatusCheckboxByStatusId;

        $scope.findDeviationAlertType = findDeviationAlertType;

        $scope.deviationReportGridOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readJobs,
                },
                pageSize: 70,
                sort: filterStateService.getSortFromQueryString(),
                filter: filterStateService.getFilterFromQueryString(),
            }),
            columns: [
                {
                    field: 'name',
                    title: $translate.instant('G_NAME')
                },
                {
                    field: "recurrence",
                    title: $translate.instant(
                        "ADMINISTRATION_DEVIATION_RECURRENCE_TYPE"
                    ),
                    template:
                        "{{getRecurrenceTypeLocalizedString(dataItem.recurrence)}}",
                },
                {
                    field: 'queryId',
                    title: $translate.instant('G_TYPE'),
                    template: '{{findDeviationAlertType(dataItem.queryId).name}}'
                },
                {
                    field: 'daysOfWeek',
                    title: $translate.instant('G_DAYS_CAPITAL'),
                    template: '{{getDaysOfWeekLocalizedString(dataItem)}}'
                },
                {
                    field: 'intervalInMinutes',
                    title: $translate.instant('ADMINISTRATION_DEVIATION_INTERVAL_MINUTES'),
                    template: '{{getIntervalMinutesLocalizedString(dataItem)}}'
                },
                {
                    field: "lastSuccessfullRun",
                    title: $translate.instant(
                        "ADMINISTRATION_DEVIATION_LAST_SUCCESSFULL_RUN"
                    ),
                    template:
                        "{{getLocalizedDateString(dataItem.lastSuccessfullRun)}}",
                },
                {
                    field: 'emailAddresses',
                    title: $translate.instant('ADMINISTRATION_DEVIATION_RECEPIENTS_NO'),
                    template: '{{getEmailParticipantsCount(dataItem.emailAddresses)}}',
                },
                {
                    field: 'status',
                    title: deviationStatuses.active.LocalizedName,
                    template: '<input type="checkbox" disabled ng-checked="{{getStatusCheckboxByStatusId(dataItem.status)}}" />',
                    width: '70px',
                    attributes: {"class": "status-col" },
                },
                {
                    field: 'edit',
                    filterable: false,
                    sortable: false,
                    groupable: false,
                    title: ' ',
                    width: '80px',
                    template: '<i class="fa fa-1-5x fa-pencil fa-fw" ng-click="openUpdateDeviationReport(dataItem.id)" aria-hidden="true"></i>' +
                        '<i class="fa fa-1-5x fa-trash fa-fw" ng-click="deleteDeviationReport(dataItem.id)" aria-hidden="true"></i>'
                }
            ],
            scrollable: {
                virtual: true,
            },
            messages: {
                noRecords: $translate.instant("G_NO_DATA")
            },
            sortable: true,
            filterable: true,
            noRecords: true,
            resizable: true,
            selectable: false,
            change: onDeviationReportRowSelected,
            sort: filterStateService.storeGridSortInQueryString,
            filter: filterStateService.storeGridFilterInQueryString,
        };

        function findDeviationAlertType(id) {
            return _.find($scope.reportQueries, { id: id });
        }

        function readJobs(e) {
            $scope.showBusyIndicator();

            $q.all([
                deviationReportService.getDeviationReports(),
                deviationReportService.getDeviationReportQueries(),
                deviationReportService.getDeviations()
            ]).then(([jobs, reportQueries, deviations]) => {
                const filteredJobs = jobs.filter(job => job.status !== deviationStatuses.invisible.Id);
                $scope.sortedJobs = _.chain(filteredJobs)
                    .sortBy(f => f.name.toLowerCase())
                    .value();

                $scope.reportQueries = reportQueries;
                $scope.deviations = deviations;

                $scope.hideBusyIndicator();
                e.success($scope.sortedJobs);
            }).catch(() => {
                $scope.hideBusyIndicator();
                e.success([]);
            });
        }

        function openNewDeviationReport() {
            openDeviationDetailsModal($translate.instant('ADMINISTRATION_DEVIATION_NEW'), formTypes.add, $scope.reportQueries, null, $scope.deviations)
                .result.then(function () { });
        }

        function openUpdateDeviationReport(deviationReportId) {
            const deviationAlert = _.find($scope.sortedJobs, { id: deviationReportId });
            openDeviationDetailsModal(deviationAlert.name, formTypes.edit, $scope.reportQueries, deviationAlert, $scope.deviations)
                .result.then(function () { });
        }

        function deleteDeviationReport(deviationReportId) {
            const confirmText = $translate.instant('ADMINISTRATION_DEVIATION_DIALOG_CONFIRM_DELETE');
            const deviationReport = _.find($scope.sortedJobs, { id: deviationReportId });

            if (confirm(`${confirmText} ${deviationReport.name}?`)) {
                deviationReportService.deleteDeviationReport(deviationReportId).then(isSuccessful => {
                    if (isSuccessful) {
                        $('#deviationReportsGrid').getKendoGrid().dataSource.read();
                    }
                });
            }
        }

        function onDeviationReportRowSelected() {
            const selectedRows = this.select();
            $rootScope.selectedContainerId = this.dataItem(selectedRows[0]).id;
        }

        function openDeviationDetailsModal(title, formType, reportQueries, deviationAlert, deviations) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: title,
                    resizable: false,
                    height: 870,
                    width: 800,
                    visible: false
                },
                templateUrl: 'app/administration/deviationReport/deviation-report-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'DeviationReportModalController',
                resolve: {
                    currentFormType: () => formType,
                    deviationAlert: () => deviationAlert,
                    deviations: () => deviations,
                    reportQueries: () => reportQueries
                }
            });
        }

        function getDaysOfWeekLocalizedString(deviationAlert) {
            const daysOfWeekBinaryArray = deviationAlert.daysOfWeek;
            if (!deviationAlert || !daysOfWeekBinaryArray || deviationAlert.recurrence == 0) return '';
            const daysOfWeekEnglish = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
            const localizedDaysOfWeek = daysOfWeekBinaryArray.map((day, index) => {
                return day === 1 ? $translate.instant(('ADMINISTRATION_DEVIATION_DAYS_OF_WEEK_' + daysOfWeekEnglish[index].toUpperCase())) : null;
            }).filter(day => day !== null);
            return localizedDaysOfWeek.length ? localizedDaysOfWeek.join(', ') : '';
        }

        function getIntervalMinutesLocalizedString(deviationAlert) {
            if (!deviationAlert || !deviationAlert.intervalInMinutes || deviationAlert.recurrence == 1) return '';
            return String(deviationAlert.intervalInMinutes);
        }

        function getLocalizedDateString(date) {
            if (!date) return '';
            return moment(date).format('DD.MM.YYYY');
        }

        function getRecurrenceTypeLocalizedString(recurrenceTypeBinary) {
            if (!recurrenceTypeBinary && recurrenceTypeBinary != 0 && recurrenceTypeBinary != 1) return '';

            return deviationAlertRecurrenceTypes[Number(recurrenceTypeBinary)]
                .LocalizedName;
        }

        function getEmailParticipantsCount(emailAddresses) {
            if (!emailAddresses) return '0';
            const arr = emailAddresses.split(';');
            return arr.length;
        }

        function getStatusCheckboxByStatusId(statusId) {
            switch (statusId) {
                case deviationStatuses.active.Id:
                    return true;
                case deviationStatuses.inactive.Id:
                    return false;
                default:
                    return false;
            }
        }
    }
})();
