(function () {
    angular.module('UndergroundWebApp').factory('locationsService', locationsService);

    locationsService.$inject = [
        '$q',
        '$http',
        'qPool',
        '$rootScope'
    ];

    function locationsService(
        $q,
        $http,
        qPool,
        $rootScope
    ) {
        let locationService = {
            addLocation,
            getLocations,
            getLocation,
            getLocationStatistics,
            getLocationStatisticsByContainer,
            updateLocation,
            deleteLocation,
        };

        return locationService;

        function addLocation(location) {
            return $http.post('/api/locations', location)
                .then(result => result.data);
        }

        function getLocations(includeContainers, includeDeletedLocations = false, includeDeletedContainers = false) {
            includeContainers = !!includeContainers;

            let deferred = qPool.defer('getLocations', { includeContainers: includeContainers });       
            
            if (!deferred.hasCompleted() && deferred.isFirst) {
                $http.get('/api/locations?includeContainers=' + includeContainers).then(function (result) {
                    if (result && result.data) {
                        let activeLocations = result.data;
                        if (!includeDeletedLocations) {
                            activeLocations = result.data.filter(s => s.status !== 99);
                        }
    
                        if (!includeDeletedContainers) {
                            activeLocations.forEach(l => l.containers = l.containers.filter(c => c.status !== 99));
                        }

                        if (!$rootScope.isAdmin() && !$rootScope.isSuperAdmin()) {
                            activeLocations = activeLocations.filter(l => $rootScope.getMunicipalityAccess(l.municipalityCode));
                        }
    
                        deferred.resolve(activeLocations);
                    }
                    else {
                        deferred.reject();
                    }
                }).catch(function () {
                    deferred.reject();
                });
            }

            return deferred.promise;
        }

        function getLocation(locationId) {
            return $http.get('/api/locations/' + locationId)
                .then(result => result.data);
        }

        function getLocationStatistics(locationId, from, to) {
            return $http.get('/api/locations/' + locationId + '/statistics?from=' + from + '&to=' + to)
                .then(result => result.data);
        }

        function getLocationStatisticsByContainer(locationId, from, to) {
            return $http.get('/api/locations/' + locationId + '/statistics/by-container?from=' + from + '&to=' + to)
                .then(result => result.data);
        }

        function updateLocation(location) {
            return $http.put('/api/locations', location)
                .then(result => result.data);
        }

        function deleteLocation(locationId) {
            let deferred = $q.defer();

            $http.delete('/api/locations/' + locationId).then(function (result) {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
