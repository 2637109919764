(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ErrorStatusController', ErrorStatusController);

    ErrorStatusController.$inject = [
        '$scope',
        '$rootScope',
        'statusService',
        'defaultDateRangeOptions',
        'municipalityService',
        'sensorApiAreaService',
        'filterStateService',
        '$translate',
        'mapUtility'
    ];

    function ErrorStatusController(
        $scope,
        $rootScope,
        statusService,
        defaultDateRangeOptions,
        municipalityService,
        sensorApiAreaService,
        filterStateService,
        $translate,
        mapUtility
    ) {
        $scope.dateRange = {};

        $scope.loadStatuses = loadStatuses;
        $scope.exportToExcel = exportToExcel;
        $scope.exportAsPdf = exportAsPdf;

        $scope.selectedMunicipalities = [];

        $scope.selectedMunicipalyy = { code: '' };
        $scope.filterMunicipalitiesData = {
            municipalities: [],
            externalSystems: [],
        };

        $scope.municipalityfilter = filterStateService.getStateFromQueryString('filter', getMunicipalityEmptyFilter());

        $scope.municipalitiesDropdownOptions = {
            settings: {
                externalIdProp: '',
            },
            events: {
                onSelectionChanged: handleMunicipalityFilterChanged,
            },
        }

        $scope.dropdownMunicipalityTranslations = {
            checkAll: $translate.instant("G_CHECKALL"),
            uncheckAll: $translate.instant("G_UNCHECK_ALL"),
            buttonDefaultText: $translate.instant("REPORTS_ERROR_STATUS_CONTROLLER_MUNINCIPALITY_DDL_BUTTON_DEFAULT_TEXT"),
            dynamicButtonTextSuffix: $translate.instant("G_DYNAMIC_BTN_TEXT_SUFFIX")
        };



        $scope.dateRangeOptions = [
            {
                value: 'day',
                label: $translate.instant("REPORTS_ERROR_STATUS_CONTROLLER_DATE_RANGE_OPTIONS_LABEL"),
                setter: (date) => date.setDate(date.getDate() - 1),
            },
            ...defaultDateRangeOptions,
        ];

        $scope.selectedAreas = [];
        $scope.allExtendedStatuses = [];

        $scope.selectedArea = { code: '' };
        $scope.filterData = {
            areas: [],
            externalSystems: [],
        };

        $scope.filter = filterStateService.getStateFromQueryString('filter', getEmptyFilter());

        $scope.areasDropdownOptions = {
            settings: {
                externalIdProp: '',
            },
            events: {
                onSelectionChanged: handleAreaFilterChanged,
            },
        }

        $scope.dropdownTranslations = {
            checkAll: $translate.instant("G_CHECKALL"),
            uncheckAll: $translate.instant("G_UNCHECK_ALL"),
            buttonDefaultText: $translate.instant("REPORTS_ERROR_STATUS_CONTROLLER_DDL_BUTTON_DEFAULT_TEXT"),
            dynamicButtonTextSuffix: $translate.instant("G_DYNAMIC_BTN_TEXT_SUFFIX")
        };

        $scope.statusGridOptions = {
            dataSource: {
                transport: {
                    read: (e) => e.success([])
                },
                sort: { field: 'createdAt', dir: 'desc' },
                pageSize: 70,
            },
            sortable: true,
            filterable: true,
            resizable: true,
            noRecords: true,
            messages: {
                noRecords: $translate.instant('G_NO_DATA'),
            },
            scrollable: {
                virtual: true
            },
            excel: {
                allPages: true,
                fileName: `${$translate.instant("REPORTS_ERROR_STATUS_CONTROLLER_EXPORT_FILENAME")}.xlsx`,
            },
            pdf: {
                allPages: true,
                fileName: `${$translate.instant("REPORTS_ERROR_STATUS_CONTROLLER_EXPORT_FILENAME")}.pdf`,
            },
            columns: [
                {
                    field: 'createdAt',
                    title: $translate.instant("REPORTS_ERROR_STATUS_CONTROLLER_TABLE_HEADER_TIME"),
                    template: `#= kendo.toString(kendo.parseDate(createdAt), "${$translate.instant("G_DEFAULT_DATE_FORMAT")}") #`,
                    filterable: false,
                    width: 145,
                },
                {
                    field: 'locationName',
                    title: $translate.instant("REPORTS_ERROR_STATUS_CONTROLLER_TABLE_HEADER_LOCATION_NAME"),
                    template: '<a href="#= locationHref#">#= locationName#</>'
                },
                {
                    field: 'deviceExternalId',
                    title: $translate.instant("REPORTS_ERROR_STATUS_CONTROLLER_TABLE_HEADER_DEVICE_EXTERNAL_ID"),
                    width: 100,
                },
                {
                    field: 'containerNumber',
                    title: $translate.instant("REPORTS_ERROR_STATUS_CONTROLLER_TABLE_HEADER_CONTAINER_NUMBER"),
                    width: 100,
                },
                {
                    field: 'fractionName',
                    title: $translate.instant("REPORTS_ERROR_STATUS_CONTROLLER_TABLE_HEADER_FRACTION"),
                    width: 110,
                    filterable: {
                        multi: true,
                    },
                },
                {
                    field: 'error',
                    title: $translate.instant("REPORTS_ERROR_STATUS_CONTROLLER_TABLE_HEADER_ERROR")
                },
                {
                    field: 'statusMessage',
                    title: $translate.instant("REPORTS_ERROR_STATUS_CONTROLLER_TABLE_HEADER_STATUS_MESSAGE")
                },
            ],
        };

        initController();

        function initController(){
            loadAreas();
            loadMunicipalities();
        }

        function loadAreas() {
            showBusyIndicator();
            sensorApiAreaService.getAreas().then((areas) => {
                const activeAreas = areas.filter(area => area.isActive === true);
                activeAreas.sort((a,b) => a.description > b.description ? 1 : -1);
                
                updateFilters(activeAreas);
            }).finally(hideBusyIndicator);
        }

        function updateFilters(areas) {
            $scope.filterData.areas = _.unionBy(
                getAreasForFilter(areas),
                $scope.filter.areas,
                'id'
            );
            $scope.filterData.areas = _.sortBy($scope.filterData.areas, 'description');
        }

        function getAreasForFilter(areas) {
            if (!areas) {
                return [];
            }

            return  _.chain(areas)
                .filter((area) => !!area)
                .map((area) => ({
                    label: area.description,
                    id: area.id,
                    points: area.points,
                }))
                .value();
        }

        function getEmptyFilter() {
            return {
                areas: [],
                externalSystems: [],
                searchTerm: '',
                noPositionOnly: false,
            };
        }

        function handleAreaFilterChanged() {
            filterLog();
                try {
                    showBusyIndicator();

                    $scope.selectedAreas = $scope.filter.areas;
    
                    console.log("all: ", $scope.allExtendedStatuses);
                    const filteredExtendedStatuses = filterBasedOnArea($scope.allExtendedStatuses)

                    $scope.statusGrid.dataSource.data(filteredExtendedStatuses);
                    } catch (error) {
                    console.error(error);
                }
                finally {
                    hideBusyIndicator();
                };
        }

        let selectedDateRange = null;
        $scope.selectedDateRangeChanged = (dateRange) => selectedDateRange = dateRange;

        function loadStatuses() {
            showBusyIndicator();

            let getStatusesPromise;
            if (selectedDateRange.value !== 'day') {
                const from = moment($scope.dateRange.from).format('YYYY-MM-DD');
                const to = moment($scope.dateRange.to).format('YYYY-MM-DD');

                getStatusesPromise = statusService.getStatusesByDate(from, to, true, true);
            } else {
                const from = moment().subtract(24, 'hours').format('YYYY-MM-DDTHH:mm:ss');
                getStatusesPromise = statusService.getLatestStatuses(true, from, true);
            }

            getStatusesPromise.then(function (statuses) {
                const extendedStatuses = statuses
                    .filter(isUserMunicipality)
                    .map(extendStatuses);

                $scope.allExtendedStatuses = extendedStatuses;
                const filteredExtendedStatuses = filterBasedOnArea(extendedStatuses)

                $scope.statusGrid.dataSource.data(filteredExtendedStatuses);
            }).catch(function (error) {
                console.error(error);
            }).finally(function () {
                hideBusyIndicator();
            });
        }

        function filterBasedOnArea(extendedStatuses) {
            let filteredByArea;
            if ($scope.filter.areas.length != 0) {
                filteredByArea = extendedStatuses.filter( extendedStatus => isInSelectedAreas(extendedStatus));
            }
            else {
                filteredByArea = extendedStatuses;
            }

            return filteredByArea;
        }

        function isInSelectedAreas(extendedStatus) {
            return $scope.selectedAreas.some((area) => isInArea(area, extendedStatus));
        }

        function isInArea(area, extendedStatus) {
            if (!area || !extendedStatus?.container) {
                return false;
            }
            return mapUtility.isInArea(area, extendedStatus.container);
        }

        function isUserMunicipality(status) {
            return status
                && status.container
                && status.container.location
                && $rootScope.authData.authUser
                && $rootScope.authData.authUser.municipalities
                && $rootScope.authData.authUser.municipalities
                    .find(m => m.code === status.container.location.municipalityCode);
        }

        function extendStatuses(status) {
            const container = status
                && status.container;
            let locationHref = '';
            if (container && container.location) {
                locationHref = location.origin + '/main/location/' + container.location.id + '/info';
            }
            return {
                ...status,
                deviceExternalId: status
                    && status.device
                    && status.device.externalId,
                containerNumber: container
                    && container.containerNumber,
                locationName: container
                    && container.location
                    && container.location.name,
                fractionName: container
                    && container.fraction
                    && container.fraction.name,
                locationHref: locationHref,
            }
        }

        function showBusyIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'errorStatus',
                destination: '#statusGrid',
                message: $translate.instant('G_BUSY_INDICATOR'),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });
        }

        function hideBusyIndicator() {
            $rootScope.$broadcast('hideBusyIndicator', 'errorStatus');
        }

        function exportToExcel() {
            $scope.statusGrid.saveAsExcel();
        }

        function exportAsPdf() {
            $scope.statusGrid.saveAsPDF();
        }

        function loadMunicipalities() {
            showBusyIndicator();

            let request;
            if($rootScope.isAdmin() || $rootScope.isSuperAdmin()) {
                request = municipalityService.getMunicipalities();
            } else {
                if($rootScope.authData?.authUser?.municipalities?.length > 0) {
                    request = municipalityService.getMunicipalitiesByCode($rootScope.authData.authUser.municipalities.map(m => m.code))
                }
            }

            if (request) {
                request.then((municipalities) => {
                    const allMunicipalits = municipalities.map((m) => ({
                        label: m.name,
                        id: m.code,
                    }));
                    allMunicipalits.sort((a, b) => a.label > b.label ? 1 : -1);
                    updateMunicipalitiesFilters(allMunicipalits);
                }).finally(hideBusyIndicator);
            } else {
                hideBusyIndicator();
            }
            
        }

        function updateMunicipalitiesFilters(municipalities) {
            $scope.filterMunicipalitiesData.municipalities = _.unionBy(
                getMunicipalitiesForFilter(municipalities),
                $scope.municipalityfilter.municipalities,
                'id'
            );
            $scope.filterMunicipalitiesData.municipalities = _.sortBy($scope.filterMunicipalitiesData.municipalities, 'name');
        }

        function getMunicipalitiesForFilter(municipalities) {
            if (!municipalities) {
                return [];
            }

            return  _.chain(municipalities)
                .filter((municipality) => !!municipality)
                .map((municipality) => ({
                    label: municipality.label,
                    id: municipality.id,
                }))
                .value();
        }

        function getMunicipalityEmptyFilter() {
            return {
                municipalities: [],
                externalSystems: [],
                searchTerm: '',
                noPositionOnly: false,
            };
        }

        function filterLog() {
            const logByMunicipality = municipalitySelected($scope.allExtendedStatuses);

            try {
                showBusyIndicator();

                $scope.selectedAreas = $scope.filter.areas;

                console.log("all: ", $scope.allExtendedStatuses);
                //$scope.allExtendedStatuses
                const filteredExtendedStatuses = filterBasedOnArea(logByMunicipality)

                $scope.statusGrid.dataSource.data(filteredExtendedStatuses);
                } catch (error) {
                console.error(error);
            }
            finally {
                hideBusyIndicator();
            };
        }

        function handleMunicipalityFilterChanged() {
            filterLog();
        }

        function municipalitySelected(logs) {
            let filteredLogs = [];

            filteredLogs = filterBasedOnMunicipality(logs);
            return filteredLogs;
        }
        function filterBasedOnMunicipality(allLogs) {
            let filteredByMunicipality;
            if ($scope.municipalityfilter.municipalities.length != 0) {
                filteredByMunicipality = allLogs.filter( log => isInSelectedMunicipalities(log));
            }
            else {
                filteredByMunicipality = allLogs;
            }

            return filteredByMunicipality;
        }

        function isInSelectedMunicipalities(log) {
            for(var i = 0; i < $scope.municipalityfilter.municipalities.length; i++) {
                if ($scope.municipalityfilter.municipalities[i].id ===  log.container.location.municipalityCode)
                {
                    return true;
                }
            }
            return false;
        }

        function setLocationRef(log) {
            let locationHref = '';
            if (log && log.container.locationId) {
                locationHref = location.origin + '/main/location/' + log.container.locationId + '/info';
            }
            log.locationHref = locationHref;
        }

    }
})();
