/**
 * This file contains all the environment specific configurations.
 */
(function () {
    var undergroundWebApp = angular.module('UndergroundWebApp');

    undergroundWebApp.config(['$localForageProvider', function ($localForageProvider) {
        $localForageProvider.config({
            driver: localforage.INDEXEDDB
        });
    }]);

    undergroundWebApp.constant('environmentConfig', {
        clientSideCacheEnabled: false,
        statusCacheMaxAge: 60 * 60 * 1000,
    });

    undergroundWebApp.constant('s2wExternalSystemId', '1851D315-6413-400E-998A-797EF5CAE143');

    undergroundWebApp.constant('serviceUrls', {
        geocodeingServiceUrl: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/',
        reenHubContainer: 'https://hub.reen.com/status/container/',
        mrFillContainer: 'https://smartcitymanager.com/platform/containers/ContainerDetails/',
        reenHubLocation: 'https://hub.reen.com/status/site/',
        nrcContainer: 'https://app.intellicont.no',
        s2wContainer: 'https://app.intellicont.no',
        auth0Api: 'https://fieldata.eu.auth0.com/'
    });

    undergroundWebApp.constant('authConfig', {
        domain: 'fieldata.eu.auth0.com',
        clientId: 'vjTMj7vKMHU3EYqpOUYP7rl5Z2PymMEx',
        apiIdentifier: 'http://localhost:5000/api'
    });
})();
