(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('LocationsController', LocationsController);

    LocationsController.$inject = [
        '$scope',
        '$state',
        '$stateParams',
        '$rootScope',
        '$timeout',
        '$kWindow',
        'locationsService',
        'formTypes',
        'fractionsUtility',
        'externalSystemUtility',
        'mapService',
        'selectDisplayedDevices',
        'locationUtility',
        'filterStateService',
        's2wExternalSystemId',
        '$translate',
        'nextEmptyingFilterUtils',
        'sensorApiAreaService',
        'mapUtility',
        'deviceUtility',
    ];

    function LocationsController(
        $scope,
        $state,
        $stateParams,
        $rootScope,
        $timeout,
        $kWindow,
        locationsService,
        formTypes,
        fractionsUtility,
        externalSystemUtility,
        mapService,
        selectDisplayedDevices,
        locationUtility,
        filterStateService,
        s2wExternalSystemId,
        $translate,
        nextEmptyingFilterUtils,
        sensorApiAreaService,
        mapUtility,
        deviceUtility,
    ) {
        let sortedLocations = [];
        let filteredLocations = [];
        let locationLocalized = $translate.instant('LOCATION_LOCATION');

        $scope.isAddLocationVisible = false;

        const locationModalOptions = {
            modal: true,
            movable: true,
            title: locationLocalized,
            resizable: true,
            height: 680,
            width: 400,
            visible: false
        };

        const persistenceRootKey = 'FILTER_VISIBILITY_LIST';

        const defaultFilterVisibilityList = {
        area: true,
        fraction: true,
        externalSystem: true,
        nextEmptyingDate: true,
        batteryVoltage: true,
        };

        $scope.exportToExcel = exportToExcel;
        $scope.exportAsPdf = exportAsPdf;
        $scope.openNewLocation = openNewLocation;
        $scope.searchLocations = searchLocations;
        $scope.onRowClicked = onRowClicked;

        $scope.isExternalSystemFilterSelected = isExternalSystemFilterSelected;
        $scope.isBatteryVoltageFilterSelected = isBatteryVoltageFilterSelected;
        $scope.isAreaFilterSelected = isAreaFilterSelected;
        $scope.isFractionFilterSelected = isFractionFilterSelected;
        $scope.isNextEmptyingDateFilterSelected = isNextEmptyingDateFilterSelected;
        $scope.toggleFilterBox = toggleFilterBox;
        $scope.toggleExternalSystemFilter = toggleExternalSystemFilter;
        $scope.toggleBatteryVoltageFilter = toggleBatteryVoltageFilter;
        $scope.toggleFractionFilter = toggleFractionFilter;
        $scope.toggleAreaFilter = toggleAreaFilter;
        $scope.toggleNextEmptyingDateFilter = toggleNextEmptyingDateFilter;
        $scope.clearFilters = clearFilters;
        $scope.getExtSystemPlaceholderColor = externalSystemUtility.getExtSystemPlaceholderColor;
        $scope.toggleFractionFiltersCollapse = toggleFractionFiltersCollapse;
        $scope.toggleAreaFiltersCollapse = toggleAreaFiltersCollapse;
        $scope.toggleBatteryVoltageFiltersCollapse = toggleBatteryVoltageFiltersCollapse;
        $scope.toggleExternalSystemFiltersCollapse = toggleExternalSystemFiltersCollapse;
        $scope.toggleNextEmptyingDateFiltersCollapse = toggleNextEmptyingDateFiltersCollapse;
        $scope.filterVisibilityList = null;

        $scope.filterData = {
            fractions: [],
            areas: [],
            externalSystems: [],
            nextEmptyingDates: [],
            batteryVoltages: []
        };

        $scope.filter = filterStateService.getStateFromQueryString('filter', getEmptyFilter());
        $scope.filterBoxVisible = !isAdvancedFiltersEmptpy();

        $scope.displayedLocationAmount = 0;
        $scope.allLocationAmount= 0;

        $timeout(250).then(initController);

        function initController() {
            $scope.appInit().then(() => {
                $scope.isAddLocationVisible = $rootScope.isVisible('CORE', { Area: 'Location', AccessType: 'W' })
                    && $scope.masterMode;

                $scope.locationOverviewGridOptions = {
                    dataSource: new kendo.data.DataSource({
                        transport: {
                            read: readLocations
                        },
                        pageSize: 69,
                        sort: { field: 'name', dir: 'asc' }
                    }),
                    excel: {
                        allPages: true,
                        fileName: `${locationLocalized}.xlsx`,
                    },
                    pdf: {
                        allPages: true,
                        fileName: `${locationLocalized}.pdf`,
                        multiPage: true,
                        repeatHeaders: true,
                        paperSize: "A4",
                    },
                    columns: [
                        {
                            title: $translate.instant('LOCATION_TABLE_HEADER_LOCATION'),
                            template: getLocationTemplate,
                            attributes: {
                                'class': 'navigable'
                            }
                        },
                        {
                            title: $translate.instant('LOCATION_TABLE_HEADER_FRACTION'),
                            template: getFractionsTemplate,
                            width: '190px',
                            attributes: {
                                'class': 'navigable'
                            }
                        }
                    ],

                    sortable: false,
                    selectable: false,
                    resizable: true,
                    click: onRowClicked,

                    scrollable: {
                        virtual: true
                    },
                    excelExport: excelExport
                };
                $scope.filterVisibilityList = loadFilterVisibilityList();
            });

            $('#locationsGrid').on('click', 'td.navigable', onRowClicked);
        }

        async function loadAreas() {
            try {
                showLocationLoadBusyIndicator();
                const areas = await sensorApiAreaService.getAreas();
                const activeAreas = areas.filter(area => area.isActive === true);
                activeAreas.sort((a, b) => a.description > b.description ? 1 : -1);
                return activeAreas;
            } finally {
                $rootScope.$broadcast('hideBusyIndicator', 'locationIndicator');
            }
        }

        function getLocationTemplate(location) {
            const detailText = getLocationAddress(location);

            return `<div class="location-data"><strong>${kendo.htmlEncode(location.name)}</strong><br>${kendo.htmlEncode(detailText)}</div>`;
        }

        function getLocationAddress(location) {
            const postalCodeAndCity = [location.postalCode, location.city].filter(Boolean).join(' ');
            const detailText = [location.address, postalCodeAndCity, location.municipalityCode].filter(Boolean).join(', ');

            return detailText;
        }

        function getFractionsTemplate(location) {
            const fractions = _.chain(location.containers)
                .map((container) => {
                    container.fraction.fill = container.fill;
                    return container.fraction
                })
                .groupBy((fraction) => fractionsUtility.getFractionIcon(fraction))
                .pickBy((_fractions, icon) => !!icon)
                .map((fractions, icon) => formatFraction(icon, fractions))
                .value()
                .sort()
                .join('');
            return `<span class="location-fractions-list">${fractions}</span>`;
        }

        function formatFraction(fractionIcon, fractions) {
            let countHtml = '';
            let fillPercent;
            if (fractions.length > 1) {
                countHtml = `<span>${fractions.length}</span>`;
                fractions.forEach(frac => {
                    if ((frac.fill || frac.fill == 0) && !fillPercent) {
                        fillPercent = frac.fill;
                    } else if (frac.fill || frac.fill == 0) {
                        fillPercent += frac.fill;
                    }
                });
                fillPercent = fillPercent / fractions.length;
                fillPercent = Math.round(fillPercent);
            } else if (fractions.length === 1) {
                fillPercent = fractions[0].fill;
            }
            var title = _.chain(fractions)
                .map((fraction) => fraction.name)
                .uniq()
                .value()
                .join(', ');
            return `
                <div class="location-fraction-icon">
                    <img ng-src="${fractionIcon}" title="${title}">
                    ${countHtml}
                    <span ng-if="${fillPercent || fillPercent == 0}" class="container-fill-percent">${fillPercent}%</span> 
                </div>`;
                }

        function openNewLocation() {
            var windowInstance = $kWindow.open({
                options: locationModalOptions,
                templateUrl: 'app/location/location-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'LocationDetailsModalController',
                resolve: {
                    currentFormType: () => formTypes.add,
                    location: () => null
                }
            });

            windowInstance.result.then(function () {
                $('#locationsGrid').getKendoGrid().dataSource.read();
            });
        }

        function readLocations(e) {
            showLocationLoadBusyIndicator();

            locationsService.getLocations(true, false, false, true).then(async function (locations) {
                sortedLocations = locations.sort(function (a, b) {
                    return a.address.toLowerCase() < b.address.toLowerCase() ? -1 : 1;
                });

                filteredLocations = [];
                await updateFilters(sortedLocations);
                searchLocations();
                setFilterCountIndicatorValues(sortedLocations, filteredLocations)
                e.success(filteredLocations);
            }, function () {
                e.success([]);
            })
            .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'locationIndicator'));
        }

        function searchLocations() {
            filterStateService.storeStateInQueryString('filter', getCurrentFilter());
            var newFilteredLocations = isFiltersEmpty()
                ? sortedLocations
                : sortedLocations.filter(isLocationMatching);
            if (!arrayEquals(filteredLocations, newFilteredLocations)) {
                filteredLocations = newFilteredLocations;
                $('#locationsGrid').getKendoGrid().dataSource.data(filteredLocations);

                if (!isFiltersEmpty()) {
                    var deviceExternalIds = _.chain(filteredLocations)
                        .flatMap((location) => location.containers)
                        .map((container) => {
                            let relatedDevice = undefined;
                            const s2wDevice = container.devices.find((device) =>
                                device.externalSystem
                                && device.externalSystem.id.toUpperCase() === s2wExternalSystemId
                            );

                            if (s2wDevice) {
                                relatedDevice = s2wDevice;
                            } else {
                                if (container.devices.length > 0) {
                                    relatedDevice = container.devices[0];
                                }
                            }

                            return relatedDevice && relatedDevice.externalId;
                        })
                        .filter(externalId => !!externalId)
                        .value();
                    const locationIds = filteredLocations.map((location) => location.id);

                    mapService.getLayer('containerLayer').setLocationFilter(deviceExternalIds);
                    mapService.getLayer('locationLayer').setLocationFilter(locationIds);
                    mapService.getLayer('fillDateLayer').setLocationFilter(deviceExternalIds);
                    mapService.getLayer('batteryStatusLayer').setLocationFilter(deviceExternalIds);
                } else {
                    mapService.getLayer('containerLayer').setLocationFilter();
                    mapService.getLayer('locationLayer').setLocationFilter();
                    mapService.getLayer('fillDateLayer').setLocationFilter();
                    mapService.getLayer('batteryStatusLayer').setLocationFilter();
                }
                setFilterCountIndicatorValues(sortedLocations, filteredLocations)
                setTimeout(() => {
                    const clusterLayer = mapService.getLayer('containerLayer');
                    if (clusterLayer) {
                        mapService.zoomToContainers(clusterLayer);
                    }
                }, 100);
            }
        }

        function toggleAreaLayerBasedOnFilter(isAreaFilterEmpty, areaLayerVisible){
            if (isAreaFilterEmpty && areaLayerVisible){
                mapService.disableLayerVisibility('areaLayer');
            } else if (!isAreaFilterEmpty && !areaLayerVisible){
                mapService.enableLayerVisibility('areaLayer');
            }

        }

        function isLocationMatching(location) {
            return searchTermMatching(location)
                && fractionMatching(location)
                && areasMatching(location)
                && nextEmptyingFilterUtils.nextEmptyingDateMatching(location, $scope.filter.nextEmptyingDates)
                && externalSystemMatching(location)
                && batteryVoltageMatching(location)                
                && noPositionFilterMatching(location);
        }

        function searchTermMatching(location) {
            if (!$scope.filter.searchTerm) {
                return true;
            }

            return isStringMatching(location.name, $scope.filter.searchTerm)
                || isStringMatching(location.address, $scope.filter.searchTerm)
                || isStringMatching(location.postalCode, $scope.filter.searchTerm)
                || isStringMatching(location.city, $scope.filter.searchTerm)
                || isStringMatching(location.municipalityCode, $scope.filter.searchTerm)
                || location.containers
                && location.containers.some(container =>
                    container.devices
                    && selectDisplayedDevices(container.devices).some((device) =>
                        isStringMatching(device.externalId, $scope.filter.searchTerm)
                    )
                );
        }

        function fractionMatching(location) {
            if ($scope.filter.fractions.length === 0) {
                return true;
            }

            if (!location.containers || location.containers.length === 0) {
                return false;
            }

            return $scope.filter.fractions.some((filter) =>
                location.containers.some((container) =>
                    container.fraction
                    && filter.id === container.fraction.id
                )
            );
        }

        function areasMatching(location) {
            if ($scope.filter.areas.length === 0) {
                return true;
            }
            return isInSelectedAreas(location);
        }

        function isInSelectedAreas(location) {
            return $scope.filter.areas.some((area) => mapUtility.isInArea(area, location));
        }

        function externalSystemMatching(location) {
            if ($scope.filter.externalSystems.length === 0) {
                return true;
            }

            if (!location.containers || location.containers.length === 0) {
                return false;
            }

            return location.containers.some((container) =>
                container.devices
                && container.devices.filter((device) => device.isActive).length > 0
                && $scope.filter.externalSystems.every((exSys) =>
                    container.devices
                        .filter((device) => device.isActive)
                        .some((device) => device.externalSystemId === exSys.id)
                )
            );
        }

        function batteryVoltageMatching(location) {
            if ($scope.filter.batteryVoltages.length === 0) {
                return true;
            }

            if (!location.containers || location.containers.length === 0) {
                return false;
            }

            return location.containers.some((container) =>
                container.devices
                && container.devices.filter((device) => device.isActive).length > 0
                && $scope.filter.batteryVoltages.some((batteryVoltage) =>
                    container.voltage > batteryVoltage.min && container.voltage <= batteryVoltage.max
                )
            );
        }

        function isStringMatching(str, term) {
            if (str) {
                return str.toString().toLowerCase().indexOf(term.toLowerCase()) !== -1;
            }
            return false;
        }

        function noPositionFilterMatching(location) {
            return !$scope.filter.noPositionOnly
                || !locationUtility.isValidLocation(location.latitude, location.longitude);
        }

        function arrayEquals(array1, array2) {
            return array1 === array2
                || array1.length === array2.length
                && array1.every(function (value, index) { return value === array2[index]; });
        }

        function onRowClicked() {
            var row = $(this).closest('tr');
            var location = $('#locationsGrid').getKendoGrid().dataItem(row);
            $state.go('main.locationDetails.info', {
                locationId: location.id,
                locationsFilter: $stateParams.filter,
            });
        }

        async function updateFilters(locations) {
            const fractionsForFilter = getFractionsForFilter(locations);
            const activeAreas = await loadAreas();
            const areasForFilter = getAreasForFilter(activeAreas);
            const nextEmptyingDatesForFilter = nextEmptyingFilterUtils.getNextEmptyingDatesForFilter(locations);
            const voltageRangesForFilter = getBatteryVoltageRangesForFilter(locations);
            
            $scope.filterData.fractions = _.unionBy(
                fractionsForFilter,
                $scope.filter.fractions,
                'id'
            );
            $scope.filterData.fractions = _.sortBy($scope.filterData.fractions, 'label');

            $scope.filterData.areas = _.unionBy(
                areasForFilter,
                $scope.filter.areas,
                'id'
            );
            $scope.filterData.areas = _.sortBy($scope.filterData.areas, 'description');

            $scope.filterData.nextEmptyingDates = nextEmptyingDatesForFilter;
            $scope.filterData.batteryVoltages = voltageRangesForFilter;


            $scope.filterData.externalSystems = _.unionBy(
                getExternalSystemsForFilter(locations),
                $scope.filter.externalSystems,
                'id'
            );
        }

        function toggleFilterBox() {
            $scope.filterBoxVisible = !$scope.filterBoxVisible;
            setTimeout(() => {
                $('#locationsGrid').getKendoGrid().resize();
            }, 0);
        }

        function toggleFractionFiltersCollapse() {
            $scope.filterVisibilityList.fraction = !$scope.filterVisibilityList.fraction;
            saveFilterVisibilityList();
        }

        function toggleAreaFiltersCollapse() {
            $scope.filterVisibilityList.area = !$scope.filterVisibilityList.area;
            saveFilterVisibilityList();
        }

        function toggleBatteryVoltageFiltersCollapse() {
            $scope.filterVisibilityList.batteryVoltage = !$scope.filterVisibilityList.batteryVoltage;
            saveFilterVisibilityList();
        }

        function toggleExternalSystemFiltersCollapse() {
            $scope.filterVisibilityList.externalSystem = !$scope.filterVisibilityList.externalSystem;
            saveFilterVisibilityList();
        }

        function toggleNextEmptyingDateFiltersCollapse() {
            $scope.filterVisibilityList.nextEmptyingDate = !$scope.filterVisibilityList.nextEmptyingDate;
            saveFilterVisibilityList();
        }

        function toggleExternalSystemFilter(externalSystem) {
            var index = _.findIndex($scope.filter.externalSystems, { id: externalSystem.id });
            if (index === -1) {
                $scope.filter.externalSystems.push(externalSystem);
            } else {
                $scope.filter.externalSystems.splice(index, 1);
            }
            try {
                searchLocations();
            } catch (error) {
                console.error(error);
            }
        }

        function toggleBatteryVoltageFilter(batteryVoltage) {
            var index = _.findIndex($scope.filter.batteryVoltages, { id: batteryVoltage.id });
            if (index === -1) {
                $scope.filter.batteryVoltages.push(batteryVoltage);
            } else {
                $scope.filter.batteryVoltages.splice(index, 1);
            }
            try {
                searchLocations();
            } catch (error) {
                console.error(error);
            }
        }

        function toggleFractionFilter(fraction) {
            var index = _.findIndex($scope.filter.fractions, { id: fraction.id });
            if (index === -1) {
                $scope.filter.fractions.push(fraction);
            } else {
                $scope.filter.fractions.splice(index, 1);
            }
            try {
                searchLocations();
            } catch (error) {
                console.error(error);
            }
        }

        function toggleNextEmptyingDateFilter(nextEmptyingDate) {
            var index = _.findIndex($scope.filter.nextEmptyingDates, { id: nextEmptyingDate.id });
            if (index === -1) {
                $scope.filter.nextEmptyingDates.push(nextEmptyingDate);
            } else {
                $scope.filter.nextEmptyingDates.splice(index, 1);
            }
            try {
                searchLocations();
            } catch (error) {
                console.error(error);
            }
        }

        function isAreaFilterEmpty() {
            return $scope.filter.areas.length === 0;
        }

        function toggleAreaFilter(area) {
            var index = _.findIndex($scope.filter.areas, { id: area.id });
            if (index === -1) {
                $scope.filter.areas.push(area);
            } else {
                $scope.filter.areas.splice(index, 1);
            }
            try {
                const areaLayerVisible = mapService.getLayer('areaLayer').visible;
                toggleAreaLayerBasedOnFilter(isAreaFilterEmpty(), areaLayerVisible);
                searchLocations();
            } catch (error) {
                console.error(error);
            }
        }


        function isExternalSystemFilterSelected(externalSystem) {
            return !!_.find($scope.filter.externalSystems, { id: externalSystem.id });
        }

        function isBatteryVoltageFilterSelected(batteryVoltage) {
            return !!_.find($scope.filter.batteryVoltages, { id: batteryVoltage.id });
        }

        function isAreaFilterSelected(area) {
            return !!_.find($scope.filter.areas, { id: area.id });
        }

        function isFractionFilterSelected(fraction) {
            return !!_.find($scope.filter.fractions, { id: fraction.id });
        }

        function isNextEmptyingDateFilterSelected(nextEmptyingDate) {
            return !!_.find($scope.filter.nextEmptyingDates, { id: nextEmptyingDate.id });
        }

        function clearFilters() {
            $scope.filter = getEmptyFilter();
            searchLocations();
        }

        //Private functions
        function extendLocation(location) {
            if (location && location.containers) {
                location.containers = location.containers.filter(c => c.status !== 99);
            }
        }

        function showLocationLoadBusyIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationIndicator',
                destination: '#locations-view',
                message: $translate.instant("G_BUSY_INDICATOR"),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });
        }

        //Private functions - Filtering
        function getFractionsForFilter(locations) {
            if (!locations) {
                return [];
            }

            return _.chain(locations)
                .flatMap((location) => location.containers)
                .uniqBy((container) => container.fraction && container.fraction.id)
                .map((container) => container.fraction)
                .filter((fraction) => !!fraction)
                .map((fraction) => ({
                    label: fraction.name,
                    id: fraction.id,
                }))
                .value();
        }

        function getAreasForFilter(areas) {
            if (!areas) {
                return [];
            }

            return _.chain(areas)
                .filter((area) => !!area)
                .map((area) => ({
                    label: area.description,
                    id: area.id,
                    points: area.points,
                }))
                .value();
        }

        function getExternalSystemsForFilter(locations) {
            if (!locations) {
                return [];
            }

            return _.chain(locations)
                .flatMap((location) => location.containers)
                .flatMap((container) => selectDisplayedDevices(container.devices)
                    .map((device) => device.externalSystem)
                )
                .uniqBy((externalSystem) =>
                    externalSystem
                    && externalSystem.id
                )
                .filter((externalSystem) => externalSystem)
                .map((externalSystem) => ({
                    id: externalSystem.id,
                    name: externalSystem.name,
                    img: externalSystemUtility.getExternalSystemIcon(externalSystem),
                    selected: false,
                }))
                .value();
        }
        function getBatteryVoltageRangesForFilter(locations) {
            if (!locations) {
                return [];
            }

            const batteryVoltageRanges = deviceUtility.getDeviceVoltageRanges();

            return batteryVoltageRanges.map((batteryVoltageRange) => ({
                id: batteryVoltageRange.id,
                label: `${batteryVoltageRange.min} - ${batteryVoltageRange.max} V`,
                min: batteryVoltageRange.min,
                max: batteryVoltageRange.max
            }));
        }
                
        function getEmptyFilter() {
            return {
                fractions: [],
                areas: [],
                nextEmptyingDates: [],
                externalSystems: [],
                batteryVoltages: [],
                searchTerm: '',
                noPositionOnly: false,
            };
        }

        function getCurrentFilter() {
            return isFiltersEmpty()
                ? null
                : $scope.filter;
        }

        function isFiltersEmpty() {
            return $scope.filter.searchTerm === ''
                && isAdvancedFiltersEmptpy();
        }

        function isAdvancedFiltersEmptpy(params) {
            return $scope.filter.fractions.length === 0
                && $scope.filter.nextEmptyingDates.length === 0
                && $scope.filter.areas.length === 0
                && $scope.filter.externalSystems.length === 0
                && $scope.filter.batteryVoltages.length === 0
                && !$scope.filter.noPositionOnly;
        }

        function exportToExcel() {
            $scope.locationOverviewGrid.saveAsExcel();
        }

        function exportAsPdf() {
            $scope.locationOverviewGrid.saveAsPDF();
        }

        function excelExport(e) {
            var sheet = e.workbook.sheets[0];

            var headers = [{
                value: $translate.instant("LOCATION_TABLE_HEADER_LOCATION"),
                bold: true
            }, {
                value: $translate.instant("LOCATION_TABLE_HEADER_ADDRESS"),
                bold: true
            }, {
                value: $translate.instant("LOCATION_TABLE_HEADER_FRACTION"),
                bold: true
            }];

            sheet.rows.splice(0, 0, { cells: headers, type: 'header' });

            for (var i = 1; i < sheet.rows.length; i++) {
                var row = sheet.rows[i];
                row.cells = [
                    {
                        value: e.data[i - 1].name
                    },
                    {
                        value: getLocationAddress(e.data[i - 1])
                    },
                    {
                        value: _.chain(e.data[i - 1].containers)
                            .countBy((container) => container.fraction.name)
                            .toPairs()
                            .map(x => formatFractionAndCount(x[0], x[1]))
                            .value()
                            .sort()
                            .join(', ')
                    }
                ];
            }
        }

        function formatFractionAndCount(fraction, count) {
            if (count === 1) {
                return fraction;
            }

            return `${fraction} (${count})`;
        }

        function saveFilterVisibilityList() {
            window.localStorage.setItem(persistenceRootKey, JSON.stringify($scope.filterVisibilityList));
        }

        function loadFilterVisibilityList() {
            return JSON.parse(window.localStorage.getItem(persistenceRootKey))
                || defaultFilterVisibilityList;
        }

        function setFilterCountIndicatorValues(sortedLocations, filteredLocations) {
            $scope.displayedLocationAmount = filteredLocations.length;
            $scope.allLocationAmount= sortedLocations.length;
        }

        $rootScope.$on('panelsResized', function () {
            var elements = document.getElementById('locations-view');
            if (elements) {
                if (elements.offsetWidth < 814) {
                    elements.classList.add('locations-menu-view-active');
                } else {
                    elements.classList.remove('locations-menu-view-active');
                }
            }
        });
    }
})();
