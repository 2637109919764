angular.module('UndergroundWebApp').directive('emptyingDatePopup', ['availableLanguages', '$rootScope', function(availableLanguages, $rootScope) {
    'use strict';

    return {
        restrict: 'AE',
        replace: true,
        templateUrl: 'app/shared/popups/emptying-date-popup.html',
        link: function (scope, element, attributes) {
            scope.$on('showEmptyingDatePopup', function (event, model) {
                const isoCode = $rootScope.getCurrentIsoLanguageCode();

                const date = new Date(model.date).toLocaleDateString(isoCode)

                element.css('left', model.left);
                element.css('top', model.top);

                element.find('.emptying-date-id').text(model.id);
                element.find('.emptying-date-date').text(date);

                element.show();
            });

            scope.$on('hideEmptyingDatePopup', function () {
                element.hide();
            });
        }
    }
}]);
