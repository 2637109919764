angular.module('UndergroundWebApp').factory('fractionsUtility', ['$rootScope', function ($rootScope) {
    function getFractionNormalizedName(fraction) {
        if (!fraction || !fraction.name) {
            return '';
        }
        switch (fraction.name.toLowerCase()) {
            case 'bio-næring':
            case 'bio':
                return 'matavfall';
            case 'plast':
            case 'plast-næring':
                return 'plastemballasje';
            case 'glass/metall':
                return 'glassemballasje';
            case 'restavfall':
            case 'rest-vask':
            case 'hytter rest':
            case 'rest-næring':
                return 'restavfall';
            case 'papir':
            case 'hytter papir':
            case 'papir-næring':
                return 'papir';
            case 'tekstil':
                return 'tekstil';
            case 'hageavfall' :
                return 'hageavfall';
            default:
                return '';
        }
    }

    function getFractionIcon(fraction) {
        if (!fraction || !fraction.name) {
            return '';
        }

        if (fraction.standardFractionId) {
            let standardFraction = $rootScope.standardFractions.find(f => f.id === fraction.standardFractionId);
            if (standardFraction) {
                return standardFraction.imageUrl;
            }
        }

        var fractionName = getFractionNormalizedName(fraction);
        if (!fractionName) {
            return '';
        }

        return '/img/icons/fraction-icons/' + fractionName + '.png';
    };

    return {
        getFractionIcon,
        getFractionNormalizedName
    };
}]);

