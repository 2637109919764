(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('areaLayerFactory', areaLayerFactory);

    areaLayerFactory.$inject = [
        '$q',
        '$rootScope',
        'authService',
        'esriLoader',
        'sensorApiAreaService',
        'mapUtility',
        'mapService',
    ];

    function areaLayerFactory(
        $q,
        $rootScope,
        authService,
        esriLoader,
        sensorApiAreaService,
        mapUtility,
        mapService,
    ) {
        var readyDeferred = $q.defer();

        var areaLayerFactory = {
            createLayerOnAdd: true,

            ready: () => readyDeferred.promise,
        };

        esriLoader.require([
            'dojo/_base/declare',
            'esri/Graphic',
            'esri/geometry/Point',
            'esri/layers/GraphicsLayer',
            'esri/symbols/SimpleLineSymbol',
            'esri/symbols/SimpleFillSymbol',
            'esri/geometry/Polygon',
            'esri/geometry/SpatialReference',
            'esri/symbols/Font',
            'esri/Color',
            'esri/symbols/TextSymbol',
        ], function (
            declare,
            Graphic,
            Point,
            GraphicsLayer,
            SimpleLineSymbol,
            SimpleFillSymbol,
            Polygon,
            SpatialReference,
            Font,
            Color,
            TextSymbol,
        ) {
            const areaLineStyle = {
                style: SimpleLineSymbol.STYLE_SOLID,
                color: [227, 139, 79, 0.8],
                width: 3
            }

            const areaFillColor = [255, 255, 127, 0.025];

            const selectedLineStyle = {
                style: SimpleLineSymbol.STYLE_SOLID,
                color: [0, 0, 178, 0.8],
                width: 3
            }

            const selectionFillColor = [100, 100, 255, 0.3];

            const selectionFill = new SimpleFillSymbol(
                SimpleFillSymbol.STYLE_SOLID,
                new SimpleLineSymbol(selectedLineStyle.style, selectedLineStyle.color, selectedLineStyle.width), selectionFillColor
            );

            const areaFill = new SimpleFillSymbol(
                SimpleFillSymbol.STYLE_SOLID,
                new SimpleLineSymbol(areaLineStyle.style, areaLineStyle.color, areaLineStyle.width), areaFillColor
            );

            areaLayerFactory.createLayer = () => new AreaLayer({});

            const AreaLayer = declare([GraphicsLayer], {
                constructor: function () {
                    this.name = 'areaLayer';
                    this.zIndex = 2;
                    this.textVisible = false;
                    this.textZoomTreshold = 9;
                    this.loadData();

                    $rootScope.$on('showAreaLayer', () => {
                        this.visible = true
                        $rootScope.$broadcast('layerVisibilityChanged', {
                            [this.name]: this.visible,
                        });
                    });
                },

                toggleVisibility: function () {
                    this.visible = !this.visible;
                },

                onZoomFinished: function (currentZoomLevel, mapView) {
                    var newTextVisibility = currentZoomLevel >= this.textZoomTreshold;
                    if (newTextVisibility === this.textVisible) return;

                    this.textVisible = newTextVisibility;
                    if (this.textVisible) {
                        this._addTextGraphics();
                    } else {
                        this._removeTextGraphics();
                    }
                },
                
                loadData: function () {
                    return $rootScope.appInit().then(() => {
                        return authService.getAccessRights().then(() => (
                            $rootScope.isVisible('CORE', { Area: 'Area', AccessType: 'R' })
                                ? sensorApiAreaService.getAreas()
                                : $q.resolve([])
                        )).then((areas) => {
                            const areaGraphics = areas.filter((area) => area
                                && area.isActive
                                && area.points
                                && area.points.length > 2
                            ).map((area) => createAreaPolygon(area, areaFill));

                            this.removeAll();
                            this.addMany(areaGraphics);
                            if (this.textVisible) {
                                this._addTextGraphics();
                            }

                            if (this.selectedAreaId) {
                                this.selectArea(this.selectedAreaId);
                            }
                        });
                    });
                },

                drawTemporaryArea: function (points) {
                    this.clearTemporaryArea();

                    const polygonGraphic = createPolygon(points, selectionFill, { isTemporary: true })
                    this.add(polygonGraphic);
                },

                clearTemporaryArea: function () {
                    const tempArea = this.graphics
                        .find((graphic) => graphic.attributes.isTemporary);

                    if (tempArea) {
                        this.remove(tempArea);
                    }
                },

                selectArea: function (id, zoomTo = true) {
                    if (
                        id === this.selectedAreaId
                        && !!this.graphics.find(g => g.attributes.isSelected)
                    ) {
                        return;
                    }

                    this.clearSelectedArea();
                    this.selectedAreaId = id;

                    const graphicToSelect = this.graphics.find(a => a.attributes.id === id);
                    if (!graphicToSelect) return;

                    const graphic = this.graphics.find(g => g.attributes.id === id);

                    const newGraphic = graphic.clone();
                    newGraphic.symbol = selectionFill;
                    newGraphic.attributes.isSelected = true;
                    this.add(newGraphic);

                    if (zoomTo) {
                        mapService.zoomToGraphics(newGraphic, 1.6);
                    }
                },

                clearSelectedArea: function () {
                    if (!this.selectedAreaId) return;

                    const graphic = this.graphics.find(g => g.attributes.isSelected);
                    this.remove(graphic);
                    this.selectedAreaId = null;
                },

                _removeTextGraphics: function () {
                    const textGraphics = this.graphics
                        .filter(g => g.attributes.type === 'text');

                    this.removeMany(textGraphics);
                },

                _addTextGraphics: function () {
                    const textGraphics = this.graphics
                        .filter(g => g.attributes.type === 'area')
                        .map(createAreaTextGraphic);

                    this.addMany(textGraphics);
                },
            });

            function isValidArea(graphic) {
                return graphic.attributes
                    && graphic.attributes.type === 'area'
                    && graphic.attributes.id
                    && !graphic.attributes.isTemporary;
            }

            function createAreaPolygon(area, fillSymbol) {

                var points = area.points
                    .sort((a, b) => a.order - b.order)
                    .map((point) => {                     

                        let coords = mapUtility.projectCoordinates(point.longitude, point.latitude);
                        return [coords.X, coords.Y];
                    });

                const areaGraphic = createPolygon(points, fillSymbol, { id: area.id });
                areaGraphic.name = area.description;
                return areaGraphic;
            }

            function createPolygon(points, fillSymbol, additionalAttributes) {
               
                const pathPoints = points
                    .map((point) => new Point(point[0], point[1], new SpatialReference({ wkid: mapUtility.getCurrentWkid() })));
                                    
                const polygon = new Polygon(new SpatialReference({ wkid: mapUtility.getCurrentWkid() }));
                polygon.addRing(pathPoints);                

                const attributes = {
                    type: 'area',
                    ...additionalAttributes,
                };

                let graphic = new Graphic(polygon, fillSymbol, attributes);               

                return graphic;
            }

            function isInPolynom(point, polygon) {
                var x = point.x, y = point.y;

                var inside = false;
                for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
                    var xi = polygon[i].x, yi = polygon[i].y;
                    var xj = polygon[j].x, yj = polygon[j].y;

                    var intersect = ((yi > y) !== (yj > y))
                        && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
                    if (intersect) inside = !inside;
                }

                return inside;
            }

            function createAreaTextGraphic(graphic) {
                var centroid = graphic.geometry.centroid;

                //draw text
                var font = new Font("12px", Font.STYLE_NORMAL, Font.VARIANT_NORMAL, Font.WEIGHT_BOLDER);
                var text = new TextSymbol(graphic.name, font, new Color([0, 0, 0]));

                //fix centroid sign
                var firstPoint = graphic.geometry.rings[0][0];
                var x = (Math.sign(firstPoint[0]) === Math.sign(centroid.x)) ? centroid.x : -centroid.x;
                var y = (Math.sign(firstPoint[1]) === Math.sign(centroid.y)) ? centroid.y : -centroid.y;

                var point = new Point(x, y);

                return new Graphic({
                    geometry: point,
                    symbol: text,
                    attributes: { type: 'text' },
                });
            }

            function getAreaHitResults(point, graphics) {
                return graphics.filter((graphic) => {
                    if (!isValidArea(graphic)) return false;

                    const polynom = graphic.geometry.rings[0]
                        .map((r) => ({ x: r[0], y: r[1] }));

                    return isInPolynom(point, polynom);
                });
            }

            readyDeferred.resolve(areaLayerFactory);
        });

        return areaLayerFactory;
    }
})();
