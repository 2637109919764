(function() {
    'use strict';

    angular
        .module('UndergroundWebApp').factory('routeGuard', routeGuard);

        routeGuard.$inject = ['$rootScope', '$state', 'localStorageService'];

    function routeGuard($rootScope, $state, localStorageService) {
        const service = {
            init: init
        };

        return service;

        function init() {
            $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
                const requiredModule = toState.data && toState.data.requiredModule;

                if (requiredModule) {                    
                    const authData = localStorageService.get('authenticationData');
                    const userModules = authData && authData.modules;

                    if (!userModules || userModules.indexOf(requiredModule) === -1) {
                        // If the user does not have the required module, prevent the state transition and redirect to the main state.
                        event.preventDefault();
                        $state.go('main');
                    }
                }
            });
        }
    }
})();
