(function () {

    angular.module('UndergroundWebApp').factory('userService', userService);

    userService.$inject = [
        '$q',
        '$http'
    ];

    function userService(
        $q,
        $http
    ) {
        return {
            getUsers: getUsers,
            addUser: addUser,
            updateUser: updateUser,
            getUserByName: getUserByName,
            changeLanguage: changeLanguage
        };

        function getUsers() {
            var deferred = $q.defer();
    
            $http.get('api/user').then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getUserByName(username) {
            var deferred = $q.defer();
    
            $http.get('api/user/getbyname?username=' + username).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        } 

        function addUser(user) {
            var deferred = $q.defer();
            $http.post('api/user/', user).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        }

        function updateUser(user) {
            var deferred = $q.defer();
            $http.put('api/user/', user).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        }

        function changeLanguage(language) {
            const deferred = $q.defer();
            $http.put('api/user/change-language', language)
            .then(function (result) {
                if (result?.data) {
                    deferred.resolve(result.data);
                }
                else {
                    throw new Error('Error changing language');
                }
            })
            .catch(function (err) {
                console.log(err)
                deferred.reject(err);
            });
            return deferred.promise;
        }
    }
}());
