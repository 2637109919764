angular.module('UndergroundWebApp').directive('secondaryMapLayersPanel', ['$rootScope', 'mapService', '$translate', function ($rootScope, mapService, $translate) {
    'use strict';

    return {
        restrict: 'AE',
        replace: true,
        templateUrl: 'app/main/directives/secondary-map-layers-panel.html',
        link: function (scope, element, attrs) {
            $rootScope.$on('layerVisibilityChanged', (_scope, visibilityList) => {
                Object.entries(visibilityList).forEach(([layerName, visible]) => {
                    const button = element.find(`.dropdown-item[name='${layerName}']`);
                    if (visible) {
                        button.addClass('active');
                    } else {
                        button.removeClass('active');
                    }
                });
            });

            scope.toggleSecondaryLayer = function (event) {
                event.stopPropagation();
                const layerName = event.target.name ?? event.target.id;
                if (layerName === 'fillDateLayer') {
                    scope.disableLayersVisibility('fillDateLayer')
                }
                else if (layerName === 'batteryStatusLayer') {
                    scope.disableLayersVisibility('batteryStatusLayer')
                }
                else if (layerName === 'containerPositionLayer') {
                    scope.disableLayersVisibility('containerPositionLayer')
                }
                mapService.toggleLayerVisibility(layerName);
            };

            scope.disableLayersVisibility = function (exceptLayerName) {
                const allLayers = ['fillDateLayer', 'batteryStatusLayer', 'containerPositionLayer'];
                allLayers.forEach(layerName => {
                    if (layerName !== exceptLayerName) {
                        mapService.disableLayerVisibility(layerName);
                    }
                });
            };

            scope.showPanelPointer = function(event, tooltipText) {
                if(event.target.nodeName === 'BUTTON') {
                    const text = tooltipText;
                    const buttonElement = event.target ;
                    const rect = buttonElement.getBoundingClientRect();
                    const left = rect.left - 400;
                    const top = rect.top + window.scrollY + 1;
                
                    $rootScope.$broadcast('showPanelPointer', {text: text, left: left, top: top});
                }
            };
            
            scope.hidePanelPointer = function() {
                $rootScope.$broadcast('hidePanelPointer');
            };

            scope.tooltipTexts = {
                fillDateLayer: $translate.instant('SHARED_POPUPS_SECONDARY_LAYERS_PANEL_EMPTYING'),
                batteryStatusLayer: $translate.instant('SHARED_POPUPS_SECONDARY_LAYERS_PANEL_BATTERY'),
                containerPositionLayer: $translate.instant('SHARED_POPUPS_SECONDARY_LAYERS_PANEL_POSITION')
            };            
        }
    };
}]);
