(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('mapUtility', mapUtility);

    mapUtility.$inject = ['$rootScope', 'availableLanguages', 'mapSettings'];

    var utm33N = '+proj=utm +zone=33 +ellps=WGS84 +datum=WGS84 +units=m +no_defs',
        wgs84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

    function mapUtility($rootScope, availableLanguages, mapSettings) {
        var service = {
            convertToUTM33N: convertToUTM33N,
            projectCoordinates: projectCoordinates,
            convertToWGS84: convertToWGS84,
            convertToWGS84IfNecessary: convertToWGS84IfNecessary,
            getCenterFromDegrees: getCenterFromDegrees,
            distanceToLineSegment: distanceToLineSegment,
            getBaseMapLayerUrl: getBaseMapLayerUrl,
            getFlyFotoLayerUrl: getFlyFotoLayerUrl,
            getMapSettings: getMapSettings,
            getCurrentProjection: getCurrentProjection,
            getCurrentWkid: getCurrentWkid,
            isInArea: isInArea
        };

        return service;

        function convertToUTM33N(lat, lng) {
            var projectedCoords = proj4(utm33N, [lng, lat]);
            return {
                X: projectedCoords[0],
                Y: projectedCoords[1]
            };
        }

        function projectCoordinates(lng, lat) {
            if (getCurrentProjection() == "UTM33N") {
                return convertToUTM33N(lat, lng);
            }

            return { X: lng, Y: lat }
        }

        function convertToWGS84(x, y) {
            var projectedCoords = proj4(utm33N, wgs84, [x, y]);

            return {
                lat: projectedCoords[1],
                lng: projectedCoords[0]
            }
        }

        function convertToWGS84IfNecessary(x, y) {
            if (getCurrentProjection() == "WGS84") {
                return { lng: x, lat: y };
            }

            return convertToWGS84(x, y);
        }

        function getMapSettings() {
            let selectedLanguageId = $rootScope.getCurrentLanguageId();
            let selectedLanguage = availableLanguages.find(x => x.id == selectedLanguageId);
            let mapSetting = mapSettings.find(x => x.mapType == selectedLanguage.mapType);
            return mapSetting;
        };

        function getBaseMapLayerUrl() {
            return getMapSettings().streetMapUrl;
        }

        function getFlyFotoLayerUrl() {
            return getMapSettings().satelliteUrl;
        }

        function getCurrentProjection() {
            return getMapSettings().projection;
        }

        function getCurrentWkid() {
            return getMapSettings().wkid;
        }

        /**
        * Get a center latitude,longitude from an array of like geopoints
        *
        * @param array data 2 dimensional array of latitudes and longitudes
        * For Example:
        * $data = array
        * (
        *   0 = > array(45.849382, 76.322333),
        *   1 = > array(45.843543, 75.324143),
        *   2 = > array(45.765744, 76.543223),
        *   3 = > array(45.784234, 74.542335)
        * );
        */
        function getCenterFromDegrees(data) {
            if (!(data.length > 0)) {
                return false;
            }

            var num_coords = data.length;

            var X = 0.0;
            var Y = 0.0;
            var Z = 0.0;

            for (var i = 0; i < data.length; i++) {
                var lat = data[i][0] * Math.PI / 180;
                var lon = data[i][1] * Math.PI / 180;

                var a = Math.cos(lat) * Math.cos(lon);
                var b = Math.cos(lat) * Math.sin(lon);
                var c = Math.sin(lat);

                X += a;
                Y += b;
                Z += c;
            }

            X /= num_coords;
            Y /= num_coords;
            Z /= num_coords;

            var lon = Math.atan2(Y, X);
            var hyp = Math.sqrt(X * X + Y * Y);
            var lat = Math.atan2(Z, hyp);

            var newX = (lat * 180 / Math.PI);
            var newY = (lon * 180 / Math.PI);

            return new Array(newX, newY);
        }

        function distanceToLineSegment(lx1, ly1, lx2, ly2, px, py) {
            return Math.sqrt(distanceSquaredToLineSegment(lx1, ly1, lx2, ly2, px, py));
        }

        function distanceSquaredToLineSegment(lx1, ly1, lx2, ly2, px, py) {
            var ldx = lx2 - lx1,
                ldy = ly2 - ly1,
                lineLengthSquared = ldx * ldx + ldy * ldy;
            return distanceSquaredToLineSegment2(lx1, ly1, ldx, ldy, lineLengthSquared, px, py);
        }

        function distanceSquaredToLineSegment2(lx1, ly1, ldx, ldy, lineLengthSquared, px, py) {
            var t; // t===0 at line pt 1 and t ===1 at line pt 2
            if (!lineLengthSquared) {
                // 0-length line segment. Any t will return same result
                t = 0;
            }
            else {
                t = ((px - lx1) * ldx + (py - ly1) * ldy) / lineLengthSquared;

                if (t < 0)
                    t = 0;
                else if (t > 1)
                    t = 1;
            }

            var lx = lx1 + t * ldx,
                ly = ly1 + t * ldy,
                dx = px - lx,
                dy = py - ly;
            return dx * dx + dy * dy;
        }

        function isInArea(area, objectWithCoords) {
            if (!area || !objectWithCoords || !objectWithCoords.latitude && !objectWithCoords.longitude) {
                return false;
            }

            let inside = false;
            let x = objectWithCoords.longitude, y = objectWithCoords.latitude;
            for (let i = 0, j = area.points.length - 1; i < area.points.length; j = i++) {
                let xi = area.points[i].longitude, yi = area.points[i].latitude;
                let xj = area.points[j].longitude, yj = area.points[j].latitude;

                let intersect = ((yi > y) !== (yj > y))
                    && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);

                if (intersect) inside = !inside;
            }

            return inside;
        }
    }
})();
