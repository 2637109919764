(function(){
    'use strict';

    angular.module('UndergroundWebApp').factory('modulesService',modulesService)

    modulesService.$inject =['$q', 'encapsuledHttpService'];
    function modulesService($q, encapsuledHttpService){
        const service = {
            getModules: getModules
        };

        return service;

        function getModules(){
            const deferred = $q.defer();
            
            encapsuledHttpService.getRequest('api/modules', deferred);   

            return deferred.promise;
        }        
    }
})();
